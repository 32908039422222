<script setup lang="ts">
import { openInNewTab, Validators } from "@/utils";
import { onMounted } from "vue";
import { useFormBuilder, alert } from "@/services";
import { localize } from "@/utils";
import router from "@/router";
import HomeHeader from "@/views/home/HomeHeader.vue";
import HomeFooter from "@/views/home/HomeFooter.vue";
import { Authenticator } from "@/libs/R2ServiceClient/service/Auth";
import { graphql } from "@/libs/R2ServiceClient/service/User";
import { useClientWrapperStore, useMemberStore } from "@/store";
import { LoginAccountStatus, Member } from "@/models";

const { setMember } = useMemberStore();

const { controls, errors, group, touch, validate, valid, invalid } = useFormBuilder();
group({
  email: { touched: false, value: "", validators: [Validators.required, Validators.email] },
  password: {
    touched: false,
    isShow: false,
    value: "",
    validators: [Validators.required, Validators.minLength(8), Validators.password],
  },
});

onMounted(init);

/**
 * 초기화
 */
function init() {
  validate();
}
/**
 * 비밀번호 보이기 형식 토글
 * @param key
 */
function togglePassword(key: string) {
  controls[key].isShow = !controls[key].isShow;
}
/**
 * 로그인 처리
 */
async function done() {
  // console.log(errors, valid());
  if (invalid()) return;

  const result = await Authenticator.login(controls.email.value, controls.password.value);
  // 로그인 성공시
  if (result) {
    // 사용자 정보 가져오기
    const LoginAccount = graphql(`
      query LoginAccount {
        loginAccount {
          status
          member {
            office {
              id
              name
              phoneNumber {
                number
                countryCode
              }
              address {
                postalCode
                address1
                address2
                city
                state
              }
              emailAddress
            }
            id
            loginId
            name {
              firstName
              lastName
              fullName
            }
            roles
            sex
            country {
              code
              name
            }
            address {
              postalCode
              address1
              address2
              city
              state
            }
            emailAddress
            phoneNumber {
              number
              countryCode
              fullNumber
            }
            birthDate
            registeredAt
            withdrawnAt
            status
          }
        }
      }
    `);
    const response = await useClientWrapperStore().user.typedQuery(LoginAccount);
    if (response.isSuccess) {
      // console.log("LoginAccount - successCallback: ", response.data);
      const result = response.data?.loginAccount;
      // 사용자 정보 설정
      setMember(result?.member as Member);
      //playground를 새탭으로 열기 /NOTE" 당분간 막아두도록 한다
      //openInNewTab("/playground");
      // 휴면 계정 검사
      if (result?.status == LoginAccountStatus.DORMANT) {
        // 휴면 계정 해제 페이지로 이동
        router.push("/home/dormant");
        return;
      }
    }
    // returnUrl로 이동. returnUrl이 없으면 /home/about으로 이동
    if (router.currentRoute.value.query.returnUrl) {
      router.push(router.currentRoute.value.query.returnUrl as string);
    } else {
      router.push("/home/about");
    }
  } else {
    alert(localize('이메일 또는 비밀번호가 일치하지 않습니다.'));
  }
}
/**
 * 로그인 계정 사용자 정보 설정
 */
async function setLoginAccount(): Promise<void> {}
</script>

<template>
  <div id="wrap" class="r2playground">
    <home-header />

    <main id="ct">
      <div class="container support">
        <div class="support-wrap">
          <ul class="nav nav-tabs support-nav">
            <li class="nav-item">
              <router-link to="" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="" class="nav-link active">Log in</router-link>
            </li>
          </ul>
          <div class="tit-head">
            <h1>Log in</h1>
            <img src="@/assets/home/img/illust-login.png" alt="" />
          </div>
        </div>
        <div class="join-box login-box">
          <div class="step-wrap">
            <img src="@/assets/home/img/logo.svg" alt="" class="" />
          </div>
          <form @submit.prevent="done()" class="join-form login-form">
            <!-- 이메일 입력-->
            <div class="form-group">
              <label class="form-label" for="email">{{ localize("이메일") }}</label>
              <div class="input-group">
                <input
                  type="email"
                  id="email"
                  class="form-control curved"
                  :class="{ 'is-invalid': controls.email.touched && !valid('email') }"
                  v-model="controls.email.value"
                  @focusout="touch('email')"
                  :placeholder="localize('아이디로 사용할 이메일 주소 입력')"
                />
                <!--    이메일 에러 메시지-->
                <div v-show="controls.email.touched && errors?.email?.required" class="invalid-feedback">
                  * {{ localize("이메일을 입력하세요.") }}
                </div>
                <div v-show="controls.email.touched && errors?.email?.email" class="invalid-feedback">
                  * {{ localize("올바른 이메일을 입력하세요.") }}
                </div>
              </div>
            </div>
            <!-- //이메일 입력-->

            <!-- 비밀번호 입력-->
            <div class="form-group">
              <label class="form-label" for="pw">{{ localize("비밀번호") }}</label>
              <div class="input-group">
                <input
                  id="pw"
                  class="form-control curved"
                  :class="{
                    'is-invalid': controls.password.touched && !valid('password'),
                    active: controls.password.isShow,
                  }"
                  v-model="controls.password.value"
                  @focusout="touch('password')"
                  @keyup.enter="done"
                  :type="controls.password.isShow ? 'text' : 'password'"
                  :placeholder="localize('영문 대소문자, 숫자, 특수문자의 조합 10자리 이상 입력')"
                />
                <div class="visibility">
                  <i
                    class="icon"
                    @click="togglePassword('password')"
                    :class="controls.password.isShow ? 'icon-eye-open' : 'icon-eye-close'"
                  ></i>
                </div>
                <!--    비밀번호 에러 메시지-->
                <div v-show="controls.password.touched && errors?.password?.required" class="invalid-feedback">
                  * {{ localize("비밀번호를 입력하세요.") }}
                </div>
                <div
                  v-show="controls.password.touched && (errors?.password?.minLength || errors?.password?.password)"
                  class="invalid-feedback"
                >
                  * {{ localize("영문 대소문자, 숫자, 특수문자 중 2종의 조합으로 8자리 이상 입력하세요.") }}
                </div>
              </div>
            </div>
            <!-- //비밀번호 입력-->
          </form>

          <!-- 로그인-->
          <div class="btn-wrap">
            <button class="btn btn-primary center w-100" @click="done" :class="{ disabled: invalid() }">
              {{ localize("로그인") }}
            </button>
          </div>
          <!-- //로그인-->

          <div class="login-menu">
            <router-link to="/home/join" class="join">{{ localize("회원가입") }}</router-link>
            <router-link to="/home/password/find" class="schPass">{{ localize("비밀번호 찾기") }}</router-link>
          </div>
        </div>
      </div>
      <!-- //container -->
    </main>

    <home-footer />
  </div>
</template>

<style scoped></style>
