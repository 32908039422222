<script setup lang="ts">
import PlaygroundLayout from "@/views/playground/PlaygroundLayout.vue";
import { IMenuItem } from "@/models";

const menuItems: IMenuItem[] = [
  { name: "Dashboard", icon: "icon-dash", link: "dashboard", open: false },
  {
    name: "Order",
    icon: "icon-order",
    link: "order",
    open: false,
    children: [
      { name: "Order List", icon: "icon-order-1", link: "list" },
      { name: "Order Request", icon: "icon-order-2", link: "request" },
    ],
  },
  {
    name: "Partner",
    icon: "icon-partner",
    link: "partner",
    open: false,
    children: [
      { name: "Planner", icon: "icon-partner-1", link: "planner" },
      { name: "R2 Center", icon: "icon-partner-2", link: "r2center" },
    ],
  },
  {
    name: "My Cloud",
    icon: "icon-cloud",
    link: "cloud",
    open: false,
    children: [
      { name: "My Cloud", icon: "icon-cloud-1", link: "my-cloud" },
      { name: "Shared Cloud", icon: "icon-cloud-2", link: "shared-cloud" },
    ],
  },
  {
    name: "Player Space",
    icon: "icon-player",
    link: "player-space",
    open: false,
    children: [
      { name: "Profile", icon: "icon-player-1", link: "profile" },
      { name: "Messaging", icon: "icon-player-2", link: "messaging" },
      { name: "My R2 Point", icon: "icon-player-3", link: "point" },
    ],
  },
  {
    name: "Support",
    icon: "icon-support",
    link: "support",
    open: false,
    children: [
      { name: "FAQ", icon: "icon-support-3", link: "faq" },
      { name: "Trouble Shooting", icon: "icon-support-2", link: "trouble-shooting" },
      { name: "Membership", icon: "icon-support-1", link: "membership" },
    ],
  },
];
</script>

<template>
  <playground-layout :menu-items="menuItems"></playground-layout>
</template>

<style scoped></style>
