<script setup lang="ts">
import TabMenu from "@/components/TabMenu.vue";
import {EditMode, IBaseMenuItem} from "@/models";
import {reactive} from "vue";

const props = defineProps({
  menuItems: {type: Array as () => IBaseMenuItem[], default: []},
});
const state = reactive({
  mode: EditMode.Edit,
  account: null,
});
const form = reactive({
  nation: '',
  bank: '',
  bankBranch: '',
  accountNumber: '',
  accountHolder: '',
  swiftBic: '',
  routingNumber: '',
  recipientAddress: '',
  attachments: [],
});
function submitForm(){

}
function resetForm(){

}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <nav class="nav nav-files">
      <tab-menu :items="menuItems" :li="false" />
    </nav>
    <!-- [D] 선택 된 순서 data-file="숫자" -->
    <div class="file-content" data-file="3">
      <strong class="file-title">정산 설정</strong>
      <div class="content">
        <div class="content-hd flex-column">
          <h3 class="fs-2">정산 계좌</h3>
          <p>정산금을 지급받을 은행 계정 정보를 확인하실 수 있습니다.</p>
        </div>
        <div class="fieldset d-block">
          <div class="box-shadow">
            <div class="content-hd mb-3">
              <h4 class="fs-3 mr-3">Account Information</h4>

              <!-- 관리자 확인 중-->
              <div class="flex-center"><span class="badge bg-ylw mr-2">관리자 확인 중</span> <p class="info-txt">관리자 확인 중에 있으며, 관리자 승인이 완료되는 이후 일자에 정산 대금이 지급됩니다.</p></div>
              <!-- //관리자 확인 중-->

            </div>

            <div v-if="state.mode == EditMode.View">
              <!-- 등록된 계좌 없음-->
              <div v-if="!state.account" class="empty">
                <p class="text-center">등록된 계좌가 없습니다.<br />정산받을 계좌가 설정되어야만 작업 대금을 받으실 수 있습니다.</p>
                <div class="btn-wrap">
                  <a href="" class="btn btn-lg">Register</a>
                </div>
              </div>
              <!-- //등록된 계좌 없음-->

              <!-- 등록된 계좌 있음-->
              <div v-else>
                <table class="tb tb-hor">
                  <tbody>
                  <tr>
                    <th>Nation</th>
                    <td>Germany</td>
                    <th>Bank</th>
                    <td>Deutsche Bank AG</td>
                    <th>Bank Brunch</th>
                    <td>Taunusanlage</td>
                  </tr>
                  <tr>
                    <th>Account number</th>
                    <td>12312123456</td>
                    <th>Account holder</th>
                    <td>Franz Anton Beckenbauer</td>
                    <th>SWIFT/BIC</th>
                    <td>123456789</td>
                  </tr>
                  <tr>
                    <th>Routing Number</th>
                    <td>00000000</td>
                    <th>Recipient Address</th>
                    <td colspan="3">Taunusanlage 12, 60325, Frankfruit am Main, German</td>
                  </tr>
                  <tr>
                    <th>Copy of account</th>
                    <td colspan="5"><button type="button" class="btn btn-sm btn-outline text-nowrap">계좌 사본 보기</button></td>
                  </tr>
                  </tbody>
                </table>
                <div class="btn-wrap">
                  <a href="" class="btn btn-lg">Modify</a>
                </div>
              </div>
              <!-- //등록된 계좌 있음-->

            </div>

            <!-- 계좌 정보 저장-->
            <form v-else @submit.prevent="submitForm" @reset.prevent="resetForm">
              <div class="account-info">
                <div class="fieldset d-block">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-label">Nation</div>
                      <select class="form-control form-select">
                        <option value="">대한민국</option>
                      </select>
                    </div>
                    <div class="col-4">
                      <div class="form-label">Bank</div>
                      <input type="text" class="form-control">
                    </div>
                    <div class="col-4">
                      <div class="form-label">Bank Brunck</div>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="fieldset d-block">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-label">Account number</div>
                      <input type="number" class="form-control">
                    </div>
                    <div class="col-4">
                      <div class="form-label">Account holder</div>
                      <input type="text" class="form-control">
                    </div>
                    <div class="col-4">
                      <div class="form-label">SWIFT/BIC</div>
                      <input type="number" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="fieldset d-block">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-label">Routing Number</div>
                      <input type="number" class="form-control">
                    </div>
                    <div class="col">
                      <div class="form-label">Recipient Address</div>
                      <input type="text" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="fieldset d-block">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">Copy of account</div>
                      <div class="thumbnail-file mb-2 d-flex">
                        <label class="thumbnail-file-label small mr-2"><input type="file" class="sr-only "></label>
                        <ul class="file-item-wrap">
                          <li>
                            <div class="file-item">
                              <i class="icon icon-filetype"></i>
                              <a href="" class="file-item-name" download>첨부파일_01.docx(0.1mb)</a>
                              <i class="icon icon-file-delete" role="button"></i>
                            </div>
                          </li>
                          <li>
                            <div class="file-item">
                              <i class="icon icon-filetype"></i>
                              <a href="" class="file-item-name" download>첨부파일_01.docx(0.1mb)</a>
                              <i class="icon icon-file-delete" role="button"></i>
                            </div>
                          </li>
                          <li>
                            <div class="file-item">
                              <i class="icon icon-filetype"></i>
                              <a href="" class="file-item-name" download>첨부파일_01.docx(0.1mb)</a>
                              <i class="icon icon-file-delete" role="button"></i>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <p class="fs-sm text-muted">
                        20MB 이하의 PDF / JPG / PNG 파일을 업로드해주세요.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="btn-wrap">
                <button type="reset" class="btn btn-lg btn-outline">취소</button>
                <button type="submit" class="btn btn-lg">저장하기</button>
              </div>
            </form>
            <!-- //계좌 정보 저장-->

          </div>
        </div>
        <div class="fieldset wide d-block">
          <div class="content-hd flex-column">
            <h3 class="fs-2">정산 정보</h3>
            <p>정산과 관련된 정책 확인을 확인해 보세요.</p>
          </div>
          <div class="row row-lg">
            <section class="col-6">
              <div class="sect-hd">
                <h4 class="fs-3">내 정산 정보</h4>
              </div>
              <div class="row asset-info">
                <div class="col-6">
                  <div class="card">
                    <strong class="d-block">현재까지 정산 받은 금액</strong>
                    <span><span class="num">586</span>P</span>
                    <a href="" class="fs-sm">정산 내역 바로가기 <i class="icon icon-arrow orange"></i></a>
                  </div>
                </div>
                <div class="col-6">
                  <div class="card">
                    <strong class="d-block">현재까지 정산 받은 금액</strong>
                    <span><span class="num">586</span>P</span>
                    <a href="" class="fs-sm">정산 내역 바로가기 <i class="icon icon-arrow orange"></i></a>
                  </div>
                </div>
              </div>
            </section>
            <section class="col-6">
              <div class="sect-hd">
                <h4 class="fs-3">정산 정책</h4>
              </div>
              <div class="asset-info policy">
                <div class="card col num">
                  1$ = 1Point
                </div>
              </div>
              <ul class="list-dot mt-3">
                <li>모든 포인트는 달러로만 환급됩니다.</li>
                <li>정산은 메가젠의 정책에 따라, 주문 완료 후 3~5일 사이에 일어나며, [직접 결제(송금)] 등의 주문은 정산되지 않습니다.</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>