<script setup lang="ts">
import { PartnerConfig } from "@/models/partner";
import { provide, reactive } from "vue";

// localize('My R2센터')
const state = reactive<PartnerConfig>({
  role: "r2center",
  name: "R2 Center",
  myName: "My R2센터",
});

provide("partner-config", state);
</script>

<template>
  <h1 class="hd-title">R2 Center</h1>
  <router-view></router-view>
</template>

<style scoped></style>
