<script setup lang="ts">
import { useJoinStore, useLocalizationStore, useNationStore } from "@/store";
import { blockPage, useFormBuilder } from "@/services";
import { localize } from "@/utils";
import { Validators } from "@/utils";
import { vMaska } from "maska";
import { computed, onMounted } from "vue";
import router from "@/router";
import SearchSelect from "@/components/SearchSelect.vue";
import { IOptionItem, Sex } from "@/models";
import BirthDate from "@/components/BirthDate.vue";
import { onBeforeRouteLeave } from "vue-router";

const { getLocale } = useLocalizationStore();
const { getNationalities, getPhone } = useNationStore();

// console.log(getLocale());

/**
 * 필수 입력 자료
 */
const { member } = useJoinStore();
// console.log("member: ", member);
/**
 * 전화번호 국가 코드 목록
 */
const countries = getNationalities();

const { controls, errors, group, touch, validate, valid, invalid } = useFormBuilder();
group({
  password: {
    touched: false,
    isShow: false,
    value: member.password,
    validators: [Validators.required, Validators.minLength(8), Validators.password],
    dependencies: ["confirm"],
  },
  confirm: {
    touched: false,
    isShow: false,
    value: member.password,
    validators: [Validators.required, Validators.match(controls, "password")],
  },
  firstName: { touched: false, value: member.name.firstName, validators: [Validators.required] },
  lastName: { touched: false, value: member.name.lastName, validators: [Validators.required] },
  year: { touched: false, value: "", validators: [Validators.required] },
  month: { touched: false, value: "", validators: [Validators.required] },
  day: { touched: false, value: "", validators: [Validators.required] },
  sex: { value: member.sex },
  nationality: { value: countries?.find((e: IOptionItem) => e.value == member.country.code) },
  mobile: { touched: false, value: member.phoneNumber.number, validators: [Validators.required] },
});
/**
 * 선택한 전화번호 국가 코드
 */
const phoneCode = computed(() => getPhone(controls.nationality.value?.value));

onMounted(init);
onBeforeRouteLeave(blockPage("/home/join/genuine"));

/**
 * 초기화
 */
async function init() {
  validate();
}

/**
 * 비밀번호 보이기 형식 토글
 * @param key
 */
function togglePassword(key: string) {
  controls[key].isShow = !controls[key].isShow;
}
/**
 * 국적 변경
 * @param item
 */
function changeNationality(item: IOptionItem) {
  controls.nationality.value = item;
}
/**
 * 완료 핸들러
 */
function done() {
  validate(true);
  // const countriesMask = '+###'
  // const birthDate = new Mask({mask: birthDateMask});
  // const country = new Mask({mask: countriesMask});
  // console.log(controls.birthDate.value, ':', birthDate.unmasked(controls.birthDate.value));
  // console.log(countries.find(p => p.key == controls.nationality.value).value, ':', country.unmasked(countries.find(p => p.key == controls.nationality.value).value))
  if (valid()) {
    member.password = controls.password.value;
    member.name.firstName = controls.firstName.value;
    member.name.lastName = controls.lastName.value;
    // 생년월일을 'YY-MM-dd' 형식으로 변환하여 member.birthDate에 저장
    member.birthDate = `${controls.year.value}-${controls.month.value}-${controls.day.value}`;
    member.sex = controls.sex.value;
    member.country.code = controls.nationality.value.value;
    member.country.name = controls.nationality.value.label;
    member.phoneNumber.countryCode = phoneCode.value;
    member.phoneNumber.number = controls.mobile.value;
    // console.log("done: ", member);
    router.push("/home/join/optional");
  }
}

function beforeRouteLeave() {
  throw new Error("Function not implemented.");
}
</script>

<template>
  <div class="join-box">
    <div class="progress-line">
      <div class="line" style="width: 60%"></div>
    </div>
    <div class="step-wrap">
      <b>STEP 3</b>
      <h2>{{ localize("필수 정보 입력") }}</h2>
    </div>
    <div class="email">{{ member.loginId }}</div>
    <form @submit.prevent="done" class="join-form">
      <!-- 비밀번호 입력-->
      <div class="form-group">
        <label class="form-label" for="pw">{{ localize("비밀번호") }}</label>
        <div class="input-group">
          <input
            id="pw"
            class="form-control curved"
            :class="{
              'is-invalid': controls.password.touched && !valid('password'),
              'is-valid': valid('password'),
              active: controls.password.isShow,
            }"
            v-model="controls.password.value"
            @focusout="touch('password')"
            :type="controls.password.isShow ? 'text' : 'password'"
            :placeholder="localize('영문 대소문자, 숫자, 특수문자의 조합 10자리 이상 입력')"
          />
          <div class="visibility">
            <i
              class="icon"
              @click="togglePassword('password')"
              :class="controls.password.isShow ? 'icon-eye-open' : 'icon-eye-close'"
            ></i>
          </div>

          <!--    비밀번호 에러 메시지-->
          <div v-show="controls.password.touched && errors?.password?.required" class="invalid-feedback">
            * {{ localize("비밀번호를 입력하세요.") }}
          </div>
          <div
            v-show="controls.password.touched && (errors?.password?.minLength || errors?.password?.password)"
            class="invalid-feedback"
          >
            * {{ localize("영문 대소문자, 숫자, 특수문자 중 2종의 조합으로 8자리 이상 입력하세요.") }}
          </div>
          <!--    사용 가능한 비밀번호-->
          <div v-show="valid('password')" class="valid-feedback">* {{ localize("사용 가능한 비밀번호입니다.") }}</div>
        </div>
      </div>
      <!-- //비밀번호 입력-->

      <!-- 확인을 위한 비밀번호 입력-->
      <div class="form-group">
        <label class="form-label" for="pw-chk">{{ localize("비밀번호 확인") }}</label>
        <div class="input-group">
          <input
            id="pw-chk"
            class="form-control curved"
            :class="{
              'is-invalid': controls.confirm.touched && !valid('confirm'),
              'is-valid': valid('confirm'),
              active: controls.confirm.isShow,
            }"
            v-model="controls.confirm.value"
            @focusout="touch('confirm')"
            :type="controls.confirm.isShow ? 'text' : 'password'"
            :placeholder="localize('확인을 위한 비밀번호 재입력')"
          />
          <div class="visibility">
            <i
              class="icon"
              @click="togglePassword('confirm')"
              :class="controls.confirm.isShow ? 'icon-eye-open' : 'icon-eye-close'"
            ></i>
          </div>
          <!--    비밀번호 에러 메시지-->
          <div v-show="controls.confirm.touched && errors?.confirm?.required" class="invalid-feedback">
            * {{ localize("확인을 위한 비밀번호를 입력하세요.") }}
          </div>
          <div v-show="controls.confirm.touched && errors?.confirm?.match" class="invalid-feedback">
            * {{ localize("비밀번호와 일치하지 않습니다.") }}
          </div>
          <!--    사용 가능한 비밀번호-->
          <div v-show="valid('confirm')" class="valid-feedback">* {{ localize("비밀번호와 일치합니다.") }}</div>
        </div>
      </div>
      <!-- //확인을 위한 비밀번호 입력-->

      <div class="d-flex">
        <div class="form-group">
          <label class="form-label" for="first-name">{{ localize("이름2") }}</label>
          <div class="input-group">
            <input
              type="text"
              id="first-name"
              class="form-control curved"
              :class="{
                'is-invalid': controls.firstName.touched && invalid('firstName'),
                'is-valid': valid('firstName'),
              }"
              v-model="controls.firstName.value"
              @focusout="touch('firstName')"
              :placeholder="localize('이름2')"
            />

            <!--    이름 에러 메시지-->
            <div v-show="controls.firstName.touched && errors?.firstName?.required" class="invalid-feedback">
              * {{ localize("이름을 입력하세요.") }}
            </div>
            <!--    사용 가능한 이름-->
            <div v-show="valid('firstName')" class="valid-feedback">* {{ localize("올바른 이름") }}</div>
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="last-name">{{ localize("성") }}</label>
          <div class="input-group">
            <input
              type="text"
              id="last-name"
              class="form-control curved"
              :class="{
                'is-invalid': controls.lastName.touched && invalid('lastName'),
                'is-valid': valid('lastName'),
              }"
              v-model="controls.lastName.value"
              @focusout="touch('lastName')"
              :placeholder="localize('성')"
            />

            <!--    성 에러 메시지-->
            <div v-show="controls.lastName.touched && errors?.lastName?.required" class="invalid-feedback">
              * {{ localize("성을 입력하세요.") }}
            </div>
            <!--    사용 가능한 성-->
            <div v-show="valid('lastName')" class="valid-feedback">* {{ localize("올바른 성") }}</div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="pw-chk">{{ localize("생년월일") }}</label>
        <div class="input-group birth-form-group">            
          <birth-date
            :controls="controls"
            :touch="touch"
            :valid="valid"
            :invalid="invalid"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="sex-chk">{{ localize("성별") }}</label>
        <div class="radio-wrap">
          <input type="radio" id="male" :value="Sex.MALE" v-model="controls.sex.value" />
          <label for="male" class="radio-btn">{{ localize("남자") }}</label>
          <input type="radio" id="female" :value="Sex.FEMALE" v-model="controls.sex.value" />
          <label for="female" class="radio-btn">{{ localize("여자") }}</label>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="country">{{ localize("국적") }}</label>
        <div class="input-group">
          <search-select
            :items="countries"
            :selectedItem="controls.nationality.value"
            @change="changeNationality($event)"
          />
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="tel">{{ localize("휴대폰 번호") }}</label>
        <div class="input-group">
          <p class="country-code">+{{ phoneCode }}</p>
          <input
            type="tel"
            id="tel"
            class="form-control curved"
            :class="{
              'is-invalid': controls.mobile.touched && invalid('mobile'),
              'is-valid': valid('mobile'),
            }"
            v-model="controls.mobile.value"
            @focusout="touch('mobile')"
            v-maska
            data-maska="#"
            data-maska-tokens="#:[0-9]:repeated"
            :placeholder="localize('숫자만 입력')"
          />

          <!--    휴대폰번호 확인 에러 메시지-->
          <div v-show="controls.mobile.touched && errors?.mobile?.required" class="invalid-feedback">
            * {{ localize("휴대폰 번호를 입력하세요.") }}
          </div>
          <!--    사용 가능한 휴대폰번호-->
          <div v-show="valid('mobile')" class="valid-feedback">* {{ localize("올바른 휴대폰 번호 입니다.") }}</div>
        </div>
      </div>
    </form>
    <div class="btn-wrap">
      <a :class="{ disabled: invalid() }" @click="done" class="btn btn-primary center w-100">{{
        localize("다음으로")
      }}</a>
    </div>
  </div>
</template>

<style scoped>
/* 회원가입 비밀번호 확인 버튼 UI 수정 */
.join-form .is-valid~.valid-feedback {position: absolute; bottom: -30px; color: #28a745 !important;}

/* 생년월일 버튼 간격 수정 */
.birth-form-group { display: flex; gap: 15px;}
</style>
