<script setup lang="ts">
import DatePicker from "@/components/DatePicker.vue";
import { reactive } from "vue";

const props = defineProps({
  start: { type: String as () => string, default: "" },
  end: { type: String as () => string, default: "" },
});
const state = reactive({
  start: props.start,
  end: props.end,
});
const emit = defineEmits<{
  (eventType: "change", start: string, end: string): void;
}>();

function changeDate(target: string, date: string): void {
  if (target === "start") {
    state.start = date;
  } else {
    state.end = date;
  }
}
function done(): void {
  emit("change", state.start, state.end);
}
</script>

<template>
  <div v-bind="$attrs" class="flex-center mr-2">
    <date-picker class="form-control" :value="state.start" @change="changeDate('start', $event)" />
    <span class="px-1">-</span>
    <date-picker class="form-control" :value="state.end" @change="changeDate('end', $event)" />
  </div>
  <button @click="done" type="button" class="btn btn-sm btn-srch">
    <span class="text-nowrap">조회</span><i class="icon icon-srch"></i>
  </button>
</template>

<style scoped></style>
