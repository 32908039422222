import { RouteRecordRaw } from "vue-router";
import SalesTrend from "@/views/playground/planner/business-management/statistics/SalesTrend.vue";
import ConsumerTrends from "@/views/playground/planner/business-management/statistics/ConsumerTrends.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/statistics/sales`,
  },
  {
    path: "sales",
    component: SalesTrend,
  },
  {
    path: "consumer",
    component: ConsumerTrends,
  },
];

export default routes;
