<script setup lang="ts">
import { localize, getFileSize } from "@/utils";
import { useClientWrapperStore } from "@/store";
import { useRoute, useRouter } from "vue-router";
import { reactive } from "vue";
import { TroubleshootingProgressStatus } from "@/models";
import { useTroubleShootingStore } from "@/store";

const router = useRouter();
const { data } = useTroubleShootingStore();

request();

function request(): void {
  console.log("request");
  const route = useRoute();
  const variables = {
    id: route.params.id,
  };
  const query = `
    query GetTroubleShooting($id: ID) {
      troubleshooting(id: $id) {
        category {
          name
          description
        }
        id
        title
        content
        status
        registerDate
        attachements {
          id
          type
          fileName
          fileSize
        }
      }
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("successCallback", result.troubleshooting);
  //       for (const key in result.troubleshooting) {
  //         data[key] = result.troubleshooting[key];
  //       }
  //     },
  //   }
  // );
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <router-link :to="{ name: 'trouble-shooting-list' }" class="btn-back"
          ><i class="icon icon-back">{{ localize("뒤로") }}</i></router-link
        >
        <h2 class="fs-1">{{ localize("문의 상세") }}</h2>
        <p>{{ localize("트러블슈팅 등록일 기준 N일 이내에") }}<br />{{ localize("답변해드립니다.") }}</p>
        <img src="@/assets/playground/img/faq.png" alt="" />
      </div>
      <div class="grid-content">
        <div class="box box-shadow inquiry">
          <div class="fieldset d-block">
            <div class="row">
              <strong class="form-label col-auto">{{ localize("문의 유형") }}<i class="dot"></i></strong>
              <div class="col">
                {{ localize(data.category.name) }}
              </div>
              <strong class="form-label col-auto">{{ localize("연관 주문번호") }}<i class="dot"></i></strong>
              <div class="col">
                {{ data.id }}
              </div>
              <strong class="form-label col-auto">{{ localize("처리 상태") }}<i class="dot"></i></strong>
              <div class="col">
                <span class="badge bg-gray">{{ localize(data.status) }}</span>
                <span class="badge bg-grn">{{ localize("완료") }}</span>
              </div>
            </div>
          </div>
          <div class="fieldset">
            <strong class="form-label">{{ localize("문의 제목") }}<i class="dot"></i></strong>
            <div class="flex-fill">
              {{ data.title }}
            </div>
          </div>
          <div class="fieldset">
            <strong class="form-label">{{ localize("문의 내용") }}<i class="dot"></i></strong>
            <div class="flex-fill">
              {{ data.content }}
            </div>
          </div>
          <div class="fieldset">
            <strong class="form-label">{{ localize("첨부파일") }}<i class="dot"></i></strong>
            <div class="flex-fill">
              <ul class="file-item-wrap">
                <li v-for="file in data.attachments">
                  <div class="file-item">
                    <i class="icon icon-filetype"></i>
                    <a class="file-item-name" download>{{ file.fileName }}({{ getFileSize(file.fileSize) }})</a>
                    <i class="icon icon-file-delete" role="button"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="btn-wrap">
            <a @click="router.go(-1)" class="btn btn-outline btn-lg">돌아가기</a>
            <router-link
              class="btn btn-lg"
              :class="{ disabled: data.status > TroubleshootingProgressStatus.InProgress }"
              to="../save"
            >
              {{ localize("수정하기") }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
