/* 
    설명: 
    AuthStorage는 내부적으로 사용되는 AuthStoreData 정보의 저장소로 웹 브라우저의 window.sessionStorage 를 사용한다
    이는 R2ServiceClient 가 웹 브라우저에서 구동되는 SPA에서 사용될 것을 염두에 두고 만들어져있기 때문인데, 
    만약 웹 브라우저에서 구동되는 것이 아닌 Node.js 환경에서 구동된다면_window.sessionStorage 를 사용할 수 없게 된다
    이 경우 Storage 를 커스텀 구현한 LocalStorage 를 사용하도록 한다
*/
class MemoryStorage implements Storage {
  
    private _data: any = {}

    get length(): number {
        return Object.keys(this._data).length
    }

    key(index: number): string | null {
        return ``
    }

    public getItem(key: string): any {
        return this._data[key]
    }

    public setItem(key: string, value: any): void {
        return this._data[key] = value
    }

    public removeItem(key: string): void {
        this._data[key] = undefined
    }

    public clear() {
        this._data = {}
    }
}

class LocalStorage {
    private static _instance: LocalStorage

    private constructor(storage: Storage) {
        this._storage = storage
    }

    public static get instance(): LocalStorage {
        if(!LocalStorage._instance) {
            LocalStorage._instance = new LocalStorage(new MemoryStorage())
        }

        return LocalStorage._instance
    }

    private _storage: Storage

    get length(): number {
        return this._storage.length
    }

    key(index: number): string | null {
        return ``
    }

    public getItem(key: string): any {
        return this._storage.getItem(key)
    }

    public setItem(key: string, value: any): void {
        return this._storage.setItem(key, value)
    }

    public removeItem(key: string): void {
        this._storage.removeItem(key)
    }

    public clear() {
        this._storage.clear()
    }
}

/*
    설명:
    Storage 는 기본적으로 웹 브라우저의 window.sessionStorage 를 사용한다
    이는 R2ServiceClient 가 웹 브라우저에서 구동되는 SPA에서 사용될 것을 염두에 두고 만들어져있기 때문인데,
    만약 웹 브라우저에서 구동되는 것이 아닌 Node.js 환경에서 구동되는 경우 window.sessionStorage 를 사용할 수 없게 된다.
    이 경우 Storage 를 커스텀 구현한 LocalStorage 를 사용하도록 한다
*/
function getContextStorage(): Storage {
    return (typeof window !== 'undefined' && window.sessionStorage !== null)? window.sessionStorage : LocalStorage.instance
}

export { getContextStorage, LocalStorage }