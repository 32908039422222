import { reactive, watch } from "vue";

export type Controls = { [key: string]: { [key: string]: any } };
export type Errors = { [key: string]: { [key: string]: boolean } };

export function useFormBuilder() {
  /**
   * 컨트롤 그룹
   */
  const controls = reactive<Controls>({});
  /**
   * 오류 그룹
   */
  const errors = reactive<Errors>({});

  /**
   * 컨트롤 그룹 설정
   * @param config 컨트롤 그룹
   */
  function group(config: Controls) {
    // 컨트롤 그룹 초기화
    for (const key of Object.keys(controls)) delete controls[key];
    // 컨트롤 그룹 설정
    Object.assign(controls || {}, config);
    // 컨트롤 그룹 전체 유효성 검사 감시 설정
    for (const key of Object.keys(controls)) {
      const control = controls[key];
      watch(control, (newValue, oldValue) => {
        // console.log('key: ', key, ' / newValue: ', newValue);
        validateControl(key);
        // 의존성이 존재하면 의존성 컨트롤들도 유효성 검사
        if (control.hasOwnProperty("dependencies")) {
          for (const p of control.dependencies) validateControl(p);
        }
      });
    }
  }
  /**
   * 해당 컨트롤 터치 처리
   * @param key 컨트롤 키
   */
  function touch(key: string) {
    const control = controls[key];
    if (!control.touched) {
      controls[key].touched = true;
      validateControl(key);
    }
  }
  /**
   * 해당 컨트롤 유효성 검사
   * @param key 컨트롤 키
   */
  function validateControl(key: string) {
    // console.log('validateControl: ', key);
    const control = controls[key];
    // 해당 컨트롤의 존재하는 오류들 초기화
    if (errors.hasOwnProperty(key)) delete errors[key];
    // 해당 컨트롤의 유효성 검사기가 존재하면 실행
    if (control.hasOwnProperty("validators")) {
      /// 해당 컨트롤의 유효성 검사기를 순회하며 오류가 발생하면 오류 객체에 추가
      for (const validator of control.validators) {
        // console.log('control.value: ', control.value);
        const result = validator(control.value);
        if (result) {
          // if(!errors.hasOwnProperty(key)) errors[key] = {};
          errors[key] = Object.assign(errors[key] || {}, result);
          break;
        }
      }
    }
  }
  /**
   * 폼 유효성 검사
   * @param isSubmit 현재 동작이 submit인지 여부
   */
  function validate(isSubmit = false) {
    // 컨트롤 그룹 전체 검사
    for (const key of Object.keys(controls)) {
      // 제출 시 컨트롤 그룹 전체 터치 처리
      if (isSubmit) {
        const control = controls[key];
        if (control.hasOwnProperty("touched")) control.touched = true;
      }
      validateControl(key);
    }
  }
  /**
   * 컨트롤 오류 설정
   * @param key 컨트롤 키
   * @param error 에러 객체. ex) { required: true }
   */
  function setError(key: string, error: { [key: string]: boolean }) {
    errors[key] = Object.assign(errors[key] || {}, error);
  }
  /**
   * 유효성 검사 결과 반환
   * 키가 존재하면 해당 컨트롤의 유효성 검사 결과 반환
   * 키가 존재하지 않으면 전체 컨트롤의 유효성 검사 결과 반환
   * @param key 유효성 검사할 컨트롤 키
   * @returns
   */
  function valid(key = ""): boolean {
    if (key) {
      return !errors.hasOwnProperty(key) || Object.keys(errors[key]).length === 0;
    } else {
      return Object.keys(errors).length === 0;
    }
  }
  /**
   * 유효성 검사 결과 반대값 반환
   * @param key
   * @returns
   */
  function invalid(key = ""): boolean {
    return !valid(key);
  }

  return { controls, errors, group, touch, validate, valid, invalid, setError };
}
