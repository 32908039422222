import { RouteRecordRaw } from "vue-router";
import ChangePassword from "@/views/home/password/ChangePassword.vue";
import FindPassword from "@/views/home/password/FindPassword.vue";
import { requireAuth } from "@/services";

const routes: Array<RouteRecordRaw> = [
  {
    path: "change",
    name: "ChangePassword",
    component: ChangePassword,
    beforeEnter: requireAuth,
  },
  {
    path: "find",
    name: "FindPassword",
    component: FindPassword,
  },
];

export default routes;
