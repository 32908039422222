import {RouteRecordRaw} from "vue-router";
import qualifyingTestRoutes from "@/router/academy/certification/qualifying-test";
import QualifyingTest from "@/views/academy/certification/qualifying-test/QualifyingTest.vue";
import Courses from "@/views/academy/certification/courses/Courses.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "qualifying-test",
        name: "QualifyingTest",
        component: QualifyingTest,
        children: qualifyingTestRoutes,
    },
    {
        path: "courses",
        name: "Courses",
        component: Courses,
    },
];

export default routes;