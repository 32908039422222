<script setup lang="ts">
import { reactive } from "vue";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import { IBaseMenuItem } from "@/models";
import { localize } from "@/utils";

const route = useRoute();

const props = defineProps({
  items: { type: Array as () => IBaseMenuItem[], default: [] },
  li: { type: Boolean, default: true },
  depths: { type: Number, default: 0 },
});

const pathSegments = route.path.split("/").slice(1);
const state = reactive({
  current: props.depths === 0 ? route.path.split("/").slice().pop() : pathSegments[props.depths],
});

onBeforeRouteUpdate((to, from) => {
  const pathSegments = to.path.split("/").slice(1);
  state.current = props.depths === 0 ? to.path.split("/").slice().pop() : pathSegments[props.depths];
});

function getLink(item: IBaseMenuItem): string {
  const basePath = props.depths === 0 ? "" : `/${pathSegments.slice(0, props.depths).join("/")}/`;
  return `${basePath}${item.link}`;
}
</script>

<template>
  <!-- li -->
  <li v-if="li" v-for="item in items">
    <router-link :to="getLink(item)" class="nav-link" :class="{ active: state.current === item.link }">{{
      localize(item.name)
    }}</router-link>
  </li>
  <!-- //li -->

  <!-- not li -->
  <router-link
    v-else
    v-for="item in items"
    :to="getLink(item)"
    class="nav-link"
    :class="{ active: state.current === item.link }"
    >{{ localize(item.name) }}
  </router-link>
  <!-- //not li -->
</template>

<style scoped></style>
