<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { getFormatString, getLocaleString, localize } from "@/utils";
import { IBaseMenuItem, IListState } from "@/models";
import { onBeforeRouteUpdate, useRoute } from "vue-router";
import TabMenu from "@/components/TabMenu.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import Pagenation from "@/components/Pagenation.vue";

const route = useRoute();

/**
 * 정렬 옵션
 */
const orderBy = [
  { name: "주문 일자 순", value: "orderAt" },
  { name: "완료 일자 순", value: "completeAt" },
];
/**
 * 검색 조건 초기화를 위한 기본값
 */
const reset: Record<string, any> = {
  orderedDate: 0,
  deliveryDate: 0,
  status: 0,
  orderBy: orderBy[0].value,
};
/**
 * 상태
 */
const state: IListState = reactive({
  page: 1,
  totalRows: 3,
  keyword: "",
  items: [{ id: "1" }, { id: "2" }, { id: "3" }],
  orderBy: reset.orderBy,
  category: route.params.category,
  menuItems: [
    { name: "All Orders (0)", link: "all", nameFormat: "All Orders ({0})" },
    { name: "Action Needs (0)", link: "action", nameFormat: "Action Needs ({0})" },
    { name: "In Progress (0)", link: "progress", nameFormat: "In Progress ({0})" },
    { name: "Completed (0)", link: "completed", nameFormat: "Completed ({0})" },
  ],
  summary: {
    all: 0,
    action: 0,
    progress: 0,
    completed: 0,
  },
  currentMenuItem: null,
});

onMounted(init);
onBeforeRouteUpdate((to, from) => {
  init(to);
});

/**
 * 초기화
 */
function init(to = route) {
  state.category = to.params.category;
  console.log("init - category: ", state.category);
  state.currentMenuItem = state.menuItems.find((e: IBaseMenuItem) => e.link == state.category);
  request();
}
/**
 * 주문 목록 조회
 * @param page 페이지
 */
async function request(page: number = 1): Promise<void> {
  console.log("request - page:", page, "keyword:", state.keyword, "orderBy:", state.orderBy);
  const variables = {
    condition: {
      fields: [
        { name: "keyword", value: state.keyword },
        // {"name": "scanPurpose", "value": state.scanPurpose},
      ],
      orderBy: {
        field: state.orderBy,
        orderBy: "DESC",
      },
    },
    page: page,
  };
  const now = new Date();
  setTimeout(() => {
    state.summary = {
      all: now.getMilliseconds() + 1000,
      action: now.getMilliseconds() + 1001,
      progress: now.getMilliseconds() + 1002,
      completed: now.getMilliseconds() + 1003,
    };
    updatTabName();
  }, 1000);
}
/**
 * 검색 조건 초기화
 */
function resetForm(): void {
  for (const key in reset) state[key] = reset[key];
  request();
}
/**
 * 탭 이름 업데이트
 */
function updatTabName() {
  for (const item of state.menuItems) {
    item.name = getFormatString(item.nameFormat, getLocaleString(state.summary[item.link]));
  }
}
</script>

<template>
  <div class="d-flex justify-content-between align-items-end hd-title">
    <div>
      <small class="hd-title-sm pl-0">Order</small>
      <h1 class="hd-title pl-0 pb-0">Order List</h1>
    </div>
    <div class="btn-wrap mr-3">
      <!-- 주문하기 -->
      <router-link to="/playground/player/order/request" class="btn btn-lg bg-primary btn-shadow">주문하기</router-link>
      <!-- //주문하기 -->
    </div>
  </div>
  <div id="ct" class="flex-layout-body">
    <div class="content">
      <div class="box">
        <form @submit.prevent="request()" @reset.prevent="resetForm">
          <div class="search">
            <search-keyword :show-reset="true" :state="state" :placeholder="localize('Case, 환자 이름 검색')" />
          </div>
          <div class="row form-row font-poppins form-wrap">
            <div class="flex-center col-auto position-relative">
              <div class="form-label font-poppins">Order Date</div>
              <div class="form-control popup fs-sm text-muted">
                <i class="icon icon-date"></i><span class="mx-3">Select Order date</span
                ><i class="icon icon-select"></i>
              </div>
              <div class="form-control popup fs-sm text-dark">
                <i class="icon icon-date"></i><span class="mx-3">24.00.00 ~ 24.00.00</span
                ><i class="icon icon-select"></i>
              </div>
              <div :class="{ active: true }" class="bx position-absolute bg-white p-4 date-popup">
                <h3 class="fs-3">Ordered Date</h3>
                <div class="my-3">
                  <div class="flex-center">
                    <div>
                      <b class="d-inline-block mb-2">Begin</b>
                      <input class="form-control" type="date" />
                    </div>
                    <span class="px-1">-</span>
                    <div>
                      <b class="d-inline-block mb-2">End</b>
                      <input class="form-control" type="date" />
                    </div>
                  </div>
                </div>
                <div class="flex-center py-3">
                  <button class="btn btn-md fs-sm btn-outline mr-2">Cancel</button>
                  <button class="btn btn-md fs-sm flex-fill">Apply</button>
                </div>
              </div>
            </div>
            <div class="flex-center col-auto position-relative">
              <div class="form-label font-poppins">Delivery Date</div>
              <div class="form-control popup fs-sm text-muted">
                <i class="icon icon-date"></i><span class="mx-3">Select Delivery date</span
                ><i class="icon icon-select"></i>
              </div>
              <div class="bx position-absolute bg-white p-4 date-popup">
                <h3 class="fs-3">Ordered Date</h3>
                <div class="my-3">
                  <div class="flex-center">
                    <div>
                      <b class="d-inline-block mb-2">Begin</b>
                      <input class="form-control" type="date" />
                    </div>
                    <span class="px-1">-</span>
                    <div>
                      <b class="d-inline-block mb-2">End</b>
                      <input class="form-control" type="date" />
                    </div>
                  </div>
                </div>
                <div class="flex-center py-3">
                  <button class="btn btn-md fs-sm btn-outline mr-2">Cancel</button>
                  <button class="btn btn-md fs-sm flex-fill">Apply</button>
                </div>
              </div>
            </div>
            <div class="flex-center col-auto position-relative status">
              <div class="form-label font-poppins">Status</div>
              <div class="form-control popup fs-sm text-muted d-flex">
                <span class="flex-fill active">Status</span><i class="icon icon-select"></i>
              </div>
              <div class="bx position-absolute bg-white p-4 date-popup status-popup">
                <h3 class="fs-4 font-weight-bold status-tit">Status</h3>
                <div class="row my-3">
                  <div class="col-auto bx px-2">
                    <div class="bx bg-light-gray p-4 h-100">
                      <h3 class="fs-4 font-weight-bold">Order Request</h3>
                      <ul class="mt-3">
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-pending mr-2"></i>
                          <span class="fs-sm">Pending</span>
                        </li>
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-prepaid mr-2"></i>
                          <span class="fs-sm">Prepaid</span>
                        </li>
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-order-rejected"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Order</span><br />
                              <span class="badge font-poppins bg-red">Rejected</span>
                            </div>
                          </div>
                        </li>
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-order-approved"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Order</span><br />
                              <span class="badge font-poppins bg-grn">Approved</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- Order Request -->
                  <div class="col bx px-2">
                    <div class="bx bg-light-gray p-4 h-100 all">
                      <div class="flex-center justify-content-between">
                        <h3 class="fs-4 font-weight-bold">In Progress</h3>
                        <label class="form-check">
                          <input type="checkbox" class="sr-only check-all" />
                          <span class="check font-weight-normal">Select All</span>
                        </label>
                      </div>
                      <ul class="d-flex flex-wrap my-3 line-bx">
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-working mr-2"></i>
                          <span class="fs-sm">Working</span>
                        </li>
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-working mr-2"></i>
                          <span class="fs-sm">Working</span>
                        </li>
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-delivering mr-2"></i>
                          <span class="fs-sm">Delivering</span>
                        </li>
                        <!-- 1 -->
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-working-rework"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Working</span><br />
                              <span class="badge font-poppins bg-purple">Rework</span>
                            </div>
                          </div>
                        </li>
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-working-rework"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Working</span><br />
                              <span class="badge font-poppins bg-purple">Rework</span>
                            </div>
                          </div>
                        </li>
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-deliverd-approved mr-2"></i>
                          <span class="fs-sm">Delivered</span>
                        </li>
                        <!-- 2 -->
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-confirmation-required"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Confirmation</span><br />
                              <span class="badge font-poppins bg-ylw">Required</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul class="d-flex flex-wrap pt-3 line-bx">
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-desconf-required"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Design Confirmation</span><br />
                              <span class="badge font-poppins bg-ylw">Required</span>
                            </div>
                          </div>
                        </li>
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-desconf-approved"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Design Confirmation</span><br />
                              <span class="badge font-poppins bg-grn">Approved</span>
                            </div>
                          </div>
                        </li>
                        <li class="flex-center px-0 py-2 col-4">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-manufacturing mr-2"></i>
                          <span class="fs-sm">Manufacturing</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- In Progress -->
                  <div class="col-3 bx px-2 all">
                    <div class="bx bg-light-gray p-4">
                      <div class="flex-center justify-content-between">
                        <h3 class="fs-4 font-weight-bold">Completed</h3>
                        <label class="form-check">
                          <input type="checkbox" class="sr-only check-all" />
                          <span class="check font-weight-normal">Select All</span>
                        </label>
                      </div>
                      <ul class="mt-3">
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-ordclose-requested"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Order Close</span><br />
                              <span class="badge font-poppins bg-blue">Requested</span>
                            </div>
                          </div>
                        </li>
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-ordclose-approved"></i>
                          <span class="fs-sm">Order Close</span>
                        </li>
                        <li class="flex-center py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <div class="badge-bx">
                            <div>
                              <i class="icon icon-ordclose-rejected"></i>
                            </div>
                            <div>
                              <span class="fs-sm font-poppins">Order Closed</span><br />
                              <span class="badge font-poppins bg-red">Rejected</span>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div class="bx bg-light-gray px-4 py-2 mt-2">
                      <ul class="">
                        <li class="flex-center px-0 py-2">
                          <label class="form-check mb-4">
                            <input type="checkbox" class="sr-only" name="checkName" />
                            <span class="check"></span>
                          </label>
                          <i class="icon icon-ord-canceled mr-2"></i>
                          <span class="fs-sm">Order Canceled</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center py-2">
                  <button class="btn btn-md fs-sm btn-outline mr-2 col-2">Cancel</button>
                  <button class="btn btn-md col-3">Apply</button>
                </div>
              </div>
            </div>
            <div class="flex-center col-auto">
              <div class="form-label">정렬</div>
              <div class="btn-check-wrap">
                <label v-for="item in orderBy" class="btn-check">
                  <input type="radio" class="sr-only" @change="request()" v-model="state.orderBy" :value="item.value" />
                  <span class="btn btn-outline-primary">{{ localize(item.name) }}</span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <nav class="nav nav-files nav-layered">
        <tab-menu :items="state.menuItems" :li="false" />
      </nav>
      <div class="file-content" data-file="1">
        <h3 class="fs-1">{{ state.currentMenuItem?.name }}</h3>
        <table class="tb tall mt-4">
          <colgroup>
            <col />
            <col />
            <col style="width: 17%" />
            <col style="width: 5%" />
            <col style="width: 5%" />
            <col style="width: 20%" />
          </colgroup>
          <thead>
            <tr>
              <th class="text-left">서비스 / 주문번호</th>
              <th class="text-left">환자명</th>
              <th class="text-left">파트너</th>
              <th class="text-left">의뢰일자</th>
              <th class="text-left">완료요청일자</th>
              <th colspan="2" class="text-left">진행 상태</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">언주플래너</span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <i class="icon icon-pending"></i>
                  <span class="fs-sm font-poppins">Pending</span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <div>
                    <i class="icon icon-confirmation-required"></i>
                  </div>
                  <div class="">
                    <span class="fs-s m font-poppins">Confirmation</span>
                    <span class="badge bg-ylw font-poppins">Required</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <div>
                    <i class="icon icon-designing-completed"></i>
                  </div>
                  <div class="">
                    <span class="fs-sm font-poppins">Design Confirmation</span>
                    <span class="badge bg-grn font-poppins">Approved</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <i class="icon icon-manufacturing"></i>
                  <span class="fs-sm font-poppins">Manufacturingn</span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <div>
                    <i class="icon icon-working-rework"></i>
                  </div>
                  <div>
                    <span class="fs-sm font-poppins">Working</span><br />
                    <span class="badge font-poppins bg-purple">Rework</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <div>
                    <i class="icon icon-addworks-rejected"></i>
                  </div>
                  <div>
                    <span class="fs-sm font-poppins">Additional works</span><br />
                    <span class="badge font-poppins bg-red">Rejected</span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <i class="icon icon-ord-canceled"></i>
                  <span class="fs-sm font-poppins">Order Canceled</span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <i class="icon icon-deliverd-approved"></i>
                  <span class="fs-sm font-poppins">Delivered</span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <i class="icon icon-prepaid"></i>
                  <span class="fs-sm font-poppins">Prepaid</span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  R2 Planning & Guide Design<br />
                  <span class="fs-xs text-muted">R-123456789</span>
                </div>
              </td>
              <td class="text-left font-weight-bold">
                <div class="font-poppins">
                  Frank,. Wechen. Bauer<br />
                  <span class="fs-xs text-muted">Custom Abutment, Temporary</span>
                </div>
              </td>
              <td class="text-left">
                <div class="flex-center">
                  <div class="flex-fill flex-center">
                    <img src="@/assets/playground/img/_temp/tb-prof.png" alt="" />
                    <span class="fs-sm font-weight-bold ml-2">대구 DDC 센터 </span>
                  </div>
                  <i class="icon icon-message"></i>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left">
                <div class="font-poppins">
                  <span class="font-weight-normal">2024.06.07</span><br />
                  <span class="fs-xs text-muted">11:25</span>
                </div>
              </td>
              <td class="text-left" colspan="2">
                <div class="badge-bx">
                  <div>
                    <i class="icon icon-order-rejected"></i>
                  </div>
                  <div class="">
                    <span class="fs-s m font-poppins">Order</span><br />
                    <span class="badge bg-red font-poppins">Rejected</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
