import { RouteRecordRaw } from "vue-router";
import OrderList from "@/views/playground/player/order/list/OrderList.vue";
import OrderRequest from "@/views/playground/player/order/request/OrderRequest.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/order/list`,
  },
  {
    path: "list",
    redirect: (to) => `/playground/player/order/list/all`,
  },
  {
    path: "list/:category",
    component: OrderList,
  },
  {
    path: "request",
    component: OrderRequest,
  },
];

export default routes;
