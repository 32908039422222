//#region (ClientSettings)
interface ServiceEndpoints {
    visitors: string
    users: string
}

class ClientSettings {
    //#region (싱글톤)
    private static _instance: ClientSettings

    private constructor() {
        if(ClientSettings._instance) {
            return ClientSettings._instance
        }

        this._appId = 'ed3e5517-7690-4654-8c06-444548ab67b5'
        this._serviceEndpoints = {
            visitors: 'http://localhost:3000/api/visitors',
            users: 'http://localhost:3000/api/users',
        }
        this._isTest = true
    }

    public static get instance(): ClientSettings {
        if(!ClientSettings._instance) {
            ClientSettings._instance = new ClientSettings()
        }

        return ClientSettings._instance
    }
    //#endregion

    private _appId: string = ''
    private _serviceEndpoints: ServiceEndpoints = {} as ServiceEndpoints
    private _isTest: boolean = true

    public get appId(): string | undefined {
        return this._appId
    }
    public set appId(appType: string) {
        this._appId = appType
    }
    public get serviceEndpoints(): ServiceEndpoints {
        return this._serviceEndpoints
    }
    public set serviceEndpoints(endpoints: ServiceEndpoints) {
        this._serviceEndpoints = endpoints
    }
    public get isTest(): boolean {
        return this._isTest
    }
    public set isTest(isTest: boolean) {
        this._isTest = isTest
    }
}

export { ClientSettings }
export type { ServiceEndpoints }