<script setup lang="ts">
import { reactive, inject } from "vue";
import { localize } from "@/utils";
import { ServiceSettingAddProps } from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAdd.vue";
/**
 * 서비스 등록 상태
 */
const data = inject<ServiceSettingAddProps>("service-setting-add-state")!;
data.step = 4;
</script>

<template>
  <div class="fieldset d-block mb-2">
    <strong class="fs-sm text-primary">STEP 4</strong>
    <h3 class="fs-2 mt-2 mb-4">등록하기</h3>
    <p class="fs-3 mb-2">등록할 상품 정보를 확인하세요.</p>
    <div class="fieldset d-block">
      <div class="box box-s d-flex">
        <div class="flex-center flex-fill">
          <img src="@/assets/playground/img/step-thum.png" alt="" />
          <div class="ml-3 flex-fill">
            <h3 class="fs-2 mb-2">R2 Planning &amp; Guide Design</h3>
            <div class="bg-white px-3 py-2 rounded w-100 d-flex flex-wrap">
              <b class="mr-3 fs-sm">서지컬가이드 +</b>
              <span class="badge mr-2">주문 R-123456789</span>
              <span class="badge"> R2 Planning & Guide Design</span>
            </div>
          </div>
        </div>
        <div class="flex-column num-bx">
          <b class="fs-sm">Starting</b>
          <span class="num"><b class="mt-2">40</b>P</span>
        </div>
      </div>
    </div>
    <div class="fieldset d-block">
      <h3 class="fs-2 mt-2 mb-4">다음 사항을 확인하세요.</h3>
      <div class="box box-s d-flex box-outline">
        <div class="flex-center flex-fill">
          <img src="@/assets/playground/img/obj.png" alt="" />
          <div class="ml-3 flex-fill">
            <a href="" class="btn-help ml-0">
              <span class="fs-sm gray font-weight-bold">Please Check</span>
            </a>
            <p class="fs-sm mt-2">
              서비스 등록 후, 메가젠의 관리자가 등록된 서비스를 확인하고 승인하면, 주문자가 서비스를 검색할 때,
              <br />여러분이 등록한 서비스가 나타나게 됩니다. 조금만 기다려주세요
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
