import {
  FileTransferError,
  FileTransferProgress,
  FileTransferResult,
  FileTransferService,
  FileTransferSource,
  FileUploadSource,
  IFileTransferService,
} from "@/libs/R2ServiceClient/service/Resource";
import { defineStore } from "pinia";
import { alert } from "@/services";
import { IUploadedFile } from "@/models";

export const useResourceWrapperStore = defineStore("resource-wrapper", () => {
  const fileTransferService: IFileTransferService = new FileTransferService();

  function onUpload(event: Event) {
    //1/ HTMLInputElement.files 로 웹 브라우저 상의 파일에 대한 정보를 가져옴
    const uploadFiles = (<HTMLInputElement>event.target).files;
    if (uploadFiles != null) upload(uploadFiles);
  }

  async function upload(
    uploadFiles: FileList,
    callbacks?: {
      successCallback?: (uploadedFiles: IUploadedFile[]) => void;
      progressCallback?: (progress: FileTransferProgress) => void;
      errorCallback?: (error: FileTransferError | undefined) => void;
      afterErrorCallback?: (error: FileTransferError | undefined) => void;
      finallyCallback?: () => void; // TODO: loading 제거 처리
    }
  ): Promise<IUploadedFile[]> {
    //2/ FileUploadSource 를 생성하고, 가져온 파일을 추가 (생성자에는 '어떤 문자열'이 들어가더라도 상관없음)
    let fileUploadSource: FileUploadSource = new FileUploadSource("order");
    for (let index = 0; index < uploadFiles.length; index++) {
      //가져 온 파일을 FileUploadSource 에 추가
      fileUploadSource.addBrowserData(uploadFiles[index]!);
    }

    //3/ FileTransferSource 를 생성하고, uploadSource 속성에 준비한 FileUploadSource 개체를 설정
    let fileTransferSource: FileTransferSource = new FileTransferSource();
    fileTransferSource.uploadSource = fileUploadSource;
    // state.uploading = true;
    // state.progressRate = 0;
    //4/ 업로드를 진행함, 업로드 진행 상황을 알기 위한 핸들러 함수를 추가할 수 있으며, 이에 대한 결과를 fileTransferResult 개체에서 확인할 수 있음
    let fileTransferResult: FileTransferResult = await fileTransferService.transfer(
      fileTransferSource,
      (progress: FileTransferProgress) => {
        // if(progress.state == 'Uploading')
        if (progress.progressRate) {
          // state.progressFileName = progress.fileName
          // state.progressRate = progress.progressRate
          callbacks?.progressCallback?.(progress);
        }
      }
    );
    // state.uploading = false;

    //5/ 데모
    let uploadedFiles: IUploadedFile[] = [];
    if (fileTransferResult.upload != null) {
      for (let index = 0; index < fileTransferResult.upload.length; index++) {
        const item = fileTransferResult.upload[index];
        if (item.transferResult == "succeed") {
          uploadedFiles.push({
            id: fileTransferResult.upload[index].fileId,
            fileName: fileTransferResult.upload[index].fileName,
            fileSize: fileTransferResult.upload[index].fileSize,
          });
        } else {
          if (callbacks?.errorCallback != null) {
            callbacks.errorCallback!(item.error);
          } else {
            //TODO: 커스텀 다이알로그 처리
            // alert(response.errors.message);
            await alert("공통 에러 처리");
          }
          callbacks?.afterErrorCallback?.(item.error);
        }
      }
      callbacks?.successCallback?.(uploadedFiles);
    }
    callbacks?.finallyCallback?.();
    return uploadedFiles;
  }

  return { upload };
});
