import {RouteRecordRaw} from "vue-router";
import Certifications from "@/views/academy/my-page/certifications/Certifications.vue";
import TestHistory from "@/views/academy/my-page/test-history/TestHistory.vue";
import testHistoryRoutes from "@/router/academy/my-page/test-history";

const routes: Array<RouteRecordRaw> = [
    {
        path: "",
        redirect: "/academy/my-page/certifications",
    },
    {
        path: "certifications",
        component: Certifications,
    },
    {
        path: "test-history",
        component: TestHistory,
        children: testHistoryRoutes
    },
];

export default routes;