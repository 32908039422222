<script setup lang="ts">
import router from "@/router";
import {onBeforeMount, reactive} from "vue";
import {PracticeQuizItem, WrittenQuizItem, useTestStore} from "@/store";

const state = reactive<{
  step: number,
  agreement: boolean,
  type: string,
  test: {
    length: number,
    minutes: number,
    quizItems: WrittenQuizItem[] | PracticeQuizItem[],
    downloadFile: string,
  },
}>({agreement: false, step: 0, test: {downloadFile: "", length: 0, minutes: 0, quizItems: []}, type: ""});

onBeforeMount(init);

/**
 * 초기화
 */
function init(){
  state.step = 1;
  state.agreement = false;
}
/**
 * 시험 유형 선택
 * @param type
 */
function readyTest(type: string){
  // TODO: 시험 문제 받아오기 구현 필요
  type == "written" ? readyWrittenTest() : readyPracticeTest();
  state.type = type;
  state.step = 2;
}
function readyWrittenTest(){
  state.test.quizItems = [
    {type: "radio", question: "문제 1", options: ["보기 1", "보기 2", "보기 3", "보기 4"], answer: 1, userAnswer: []},
    {type: "radio", question: "문제 2", options: ["보기 1", "보기 2", "보기 3", "보기 4", "보기 5"], answer: 2, userAnswer: []},
    {type: "checkbox", question: "문제 3", options: ["보기 1", "보기 2", "보기 3", "보기 4", "보기 5"], answer: [3,4], userAnswer: []},
  ];
  state.test.length = state.test.quizItems.length;
  state.test.minutes = 20;
}
function readyPracticeTest(){
  state.test.downloadFile = '/public/language.csv'; // 파일 다운로드 URL
  state.test.quizItems = [
    {question: "문제 1", fileItems: []},
    {question: "문제 2", fileItems: []},
    {question: "문제 3", fileItems: []},
  ];
  state.test.length = state.test.quizItems.length;
  state.test.minutes = 30;
}
/**
 * 시험 시작
 * @param type
 */
function startTest(type: string){
  type == "written" ? startWrittenTest() : startPracticeTest();
}
function startWrittenTest(){
  // 현재 시간에서 state.test.minutes 더한 시간
  const endTime = new Date(new Date().getTime() + state.test.minutes * 60 * 1000);
  useTestStore().setWrittenTest(endTime, state.test.quizItems as WrittenQuizItem[]);
  router.replace("written-test/1");
}
function startPracticeTest(){
  // 현재 시간에서 state.test.minutes 더한 시간
  const endTime = new Date(new Date().getTime() + state.test.minutes * 60 * 1000);
  useTestStore().setPracticeTest(endTime, state.test.downloadFile, state.test.quizItems as PracticeQuizItem[]);
  router.replace("practice-test/0");
}
</script>

<template>
  <h1>Home</h1>
<!--  시험 유형 선택-->
  <div v-show="state.step == 1">
    <button @click="readyTest('written')">필기 시험 응시하기</button>
    |
    <button @click="readyTest('practice')">실기 시험 응시하기</button>
  </div>
<!--  약관 동의-->
  <div v-show="state.step == 2">
    <div v-show="state.type == 'written'">
      Qualifying test 정보
      <table>
        <tr>
          <td>시험 유형</td>
          <td>객관식 총 {{ state.test.length }}문항</td>
        </tr>
        <tr>
          <td>제한 시간</td>
          <td>{{ state.test.minutes }}분</td>
        </tr>
        <tr>
          <td>합격 기준</td>
          <td>100점 만점 기준 80점 이상 득점</td>
        </tr>
        <tr>
          <td>시험 결과</td>
          <td>답안 제출 시 자동 채점을 통해 점수 및 합격 여부 확인 가능</td>
        </tr>
        <tr>
          <td>주의 사항</td>
          <td>제한 시간 경과 시 답안은 자동 제출됩니다.<br/>
            답안 제출 시 시험은 종료됩니다.<br/>
            시험은 하루에 1회까지 응시할 수 있습니다.<br/>
            필기 시험 합격 후 실기 시험 합격 시 최종 합격 처리됩니다.</td>
        </tr>
      </table>
    </div>
    <div v-show="state.type == 'practice'">
      Practice test 정보
      <table>
        <tr>
          <td>시험 유형</td>
          <td>실습 파일 제출형 {{ state.test.length }}문항</td>
        </tr>
        <tr>
          <td>제한 시간</td>
          <td>{{ state.test.minutes }}분</td>
        </tr>
        <tr>
          <td>합격 기준</td>
          <td>A/B/C/D 등급 기준 B이상 획득</td>
        </tr>
        <tr>
          <td>시험 결과</td>
          <td>답안 제출 시 수동 채점 통해 24시간 이내 발표<br/>
            등급 및 합격 여부 확인 가능</td>
        </tr>
        <tr>
          <td>주의 사항</td>
          <td>제한 시간 경과 시 답안은 자동 제출됩니다.<br/>
            실습 파일 제출 시 시험은 종료됩니다.<br/>
            시험은 하루에 1회까지 응시할 수 있습니다.<br/>
            필기 시험 합격 후 실기 시험 합격 시 최종 합격 처리됩니다.</td>
        </tr>
      </table>
    </div>
    <label>
      <input type="checkbox" v-model="state.agreement">시험 응시 과정에서 부정 행위 발생 시 불이익을 감수하겠습니다.
    </label>
    <button :disabled="!state.agreement" @click="startTest(state.type)">응시하기</button>
  </div>
</template>

<style scoped>
table{
  border-collapse: collapse;
  border: 1px solid black;
}
td{
  border: 1px solid black;
  text-align: left;
  padding: 5px 5px 5px 5px;
}
</style>