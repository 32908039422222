<script setup lang="ts">
import { reactive } from "vue";
import { localize, getFormatString } from "@/utils";
import PartnerHeader from "@/views/playground/player/partner/PartnerHeader.vue";
import { useRoute } from "vue-router";

const route = useRoute();
/**
 * 파트너 아이디
 */
const partnerId = route.params.partnerId;
console.log("partnerId", partnerId);

const state = reactive({
  total: 75,
  guide: 35,
  crown: 22,
  abutment: 20,
});
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <partner-header></partner-header>
    <div v-if="state.total === 0" class="my-planner">
      <div class="my-planner-top">
        <div class="profile-thumb">
          <img src="@/assets/playground/img/_temp/profile2.jpg" alt="" />
        </div>
        <div class="my-planner-num">
          <b class="font-poppins">0</b>
          <p v-html="getFormatString(localize('{0}에게 맡긴<br />의뢰가 없습니다.'), '언주플래너')" />
        </div>
        <div class="profile-thumb">
          <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
        </div>
      </div>
      <p class="fs-3 text-center" v-html="localize('지금 첫 주문을 해보시겠습니까?')" />
      <a href="" class="btn btn-outline">{{ localize("제공 서비스 보러 가기") }}</a>
    </div>
    <div v-else class="my-planner">
      <div class="my-planner-top">
        <div class="profile-thumb">
          <img src="@/assets/playground/img/_temp/profile2.jpg" alt="" />
        </div>
        <div class="my-planner-num">
          <b class="font-poppins">75</b>
          <p
            v-html="
              getFormatString(
                localize('{0}에 <b>{1}건</b>에 해당하는<br />주문 건을 요청하셨습니다.'),
                '언주플래너',
                state.total
              )
            "
          />
        </div>
        <div class="profile-thumb">
          <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
        </div>
      </div>
      <div class="box">
        <dl class="desc">
          <dt>R2 Planning & Guide Design</dt>
          <dd>35</dd>
        </dl>
        <dl class="desc">
          <dt>Customized Abutment & Crown</dt>
          <dd>22</dd>
        </dl>
        <dl class="desc">
          <dt>Customized Abutment & Crown</dt>
          <dd>20</dd>
        </dl>
      </div>
      <button type="button" class="btn btn-outline">{{ localize("전체 주문 기록 보기") }}</button>
    </div>
  </div>
</template>

<style scoped></style>
