import { RouteRecordRaw } from "vue-router";
import MyCloud from "@/views/playground/player/cloud/MyCloud.vue";
import SharedCloud from "@/views/playground/player/cloud/SharedCloud.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/cloud/my-cloud`,
  },
  {
    path: "my-cloud",
    component: MyCloud,
  },
  {
    path: "shared-cloud",
    component: SharedCloud,
  },
];

export default routes;
