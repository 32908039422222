import { RouteRecordRaw } from "vue-router";
import Completed from "@/views/playground/planner/business-management/settlement-management/Completed.vue";
import Revenue from "@/views/playground/planner/business-management/settlement-management/revenue/Revenue.vue";
import Settings from "@/views/playground/planner/business-management/settlement-management/Settings.vue";
import revenueRoutes from "@/router/playground/planner/business/settlement/revenue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/settlement/completed`,
  },
  {
    path: "completed",
    component: Completed,
  },
  {
    path: "revenue",
    component: Revenue,
    children: revenueRoutes,
  },
  {
    path: "settings",
    component: Settings,
  },
];

export default routes;
