<script setup lang="ts">
</script>

<template>
  <small class="hd-title-sm">Support</small>
  <router-view></router-view>
</template>

<style scoped>

</style>