import { AuthenticationSettings } from '../settings/AuthenticationSettings'
import { AuthProxy } from './AuthProxy'

//#region (HowToMethods)
class HowToMethods {
    static howToSetToken(headers: any) {
        const token = AuthenticationSettings.instance.token;
        headers['Authorization'] = `Bearer ${token}`;
    }

    static async howToRefresh(): Promise<boolean> {
        const success = await AuthProxy.refresh();
        return success;
    }
}
//#endregion

export { AuthProxy, HowToMethods }