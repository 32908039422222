<script setup lang="ts">
import { useHead } from "@vueuse/head";
import { reactive } from "vue";
import css from "@/assets/home/css/import.css?inline";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperClass } from "swiper/types";
import { Mousewheel, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { alert } from "@/services";

const emit = defineEmits<{
  (eventType: "change-css", style: string): void;
}>();

emit("change-css", css);

useHead({
  title: "R2 Playground",
  meta: [
    { name: "viewport", content: "width=device-width, initial-scale=1, shrink-to-fit=no" },
    { name: "format-detection", content: "telephone=no" },
  ],
});
</script>
<template>
  <div id="wrap" class="page-digital-labrary">
    <div class="hd">
      <!-- sidebar -->
      <button type="button" class="menu-open">menu</button>
      <nav id="sidebar" class="flex-layout">
        <div class="sidebar-hd flex-layout-hd">
          <div class="all-toggle">
            <span>All</span>
            <div class="form-check form-switch">
              <input
                class="form-check-input custom-check-input"
                type="checkbox"
                id="mySwitch"
                name="darkmode"
                value="yes"
                checked
              />
            </div>
          </div>
        </div>
        <div class="sidebar-body flex-layout-body">
          <ul class="library-gnb">
            <!-- [D] : .on 메뉴 열림 -->
            <li class="gnb-collapse on">
              <a href="" class="gnb-link">ANYRIDGE<i class="icon icon-toggle"></i></a>
              <ul class="gnb-depth">
                <li><a href="" class="gnb-link" title="Octa">Octa</a></li>
                <li><a href="" class="gnb-link" title="Mua">Mua</a></li>
              </ul>
            </li>
            <li class="gnb-collapse">
              <a href="" class="gnb-link">BLUE DIAMOND<i class="icon icon-toggle"></i></a>
              <ul class="gnb-depth">
                <li><a href="" class="gnb-link" title="menu1">menu1</a></li>
                <li><a href="" class="gnb-link" title="menu1">menu2</a></li>
              </ul>
            </li>
            <li class="gnb-collapse">
              <a href="" class="gnb-link">ANYONE<i class="icon icon-toggle"></i></a>
              <ul class="gnb-depth">
                <li><a href="" class="gnb-link" title="menu1">menu1</a></li>
                <li><a href="" class="gnb-link" title="menu1">menu2</a></li>
              </ul>
            </li>
            <li class="">
              <a href="" class="gnb-link">Ari</a>
            </li>
            <li class="">
              <a href="" class="gnb-link">MiNi</a>
            </li>
            <li class="gnb-collapse">
              <a href="" class="gnb-link">ST<i class="icon icon-toggle"></i></a>
              <ul class="gnb-depth">
                <li><a href="" class="gnb-link" title="menu1">menu1</a></li>
                <li><a href="" class="gnb-link" title="menu1">menu2</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
      <!-- //sidebar -->
    </div>

    <!-- container -->
    <main id="content" class="flex-layout">
      <!-- 페이지 시작 -->

      <div id="ct" class="flex-layout-body">
        <div class="cont-hd">
          <div class="cont-tit">
            <b>Welcome R2 DIGITAL LIBRARY</b>
            <span>임플란트 시술에 필요한 MEGAGEN 라이브러리를 지원합니다.</span>
          </div>
          <div class="cont-util">
            <a href="" target="_blank"><i class="icon icon-r2playgound"></i> R2 Playground</a>
            <a href="" target="_blank">R2 Solution</a>
            <a href="" class="btn btn-secondary btn-sm"><span>Digital Library</span><i class="icon icon-arrow"></i></a>
          </div>
        </div>
        <div class="anyridge">
          <img src="@/assets/home/img/logo-anyridge.png" alt="" />
          <span>Octa</span>
        </div>
        <div class="downlaod-swiper-wrp">
          <div class="swiper-hd">
            <img src="@/assets/home/img/scan-abutment.png" alt="" />
            <span>Scan Abutment</span>
          </div>
          <swiper
            :observer="true"
            :observe-parents="true"
            :slides-per-view="1"
            :breakpoints="{
              768: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1500: {
                slidesPerView: 4,
                spaceBetween: 80,
              },
            }"
            :modules="[Navigation]"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
          >
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-ZrGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>ZrGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-TiGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>TiGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-PMMA-Abutment.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>PMMA Abutment</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-RP-Anlog.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>RP Anlog</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-ZrGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>ZrGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-TiGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>TiGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-PMMA-Abutment.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>PMMA Abutment</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-RP-Anlog.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>RP Anlog</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-controls type-line">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </swiper>
        </div>
        <div class="downlaod-swiper-wrp">
          <div class="swiper-hd">
            <img src="@/assets/home/img/scan-healing-abutment.png" alt="" />
            <span>Scan Healing Abutment</span>
          </div>
          <swiper
            :observer="true"
            :observe-parents="true"
            :slides-per-view="1"
            :breakpoints="{
              768: {
                slidesPerView: 2,
                spaceBetween: 50,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1500: {
                slidesPerView: 4,
                spaceBetween: 80,
              },
            }"
            :modules="[Navigation]"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
          >
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-ZrGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>ZrGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-TiGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>TiGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-PMMA-Abutment.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>PMMA Abutment</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-RP-Anlog.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>RP Anlog</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-ZrGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>ZrGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-TiGEN.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>TiGEN</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-PMMA-Abutment.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>PMMA Abutment</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="slide-img">
                <img src="@/assets/home/img/slide-RP-Anlog.png" alt="" />
              </div>
              <div class="swiper-inner">
                <span>RP Anlog</span>
                <div class="btn-wrap">
                  <a href="" class="btn btn-outline center">Download <i class="icon icon-download-btn"></i></a>
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-controls type-line">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </swiper>
        </div>
      </div>
      <!-- //페이지 시작 -->
    </main>
    <!-- //container -->
  </div>
  <!-- modal -->
  <div
    id="showPopup"
    class="modal library-modal popup list-container"
    tabindex="-1"
    data-backdrop="static"
    aria-modal="true"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <p>Library Download</p>
          <button type="button" class="icon-close" data-dismiss="modal"><span class="sr-only">닫기</span></button>
        </div>
        <div class="modal-body">
          <div class="step">
            <span>ANYRIDGE</span>
            <i class="icon icon-chevron-right"></i>
            <span>Octa</span>
            <i class="icon icon-chevron-right"></i>
            <span>Scan Healing Abutment</span>
            <i class="icon icon-chevron-right"></i>
            <span>ZrGEN</span>
          </div>
          <div class="form-check font-poppins">
            <input class="form-check-input" type="checkbox" value="" id="agree" />
            <label class="form-check-label" for="agree">Agree Non-Discloser Agreement (Required)</label>
          </div>
          <div class="bx">
            <div class="bx-inner">
              Please read this carefully and accept all the terms and conditions below prior to downloading and using
              digital libraries By downloading the digital libraries, you agree to consent to its terms If you DO NOT
              agree to be bound by all such terms, click the “ button and DO NOT download any library. <br /><br />
              As a “ you shall fully understand that this library and relative information are properties of MegaGen
              Implant Co Ltd and shall be solely used for personal, non commercial use. MegaGen Implant Co Ltd is, and
              shall at all times remain, the sole owner of all intellectual property and other rights (whether
              proprietary or otherwise) subsisting in, or used in connection with all digital libraries.<br /><br />
              Please read this carefully and accept all the terms and conditions below prior to downloading and using
              digital libraries By downloading the digital libraries, you agree to consent to its terms If you DO NOT
              agree to be bound by all such terms, click the “ button and DO NOT download any library. <br /><br />
              As a “ you shall fully understand that this library and relative information are properties of MegaGen
              Implant Co Ltd and shall be solely used for personal, non commercial use. MegaGen Implant Co Ltd is, and
              shall at all times remain, the sole owner of all intellectual property and other rights (whether
              proprietary or otherwise) subsisting in, or used in connection with all digital libraries.
            </div>
          </div>
          <div class="download-wrp">
            <div class="download-bx">
              <div class="img-wrp">
                <img src="@/assets/home/img/download1.png" alt="" />
              </div>
              <div class="btn-wrap">
                <a href="" class="btn btn-primary btn-sm center">Download <i class="icon icon-download-btn"></i></a>
              </div>
            </div>
            <div class="download-bx">
              <div class="img-wrp">
                <img src="@/assets/home/img/download2.png" alt="" />
              </div>
              <div class="btn-wrap">
                <a href="" class="btn btn-primary btn-sm center">Download <i class="icon icon-download-btn"></i></a>
              </div>
            </div>
            <div class="download-bx">
              <div class="img-wrp">
                <img src="@/assets/home/img/download3.png" alt="" />
              </div>
              <div class="btn-wrap">
                <a href="" class="btn btn-primary btn-sm disabled center"
                  >Download <i class="icon icon-download-btn"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css" scoped></style>
