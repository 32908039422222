<script setup lang="ts">
import TabMenu from "@/components/TabMenu.vue";
import { IBaseMenuItem, IPeriodItem } from "@/models";
import { getFormatDate, localize } from "@/utils";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { IListState } from "@/models/i-list-state.ts";
import { reactive } from "vue";
import DatePeriod from "@/components/DatePeriod.vue";
import { subMonths, subYears } from "date-fns";

const now = new Date();

const periodItems: IPeriodItem[] = [
  { key: "직접입력", value: 0, period: null },
  {
    key: "당월",
    value: "1",
    period: {
      start: getFormatDate(new Date(now.getFullYear(), now.getMonth(), 1)),
      end: getFormatDate(now),
    },
  },
  {
    key: "1개월",
    value: "2",
    period: {
      start: getFormatDate(subMonths(now, 1)),
      end: getFormatDate(now),
    },
  },
  {
    key: "2개월",
    value: "3",
    period: {
      start: getFormatDate(subMonths(now, 2)),
      end: getFormatDate(now),
    },
  },
  {
    key: "3개월",
    value: "4",
    period: {
      start: getFormatDate(subMonths(now, 3)),
      end: getFormatDate(now),
    },
  },
  {
    key: "6개월",
    value: "5",
    period: {
      start: getFormatDate(subMonths(now, 6)),
      end: getFormatDate(now),
    },
  },
  {
    key: "1년",
    value: "6",
    period: {
      start: getFormatDate(subYears(now, 1)),
      end: getFormatDate(now),
    },
  },
];

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
});
const state: IListState = reactive({
  periodOption: 1,
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
});
function changePeriodOption(option: number): void {
  state.periodOption = option;
  console.log(state.periodOption);
  if (state.periodOption > 0) {
    const item = periodItems.find((item) => item.value == state.periodOption);
    console.log("changePeriodOption", `${item?.period?.start} ~ ${item?.period?.end}`);
  }
}
function changePeriodDirect(start: string, end: string): void {
  console.log("changePeriod", `${start} ~ ${end}`);
}
function resetForm(): void {
  state.periodOption = 1;
  console.log("resetForm", state.periodOption);
  request();
}
function request(page: number = 1): void {
  console.log("request", state.keyword, page);
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <nav class="nav nav-files">
      <tab-menu :items="menuItems" :li="false" />
    </nav>
    <!-- [D] 선택 된 순서 data-file="숫자" -->
    <div class="file-content" data-file="1">
      <strong class="file-title">완료된 주문 집계</strong>
      <div class="content">
        <div class="box">
          <form @submit.prevent="request()" @reset.prevent="resetForm">
            <div class="search">
              <search-keyword
                :show-reset="true"
                :state="state"
                :placeholder="localize('이름, 아이디, 병원명으로 검색해보세요')"
              />
            </div>
            <div class="row form-row">
              <div class="flex-center">
                <div class="form-label">조건</div>
                <select class="form-control form-select">
                  <option value="">1개월</option>
                </select>
              </div>
              <div class="flex-center">
                <div class="form-label">기간</div>
                <div class="flex-center">
                  <input
                    class="form-switch-input"
                    type="checkbox"
                    @change="changePeriodOption(($event.target as HTMLInputElement).checked ? 0 : 1)"
                  />
                  <h5 class="fs-sm cell-title" style="margin: 10px">직접입력</h5>
                </div>
                <date-period class="flex-center" v-if="state.periodOption === 0" @change="changePeriodDirect" />
                <div v-else class="btn-check-wrap">
                  <label v-for="item in periodItems.slice(1)" class="btn-check">
                    <input
                      type="radio"
                      class="sr-only"
                      @change="changePeriodOption(item.value as number)"
                      v-model="state.periodOption"
                      :value="item.value"
                    />
                    <span class="btn btn-outline-primary">{{ item.key }}</span>
                  </label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="flex-center justify-content-between">
          <ul class="order-list-wrap v2">
            <li>
              주문 <span class="fs-2"><span class="num">180</span>건</span>
            </li>
            <li>
              주문 <span class="fs-2"><span class="num">185</span>P</span>
            </li>
            <li>
              미결제액 <span class="fs-2"><span class="num">53</span>P</span>
            </li>
          </ul>
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="" />
            <label class="fs-sm" for="">상세 내역 표기</label>
          </div>
        </div>
        <table class="tb tb-space">
          <colgroup></colgroup>
          <thead>
            <tr>
              <th>주문번호</th>
              <th>병원 (클리닉)</th>
              <th>주문 일</th>
              <th>결제금</th>
              <th>결제 방법</th>
              <th>상태</th>
              <th colspan="">처리</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr class="tb-link-item active" data-toggle="table">
              <td class="font-weight-bold">R-123456789</td>
              <td class="font-weight-bold">Surgical Guide (Design)</td>
              <td>2024.02.16</td>
              <td class="fs-3">23P</td>
              <td>송금 (개인 간)</td>
              <td><span class="badge bg-red">미결제</span></td>
              <td>
                <button type="button" class="btn btn-sm fs-sm" data-toggle="modal" data-target="#modal-payment">
                  결제 처리
                </button>
              </td>
              <td><i class="icon icon-drop"></i></td>
            </tr>
            <tr class="table-collapse">
              <td colspan="8">
                <div class="row">
                  <div class="col">
                    <table class="tb tb-bordered">
                      <colgroup>
                        <col style="width: 20%" />
                        <col span="2" />
                        <col span="4" style="width: 7%" />
                      </colgroup>
                      <thead>
                        <th colspan="4" class="fs-3 text-left">Surgical Guide</th>
                        <th>단가</th>
                        <th>수량</th>
                        <th>총액</th>
                      </thead>
                      <tbody>
                        <tr>
                          <th>진단비</th>
                          <td class="text-left">기본 진단비</td>
                          <td></td>
                          <td></td>
                          <td>30P</td>
                          <td></td>
                          <td><b>30P</b></td>
                        </tr>
                        <tr>
                          <th rowspan="3">서지컬 가이드</th>
                          <td class="text-left">홀</td>
                          <td></td>
                          <td class="fs-xs">첫줄</td>
                          <td class="fs-xs">10P</td>
                          <td class="fs-xs">이후 홀당</td>
                          <td class="fs-xs">8P</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td class="text-left"></td>
                          <td class="text-left"></td>
                          <td></td>
                          <td>x4</td>
                          <td><b>32P</b></td>
                        </tr>
                        <tr>
                          <td>메탈 슬리브</td>
                          <td class="text-left">PMMA Abutment</td>
                          <td></td>
                          <td>5P</td>
                          <td>x4</td>
                          <td><b>32P</b></td>
                        </tr>
                        <tr>
                          <th>Bridge</th>
                          <td class="text-left">Ponic</td>
                          <td class="text-left">PMMA Abutment</td>
                          <td></td>
                          <td>4P</td>
                          <td>x3</td>
                          <td><b>30P</b></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="col-auto">
                    <div class="point-box">
                      <b>총 합계약 (포인트)</b><span><span class="num">167</span>P</span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr class="tb-link-item" data-toggle="table">
              <td class="font-weight-bold">R-123456789</td>
              <td class="font-weight-bold">Surgical Guide (Design)</td>
              <td>2024.02.16</td>
              <td class="fs-3">23P</td>
              <td>송금 (개인 간)</td>
              <td><span class="badge bg-grn">결제됨</span></td>
              <td></td>
              <td><i class="icon icon-drop"></i></td>
            </tr>
            <tr class="table-collapse">
              <td colspan="8">
                <div class="d-flex justify-content-between">
                  <table class="tb tb-guide col-9">
                    <colgroup>
                      <col style="width: 20%" />
                      <col style="width: 25%" />
                      <col style="width: 25%" />
                      <col style="width: 7%" />
                      <col style="width: 7%" />
                      <col style="width: 7%" />
                      <col style="width: 7%" />
                    </colgroup>
                    <thead>
                      <th colspan="4" class="fs-3 text-left">Surgical Guide</th>
                      <th>단가</th>
                      <th>수량</th>
                      <th>총액</th>
                    </thead>
                    <tbody>
                      <tr>
                        <th>진단비</th>
                        <td>기본 진단비</td>
                        <td></td>
                        <td></td>
                        <td>30P</td>
                        <td></td>
                        <td class="fs-sm">30P</td>
                      </tr>
                      <tr>
                        <th rowspan="3">서지컬 가이드</th>
                        <td>홀</td>
                        <td></td>
                        <td class="fs-xs">첫줄</td>
                        <td class="fs-xs">10P</td>
                        <td class="fs-xs">이후 홀당</td>
                        <td class="fs-xs">8P</td>
                      </tr>
                      <tr class="dash">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>x4</td>
                        <td class="fs-sm">32P</td>
                      </tr>
                      <tr>
                        <td>메탈 슬리브</td>
                        <td>PMMA Abutment</td>
                        <td></td>
                        <td>5P</td>
                        <td>x4</td>
                        <td class="fs-sm">32P</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="point-box">
                    <b>총 합계약 (포인트)</b><span><span class="num">167</span>P</span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ul class="paging planner">
          <li><a href="" class="icon icon-paging disabled">이전</a></li>
          <li><a href="" class="paging-item active">1</a></li>
          <li><a href="" class="paging-item">2</a></li>
          <li><a href="" class="paging-item">3</a></li>
          <li><a href="" class="paging-item">4</a></li>
          <li><a href="" class="paging-item">5</a></li>
          <li><button type="button" class="paging-item bubble">...</button></li>
          <li><a href="" class="paging-item">11</a></li>
          <li><a href="" class="icon icon-paging">다음</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
