<script setup lang="ts">
import { useHead } from "@vueuse/head";
import css from "@/assets/home/css/import.css?inline";

const emit = defineEmits<{
  (eventType: "change-css", style: string): void;
}>();

emit("change-css", css);

useHead({
  title: "R2 Playground",
  meta: [
    { name: "viewport", content: "width=device-width, initial-scale=1, shrink-to-fit=no" },
    { name: "format-detection", content: "telephone=no" },
  ],
});
</script>

<template>
  <router-view></router-view>
</template>

<css scoped>

</css>
