import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import playgroundRoutes from "@/router/playground";
import academyRoutes from "@/router/academy";
import homeRoutes from "@/router/home";
import adminRoutes from "@/router/admin";
import Admin from "@/views/admin/Admin.vue";
import Error from "@/views/Error.vue";
import Home from "@/views/home/Home.vue";
import Academy from "@/views/academy/Academy.vue";
import Playground from "@/views/playground/Playground.vue";
import QueryView from "@/views/test/QueryView.vue";
import ResourceView from "@/views/test/ResourceView.vue";
import { requireAuth } from "@/services";
import DigitalLibrary from "@/views/library/DigitalLibrary.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/test/query",
    component: QueryView,
  },
  {
    path: "/test/resource",
    component: ResourceView,
  },
  {
    path: "/admin",
    component: Admin,
    children: adminRoutes,
  },
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    component: Home,
    children: homeRoutes,
  },
  {
    path: "/playground",
    component: Playground,
    children: playgroundRoutes,
    meta: {
      level: 1,
    },
    beforeEnter: requireAuth,
  },
  {
    path: "/academy",
    component: Academy,
    children: academyRoutes,
  },
  {
    path: "/library",
    component: DigitalLibrary,
  },
  {
    path: "/error",
    component: Error,
  },
  {
    path: "/error/:code",
    component: Error,
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/error/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
