import { RouteRecordRaw } from "vue-router";
import History from "@/views/playground/r2center/business-management/settlement-management/revenue/History.vue";
import OrderList from "@/views/playground/r2center/business-management/settlement-management/revenue/OrderList.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/settlement/revenue/history`,
  },
  {
    path: "history",
    component: History,
  },
  {
    path: "order-list",
    component: OrderList,
  },
];

export default routes;
