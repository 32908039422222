<script setup lang="ts">

</script>

<template>
  <h1>MyPage</h1>
  <router-link to="/academy/my-page/certifications">Certifications</router-link>
  |
  <router-link to="/academy/my-page/test-history">TestHistory</router-link>
  <router-view></router-view>
</template>

<style scoped>

</style>