<script setup lang="ts">
import { localize } from "@/utils";
import { alert } from "@/services";
import { ref, Ref } from "vue";

//HERE
const downloadLink: Ref<string> = ref("https://download.r2playground.com");
</script>

<template>
  <footer id="ft">
    <div class="container container-fluid">
      <div class="ft-top">
        <a href="#" class="logo black"></a>
        <nav class="fnb">
          <button @click="alert(localize('준비 중 입니다.'))">{{ localize("회사소개") }}</button>
          <i class="dot"></i>
          <button @click="alert(localize('준비 중 입니다.'))">{{ localize("이용약관") }}</button>
          <i class="dot"></i>
          <button @click="alert(localize('준비 중 입니다.'))">{{ localize("개인정보처리방침") }}</button>
        </nav>
      </div>
      <div class="ft-info">
        <div class="ft-addres">
          <strong class="ft-info-title">{{ localize("메가젠임플란트 주식회사") }}</strong>
          <p
            v-html="localize('대표이사 : 박광범 주소 : 대구광역시 달성군 다사읍 세천로 7길 45 <br> 사업자등록번호 : 504-81-43999 <br class=\'d-lg-none\'> 통신판매신고번호 : 제 2013-서울강남-02124')" />
          <p class="copy">Copyright MegaGen Implant Co., Ltd. All rights reserved.</p>
        </div>

        <nav class="fnb">
          <button @click="alert(localize('준비 중 입니다.'))">{{ localize("회사소개") }}</button>
          <i class="dot"></i>
          <button @click="alert(localize('준비 중 입니다.'))">{{ localize("이용약관") }}</button>
          <i class="dot"></i>
          <button @click="alert(localize('준비 중 입니다.'))">{{ localize("개인정보처리방침") }}</button>
        </nav>

        <div class="ft-cs">
          <strong class="ft-info-title">Customer center</strong>
          Korea(HQ) : <a href="tel:+82-53-222-3200">+82-53-222-3200</a> <br />
          Euro zone : <a href="tel:+370-6874-9650">+370-6874-9650</a> <br /><br />
          Web registering & Troubleshooting : <a href="mailto:cs@r2gate.com">cs@r2gate.com</a> <br />
          Orders & Service : <a href="mailto:service@r2gate.com">service@r2gate.com</a><br />
          Business opportunities : <a href="mailto:r2b@r2gate.com">r2b@r2gate.com</a>

          <p class="copy">Copyright MegaGen Implant Co., Ltd. All rights reserved.</p>
        </div>
        <a v-bind:href="downloadLink" target="_blank"><button class="btn btn-outline-primary btn-sm">
            APP Download <i class="icon icon-download"></i>
          </button></a>
      </div>
    </div>
  </footer>
</template>

<style scoped></style>
