<script setup lang="ts">
import { reactive, ref } from "vue";
import R2Dialog from "@/components/R2Dialog.vue";
import { localize } from "@/utils";
import axios from "axios";

const emit = defineEmits<{
  (eventType: "select", address: string): void; // 주소 선택 이벤트
}>();

defineExpose({
  open,
});

/**
 * 카카오 주소 검색 API 키
 */
const apiKey = "c7d2e5ad4caffa9ef0e99188ede5e307";

/**
 * 팝업창 참조
 */
const addressDialogRef = ref<InstanceType<typeof R2Dialog> | null>(null);
/**
 * 주소 검색 결과 리스트 참조
 */
const listContainer = ref();
/**
 * 주소 검색 요청 정보
 */
const request = reactive({ keyword: "", page: 1, size: 30, enabled: true });

const state = reactive({
  address: "",
  keyword: "",
  foundItems: [] as { [key: string]: any }[],
});

/**
 * 주소 검색 팝업창 열기
 */
function open(): void {
  state.keyword = "";
  state.foundItems = [];
  request.keyword = "";
  request.page = 1;
  addressDialogRef.value?.open();
}
/**
 * 주소 검색 팝업창 닫기
 */
function onClose(result: boolean) {
  if (result) {
    emit("select", state.address);
  }
}
/**
 * 주소 검색
 */
async function search() {
  if (state.keyword.length < 2) return;
  request.keyword = state.keyword;
  request.page = 1;
  await getAddress();
}
/**
 * 주소 스크롤 이벤트
 */
function scrollAddress() {
  if (!listContainer.value) return;
  const container = listContainer.value as HTMLElement;
  const scrollHeight = container.scrollHeight;
  const scrollTop = container.scrollTop;
  const clientHeight = container.clientHeight;
  if (scrollHeight > clientHeight && scrollHeight - scrollTop - clientHeight == 0) {
    getAddress();
  }
}
/**
 * 주소 요청해서 가져오기
 */
async function getAddress() {
  if ((!request.enabled && request.keyword.length < 2) || request.page < 1) return;
  // console.log(request);
  request.enabled = false;
  if (request.page == 1) state.foundItems = [];
  axios
    .get(
      `https://dapi.kakao.com/v2/local/search/address?query=${request.keyword}&size=${request.size}&page=${request.page}`,
      {
        headers: {
          Authorization: `KakaoAK ${apiKey}`,
        },
      }
    )
    .then((response) => {
      const info = response.data.meta;
      request.page = info.is_end ? 0 : request.page + 1;
      state.foundItems = state.foundItems.concat(response.data.documents);
      // TODO: 검색 아이템 보여줄 때 지번, 도로명 처리.
      // 지번 정보가 없는 경우 도로명 주소를 표시하고, 지번 정보가 있는 경우 지번 주소를 표시.
      // 도로명 주소가 없는 경우 지번 주소를 표시.
      // 건물명이 있을 경우 건물명 표시.
      // console.log(foundItems.value);
      request.enabled = true;
    })
    .catch((error) => {
      // TODO: 에러 처리
      // 어떤 식으로 처리할지 협의 필요.
      // console.log("Error fetching posts:", error);
      request.enabled = true;
    });
}
/**
 * 주소 정보 선택
 * @param item 주소 정보
 */
function selectAddress(item: { [key: string]: any }): void {
  // console.log(item);
  // state.address = item;
  // TODO: 주소 표시 형식 확정 필요.
  //  카카오는 지번 정보를 제공하지 않음.
  //  도로명 주소일 경우 우편번호 5자리 정보가 있거나 없을 수 있음.
  state.address = getFullAddress(item);
  // showPopup.value = false;
}
/**
 *
 * @param item
 */
function getFullAddress(item: { [key: string]: any }) {
  let value = item.address_name;
  if (item.road_address?.zone_no) value = `[${item.road_address?.zone_no}]` + value;
  return value;
}
</script>
<template>
  <r2-dialog ref="addressDialogRef" modal-class="popup modal-md" @close="onClose">
    <div class="text-center">
      <h4 class="font-weight-bold mb-4">{{ localize("주소 찾기") }}</h4>
    </div>
    <div class="form-group align-items-center search-bx">
      <label class="form-label" for="search">{{ localize("검색어") }}</label>
      <div class="input-group">
        <input
          v-model="state.keyword"
          @keyup.enter="search"
          type="text"
          id="search"
          class="form-control curved mr-2"
          :placeholder="localize('도로명 또는 지번주소를 입력하세요.')"
        />
        <a @click="search" class="btn btn-primary center">{{ localize("검색") }}</a>
      </div>
    </div>
    <div class="list-container">
      <ul class="item-list" ref="listContainer" @scroll="scrollAddress">
        <li v-show="request.keyword.length > 0 && state.foundItems.length == 0">
          {{ localize("검색 결과가 없습니다.") }}
        </li>
        <li
          v-for="(item, index) in state.foundItems"
          :key="index"
          @click="selectAddress(item)"
          :class="{ active: getFullAddress(item) == state.address }"
          class="address-item"
        >
          {{ item.address?.address_name }}<br />
          {{ item.road_address?.address_name }}
        </li>
      </ul>
    </div>
  </r2-dialog>
</template>
<stype scoped>

</stype>
