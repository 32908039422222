import {RouteRecordRaw} from "vue-router";
import Dashboard from "@/views/academy/my-page/test-history/Dashboard.vue";
import QualifyingTest from "@/views/academy/my-page/test-history/QualifyingTest.vue";
import SharpenSkills from "@/views/academy/my-page/test-history/SharpenSkills.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "",
        redirect: "/academy/my-page/test-history/dashboard",
    },
    {
        path: "dashboard",
        component: Dashboard,
    },
    {
        path: "qualifying-test/:category",
        component: QualifyingTest,
    },
    {
        path: "sharpen-skills",
        component: SharpenSkills,
    },
];

export default routes;