import { RestClient, RestRequest, RestResponse } from "../../../base"
import { FileDeletionReportSubmitRequest, FileDeletionReportSubmitResponse, FileRegisterRequest, FileRegisterResponse, FileRegistrationReportSubmitRequest, FileRegistrationReportSubmitResponse } from "./type"

class ResourceProcessor {

    public constructor() {

    }

    private __fixed__: string = `https://testr2plgapp.azurewebsites.net/`

    public __hidden__post__(restRequest: RestRequest): Promise<RestResponse> {
        let restClient = new RestClient(this.__fixed__)
        return restClient.post(restRequest)
    }

    public __hidden__get__(restRequest: RestRequest): Promise<RestResponse> {
        let restClient = new RestClient(this.__fixed__)
        return restClient.get(restRequest)
    }

    public __hidden__put__(restRequest: RestRequest): Promise<RestResponse> {
        let restClient = new RestClient(this.__fixed__)
        return restClient.put(restRequest)
    }


    public async submitRegistrationReport(request: FileRegistrationReportSubmitRequest): Promise<FileRegistrationReportSubmitResponse> {
        let retVal: FileRegistrationReportSubmitResponse = {
            isSuccess: false
        }

        const restRequest = new RestRequest()
        restRequest.uri = `/resource/reports/registration/`
        restRequest.data = request
        let restResponse: RestResponse =  await this.__hidden__post__(restRequest) //await RestProxy.post(restRequest)
        if(restResponse.isSuccess) {
            retVal.isSuccess = true
            retVal.data = restResponse.data
        }

        return retVal
    }

    public async register(request: FileRegisterRequest): Promise<FileRegisterResponse> {
        let retVal: FileRegisterResponse = {
            isSuccess: false
        }

        const restRequest = new RestRequest()
        restRequest.uri = `/resource/files/`
        restRequest.data = request
        let restResponse: RestResponse = await this.__hidden__post__(restRequest) //await RestProxy.post(restRequest)
        if(restResponse.isSuccess) {
            retVal.isSuccess = true
            retVal.data = restResponse.data
        }

        return retVal
    }

    public async submitDeletionReport(request: FileDeletionReportSubmitRequest) : Promise<FileDeletionReportSubmitResponse> {
        let retVal: FileDeletionReportSubmitResponse = {
            isSuccess: false
        }

        const restRequest = new RestRequest()
        restRequest.uri = `/resource/reports/deletion/`
        restRequest.data = request
        let restResponse: RestResponse = await this.__hidden__post__(restRequest) //await RestProxy.post(restRequest)
        if(restResponse.isSuccess) {
            retVal.isSuccess = true
            retVal.data = restResponse.data
        }

        return retVal
    }
}

export { ResourceProcessor }