import { RouteRecordRaw } from "vue-router";
import Notification from "@/views/playground/player/player-space/messaging/Notification.vue";
import Notice from "@/views/playground/player/player-space/messaging/Notice.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/player-space/messaging/notification`,
  },
  {
    path: "notification",
    component: Notification,
  },
  {
    path: "notice",
    component: Notice,
  },
];

export default routes;
