import { JoinMember, JoinOffice, Sex } from "@/models";
import { deepMerge } from "@/utils";
import { defineStore } from "pinia";
import { reactive } from "vue";

export const useJoinStore = defineStore("join", () => {
  /**
   * 회원 정보 기본값
   */
  const _member = {
    agreement: {
      id: "AGR20240818", // "AGR20240818"
      version: "1.0", // "1.0"
      email: false,
      sms: false,
    },
    verificationRequestId: "", // "7734c41d2b0141cd94e9101e678055c0"
    loginId: "", // "r2.dev4@imegaen.com"
    emailAddress: "", // "r2.dev4@imegaen.com"
    password: "",
    name: {
      firstName: "", // "John"
      lastName: "", // "Doe"
    },
    birthDate: "",
    sex: Sex.MALE,
    country: {
      code: "KR", // "US"
      name: "대한민국", // "United States"
    },
    phoneNumber: {
      countryCode: "", // "+1"
      number: "", // "5551234567"
    },
    address: {
      postalCode: "", // "90210"
      address1: "", // "123 Beverly Hills"
      address2: "", // "Apt 45"
      city: "", // "Los Angeles"
      state: "", // "CA"
    },
  };
  /**
   * 사무실 정보 기본값
   */
  const _office = {
    name: "", // "Main Office"
    phoneNumber: {
      countryCode: "", // "+1"
      number: "", // "5559876543"
    },
    address: {
      postalCode: "", // "10001"
      address1: "", // "456 Park Avenue"
      address2: "", // "Suite 101"
      city: "", // "New York"
      state: "", // "NY"
    },
  };

  /**
   * 회원 정보
   */
  const member = reactive<JoinMember>({ ..._member });
  /**
   * 사무실 정보
   */
  const office = reactive<JoinOffice>({ ..._office });

  /**
   * 초기화
   */
  const initJoin = () => {
    deepMerge(member, _member); // member 값 _member 값으로 초기화
    deepMerge(office, _office); // office 값 _office 값으로 초기화
  };

  return { member, office, initJoin };
});
