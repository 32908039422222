<script setup lang="ts">
import { localize } from "@/utils";
import { reactive } from "vue";
import PartnerHeader from "@/views/playground/player/partner/PartnerHeader.vue";
import { useRoute } from "vue-router";

const route = useRoute();
/**
 * 파트너 아이디
 */
const partnerId = route.params.partnerId;
console.log("partnerId", partnerId);

const state = reactive({
  items: [],
});
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <partner-header></partner-header>
    <div v-if="false && state.items.length === 0" class="content">
      <p class="empty" v-html="localize('아직 등록된 리뷰 및 평점이 없습니다.')" />
    </div>
    <div v-else class="content">
      <div class="box">
        <div class="rating-wrap">
          <div class="rating-total">
            <div class="num">
              <span>4.9</span>
              <small>/</small>
              <span>5.0</span>
            </div>
            <div class="rating">
              <i class="icon icon-star on"></i>
              <i class="icon icon-star on"></i>
              <i class="icon icon-star on"></i>
              <i class="icon icon-star on"></i>
              <i class="icon icon-star on"></i>
            </div>
          </div>
          <div class="rating-progress">
            <div class="flex-center">
              <strong>{{ localize("정확성") }}</strong>
              <div class="meta">
                <i class="icon icon-star">{{ localize("평점") }}</i
                >4.9
              </div>
              <div class="progress"><div class="progress-bar" style="width: 98%"></div></div>
            </div>
            <div class="flex-center">
              <strong>{{ localize("신속성") }}</strong>
              <div class="meta">
                <i class="icon icon-star">{{ localize("평점") }}</i
                >4.9
              </div>
              <div class="progress"><div class="progress-bar" style="width: 98%"></div></div>
            </div>
            <div class="flex-center">
              <strong>{{ localize("응대성") }}</strong>
              <div class="meta">
                <i class="icon icon-star">{{ localize("평점") }}</i
                >4.9
              </div>
              <div class="progress"><div class="progress-bar" style="width: 98%"></div></div>
            </div>
          </div>
        </div>
      </div>
      <ul class="review-group">
        <li class="review media">
          <div class="profile-thumb">
            <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
          </div>
          <div class="media-body">
            <div class="flex-center">
              <b>김메가</b><span class="text-gray ml-1">{{ localize("님") }}</span>
              <span class="fs-sm text-muted ml-3">2024.00.00 00:00</span>
            </div>
            <div class="reveiw-meta">
              <div>3.0</div>
              <div class="rating">
                <i class="icon icon-star on"></i>
                <i class="icon icon-star on"></i>
                <i class="icon icon-star on"></i>
                <i class="icon icon-star"></i>
                <i class="icon icon-star"></i>
              </div>
              <div>({{ localize("정확성") }} 5, {{ localize("신속성") }} : 3, {{ localize("응대성") }} : 4)</div>
            </div>
            <div class="fs-sm mb-3">
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다.
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다.
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다.
            </div>
            <div class="badge-group">
              <div class="badge">R2 Planning & Guide Design</div>
              <div class="badge">R2 Planning & Guide Design</div>
            </div>
          </div>
        </li>
        <li class="review media">
          <div class="profile-thumb">
            <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
          </div>
          <div class="media-body">
            <div class="flex-center">
              <b>김메가</b><span class="text-gray ml-1">님</span>
              <span class="fs-sm text-muted ml-3">2024.00.00 00:00</span>
            </div>
            <div class="reveiw-meta">
              <div>3.0</div>
              <div class="rating">
                <i class="icon icon-star on"></i>
                <i class="icon icon-star on"></i>
                <i class="icon icon-star on"></i>
                <i class="icon icon-star"></i>
                <i class="icon icon-star"></i>
              </div>
              <div>(정확성 5, 신속성 : 3, 응대성 : 4)</div>
            </div>
            <div class="fs-sm mb-3">
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다.
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다.
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다.
            </div>
            <div class="badge-group">
              <div class="badge">R2 Planning & Guide Design</div>
              <div class="badge">R2 Planning & Guide Design</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped></style>
