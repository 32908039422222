<script setup lang="ts">
import { localize } from "@/utils";
import css from "@/assets/playground/css/import.css?inline"; // TODO: css 파일 경로 수정

const emit = defineEmits<{
  (eventType: "change-css", style: string): void;
}>();

emit("change-css", css);
</script>

<template>
  <h1>Academy</h1>
  <router-link to="/academy/courses">R2 Courses</router-link>
  |
  <router-link to="/academy/certification">R2 Certification</router-link>
  |
  <router-link to="/academy/sharpen-skills">Sharpen skills</router-link>
  |
  <router-link to="/academy/my-page">My page</router-link>
  <router-view></router-view>
</template>

<style scoped></style>
