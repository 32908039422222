import { RouteRecordRaw } from "vue-router";
import ChargeHistory from "@/views/playground/player/player-space/point/ChargeHistory.vue";
import PaymentHistory from "@/views/playground/player/player-space/point/PaymentHistory.vue";
import Charge from "@/views/playground/player/player-space/point/Charge.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/player-space/point/charge-history`,
  },
  {
    path: "charge-history",
    component: ChargeHistory,
  },
  {
    path: "payment-history",
    component: PaymentHistory,
  },
  {
    path: "charge",
    component: Charge,
  },
];

export default routes;
