<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { localize } from "@/utils";
import Chart from "chart.js/auto";
import TabMenu from "@/components/TabMenu.vue";
import R2Dialog from "@/components/R2Dialog.vue";
import { IBaseMenuItem } from "@/models";

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
});

/**
 * 차트 참조
 */
const chartRef = ref<HTMLCanvasElement | null>(null);
/**
 * 팝업창 참조
 */
const detailDialogRef = ref<InstanceType<typeof R2Dialog> | null>(null);

const state = reactive({
  chart: {
    // TODO: 데이터 구조에 따라 변경 필요. 예) [{ label: "1월", data: 580 }, ...]. map으로 변경
    labels: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월"],
    data: [580, 490, 600, 580, 600, 440, 580, 600, 560],
  },
});

onMounted(() => {
  initChart();
});

/**
 * 차트 초기화
 */
function initChart() {
  const ctx = chartRef.value?.getContext("2d");
  if (!ctx) {
    console.error("ctx is null");
    return;
  }
  const { labels, data } = state.chart;

  new Chart(ctx, {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          backgroundColor: "#F87C27",
          data: data,
          barThickness: 32,
          borderWidth: 1,
          borderRadius: 8,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            color: "transparent",
          },
        },
        y: {
          beginAtZero: false,
          min: 350,
          grid: {
            tickBorderDash: [4],
          },
        },
      },
    },
  });
}
/**
 * 통계 전체보기
 * @param serviceId 서비스 ID
 */
async function openDetail(serviceId: string) {
  console.log("openDetail - serviceId:", serviceId);
  // TODO: 서비스 ID에 따른 데이터 요청
  detailDialogRef.value?.open();
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="d-flex align-items-center justify-content-between mb-5">
      <ul class="nav nav-tabs-fluid v2 mb-0">
        <tab-menu :items="menuItems" />
      </ul>
      <p class="info-txt">서비스 상품 등록 및 수정은 [Order Management] → [서비스 상품 관리]에서 하실 수 있습니다</p>
    </div>
    <div class="">
      <p class="fs-2 mb-3">서비스 동향</p>
      <div class="d-flex chart-box-wrap">
        <div class="col-6 div box box-shadow chart-box orange">
          <div class="box box-color">
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
            <div class="count-wrap">
              <span class="badge">최근 최다 주문 서비스</span>
              <p class="sort fs-4 font-weight-bold">Customized Abutment & Temporary</p>
              <span class="count fs-sm font-weight-bold"><b>126</b>건</span>
            </div>
          </div>
          <div class="bg-white w-100">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="fs-3">최근 서비스 주문 빈도</p>
              <p @click="openDetail('serviceId1')" class="link-only flex-center text-primary fs-sm font-weight-bold">
                통계 전체보기<i class="icon icon-arrow orange ml-1"></i>
              </p>
            </div>
            <div>
              <div class="bar-chart">
                <div class="bar" style="width: 71%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">Customized Abutment & Temporary</p>
                  <span class="count flex-center"><b>126</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 68%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">R2 Planning & Guide</p>
                  <span class="count flex-center"><b>100</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 56%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">Crown & Bridge</p>
                  <span class="count flex-center"><b>88</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 54%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">Digital Oral Design (Dental Avatar)</p>
                  <span class="count flex-center"><b>72</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 10%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">R2 Guide ONE-DAY Teeth</p>
                  <span class="count flex-center"><b>32</b>건</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6 div box box-shadow chart-box blue">
          <div class="box box-color">
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
            <div class="count-wrap">
              <span class="badge">전체 최다 주문 서비스</span>
              <p class="sort fs-4 font-weight-bold">R2 Planning & Guide</p>
              <span class="count fs-sm font-weight-bold"><b>16</b>건</span>
            </div>
          </div>
          <div class="bg-white w-100">
            <div class="d-flex justify-content-between align-items-center mb-3">
              <p class="fs-3">최근 서비스 주문 빈도</p>
              <p @click="openDetail('serviceId2')" class="link-only flex-center text-primary fs-sm font-weight-bold">
                통계 전체보기<i class="icon icon-arrow orange ml-1"></i>
              </p>
            </div>
            <div>
              <div class="bar-chart">
                <div class="bar" style="width: 71%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">Customized Abutment & Temporary</p>
                  <span class="count flex-center"><b>126</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 68%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">R2 Planning & Guide</p>
                  <span class="count flex-center"><b>100</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 56%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">Crown & Bridge</p>
                  <span class="count flex-center"><b>88</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 54%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">Digital Oral Design (Dental Avatar)</p>
                  <span class="count flex-center"><b>72</b>건</span>
                </div>
              </div>
              <div class="bar-chart">
                <div class="bar" style="width: 10%"></div>
                <div class="d-flex justify-content-between align-items-center w-100">
                  <p class="sort">R2 Guide ONE-DAY Teeth</p>
                  <span class="count flex-center"><b>32</b>건</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="fs-2 mt-5 mb-3">월별 매출 추이</p>
      <div class="d-flex">
        <div class="box box-shadow col mr-4">
          <div class="d-flex justify-content-between align-items-center mb-4">
            <p class="fs-3">월별 매출 통계</p>
            <div class="flex-center">
              <input class="form-control" type="date" />
              <span class="px-1">-</span>
              <input class="form-control" type="date" />
            </div>
          </div>
          <canvas ref="chartRef" id="barChart" style="width: 100%; height: 260px"></canvas>
        </div>
        <div class="box box-outline col">
          <p class="fs-3 mb-3">최근 주문 건 수 비교</p>
          <!-- [D] 증가: increase / 감소: decrease -->
          <div class="box compare-box increase">
            <div class="circle">
              <span>+<b>15</b>%</span>
            </div>
            <p>저번 달은 저저번 달에 비해<br />주문 수가 <b>증가</b>했어요.</p>
          </div>
          <div class="fieldset mt-4 justify-content-between align-items-center font-weight-bold">
            <span class="fs-xs text-muted">2024.07</span>
            <p class="flex-center fs-4">
              <span class="flex-center mr-2"><b class="fs-3 font-poppins">16</b>건</span>
              <span class="badge increase">+15%</span>
            </p>
          </div>
          <div class="fieldset mt-4 justify-content-between align-items-center font-weight-bold">
            <span class="fs-xs text-muted">2024.06</span>
            <p class="flex-center fs-4">
              <span class="flex-center mr-2"><b class="fs-3 font-poppins">12</b>건</span>
              <span class="badge increase">+15%</span>
            </p>
          </div>
          <div class="fieldset mt-4 justify-content-between align-items-center font-weight-bold">
            <span class="fs-xs text-muted">2024.05</span>
            <p class="flex-center fs-4">
              <span class="flex-center mr-2"><b class="fs-3 font-poppins">2</b>건</span>
              <span class="badge decrease">+15%</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <r2-dialog ref="detailDialogRef" id="orderfrequency-modal" modal-class="modal-md" :no-button="true">
    <div class="text-center">
      <p class="fs-2 mb-3">최근 서비스 주문 빈도</p>
      <div class="content">
        <div class="d-flex align-items-center">
          <span class="rank-circle mr-3" data-rank="1">1</span>
          <div class="bar-chart">
            <div class="bar" style="width: 71%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">Customized Abutment & Temporary</p>
              <span class="count flex-center"><b>126</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="2">2</span>
          <div class="bar-chart">
            <div class="bar" style="width: 62%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Planning & Guide</p>
              <span class="count flex-center"><b>126</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="3">3</span>
          <div class="bar-chart">
            <div class="bar" style="width: 42%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">Digital Oral Design (Dental Avatar)</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">4</span>
          <div class="bar-chart">
            <div class="bar" style="width: 42%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">Digital Oral Design (Dental Avatar)</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">5</span>
          <div class="bar-chart">
            <div class="bar" style="width: 30%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Guide ONE-DAY Teeth</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">6</span>
          <div class="bar-chart">
            <div class="bar" style="width: 30%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Guide ONE-DAY Teeth</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">7</span>
          <div class="bar-chart">
            <div class="bar" style="width: 30%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Guide ONE-DAY Teeth</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">8</span>
          <div class="bar-chart">
            <div class="bar" style="width: 30%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Guide ONE-DAY Teeth</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">9</span>
          <div class="bar-chart">
            <div class="bar" style="width: 30%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Guide ONE-DAY Teeth</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center mt-2">
          <span class="rank-circle mr-3" data-rank="">10</span>
          <div class="bar-chart">
            <div class="bar" style="width: 30%"></div>
            <div class="d-flex justify-content-between align-items-center w-100">
              <p class="sort">R2 Guide ONE-DAY Teeth</p>
              <span class="count flex-center"><b>26</b>건</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </r2-dialog>
  <!-- //modal -->
</template>

<style scoped></style>
