<script setup lang="ts">
import { reactive } from "vue";
import { localize } from "@/utils";
import { IBaseMenuItem, IListState, PhoneNumber } from "@/models";
import { confirm } from "@/services";
import TabMenu from "@/components/TabMenu.vue";
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";

interface IMember {
  id: string;
  emailAddress: string;
  name: string;
  phoneNumber: PhoneNumber;
  date: string;
}

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
});

const state: IListState = reactive({
  page: 1,
  totalRows: 1,
  keyword: "",
  items: <IMember[]>[],
});

request();

/**
 * 구성원 목록 요청
 * @param page 페이지 번호
 */
function request(page: number = 1): void {
  console.log("request", state.keyword, page);
  state.items = [
    {
      id: "1",
      emailAddress: "test1@test.com",
      name: "김철수",
      phoneNumber: { fullNumber: "+82-010-0000-1111" },
      date: "2024.01.31",
    },
    {
      id: "2",
      emailAddress: "test2@test.com",
      name: "이철수",
      phoneNumber: { fullNumber: "+82-010-0000-2222" },
      date: "2024.02.31",
    },
    {
      id: "3",
      emailAddress: "test3@test.com",
      name: "박철수",
      phoneNumber: { fullNumber: "+82-010-0000-3333" },
      date: "2024.03.31",
    },
  ];
}
/**
 * 구성원 추가
 * @param item 추가 구성원 정보
 */
async function addMember(item: IMember) {
  console.log("addMember", item.id);
  if (await confirm("센터 구성원을 추가하시겠습니까?")) {
    // TODO: remove member
  }
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <ul class="nav nav-tabs-fluid v2">
      <tab-menu :items="menuItems" />
    </ul>
    <div class="content">
      <div class="tb-hd align-items-start mb-0">
        <div class="content-hd flex-column">
          <h3 class="fs-2">센터 구성원 관리</h3>
          <p>센터 구성원 정보를 등록하세요.</p>
        </div>
        <form @submit.prevent="request()">
          <search-keyword :state="state" :placeholder="localize('검색어를 입력해 주세요')" />
        </form>
      </div>
      <table class="tb">
        <colgroup>
          <col style="width: 25%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>연락처</th>
            <th>회원가입일자</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in state.items">
            <th>{{ item.emailAddress }}/th></th>
            <td>{{ item.name }}</td>
            <td>{{ item.phoneNumber.fullNumber }}</td>
            <td>{{ item.date }}</td>
            <td>
              <button @click="addMember(item)" class="btn btn-outline-light btn-sm">추가</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
  </div>
</template>

<style scoped></style>
