<script setup lang="ts">
import {useRoute} from "vue-router";
import router from "@/router";
import {reactive} from "vue";

const route = useRoute();
const score = Number(route.params?.score);
const state = reactive({
  type: route.params?.type,
  score: score,
  success: score >= 80,
});
</script>

<template>
  <div v-if="state.type == 'written'">
    <h1>Result</h1>
    <p>route.params.license: {{route.params.license}} / <br/>route.params.success: {{ state.success }} / <br/>route.params.score:
      {{ state.score }}</p>
    <div>
      <button @click="router.replace({name: 'QualifyingTest'})">Close</button>
      <button v-if="state.success" @click="router.replace({name: 'Home'})">Take practice test</button>
      <button v-else @click="router.replace({name: 'Courses'})">Go to courses</button>
    </div>
  </div>
  <div v-else>
    <h1>Result</h1>
    <p>route.params.license: {{route.params.license}}</p>
    <p>과제 파일 제출이 완료되었습니다.</p>
    <p>
      시험 결과는 제출 시점 기준 24시간 이내 발표됩니다.<br/>
      [My page > My test history]에서 확인하실 수 있습니다.<br/>
    </p>
    <div>
      <button @click="router.replace({name: 'QualifyingTest'})">Close</button>
      <button @click="router.replace('/academy/my-page')">Go to My test history ></button>
    </div>
  </div>
</template>

<style scoped>

</style>