<script setup lang="ts">
import { LocaleType, useLocalizationStore, useMemberStore, useNationStore, useSessionStore } from "@/store";
import { menuLanguages } from "@/configuration";
import { alert } from "@/services";
import { localize, openInNewTab } from "@/utils";
import { Authenticator } from "@/libs/R2ServiceClient/service/Auth";
import { useRouter } from "vue-router";
import { ref } from 'vue';

const router = useRouter();

const { setLanguage } = useLocalizationStore();
const { initNation } = useNationStore();
const { getMember, removeMember } = useMemberStore();

const member = getMember();

/**
 * 드롭다운 상태 변수
 */
const isPlaygroundDropdownOpen = ref(false);
const isSupportDropdownOpen = ref(false);
const isLangDropdownOpen = ref(false);

/**
 * 로그아웃
 */
async function logout(): Promise<void> {
  // console.log("logout - member.id", member.id);
  removeMember();
  closeMobileMenu();
  router.push("/home/login");
  const result = await Authenticator.logout();
  if (!result) {
    console.error("로그아웃 요청 실패");
  }
}
/**
 * 모바일이거나 화면이 작을 경우의 전체 메뉴 열기/닫기
 */
function toggleMobileMenu(): void {
  document.body.classList.toggle("open-gnb-menu");
}
/**
 * 모바일이거나 화면이 작을 경우의 전체 메뉴 닫기
 */
function closeMobileMenu(): void {
  document.body.classList.remove("open-gnb-menu");
}
/**
 * 언어 변경
 * @param locale
 * @param event
 */
function changeLocale(locale: LocaleType, event: Event): void {
  setLanguage(locale);
  initNation(locale);
  closeMobileMenu();
  closeLangDropdown();
}

/**
 * 드롭다운 열기/닫기
 */
 function openPlaygroundDropdown(): void {
  isPlaygroundDropdownOpen.value = true;
}

function closePlaygroundDropdown(): void {
  isPlaygroundDropdownOpen.value = false;
}

/**
 * 고객지원 드롭다운 열기/닫기
 */
 function openSupportDropdown(): void {
  isSupportDropdownOpen.value = true;
}

function closeSupportDropdown(): void {
  isSupportDropdownOpen.value = false;
}

/**
 * 언어 선택 드롭다운 열기/닫기
 */
 function openLangDropdown(): void {
  isLangDropdownOpen.value = true;
}

function closeLangDropdown(): void {
  isLangDropdownOpen.value = false;
}
</script>

<template>
  <header id="hd">
    <div class="container container-fluid">
      <!-- 사이트 로고 -->
      <router-link to="/home" class="logo text-hide">R2 playground</router-link>
      <!-- //사이트 로고 -->

      <nav id="gnb">
        <ul class="gnb">
          <!-- R2 Playground 메뉴 -->
          <li id="custom-dropdown" class="dropdown" @mouseover="openPlaygroundDropdown" @mouseleave="closePlaygroundDropdown">
            <router-link to="/home/playground" @click="closeMobileMenu">R2 Playground</router-link>
            <ul class="sub-menu" :class="{ open: isPlaygroundDropdownOpen }">
              <li><router-link to="/home/playground/player" @click="() => {closeMobileMenu(); closePlaygroundDropdown();}">R2 Player</router-link></li>
              <!-- <li><router-link to="/home/playground/planner" @click="closeMobileMenu">R2 Planner</router-link></li>
              <li><router-link to="/home/playground/r2center" @click="closeMobileMenu">R2 Center</router-link></li> -->
            </ul>
          </li>
          <!-- //R2 Playground 메뉴 -->

          <!-- R2 Solution 링크 -->
          <li><router-link to="/home/solution" @click="closeMobileMenu">R2 Solution</router-link></li>
          <!-- //R2 Solution 메뉴 -->

          <!-- 고객지원 메뉴 -->
          <li id="custom-dropdown" class="dropdown" @mouseover="openSupportDropdown" @mouseleave="closeSupportDropdown">
            <router-link to="/home/support" @click="closeMobileMenu">Customer Support</router-link>
            <ul class="sub-menu" :class="{ open: isSupportDropdownOpen }">
              <li><router-link to="/home/support/faq" @click="() => {closeMobileMenu(); closeSupportDropdown();}">FAQ</router-link></li>
              <li><router-link to="/home/support/inquiry-save" @click="() => {closeMobileMenu(); closeSupportDropdown();}">1:1 inquiry</router-link></li>
            </ul>
          </li>
          <!-- //고객지원 메뉴 -->
        </ul>

        <p class="academy">
          <!-- 플레이그라운드 주문하기 -->
          <!--<a @click="alert(localize('준비 중 입니다.'))">Order</a>-->
          <!-- //플레이그라운드 주문하기 -->
          <!-- 아카데미 -->
          <!--<a @click="alert(localize('준비 중 입니다.'))">R2 Academy</a>-->
          <!-- //아카데미 -->
           <div v-if="member.id != null" style="font-size: 0.8rem; font-weight: 500; border-radius: 5px; background-color: #33333315; padding: 4px;">
            welcome, {{ member.name.fullName }}
           </div>
        </p>

        <div class="hd-util">
          <!-- 로그인 / 로그아웃 -->
          <a v-if="member.id != null" @click="logout">Log Out</a>
          <router-link v-else to="/home/login" @click="closeMobileMenu">Log in</router-link>
          <!-- //로그인 / 로그아웃 -->

          <!-- 회원가입 -->
          <router-link v-if="!member.id" to="/home/join" @click="closeMobileMenu">Sign up</router-link>
          <!-- //회원가입 -->

          <!-- 언어 선택 -->
          <div id="custom-dropdown" class="dropdown lang" @mouseover="openLangDropdown" @mouseleave="closeLangDropdown">
            <a class="icon icon-lang"></a>
            <ul class="sub-menu" :class="{ open: isLangDropdownOpen }">
              <li v-for="item in menuLanguages">
                <a href="#" @click="changeLocale(item.value as LocaleType, $event)">{{ item.label }}</a>
              </li>
            </ul>
          </div>
          <!-- //어 선택 -->

          <!-- 디지털 도서관 -->
          <button class="btn btn-secondary btn-sm">
            <a href="https://r2gate.com/cad-cam?cate=3shape" target="_blank"><span>Digital Library</span><i class="icon icon-arrow"></i></a>
          </button>
          <!-- //디지털 도서관 -->
        </div>
      </nav>
      <button type="button" class="menu-open" @click="toggleMobileMenu">menu</button>
    </div>
  </header>
</template>

<style scoped>
/**
 * 연산자 우선순위 높이기 위해 id값 설정
 */
#custom-dropdown .sub-menu {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
}

#custom-dropdown .sub-menu.open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
</style>
