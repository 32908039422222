import { RouteRecordRaw } from "vue-router";
import ServiceSetting from "@/views/playground/r2center/order-management/service-setting/ServiceSetting.vue";
import OrderedList from "@/views/playground/r2center/order-management/ordered-list/OrderedList.vue";
import serviceRoutes from "@/router/playground/r2center/order/service";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/order/service`,
  },
  {
    path: "service",
    component: ServiceSetting,
    children: serviceRoutes,
  },
  {
    path: "ordered",
    component: OrderedList,
  },
];

export default routes;
