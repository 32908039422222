<script setup lang="ts">
import { useTimer, useFormBuilder } from "@/services";
import { localize } from "@/utils";
import { onMounted, onUnmounted, reactive, ref } from "vue";

const props = defineProps({
  /**
   * 폼 빌더
   */
  formBuilder: {
    type: Object as () => ReturnType<typeof useFormBuilder>,
    required: true,
  },
  /**
   * 인증코드 만료 시간(초)
   */
  expiredSeconds: {
    type: Number,
    default: 3 * 60,
  },
});

const emit = defineEmits<{
  (eventType: "submit", event: Event): void; // 제출 이벤트
}>();

defineExpose({ reset });

/**
 * 폼 빌더 관련
 */
const { controls, errors, group, touch, validate, valid, invalid } = props.formBuilder;

const state = reactive({
  /**
   * 남은 시간
   */
  remainTime: { minutes: "", seconds: "" },
});

/**
 * 인증코드 입력 필드
 */
const codeRef = ref<HTMLInputElement | null>(null);

/**
 * 인증코드 만료 타이머
 */
const { startTimer, stopTimer } = useTimer(
  props.expiredSeconds,
  /**
   * 남은 시간
   * @param minutes 남은 분
   * @param seconds 남은 초
   */
  (minutes, seconds) => {
    state.remainTime.minutes = minutes.toString().padStart(2, "0");
    state.remainTime.seconds = seconds.toString().padStart(2, "0");
  },
  /**
   * 타이머 종료
   */
  () => {
    controls.code.touched = true;
    validate();
  }
);

onMounted(() => {
  // console.log("onMounted");
  reset();
});
onUnmounted(() => {
  // console.log("onUnmounted");
  stopTimer();
});

/**
 * 초기화
 */
function reset(): void {
  // console.log("reset");
  startTimer();
  requestAnimationFrame(() => {
    codeRef.value?.focus();
    controls.code.touched = false;
  });
}
function submit(event: Event): void {
  emit("submit", event);
  event.preventDefault();
}
</script>
<template>
  <!-- 인증 메일 발송완료 메시지-->
  <div class="popup">
    <i class="icon icon-valid mr-1"></i>
    <span>{{ localize("인증 메일을 발송하였습니다. 메일함 또는 스팸 메일함을 확인해주세요.") }}</span>
    <div>{{ state.remainTime.minutes }} : {{ state.remainTime.seconds }}</div>
  </div>
  <!-- //인증 메일 발송완료 메시지-->

  <!-- 인증 코드 입력-->
  <div class="form-group mt-3">
    <label class="form-label" for="name">{{ localize("인증 코드") }}</label>
    <div class="input-group">
      <!-- [D] : 인증미완료: is-invalid / 인증완료: is-valid -->
      <input
        ref="codeRef"
        type="text"
        class="form-control curved"
        :class="{
          'is-invalid': controls.code.touched && invalid('code'),
          'is-valid': valid('code'),
        }"
        v-model="controls.code.value"
        @keyup="touch('code')"
        @keydown.enter="submit"
        :maxlength="6"
      />

      <!-- 인증 코드 에러 메시지-->
      <div v-show="controls.code.touched && errors?.code?.required" class="invalid-feedback">
        * {{ localize("인증 코드를 입력하세요.") }}
      </div>
      <div v-show="controls.code.touched && errors?.code?.expired" class="invalid-feedback">
        * {{ localize("인증 코드의 유효 시간이 만료되었습니다.") }}
      </div>
      <div v-show="controls.code.touched && errors?.code?.minLength" class="invalid-feedback">
        * {{ localize("6자리 인증 코드를 입력하세요.") }}
      </div>

      <!-- 올바른 인증 코드-->
      <div v-show="valid()" class="valid-feedback">* {{ localize("올바른 인증 코드.") }}</div>
    </div>
  </div>
  <!-- //인증 코드 입력-->
</template>
