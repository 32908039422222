import { RouteRecordRaw } from "vue-router";
import Home from "@/views/playground/player/support/membership/MembershipHome.vue";
import MemberSetting from "@/views/playground/player/support/membership/MemberSetting.vue";
import LeaveMember from "@/views/playground/player/support/membership/LeaveMember.vue";
import ChangeID from "@/views/playground/player/support/membership/ChangeID.vue";
import ChangePassword from "@/views/playground/player/support/membership/ChangePassword.vue";
import AlarmSetting from "@/views/playground/player/support/membership/AlarmSetting.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/playground/player/support/membership/home",
  },
  {
    path: "home",
    component: Home,
  },
  {
    path: "member-setting",
    component: MemberSetting,
  },
  {
    path: "leave",
    component: LeaveMember,
  },
  {
    path: "change-id",
    component: ChangeID,
  },
  {
    path: "change-password",
    component: ChangePassword,
  },
  {
    path: "alarm-setting",
    component: AlarmSetting,
  },
];

export default routes;
