import { RouteRecordRaw } from "vue-router";
import Dashboard from "@/views/playground/r2center/dashboard/Dashboard.vue";
import OrderManagement from "@/views/playground/r2center/order-management/OrderManagement.vue";
import BusinessManagement from "@/views/playground/r2center/business-management/BusinessManagement.vue";
import businessRoutes from "@/router/playground/r2center/business";
import orderRoutes from "@/router/playground/r2center/order";
import Welcome from "@/views/playground/r2center/Welcome.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/welcome`,
    // redirect: (to) => `/playground/${to.meta.role}/order`,
  },
  {
    path: "welcome",
    component: Welcome,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "order",
    component: OrderManagement,
    children: orderRoutes,
  },
  {
    path: "business",
    component: BusinessManagement,
    children: businessRoutes,
  },
];

export default routes;
