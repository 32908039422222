<script setup lang="ts">
import { localize } from "@/utils";
import PartnerHeader from "@/views/playground/player/partner/PartnerHeader.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation } from "swiper/modules";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
/**
 * 파트너 아이디
 */
const partnerId = route.params.partnerId;
console.log("partnerId", partnerId);

const state = reactive({
  photoItems: [
    { src: "/src/assets/playground/img/_temp/img3-1.jpg" },
    { src: "/src/assets/playground/img/_temp/img3-2.jpg" },
    { src: "/src/assets/playground/img/_temp/img3.jpg" },
  ],
});
onMounted(() => {
  // swiperRef.value!.swiper.update();
});
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <partner-header></partner-header>
    <div class="content">
      <div class="fieldset d-block wide">
        <div class="img-swiper-wrap">
          <div class="img-swiper">
            <swiper
              :loop="true"
              :modules="[Navigation]"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :slidesPerView="1"
              :slidesPerGroup="1"
            >
              <swiper-slide v-for="item in state.photoItems" :key="item.src">
                <img :src="item.src" alt="" />
              </swiper-slide>
            </swiper>
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
          </div>
          <div class="d-flex flex-column justify-content-between">
            <div>
              <h4 class="fs-3 mb-3">{{ localize("소개말") }}</h4>
              <p>
                어떤 일이 있더라도 반드시 기한 안에 해내는 언주플래너입니다 :-)<br />
                ㅇㅇㅇ, ㅇㅇㅇㅇㅇ, ㅇㅇ에 자신 있습니다.<br />
                믿고 맡겨주시면 최선을 다해 제작해드리겠습니다. 감사합니다.
              </p>
            </div>
            <div>
              <div class="thumbnail-file-wrap slide-thumbnail">
                <div>
                  <img src="/src/assets/playground/img/_temp/img3-1.jpg" alt="" />
                </div>
                <div>
                  <img src="/src/assets/playground/img/_temp/img3.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fieldset d-block wide">
        <div class="row">
          <div class="col-6">
            <h4 class="fs-3 mb-3">{{ localize("영업 정보") }}</h4>
            <ul class="list-icon manage-info">
              <li class="flex-center">
                <i class="icon icon-status"></i><b>{{ localize("영업 상태") }}</b
                ><span class="badge bg-grn">{{ localize("영업 중") }}</span>
              </li>
              <li class="flex-center">
                <i class="icon icon-clock"></i><b>{{ localize("영업 시간") }}</b
                >00:00 AM - 00:00 PM
              </li>
              <li class="flex-center">
                <i class="icon icon-calendar"></i><b>BUSY</b><span class="dot bg-grn mr-1"></span
                >{{ localize("한가함") }}
              </li>
            </ul>
          </div>
          <div class="col-6">
            <h4 class="fs-3 mb-3">{{ localize("업력 정보") }}</h4>
            <ul class="manage-info">
              <li><b class="period">1년 8개월</b> 2016년 하늘기공소 근무</li>
              <li><b class="period">1년 8개월</b> 2016년 하늘기공소 근무</li>
              <li><b class="period">1년 8개월</b> 2016년 하늘기공소 근무</li>
              <li><b class="period">1년 8개월</b> 2016년 하늘기공소 근무</li>
              <li><b class="period">1년 8개월</b> 2016년 하늘기공소 근무</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
