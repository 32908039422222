<script setup lang="ts">
import css from "@/assets/home/css/import.css?inline";
import { getFormatString, localize } from "@/utils";
import router from "@/router";
import HomeFooter from "@/views/home/HomeFooter.vue";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const emit = defineEmits<{
  (eventType: "change-css", style: string): void;
}>();

emit("change-css", css);

const route = useRoute();
/**
 * 상태 코드
 */
const statusCode = (route.params.code ?? "404") as string;

// localize('요청하신 페이지에 대한 액세스 권한이 없습니다.')
// localize('잘못된 경로로 접근하셨습니다.')
// localize('요청하신 페이지를 찾을 수 없습니다.')
// TODO: 에러 메시지 정리
const reasons: Record<string, Record<string, string>> = {
  "401": { title: "Unauthorized", message: "요청하신 페이지에 대한 액세스 권한이 없습니다." },
  "403": { title: "Forbidden", message: "잘못된 경로로 접근하셨습니다." },
  "404": { title: "Not found", message: "요청하신 페이지를 찾을 수 없습니다." },
};
const reason = reasons[statusCode];

/**
 * 상태
 */
const state = reactive({
  home: "/",
  reason: reason.title,
  description: reason.message,
});

onMounted(() => {
  router.beforeEach((to, from, next) => {
    // 이전 경로를 /로 분리해서 첫번째 경로를 대메뉴로 설정
    const home = `/${from.path.split("/").slice(1)[0]}`;
    next();
  });
});
/**
 * 이전 페이지로 이동
 */
function goBack() {
  router.go(-1);
}
</script>

<template>
  <div id="wrap">
    <main id="ct" class="mt-0">
      <!-- 페이지 시작 -->
      <div class="container support error">
        <div
          class="support-wrap p-0 text-center position-relative d-flex align-items-center flex-column justify-content-center"
        >
          <h1>
            ERROR
            <img src="@/assets/home/img/error/main-obj-1.svg" alt="" class="obj" />
            <img src="@/assets/home/img/error/main-obj-2.svg" alt="" class="obj" />
          </h1>
          <strong class="not-found fs-3 font-poppins">{{ state.reason }}</strong>
          <p class="fs-4 desc" v-html="localize(state.description)" />
          <div class="btn-wrap">
            <a class="btn btn-outline justify-content-center fsmall" @click="goBack">{{ localize("이전으로") }}</a>
            <router-link class="btn btn-primary justify-content-center fsmall" :to="{ path: '/', replace: true }">{{
              localize("홈 메인으로 이동")
            }}</router-link>
          </div>
          <img src="@/assets/home/img/error/error-obj.png" alt="" class="position-absolute error-obj" />
        </div>
      </div>
      <!-- //container -->
    </main>

    <home-footer />
  </div>
</template>

<style scoped></style>
