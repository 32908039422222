<script setup lang="ts">
import { localize } from "@/utils";
import PartnerHeader from "@/views/playground/player/partner/PartnerHeader.vue";
import { useRoute } from "vue-router";

const route = useRoute();
/**
 * 파트너 아이디
 */
const partnerId = route.params.partnerId;
console.log("partnerId", partnerId);
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <partner-header></partner-header>
    <ul class="row card-row">
      <li class="col-4">
        <div class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins"
              >R2 Planning & Guide Solution R2 Planning & Guide SolutionR2 Planning & Guide Solution</strong
            >
            <div class="service-item-info">
              <div class="text-nowrap">
                <p>
                  {{ localize("총 주문") }} <b>52{{ localize("건") }}</b>
                </p>
                <p>
                  {{ localize("진행 중인 주문") }} <b>3{{ localize("건") }}</b>
                </p>
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <a href="" class="btn btn-block btn-lg">{{ localize("주문하기") }}</a>
          </div>
        </div>
      </li>
      <li class="col-4">
        <div class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins">Customized Abutment & Temporary</strong>
            <div class="service-item-info">
              <div class="text-nowrap">
                <p>
                  {{ localize("총 주문") }} <b>52{{ localize("건") }}</b>
                </p>
                <p>
                  {{ localize("진행 중인 주문") }} <b>3{{ localize("건") }}</b>
                </p>
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <a href="" class="btn btn-block btn-lg">{{ localize("주문하기") }}</a>
          </div>
        </div>
      </li>
      <li class="col-4">
        <div class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins">Customized Abutment & Temporary</strong>
            <div class="service-item-info">
              <div class="text-nowrap">
                <p>
                  {{ localize("총 주문") }} <b>52{{ localize("건") }}</b>
                </p>
                <p>
                  {{ localize("진행 중인 주문") }} <b>3{{ localize("건") }}</b>
                </p>
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <a href="" class="btn btn-block btn-lg">{{ localize("주문하기") }}</a>
          </div>
        </div>
      </li>
      <li class="col-4">
        <div class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins">R2 Planning & Guide Solution</strong>
            <div class="service-item-info">
              <div class="text-nowrap">
                <p>
                  {{ localize("총 주문") }} <b>52{{ localize("건") }}</b>
                </p>
                <p>
                  {{ localize("진행 중인 주문") }} <b>3{{ localize("건") }}</b>
                </p>
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <a href="" class="btn btn-block btn-lg">{{ localize("주문하기") }}</a>
          </div>
        </div>
      </li>
      <li class="col-4">
        <div class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins">Customized Abutment & Temporary</strong>
            <div class="service-item-info">
              <div class="text-nowrap">
                <p>
                  {{ localize("총 주문") }} <b>52{{ localize("건") }}</b>
                </p>
                <p>
                  {{ localize("진행 중인 주문") }} <b>3{{ localize("건") }}</b>
                </p>
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <a href="" class="btn btn-block btn-lg">{{ localize("주문하기") }}</a>
          </div>
        </div>
      </li>
      <li class="col-4">
        <div class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins">Customized Abutment & Temporary</strong>
            <div class="service-item-info">
              <div class="text-nowrap">
                <p>
                  {{ localize("총 주문") }} <b>52{{ localize("건") }}</b>
                </p>
                <p>
                  {{ localize("진행 중인 주문") }} <b>3{{ localize("건") }}</b>
                </p>
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <a href="" class="btn btn-block btn-lg">{{ localize("주문하기") }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
