<script setup lang="ts">
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { onMounted, reactive } from "vue";
import { getFileSize, localize } from "@/utils";
import { useClientWrapperStore } from "@/store";
import { IListState } from "@/models/i-list-state.ts";
import { toRaw } from "@vue/reactivity";
import { ScanPurposeType } from "@/models";

const scanPurpose = [{ name: "Health Report", value: ScanPurposeType.HealthReport }];
// localize('케이스 순')
// localize('스캔일자 순')
const orderBy = [
  { name: "케이스 순", value: "caseNo" },
  { name: "스캔일자 순", value: "scanPurpose" },
];

/**
 * 정렬 옵션
 */
const reset: Record<string, any> = {
  scanPurpose: 0,
  orderBy: orderBy[0].value,
};
/**
 * 검색 조건 초기화를 위한 기본값
 */
const state: IListState = reactive({
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
  usages: null,
  scanPurpose: reset.scanPurpose,
  orderBy: reset.orderBy,
});

onMounted(init);

/**
 * 초기화
 */
function init() {
  request();
  requestUsage();
}
/**
 * 검색 조건 초기화
 */
function resetForm(): void {
  for (const key in reset) state[key] = reset[key];
  request();
}
function request(page: number = 1): void {
  console.log("request", toRaw(state));
  const variables = {
    condition: {
      fields: [
        { name: "keyword", value: state.keyword },
        // {"name": "scanPurpose", "value": state.scanPurpose},
      ],
      orderBy: {
        field: state.orderBy,
        orderBy: "DESC",
      },
    },
    page: page,
  };
  const query = `
    query GetCloudCases(
      $condition: SearchConditionInput = {}
      $page: Int = 1
      $pageSize: Int = 10
    ) {
      cloudCases(condition: $condition, page: $page, pageSize: $pageSize) {
        data{
          id
          caseNo
          patientName
          scanPurpose
          scanDate
          attachments{
            id
            fileName
            fileSize
          }
          owner{
            id
            name
            image {
              id
              fileName
            }
          }
        }
        paging{
          currentPage,
          totalItems,
        }
      }
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("request - successCallback: ", result.cloudCases);
  //       const paging = result.cloudCases.paging;
  //       state.page = paging.currentPage;
  //       state.totalRows = paging.totalItems;
  //       // state.items = (result.partners.data as Item[]).map(item => reactive(item));
  //       state.items = result.cloudCases.data;
  //     },
  //   }
  // );
}
function requestUsage(): void {
  console.log("requestUsage");
  const variables = {
    playerId: "1", //TODO: playerId 실제 가져오기
  };
  const query = `
    query GetUsedCloudStorageSizedGet(
      $playerId: ID!
    ){
      usedCloudStorageSizedGet(
        playerId: $playerId
      ){
        usedSize
        totalSize
      }
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("requestUsage - successCallback: ", result.usedCloudStorageSizedGet);
  //       state.usages = result.usedCloudStorageSizedGet;
  //     },
  //   }
  // );
}
</script>

<template>
  <h1 class="hd-title">My Cloud</h1>
  <div id="ct" class="flex-layout-body">
    <div class="box">
      <form @submit.prevent="request()" @reset.prevent="resetForm">
        <div class="row form-row">
          <div class="search col">
            <search-keyword :show-reset="true" :state="state" :placeholder="localize('Case, 환자 이름 검색')" />
          </div>
          <div class="col-auto flex-center">
            <div class="form-label">{{ localize("스캔 목적") }}</div>
            <select class="form-control form-select mr-3" v-model="state.scanPurpose">
              <option v-for="item in scanPurpose" :value="item.value">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-auto flex-center">
            <div class="form-label">{{ localize("정렬") }}</div>
            <div class="btn-check-wrap">
              <label class="btn-check">
                <input type="radio" name="c1" class="sr-only" />
                <span class="btn btn-outline-primary">{{ localize("케이스 순") }}</span>
              </label>
              <label v-for="item in orderBy" class="btn-check">
                <input type="radio" class="sr-only" @change="request()" v-model="state.orderBy" :value="item.value" />
                <span class="btn btn-outline-primary">{{ localize(item.name) }}</span>
              </label>
            </div>
          </div>
          <div v-if="state.usages" class="col-auto flex-center usage">
            <div class="form-label mr-3">Usage</div>
            <div class="flex-center">
              <b class="usage-amount">{{ getFileSize(state.usages.usedSize) }}</b
              >/ {{ getFileSize(state.usages.totalSize) }}
            </div>
          </div>
        </div>
      </form>
    </div>

    <!-- cloud 목록 -->
    <div class="content">
      <table class="tb">
        <colgroup>
          <col style="width: 36%" />
          <col span="3" />
          <col style="width: 12%" />
        </colgroup>
        <thead>
          <tr>
            <th>Case</th>
            <th>{{ localize("환자 이름") }}</th>
            <th>{{ localize("스캔 목적") }}</th>
            <th>{{ localize("스캔 일자") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <!-- cloud -->
          <tr v-for="item in state.items">
            <td class="font-weight-bold">{{ item.caseNo }}</td>
            <td class="font-weight-bold">{{ item.patientName }}</td>
            <td>Health Report</td>
            <td>
              {{ item.scanDate
              }}<!--<span class="time">{{ item.scanDate }}</span>-->
            </td>
            <td>
              <button v-for="file in item.attachments" :title="file.fileName" class="icon icon-download">
                {{ localize("다운로드") }}
              </button>
              <button class="icon icon-delete">{{ localize("삭제") }}</button>
              <!--            <button class="icon icon-download" @click="downloadAttachment">{{ localize('다운로드') }}</button>-->
              <!--            <button class="icon icon-delete" @click="deleteAttachment">{{ localize('삭제') }}</button>-->
            </td>
          </tr>
          <!-- //cloud -->
        </tbody>
      </table>
      <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
    </div>
    <!-- //cloud 목록 -->
  </div>
</template>

<style scoped></style>
