<script setup lang="ts">
import { inject, reactive } from "vue";
import { localize } from "@/utils";
import { ServiceSettingAddProps } from "./ServiceSettingAdd.vue";
const data = inject<ServiceSettingAddProps>("service-setting-add-state")!;
</script>

<template>
  <li :class="{ active: data.step == 1 }">
    <span
      >서비스 선택 및 작업 시간 지정
      <b class="step-desc">{{ data.service.name }}</b>
    </span>
  </li>
  <li :class="{ active: data.step == 2 }">
    <span
      >이미지 등록
      <b class="step-desc">1개의 이미지 등록됨 </b>
    </span>
  </li>
  <li :class="{ active: data.step == 3 }">
    <span
      >작업 대금 설정
      <b class="step-desc">Starting 40P </b>
    </span>
  </li>
  <li :class="{ active: data.step == 4 }">
    <span
      >등록 완료
      <b class="step-desc"></b>
    </span>
  </li>
</template>

<style scoped></style>
