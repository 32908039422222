import {defineStore} from "pinia";
import {ref} from "vue";

export type LocaleType = 'ko-KR' | 'en-US'

export class LocaleItem {
    key = '';
    'ko-KR' = '';
    'en-US' = '';
}

export const useLocalizationStore = defineStore('localization', () => {

    /**
     * 키는 존재하지만 설정 언어가 null인 경우 대신 키를 보여줄지 여부
     */
    const showKeyInsteadOfNull = true;
    /**
     * 다국어 현지화 자료 목록
     */
    const _items = ref<LocaleItem[]>([]);
    /**
     * 설정 언어
     */
    const _language = ref<LocaleType>('ko-KR');
    /**
     * date-fns 라이브러리에서 사용하는 로케일
     */
    const _locale = ref();
    /**
     * 다국어 현지화 자료 목록 반환
     */
    function getItems(): LocaleItem[] {
        return _items.value;
    }
    /**
     * 다국어 현지화 자료 목록 설정
     * @param value
     */
    function setItems(value: LocaleItem[]): void {
        _items.value = value;
    }
    /**
     * 설정된 언어 반환
     */
    function getLanguage(): LocaleType {
        return _language.value;
    }
    /**
     * 언어 설정
     * @param value
     */
    async function setLanguage(value: LocaleType) {
        _language.value = value;
        localStorage.setItem('language', value);
        const fnsLocale = await import(`date-fns/locale`);
        if(value == 'ko-KR') {
            _locale.value = fnsLocale.ko;
        }else {
            _locale.value = fnsLocale.enUS;
        }
        // console.log(_locale.value);
    }
    /**
     * 설정된 로케일 반환
     */
    function getLocale() {
        return _locale.value;
    }

    /**
     * 사용자 언어 초기화
     * 로컬저장소에 설정이 없는 경우 현재 브라우져 언어로 설정하고 저장
     * @returns {string}
     */
    async function initLanguage(): Promise<void> {
        const value = localStorage.getItem('language');
        if (!value) {
            let defaultLanguage = navigator.language;
            if (!(defaultLanguage in new LocaleItem())) defaultLanguage = 'en-US';
            // console.log(!(defaultLanguage in new LocaleItem()));
            await setLanguage(defaultLanguage as LocaleType);
        } else {
            await setLanguage(value as LocaleType);
        }
    }
    /**
     * 해당하는 언어의 문자열 반환
     * 외부 노출용
     * @param {string} key
     * @returns {string}
     */
    function pick(key: string): string {
        // console.log(key);
        if (!_items.value) {
            console.log('다국어 현지화 자료가 없습니다.');
            return decodeSpecialCharacters(key);
        }
        // console.log(`key: ${key}, items.value.length: ${_items.value}`);
        const foundItems = _items.value.filter(item => {
            return (item as LocaleItem).key == key;
            // return (item as LocaleItem).key == key.replace(/'/g, "\\'");
        });
        // console.log('foundItems', foundItems.length);
        if (foundItems.length > 0) {
            // console.log('found:', foundItems[0], ', language:', _language.value, ', result:', foundItems[0][_language.value]);
            const foundWords = foundItems[0][_language.value];
            if (showKeyInsteadOfNull && !foundWords) { // 키는 존재하지만 해당 언어의 값이 없는 경우
                return decodeSpecialCharacters(key);
            }
            return decodeSpecialCharacters(foundWords);
        } else {
            // console.log(`해당 현지화 자료가 없습니다. - key: '${key}'`);
            return decodeSpecialCharacters(key);
        }
    }

    function decodeSpecialCharacters(str: string): string {
        // console.log(str, str.replace(/###/g, '<'));
        return str;
        // return str.replace(/###/g, '<');
    }

    return {getItems, setItems, getLocale, getLanguage, setLanguage, initLanguage, pick};

});
