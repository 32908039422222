<script setup lang="ts">
import { blockPage } from "@/services";
import { useJoinStore } from "@/store";
import { localize } from "@/utils";
import { onBeforeRouteLeave } from "vue-router";

const { member } = useJoinStore();

onBeforeRouteLeave(blockPage("/home/join/optional"));
</script>

<template>
  <div class="join-box">
    <div class="progress-line">
      <div class="line" style="width: 100%"></div>
    </div>
    <div class="step-wrap">
      <b>STEP 5</b>
      <h2>{{ localize("회원 가입 완료") }}</h2>
    </div>
    <div class="welcome">
      <img src="@/assets/home/img/icon-join-complete.png" alt="" />
      <p>{{ localize("R2 Player가 되신 것을 환영합니다.") }}</p>
    </div>
    <div class="bx-purple">
      <b>{{ member.emailAddress }}</b
      >{{ localize("님") }}<br />
      {{ localize("로그안하시면 서비스를 이용하실 수 있습니다.") }}
    </div>
    <div class="btn-wrap">
      <router-link to="/home/login" class="btn btn-primary center w-100">{{ localize("로그인") }}</router-link>
    </div>
  </div>
</template>

<style scoped></style>
