import { defineStore } from "pinia";
import { QueryRequest, QueryResponse, ServiceBase, ServiceFactory } from "@/libs/R2ServiceClient";
import { alert, hideLoading, showLoading } from "@/services";
import { OperationVariables, TypedDocumentNode } from "@apollo/client";

export const useClientWrapperStore = defineStore("client-wrapper", () => {
  const serviceFactory = ServiceFactory.instance;
  const visitor = new ServiceWrapperBase(serviceFactory.getVisitorService());
  const user = new ServiceWrapperBase(serviceFactory.getUserService());

  return { visitor, user };
});

export class ServiceWrapperBase {
  constructor(private queryService: ServiceBase) {}

  /**
   * 타입이 정의된 쿼리를 실행한다
   * @param document 쿼리 문서
   * @param options 옵션들
   * @param options.variables 파라메터
   * @param options.successCallback 성공 시 호출되는 콜백 함수
   * @param options.errorCallback 에러 시 호출되는 콜백 함수
   * @param options.afterErrorCallback 에러 후 호출되는 콜백 함수
   * @param options.finallyCallback 마지막에 호출되는 콜백 함수
   * @param options.noLoading 로딩 미사용 여부
   * @returns 응답
   */
  public async typedQuery<TResponse, TVariables extends OperationVariables>(
    document: TypedDocumentNode<TResponse, TVariables>,
    options: {
      variables?: TVariables;
      successCallback?: (data: TResponse) => void;
      errorCallback?: (error: any) => void;
      afterErrorCallback?: (errors: any) => void;
      finallyCallback?: () => void;
      noLoading?: boolean;
    } = { variables: {} as TVariables, noLoading: false }
  ): Promise<QueryResponse<TResponse>> {
    const { variables, noLoading } = options;
    if (noLoading != true) showLoading();
    const response = await this.queryService.typedQuery(document, variables ?? ({} as TVariables));
    this._processResponse(response, options);
    return response;
  }

  /**
   * 타입이 정의된 뮤테이션을 실행한다
   * @param document 쿼리 문서
   * @param options 옵션들
   * @param options.variables 파라메터
   * @param options.successCallback 성공 시 호출되는 콜백 함수
   * @param options.errorCallback 에러 시 호출되는 콜백 함수
   * @param options.afterErrorCallback 에러 후 호출되는 콜백 함수
   * @param options.finallyCallback 마지막에 호출되는 콜백 함수
   * @param options.noLoading 로딩 미사용 여부
   * @returns 응답
   */
  public async typedMutate<TResponse, TVariables extends OperationVariables>(
    document: TypedDocumentNode<TResponse, TVariables>,
    options: {
      variables?: TVariables;
      successCallback?: (data: TResponse) => void;
      errorCallback?: (errors: any) => void;
      afterErrorCallback?: (error: any) => void;
      finallyCallback?: () => void;
      noLoading?: boolean;
    } = { variables: {} as TVariables, noLoading: false }
  ): Promise<QueryResponse<TResponse>> {
    const { variables, noLoading } = options;
    if (noLoading != true) showLoading();
    const response = await this.queryService.typedMutate(document, variables ?? ({} as TVariables));
    this._processResponse(response, options);
    return response;
  }

  /**
   *
   * @param response 응답
   * @param options 옵션들
   * @param options.successCallback 성공 시 호출되는 콜백 함수
   * @param options.errorCallback 에러 시 호출되는 콜백 함수
   * @param options.afterErrorCallback 에러 후 호출되는 콜백 함수
   * @param options.finallyCallback 마지막에 호출되는 콜백 함수
   * @param options.noLoading 로딩 미사용 여부
   */
  private _processResponse<TResponse = any>(
    response: QueryResponse<TResponse>,
    options: {
      successCallback?: (data: TResponse) => void;
      errorCallback?: (errors: any) => void;
      afterErrorCallback?: (errors: any) => void;
      finallyCallback?: () => void;
      noLoading?: boolean;
    } = { noLoading: true }
  ): void {
    const { noLoading } = options;
    if (response.isSuccess) {
      options.successCallback?.(response.data!);
    } else {
      if (options.errorCallback != null) {
        options.errorCallback!(response.errors);
      } else {
        response.errors?.forEach((err: { extensions: { code: any; message: any } }) => {
          console.log(`에러: ${err.extensions.code}, ${err.extensions.message}`);
          alert(err.extensions.message);
        });
      }
      options.afterErrorCallback?.(response.errors);
    }
    if (noLoading != true) hideLoading();
    options.finallyCallback?.();
  }
}
