import { DocumentNode } from 'graphql';
import { OperationVariables, TypedDocumentNode } from '@apollo/client';
import { graphql } from 'gql.tada'
import { GraphQLClient } from '../../base';
import { QueryRequest, QueryResponse, LogicError, LogicErrorExtensions } from './type';

interface IQueryService {
    query<TResponse = any, TValue extends Record<string, any> | undefined = Record<string, any>>(request: QueryRequest<TValue>): Promise<QueryResponse<TResponse>>;
    mutate<TResponse = any, TValue extends Record<string, any> | undefined = Record<string, any>>(request: QueryRequest<TValue>): Promise<QueryResponse<TResponse>>;
}

abstract class ServiceBase implements IQueryService {
    protected readonly _client: GraphQLClient;

    constructor(graphqlClient: GraphQLClient) {
        this._client = graphqlClient;
    }

    protected transformErrors(errors: any[]): LogicError[] {
        return errors.map(err =>{
            const ext: any = err.cause.extensions

            let errObj = {
                message: ext?.message || err.message,
                extensions :{
                    code: ext?.code || 'UNKNOWN_ERROR',
                    message: ext?.message || err.message,
                    description: ext?.description || '',
                }
            }

            return errObj
        });
    }

    async query<TResponse = any, TValue extends Record<string, any> | undefined = Record<string, any>>(
        request: QueryRequest<TValue>
    ): Promise<QueryResponse<TResponse>> {
        const response = await this._client.query<TResponse, TValue>({
            query: request.query,
            variables: request.variables,
        });

        return {
            data: response.data,
            errors: response.errors ? this.transformErrors(response.errors) : [],
            isSuccess: response.isSuccess,
        };
    }

    async typedQuery<TResponse, TVariables extends OperationVariables>(
        document: TypedDocumentNode<TResponse, TVariables>,
        variables: TVariables
    ): Promise<QueryResponse<TResponse>> {
        // 필요시 형 변환을 적용하여 TVariables를 OperationVariables로 처리
        const response = await this._client.typedQuery<TResponse, OperationVariables>(
            document as TypedDocumentNode<TResponse, OperationVariables>,
            variables as OperationVariables
        );

        return {
            data: response.data,
            errors: response.errors ? this.transformErrors(response.errors) : [],
            isSuccess: response.isSuccess,
        };
    }

    async mutate<TResponse = any, TValue extends Record<string, any> | undefined = Record<string, any>>(
        request: QueryRequest<TValue>
    ): Promise<QueryResponse<TResponse>> {
        const response = await this._client.mutate<TResponse, TValue>({
            query: request.query,
            variables: request.variables,
        });

        return {
            data: response.data,
            errors: response.errors ? this.transformErrors(response.errors) : [],
            isSuccess: response.isSuccess,
        };
    }

    async typedMutate<TResponse, TVariables extends OperationVariables>(
        document: TypedDocumentNode<TResponse, TVariables>,
        variables: TVariables
    ): Promise<QueryResponse<TResponse>> {
        // 필요시 형 변환을 적용하여 TVariables를 OperationVariables로 처리
        const response = await this._client.typedMutate<TResponse, OperationVariables>(
            document as TypedDocumentNode<TResponse, OperationVariables>,
            variables as OperationVariables
        );

        return {
            data: response.data ?? null,
            errors: response.errors ? this.transformErrors(response.errors) : [],
            isSuccess: response.isSuccess,
        };
    }

    subscribe<TData = any>(
        query: string | DocumentNode, 
        variables?: Record<string, any>, 
        customHeaders?: Record<string, string>, 
        callback?: (data: TData) => void
    ) {
        return this._client.subscribe<TData>(
            query,
            variables,
            customHeaders,
            callback
        );
    }
}

export { ServiceBase }