<script setup lang="ts">
import {useRoute} from "vue-router";
import {useTestStore} from "@/store";
import {reactive} from "vue";
import router from "@/router";

const route = useRoute();
const store = useTestStore().written;
const state = reactive({
  answers: store.quizItems.map(item => item.userAnswer),
});

function done() {
  // TODO: 시험 종료 처리 구현 필요
  console.log("done");
  router.replace({
    name: "Result",
    params: {
      type: "written",
      score: 80,
    }
  });
}
</script>

<template>
  <h1>Checking</h1>
  <p>route.params.license: {{route.params.license}} / <br/>제출 답안:{{ JSON.stringify(state.answers) }}</p>
  <button @click="done">채점완료</button>
</template>

<style scoped>

</style>