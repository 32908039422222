import { RouteRecordRaw } from "vue-router";
import Dashboard from "@/views/playground/planner/dashboard/Dashboard.vue";
import OrderManagement from "@/views/playground/planner/order-management/OrderManagement.vue";
import BusinessManagement from "@/views/playground/planner/business-management/BusinessManagement.vue";
import businessRoutes from "@/router/playground/planner/business";
import orderRoutes from "@/router/playground/planner/order";
import Welcome from "@/views/playground/planner/Welcome.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/welcome`,
    //redirect: (to) => `/playground/${to.meta.role}/order`,
  },
  {
    path: "welcome",
    component: Welcome,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "order",
    component: OrderManagement,
    children: orderRoutes,
  },
  {
    path: "business",
    component: BusinessManagement,
    children: businessRoutes,
  },
];

export default routes;
