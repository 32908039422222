import { RouteRecordRaw } from "vue-router";
import Profile from "@/views/playground/player/player-space/Profile.vue";
import Messaging from "@/views/playground/player/player-space/messaging/Messaging.vue";
import Point from "@/views/playground/player/player-space/point/Point.vue";
import messagingRoutes from "@/router/playground/player/player-space/messaging";
import pointRoutes from "@/router/playground/player/player-space/point";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/player-space/profile`,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "messaging",
    component: Messaging,
    children: messagingRoutes,
  },
  {
    path: "point",
    component: Point,
    children: pointRoutes,
  },
];

export default routes;
