<script setup lang="ts">
import { alert } from "@/services";
import { localize } from "@/utils";
import HomeFooter from "@/views/home/HomeFooter.vue";
import HomeHeader from "@/views/home/HomeHeader.vue";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref, Ref } from "vue";

const downloadLink: Ref<string> = ref("https://download.r2playground.com");
</script>

<template>
  <div id="wrap" class="r2playground">
    <home-header />

    <!-- container -->
    <!-- 페이지 시작 -->
    <main id="ct">
      <div class="player-intro illust-intro">
        <span>{{ localize("일하는 것이 가장 즐거운 놀이다") }}</span>
        <h1 class="heading">R2 Player</h1>
        <p
          class="fs-4"
          v-html="
            localize('Simple implant case부터 복잡한 심미, 전악 보철까지 <br class=\'d-none d-lg-block\' />각 상황에 맞는 R2 solution을 만나 보세요.')
          "
        />
        <div class="player-intro-illu">
          <div class="r2 d-none d-md-block"></div>
          <img src="@/assets/home/img/playground/r2.png" class="d-md-none" alt="" />
          <swiper
            :loop="true"
            :observer="true"
            :observe-parents="true"
            :modules="[Navigation, Pagination]"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :pagination="{
              el: '.illust-intro .swiper-pagination',
              type: 'bullets',
            }"
          >
            <swiper-slide>
              <router-link :to="{ name: 'home-r2-solution', query: { section: 0 } }" class="illu-s1">
                <strong>
                  R2 Digital
                  <br />
                  Implant
                </strong>
                <div class="txt" v-html="localize('case의 임플란트 진단 및<br />R2 Guide \& ONE-DAY Solution')" />
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'home-r2-solution', query: { section: 1 } }" class="illu-s2">
                <strong>
                  Digital Oral
                  <br />
                  Design (D.O.D)
                </strong>
                <div class="txt">{{ localize("전악 심미 보철 Solution") }}</div>
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'home-r2-solution', query: { section: 2 } }" class="illu-s4">
                <strong>FACE GIDE</strong>
                <div class="txt" v-html="localize('정확하고, 안전한<br />디지털 양악, 재건 Solution')" />
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <router-link :to="{ name: 'home-r2-solution', query: { section: 3 } }" class="illu-s3">
                <strong>R2 Otrho</strong>
                <div class="txt" v-html="localize('완벽한 심미의 마무리를 위한<br />성인 투명 교정 Solution')" />
              </router-link>
            </swiper-slide>
            <swiper-slide>
              <!-- <a @click="openInNewTab('/academy')" class="illu-l1"> -->
              <a @click="alert(localize('준비 중 입니다.'))" class="illu-l1">
                <strong>R2 Academy</strong>
                <div class="txt" v-html="localize('R2 사용의 모든 것!<br />R2 Academy에서 알려드려요')" />
              </a>
            </swiper-slide>
            <swiper-slide>
              <!--<router-link to="/home/playground/planner" class="illu-l2">
                <strong>
                  R2 Planning
                  <br />
                  & Design Service
                </strong>
                <div class="txt" v-html="localize('공인된 R2 Specialist의 진단과<br />디자인 서비스를 경험 하세요')" />
              </router-link>-->
              <a @click="alert(localize('준비 중 입니다.'))" class="illu-l2">
                <strong>
                  R2 Planning
                  <br />
                  & Design Service
                </strong>
                <div class="txt" v-html="localize('공인된 R2 Specialist의 진단과<br />디자인 서비스를 경험 하세요')" />
              </a>
            </swiper-slide>
            <swiper-slide>
              <!--<router-link to="/home/playground/r2center" class="illu-l3">
                <strong>R2 Center Service</strong>
                <div
                  class="txt"
                  v-html="localize('R2 digital Center에서 high quality의<br />보철물을 주문하실 수 있습니다.')"
                />
              </router-link>-->
              <a @click="alert(localize('준비 중 입니다.'))" class="illu-l3">
                <strong>R2 Center Service</strong>
                <div class="txt" v-html="localize('R2 digital Center에서 high quality의<br />보철물을 주문하실 수 있습니다.')" />
              </a>
            </swiper-slide>
            <div class="swiper-pagination"></div>
          </swiper>
        </div>
      </div>
      <div class="container">
        <section class="sect">
          <div class="subtitle-wrap">
            <h2 class="font-poppins">
              The Joyable
              <br />
              R2 Solutions
            </h2>
            <p
              v-html="
                localize('R2 planner는 디지털 전문가로써,<br />다양한 전문영역에서 역량을 발휘 하고, 맞춤형 서비스를 제공합니다.')
              "
            />
          </div>
          <swiper
            class="text-pagination-slider"
            :loop="true"
            :observer="true"
            :observe-parents="true"
            :modules="[Navigation, Pagination]"
            :navigation="{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }"
            :pagination="{
              el: '.text-pagination-slider .swiper-pagination',
              type: 'bullets',
            }"
          >
            <swiper-slide>
              <div class="swiper-img">
                <img src="@/assets/home/img/playground/r2player1-1.png" alt="" />
              </div>
              <div class="btn-desc">
                <h3>R2 Digital Implant</h3>
                <p
                  v-html="
                    localize('R2 Playground에서 이용가능한<br/> 다양한 Software를 통해<br/> Simple case의 임플란트 진단 및<br/> ONE-DAY Solution을 경험해 보세요.')
                  "
                />
                <router-link :to="{ name: 'home-r2-solution', query: { section: 0 } }" class="btn btn-primary">
                  {{ localize("바로가기") }}
                  <i class="icon icon-arrow"></i>
                </router-link>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-img">
                <img src="@/assets/home/img/playground/r2player1-2.png" alt="" />
              </div>
              <div class="btn-desc">
                <h3>
                  R2studio Q
                  <br />
                  Digital Oral Design
                </h3>
                <p v-html="localize('전악 심미보철 Solution')" />
                <router-link :to="{ name: 'home-r2-solution', query: { section: 1 } }" class="btn btn-primary">
                  {{ localize("바로가기") }}
                  <i class="icon icon-arrow"></i>
                </router-link>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-img">
                <img src="@/assets/home/img/playground/r2player1-3.png" alt="" />
              </div>
              <div class="btn-desc">
                <h3>Meg Aligner</h3>
                <p
                  v-html="
                    localize('완벽한 심미 마무리로 성인 투명 교정 서비스<br /> 부족한 공간 교정, 삐뚤한 치아교정<br /> 투명교정 서비스를 경험해 보세요.')
                  "
                />
                <router-link :to="{ name: 'home-r2-solution', query: { section: 6 } }" class="btn btn-primary">
                  {{ localize("바로가기") }}
                  <i class="icon icon-arrow"></i>
                </router-link>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-img">
                <img src="@/assets/home/img/playground/r2player1-4.png" alt="" />
              </div>
              <div class="btn-desc">
                <h3>FaceGIDE</h3>
                <p
                  v-html="
                    localize('이미 많은 의사가 선택한 디지털 양악<br /> 양악, 재건 수술을 톡톡히<br /> 도와주는 FACEGIDE Solution을<br /> 경험해 보세요.')
                  "
                />
                <router-link :to="{ name: 'home-r2-solution', query: { section: 2 } }" class="btn btn-primary">
                  {{ localize("바로가기") }}
                  <i class="icon icon-arrow"></i>
                </router-link>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-img">
                <img src="@/assets/home/img/playground/r2player1-5.png" alt="" />
              </div>
              <div class="btn-desc">
                <h3>
                  R2studio Q
                  <br />
                  Smile Creator
                </h3>
                <p
                  v-html="
                    localize('R2Q면, 즉시 상담이 가능한 Ai Smile Creator<br /> 환자를 스캔하면 상담은 5분안에 가능<br /> 환자의 치과에 대한 신뢰는 평생!')
                  "
                />
                <router-link :to="{ name: 'home-r2-solution', query: { section: 7 } }" class="btn btn-primary">
                  바로가기
                  <i class="icon icon-arrow"></i>
                </router-link>
              </div>
            </swiper-slide>
            <div class="swiper-pagination pagination_bullet"></div>
            <div class="swiper-controls type-line">
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </swiper>
        </section>
        <section class="sect">
          <div class="subtitle-wrap">
            <h2 class="font-poppins">
              Let’ Play
              <br />
              Together
            </h2>
            <span
              v-html="
                localize('R2 Playground 온라인 주문 서비스로 빠르고 편리한 맞춤형 서비스를 경험하세요!<br />인증된 전세계의 R2 planner와 R2Center가 당신의 주문을 기다립니다.')
              "
            />
            <img src="@/assets/home/img/playground/r2player2-tit.png" alt="" class="subtit-illu" />
          </div>
          <div class="timeline">
            <ul>
              <li>
                <img src="@/assets/home/img/playground/r2player2-1.png" alt="" />
                <div class="btn-desc">
                  <h3>R2 Playground APP</h3>
                  <p v-html="localize('R2 Playground APP을 설치하고<br />W데이터 전송에서 주문까지 간편하게')" />
                  <a v-bind:href="downloadLink" target="_blank" class="btn btn-primary">
                    APP Download
                    <i class="icon icon-arrow"></i>
                  </a>
                </div>
              </li>
              <li>
                <img src="@/assets/home/img/playground/r2player2-2.png" alt="" />
                <div class="btn-desc">
                  <h3>R2 Planner</h3>
                  <p v-html="localize('R2 Planner의 즉각적이고,<br /> 편리한 디자인 서비스를 경험하세요.')" />
                  <!--<router-link to="planner" class="btn btn-primary">
                    R2 Planner
                    <i class="icon icon-arrow"></i>
                  </router-link>-->
                  <a @click="alert(localize('준비 중 입니다.'))" class="btn btn-primary">
                    R2 Planner
                    <i class="icon icon-arrow"></i>
                  </a>
                </div>
              </li>
              <li>
                <img src="@/assets/home/img/playground/r2player2-3.png" alt="" />
                <div class="btn-desc">
                  <h3>R2 Center</h3>
                  <p v-html="localize('Surgical Guide부터 맞춤형 보철물까지<br /> 전문적인 서비스를 경험하세요.')" />
                  <!--<router-link to="/home/playground/r2center" class="btn btn-primary">
                    R2 Center
                    <i class="icon icon-arrow"></i>
                  </router-link>-->
                  <a @click="alert(localize('준비 중 입니다.'))" class="btn btn-primary">
                    R2 Center
                    <i class="icon icon-arrow"></i>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </section>
        <section class="sect text-center">
          <h3 class="fs-3 mb-2">
            {{ localize("R2 Playground 를 더 재미있게 즐겨보세요") }}
          </h3>
          <p class="fs-4 mb-4">
            {{ localize("R2 Academy 에서는 다양한 교육 컨텐츠와 영상을 제공합니다.") }}
          </p>
          <a @click="alert(localize('준비 중 입니다.'))" class="btn btn-outline">
            R2 Academy
            <i class="icon icon-arrow-black"></i>
          </a>
          <div class="bubble-wrap">
            <div class="bubble-right">
              <div class="bubble">
                <span v-html="localize('R2 academy는 누구나 R2에 대한<br />모든 정보를 공유해드립니다.')" />
                <img src="@/assets/home/img/icon-smile.png" alt="" class="icon" />
              </div>
              <div class="bubble">
                {{ localize("R2의 모든 사용법을 알 수 있어요!") }}
              </div>
              <div class="bubble" v-html="localize('R2 동영상 콘텐츠로 쉽게! 빠르게!<br />재미있게! 학습해요')" />
            </div>
            <div>
              <img src="@/assets/home/img/playground/r2player3.jpg" alt="" />
            </div>
            <div class="bubble-left">
              <div class="bubble">
                <span v-html="localize('R2 사용중 모르는게 있다면<br />R2 academy를 이용해보세요')" />
                <img src="@/assets/home/img/icon-thumb.png" alt="" class="icon" />
              </div>
              <div class="bubble">
                {{ localize("R2 Player의 배움터로 오세요") }}
              </div>
              <div class="bubble">{{ localize("R2 Academy에서 만나요") }}</div>
            </div>
          </div>
        </section>
      </div>
      <!-- //container -->
    </main>
    <!-- footer -->
    <home-footer />
    <!-- //footer -->

    <div class="bg-blur"></div>
  </div>
</template>

<style scoped></style>
