import { RouteRecordRaw } from "vue-router";
import List from "@/views/playground/r2center/order-management/service-setting/ServiceSettingList.vue";
import Add from "@/views/playground/r2center/order-management/service-setting/add/ServiceSettingAdd.vue";
import Edit from "@/views/playground/r2center/order-management/service-setting/ServiceSettingEdit.vue";
import addRoutes from "@/router/playground/r2center/order/service/add";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/order/service/list`,
  },
  {
    path: "list",
    component: List,
  },
  {
    path: "add",
    component: Add,
    children: addRoutes,
  },
  {
    path: "edit/:id",
    component: Edit,
  },
];

export default routes;
