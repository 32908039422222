import { defineStore } from "pinia";
import { useClientWrapperStore } from "./client-wrapper";
import { graphql } from "@/libs/R2ServiceClient/service/Visitor";

export const useFaqStore = defineStore("faq", () => {
  const _categories = <{ [key: string]: FaqCategory[] }>{};

  /**
   * FAQ 분류 목록 조회
   */
  async function getCategories(boardId: string): Promise<FaqCategory[]> {
    // 캐시된 데이터가 있으면 반환
    if (_categories.hasOwnProperty(boardId)) {
      return _categories[boardId];
    }
    const Categories = graphql(`
      query Categories($boardId: String!) {
        categories(boardId: $boardId) {
          code
          name
        }
      }
    `);
    const variables = {
      boardId: boardId,
    };
    const response = await useClientWrapperStore().visitor.typedQuery(Categories, { variables: variables });
    if (response.data) {
      // console.log(`${boardId} - getCategories: `, response.data);
      // 받아온 데이터를 캐시
      _categories[boardId] = response.data.categories;
    }

    return _categories[boardId];
  }

  return { getCategories };
});

export interface FaqCategory {
  code: string;
  name: string;
}
