<script setup lang="ts">
import { useJoinStore, useNationStore, useClientWrapperStore } from "@/store";
import { useFormBuilder } from "@/services";
import { localize } from "@/utils";
import { vMaska } from "maska";
import { computed, onMounted, ref, toRaw } from "vue";
import router from "@/router";
import AddressPicker from "@/components/AddressPicker.vue";
import { graphql } from "@/libs/R2ServiceClient/service/User";

const { member, office } = useJoinStore();
// console.log("member: ", member, "office: ", office);

// member.id = 'multicg96@gmail.com';
// member.nationality = 'DE';
const isKorean = member.country.code == "KR";

const countriesMask = "+###";

const { getPhone } = useNationStore();
const phoneCode = computed(() => getPhone(member.country.code));

const { controls, errors, group, touch, validate, valid, invalid } = useFormBuilder();
group({
  phone: { value: "" },
  company: { value: "" },
  address1: { value: "" },
  address2: { value: "" },
  city: { value: "" },
  state: { value: "" },
  postalCode: { value: "" },
});

/**
 * 주소 검색 팝업창
 */
const addressPickerRef = ref<InstanceType<typeof AddressPicker> | null>(null);

onMounted(init);

/**
 * 초기화
 */
function init() {
  validate();
}

/**
 * 주소 검색 팝업창 열기
 */
function openAddressDialog(event: Event): void {
  addressPickerRef.value?.open();
  event.preventDefault();
}
/**
 * 주소 선택 핸들러
 * @param address 주소
 */
function onSelectAddress(address: string): void {
  controls.address1.value = address;
}
/**
 * 완료 핸들러
 */
async function done(): Promise<void> {
  validate(true);
  if (valid()) {
    office.name = controls.company.value;
    office.phoneNumber.countryCode = phoneCode.value;
    office.phoneNumber.number = controls.phone.value;
    office.address.address1 = controls.address1.value;
    office.address.address2 = controls.address2.value;
    office.address.city = controls.city.value;
    office.address.state = controls.state.value;
    office.address.postalCode = controls.postalCode.value;
    // console.log("done: ", member, office);
    const JoinMember = graphql(`
      mutation JoinMember($input: JoinMemberInput!) {
        joinMember(input: $input) {
          loginId
          isSuccess
        }
      }
    `);

    // TODO: email, sms 추가 후 코드 제거
    delete member.agreement.email;
    delete member.agreement.sms;

    const JoinMemberVariables = {
      input: {
        member: member,
        office: office,
      },
    };
    // console.log("JoinMemberVariables: ", JSON.stringify(JoinMemberVariables));
    const result = await useClientWrapperStore().user.typedMutate(JoinMember, {
      variables: JoinMemberVariables,
      successCallback: (result) => {
        // console.log("done - successCallback: ", result);
        if (result.joinMember.isSuccess) {
          // console.log("회원가입 성공 - loginId: ", result.joinMember.loginId);
          router.push("/home/join/complete");
        }
      },
    });
  }
}
</script>

<template>
  <div class="join-box">
    <div class="progress-line">
      <div class="line" style="width: 80%"></div>
    </div>
    <div class="step-wrap">
      <b>STEP 4</b>
      <h2>{{ localize("선택 정보 입력") }}</h2>
    </div>
    <div class="email">{{ member.loginId }}</div>
    <form @submit.prevent="done" class="join-form">
      <div class="form-group">
        <label class="form-label" for="tel">{{ localize("휴대폰 번호") }}</label>
        <div class="input-group">
          <p class="country-code">+{{ phoneCode }}</p>
          <input
            type="tel"
            id="tel"
            class="form-control curved"
            v-model="controls.phone.value"
            v-maska
            data-maska="#"
            data-maska-tokens="#:[0-9]:repeated"
            :placeholder="localize('숫자만 입력')"
          />
        </div>
      </div>

      <!-- 대한민국 전용-->
      <span v-if="isKorean">
        <div class="form-group">
          <label class="form-label" for="office-name">{{ localize("오피스 이름") }}</label>
          <div class="input-group">
            <input v-model="controls.company.value" type="text" id="office-name" class="form-control curved" />
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="office-address">{{ localize("오피스 주소") }}</label>
          <div class="input-group">
            <input
              v-model="controls.postalCode.value"
              type="text"
              id=""
              class="form-control curved"
              :placeholder="localize('우편번호')"
              disabled
            />
            <a href="#" @click="openAddressDialog" class="btn btn-primary center">{{ localize("검색") }}</a>
          </div>
          <div class="input-group mt-2">
            <input
              v-model="controls.address1.value"
              type="text"
              id=""
              class="form-control curved"
              :placeholder="localize('주소 입력')"
              disabled
            />
          </div>
          <div class="input-group mt-2">
            <input
              v-model="controls.address2.value"
              type="text"
              id="office-address"
              class="form-control curved"
              :placeholder="localize('상세 주소 입력')"
            />
          </div>
        </div>
      </span>
      <!-- //대한민국 전용-->

      <!-- 해외 전용-->
      <span v-else>
        <div class="form-group">
          <label class="form-label" for="office-name">{{ localize("사업장 이름") }}</label>
          <div class="input-group">
            <input v-model="controls.company.value" type="text" id="office-name" class="form-control curved" />
          </div>
        </div>
        <div class="form-group">
          <label class="form-label" for="office-address">{{ localize("사업장 주소") }}</label>
          <div class="mb-4">
            <label for="address1" class="detail mb-1">Address</label>
            <div class="input-group mt-1">
              <input
                v-model="controls.address1.value"
                type="text"
                id="address1"
                class="form-control curved"
                placeholder="Address 1"
              />
            </div>
            <div class="input-group mt-2">
              <input
                v-model="controls.address2.value"
                type="text"
                id="address2"
                class="form-control curved"
                placeholder="Address 2"
              />
            </div>
          </div>
          <div class="mb-4">
            <label for="city" class="detail mb-1">City</label>
            <div class="input-group">
              <input
                v-model="controls.city.value"
                type="text"
                id="city"
                class="form-control curved"
                placeholder="City"
              />
            </div>
          </div>
          <div class="mb-4">
            <label for="state-region" class="detail mb-1">State / Region</label>
            <div class="input-group">
              <input
                v-model="controls.state.value"
                type="text"
                id="state-region"
                class="form-control curved"
                placeholder="State / Region"
              />
            </div>
          </div>
          <div>
            <label for="postalCode" class="detail mb-1">Zip Code</label>
            <div class="input-group">
              <input
                v-model="controls.postalCode.value"
                type="text"
                id="postalCode"
                class="form-control curved"
                placeholder="Zip Code"
              />
            </div>
          </div>
        </div>
      </span>
      <!-- //해외 전용-->
    </form>
    <div class="btn-wrap">
      <a :class="{ disabled: invalid() }" @click="done" class="btn btn-primary center w-100">{{
        localize("회원가입")
      }}</a>
    </div>
  </div>

  <!--  주소 검색 팝업창-->
  <address-picker @select="onSelectAddress" ref="addressPickerRef" />
  <!--  //주소 검색 팝업창-->
</template>

<style scoped></style>
