<script setup lang="ts">
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { IListState } from "@/models/i-list-state.ts";
import router from "@/router";
import { localize } from "@/utils";
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
// localize("전체")
// localize("R2 솔루션")
// localize("R2 플레이그라운드")
// localize("역할 (플레이어)")
// localize("역할 (플래너)")
// localize("역할 (R2센터)")
// localize("역할 (R2아카데미)")
const menuItems = [
  { name: "전체", link: "all" },
  { name: "R2 솔루션", link: "r2-solution" },
  { name: "R2 플레이그라운드", link: "r2-playground" },
  { name: "역할 (플레이어)", link: "role-player" },
  { name: "역할 (플래너)", link: "role-planner" },
  { name: "역할 (R2센터)", link: "role-r2" },
  { name: "역할 (R2아카데미)", link: "role-r2-academy" },
];

const state: IListState = reactive({
  category: route.params.category,
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
});

request();

onMounted(() => {
  router.beforeEach((to, from, next) => {
    state.category = to.params.category;
    next();
    request();
  });
});
function request(page: number = 1): void {
  // console.log("request", state.category, state.keyword, state.page);
  const variables = {
    condition: {
      fields: [
        {
          name: "keyword",
          value: state.keyword,
        },
      ],
      orderBy: {
        field: "registerDate",
        orderBy: "DESC",
      },
    },
    page: page,
  };
  const query = `
    query GetFaqs($condition: SearchConditionInput, $page: Int) {
      faqs(condition: $condition, page: $page) {
        data {
          category {
            name
            description
          }
          title
          content
        }
        paging {
          totalItems
          currentPage
        }
      }
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("successCallback", result.faqs);
  //       const paging = result.faqs.paging;
  //       state.page = paging.currentPage;
  //       state.totalRows = paging.totalItems;
  //       state.items = result.faqs.data.map((item: Record<string, any>) => {
  //         return {
  //           ...item,
  //           active: false,
  //         };
  //       });
  //     },
  //     // finallyCallback: () => {
  //     //   alert("완료 후 공통 처리");
  //     // }
  //   }
  // );
}
</script>

<template>
  <h1 class="hd-title">FAQ</h1>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side narrow">
        <strong>주요 카테고리</strong>
        <ul class="faq-navi">
          <li v-for="item in menuItems" :class="{ active: item.link === state.category }">
            <router-link :to="item.link">{{ localize(item.name) }}</router-link>
          </li>
        </ul>
      </div>
      <div class="grid-content">
        <div class="box">
          <form @submit.prevent="request()">
            <div class="row form-row">
              <div class="search col">
                <p class="fs-3 mr-4">무엇을 도와드릴까요?</p>
                <search-keyword :state="state" :placeholder="localize('Case, 환자 이름 검색')" />
              </div>
            </div>
          </form>
        </div>
        <div class="content">
          <table class="tb faq">
            <tbody>
              <!-- FAQ 목록 -->
              <template v-for="item in state.items">
                <tr :class="{ active: item.active }">
                  <td @click="item.active = !item.active" data-toggle="table">
                    <strong class="tb-title"
                      ><span class="badge">{{ item.category.name }}</span
                      >{{ item.title }}</strong
                    >
                  </td>
                </tr>
                <tr class="table-collapse">
                  <td>
                    <p>{{ item.content }}</p>
                  </td>
                </tr>
              </template>
              <!-- //FAQ 목록 -->
            </tbody>
          </table>
          <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
