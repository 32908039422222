class FileUploadSource {
	private _sourceType: string
	private _environment: 'BROWSER' | 'OS'	//업로드 할 때, '웹 페이지'에서 등록하는가 /아니면/ '시스템'에서 등록하는가 여부를 파악한다
	private _browserDatas: File[]
	private _osFilePaths: string[]

	public constructor(sourceType: string = '') {
		this._sourceType = sourceType
		this._environment = (typeof window !== 'undefined' && window.sessionStorage !== null)? 'BROWSER' : 'OS'
		this._browserDatas = []
		this._osFilePaths = []
	}

	public get sourceType(): string {
		return this._sourceType
	}

	public get environment(): 'BROWSER' | 'OS' {
		return this._environment
	}

	public get browserDatas(): File[] {
		return this._browserDatas
	}

	public get osFilePaths(): string[] {
		return this._osFilePaths
	}

	public addFilePath(filePath: string): void {
		if(this._environment === 'BROWSER') {
			throw 'You cannot adding upload target to this when you run on Browser'
		}

		this._osFilePaths.push(filePath)
	}

	public addBrowserData(inputFile: File) {
		if(this._environment == 'OS') {
			throw 'You cannot adding upload target to this when you run on OS'
		}

		this._browserDatas.push(inputFile)
	}

	public clear(): void {
		this._browserDatas.splice(0, this._browserDatas.length)
		this._osFilePaths.splice(0, this._osFilePaths.length)
	}
}

class FileDeleteSource {
	private _targets: string[]

	constructor() {
		this._targets = []
	}

	public add(fileId: string): void {
		this._targets.push(fileId)
	}

	public clear(): void {
		this._targets.splice(0, this._targets.length)
	}

	public get items(): string[] {
		return this._targets
	}
}

class FileTransferSource {
	private _uploadSource: FileUploadSource | undefined
	private _deleteSource: FileDeleteSource | undefined

    public constructor(uploadSource: FileUploadSource | undefined = undefined, deleteSource: FileDeleteSource | undefined = undefined) {
        this._uploadSource = uploadSource
        this._deleteSource = deleteSource
    }

    public get uploadSource(): FileUploadSource | undefined {
        return this._uploadSource
    }

    public get deleteSource(): FileDeleteSource | undefined {
        return this._deleteSource
    }

    public set uploadSource(uploadSource: FileUploadSource | undefined) {
        this._uploadSource = uploadSource
    }

    public set deleteSource(deleteSource: FileDeleteSource | undefined) {
        this._deleteSource = deleteSource;
    }
}

interface FileTransferProgress {
	state : 'Preparing' | 'Uploading' | 'Deleting' | 'Completed'
	fileName?: string | undefined
	progressRate?: number | undefined
	totalFileSize?: number | undefined
	transferred?: number | undefined
	remainingTime?: number | undefined
}

interface FileTransferResult {
	upload?: FileUploadResult[] | undefined
	delete?: FileDeleteResult[] | undefined
}

interface FileTransferError {
	reason: string
}

interface FileUploadResult {
	fileId?: string | undefined
	filePath?: string | undefined
	fileName?: string | undefined
	fileSize?: number | undefined
	uri?: string | undefined
	transferResult: 'succeed' | 'failed'
	error?: FileTransferError | undefined
}

interface FileDeleteResult {
	fileId: string
	transferResult: 'succeed' | 'failed'
	error?: FileTransferError
}

export { FileUploadSource, FileDeleteSource, FileTransferSource }
export type { FileTransferProgress, FileTransferResult, FileTransferError, FileUploadResult, FileDeleteResult }