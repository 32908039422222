import { IFileUploader } from './interface'

/* 
    
    REFERENCE 
    ---------
    웹브라우저 환경일때는, './_browser/' 모듈에 있는 FileUploader 클래스를, 
    NODE.JS 환경(운영체제) 환경일때는, './_system' 모듈에 있는 FileUploader 클래스를 가져오도록 한다_환경에 따라 동적으로 가져오고자하는 모듈을 선택할 수 있으므로, 우연의 일치로 './system' 모듈이 섞여들어가 웹 상에서 오류를 일으키는 일은 없게 된다
    [참조: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/import]

    *왜 문제가 되냐고?
    파일의 크기를 알아내기 위해서 간단하게
    웹브라우저 환경일때는 File 개체로부터 size 등의 정보를 얻어올 수 있다고 생각할 수 있다
    단, NODE.JS 환경은 웹브라우저 환경과 다르게 window 및 File 개체가 존재하지 않는다는 문제로 인해, NODE.JS 에서 제공하는 fs 모듈을 사용하여 값을 가져와야만 한다
    이 경우 fs 모듈을 가져오기 위해서 const fs = require('fs') 구문을 사용하는데, 이 '구문' 웹 브라우저 환경에서 '인식'이 되지 않는다
    그리고 NODE.JS 환경에서 제공되는 fs 같은 모듈들은 웹브라우저 환경에서 '인식'되지도 '실행'되지도 않는다
    그래서 아무 생각없이 import 를 한 다음에 실행하면
    컴파일 시점 그리고 실행 시점에서 interpreting 을 진행하게 되며, 
    웹브라우징 환경에서는 ['fs'가 뭔가요?] 같은 오류 메시지를 보게 된다

    이게 웹과 시스템에서 둘 다 사용할 수 있도록 라이브러리를 만들 때의 가장 고질적인 문제점_고역_이라고 할 수 있는데,
    이 문제를 동적 import 로 해결해보고자 한 것이다
    
*/
let func = async (): Promise<any> => { 
    let _module: any = null
    if((typeof window !== 'undefined' && window.sessionStorage !== null)) { 
        _module = await import('./_browser') 
    } 
    else {
        _module = await import('./_system')
    }
    return _module
}

async function getFileUploader(): Promise<IFileUploader> {
    return new (await func()).FileUploader()
}

export * from './interface'
export { getFileUploader }