<script setup lang="ts">

</script>

<template>
  <h1>Certification</h1>
  <router-view></router-view>
</template>

<style>
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.50);
}
.window{
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  height: 600px;
  transform: translate(-50%, -50%);
  background-color: white;
}
.title-bar{
  display: flex;
  justify-content: flex-end;
  height: 30px;
  background-color: #e0e0e0;
}
</style>