<script setup lang="ts">
import {reactive, watch} from "vue";
import {IOptionItem} from "@/models";
import {useRoute} from "vue-router";
import Pagenation from "@/components/Pagenation.vue";

// TODO: 필터, 정렬, 페이지네이션을 구현할 때 로컬? 리모트? 확인 필요

const route = useRoute();

const categories = [
  {label: "all", value: "all"},
  {label: "Implant", value: "Implant"},
  {label: "Ortho", value: "Ortho"},
  {label: "FaceGiDE", value: "FaceGiDE"},
  {label: "CAD", value: "CAD"},
];

const state = reactive({
  category: route.params.category,
  pagenation: {
    totalRows: 1000,
    page: 1,
  },
});

/**
 * route.params.category 감시
 */
watch(() => route.params.category, (newIndex, oldIndex) => {
  // TODO: Written-test 처럼 onBeforeMount, Method를 이용해서 제거할 수 없는지 고민.
  //  수정하게 되면 router-link를 사용할 수 없음.
  //  router-link를 사용하면 route.params.category가 변경되지 않음.
  //  버튼의 상태를 바꾸기 위한 처리가 불가피한 Test들과는 다른 상황.
  state.category = newIndex;
});
function changePage(page: number){
  // console.log(`"changePage", ${page}"`);
  state.pagenation.page = page;
}
</script>

<template>
  <h1>QualifyingTest</h1>
  <span v-for="(item, index) in categories" :key="index">
    {{index > 0 ? " | " : ""}}
    <b v-if="state.category == item.value">{{ item.label }}</b>
    <router-link v-else :to="`/academy/my-page/test-history/qualifying-test/${item.value}`">{{ item.label }}</router-link>
  </span>
  <div>

  </div>
  <div>
    <Pagenation :totalRows="state.pagenation.totalRows" :page="state.pagenation.page" @change="changePage($event)" />
  </div>
</template>

<style scoped>

</style>