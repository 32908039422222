<script setup lang="ts">
import { reactive } from "vue";
import { localize } from "@/utils";
import { IListState } from "@/models";
import { confirm } from "@/services";
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";

interface IReview {
  id: string;
}

const state: IListState = reactive({
  page: 1,
  totalRows: 1,
  keyword: "",
  items: <IReview[]>[],
});

request();

/**
 * 검색 조건 초기화
 */
function resetForm(): void {
  request();
}
/**
 * 리뷰 목록 요청
 * @param page 페이지 번호
 */
function request(page: number = 1): void {
  console.log("request", state.keyword, page);
  state.items = [
    {
      id: "1",
    },
    {
      id: "2",
    },
    {
      id: "3",
    },
  ];
}
</script>

<template>
  <h1 class="hd-title">Review Management</h1>
  <div id="ct" class="flex-layout-body">
    <div class="content">
      <div class="box">
        <form @submit.prevent="request()" @reset.prevent="resetForm">
          <div class="search">
            <search-keyword
              :show-reset="true"
              :state="state"
              :placeholder="localize('이름, 치과명, 전화번호, 이메일로 검색해보세요')"
            />
          </div>
          <div class="row form-row">
            <div class="flex-center">
              <div class="form-label">서비스별</div>
              <select class="form-control form-select w2x">
                <option value="">Customized Abutment & Temporary Crown</option>
              </select>
            </div>
            <div class="col-auto flex-center">
              <div class="form-label">정렬</div>
              <div class="btn-check-wrap">
                <label class="btn-check">
                  <input type="radio" name="c1" class="sr-only" checked />
                  <span class="btn btn-outline-primary">최근 주문 순</span>
                </label>
                <label class="btn-check">
                  <input type="radio" name="c1" class="sr-only" />
                  <span class="btn btn-outline-primary">평점 높은 순</span>
                </label>
                <label class="btn-check">
                  <input type="radio" name="c1" class="sr-only" />
                  <span class="btn btn-outline-primary">평점 낮은 순</span>
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ul class="review-group">
        <li v-for="item in state.items" class="review media">
          <div class="profile-thumb">
            <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
          </div>
          <div class="media-body">
            <div class="flex-center">
              <b>김메가</b><span class="text-gray ml-1">님</span>
              <span class="fs-sm text-muted ml-3">2024.00.00 00:00</span>
            </div>
            <div class="reveiw-meta">
              <div>3.0</div>
              <div class="rating">
                <i class="icon icon-star on"></i>
                <i class="icon icon-star on"></i>
                <i class="icon icon-star on"></i>
                <i class="icon icon-star"></i>
                <i class="icon icon-star"></i>
              </div>
              <div>(정확성 5, 신속성 : 3, 응대성 : 4)</div>
            </div>
            <div class="fs-sm mb-3">
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다.
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다.
              리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이
              여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에 들어갑니다. 리뷰 내용이 여기에
              들어갑니다. 리뷰 내용이 여기에 들어갑니다.
            </div>
            <div class="badge-group">
              <div class="badge">R2 Planning & Guide Design</div>
              <div class="badge">R2 Planning & Guide Design</div>
            </div>
          </div>
        </li>
      </ul>
      <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
    </div>
  </div>
</template>

<style scoped></style>
