import { RouteRecordRaw } from "vue-router";
import detailRoutes from "@/router/academy/certification/detail";
import Home from "@/views/academy/certification/CertificationHome.vue";
import Detail from "@/views/academy/certification/CertificationDetail.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/academy/certification/home",
  },
  {
    path: "home",
    component: Home,
  },
  {
    path: "detail/:license",
    component: Detail,
    children: detailRoutes,
  },
];

export default routes;
