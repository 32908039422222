import { RouteRecordRaw } from "vue-router";
import Profile from "@/views/playground/planner/business-management/service-management/information/Profile.vue";
import History from "@/views/playground/planner/business-management/service-management/information/History.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/service/information/profile`,
  },
  {
    path: "profile",
    component: Profile,
  },
  {
    path: "history",
    component: History,
  },
];

export default routes;
