import { RouteRecordRaw } from "vue-router";
import orderRoutes from "@/router/playground/player/order";
import partnerRoutes from "@/router/playground/player/partner";
import cloudRoutes from "@/router/playground/player/cloud";
import playerSpaceRoutes from "@/router/playground/player/player-space";
import supportRoutes from "@/router/playground/player/support";
import Dashboard from "@/views/playground/player/dashboard/Dashboard.vue";
import Order from "@/views/playground/player/order/Order.vue";
import Partner from "@/views/playground/player/partner/Partner.vue";
import Cloud from "@/views/playground/player/cloud/Cloud.vue";
import PlayerSpace from "@/views/playground/player/player-space/PlayerSpace.vue";
import Support from "@/views/playground/player/support/Support.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/dashboard`,
  },
  {
    path: "dashboard",
    component: Dashboard,
  },
  {
    path: "order",
    component: Order,
    children: orderRoutes,
  },
  {
    path: "partner",
    component: Partner,
    children: partnerRoutes,
  },
  {
    path: "cloud",
    component: Cloud,
    children: cloudRoutes,
  },
  {
    path: "player-space",
    component: PlayerSpace,
    children: playerSpaceRoutes,
  },
  {
    path: "support",
    component: Support,
    children: supportRoutes,
  },
];

export default routes;
