import { AuthStorage, AuthenticationSettings, ClientSettings, ServiceEndpoints } from './settings'
import { IAuthStorage } from './settings'
import { ServiceFactory } from './service'
import { getContextStorage } from './util/Storage'

interface ClientConfig {
	appId: string
	serviceEndpoints: ServiceEndpoints
	authStorage?: IAuthStorage
	isTest: boolean
}

class R2ServiceClient {
    private constructor() {
        throw 'R2ServiceClient class was unabled to create a instance by the architectural decision'
    }

    private static _isInitialized: boolean = false

    static initialize(config: ClientConfig): void {
        if(!R2ServiceClient._isInitialized) {
            if(config === undefined) {
                throw 'config must have some properties'
            }

            //C1. 전역적으로 사용될 인증정보(AuthStorageData) 저장소를 설정한다
            const as = AuthenticationSettings.instance
            /* 
                R2ServiceClient 에서 인증정보를 저장할 Storage 를 설정한다
                as.authStorage = (config.authStorage)? config.authStorage : new AuthStorage(window.sessionStorage)
            */
           as.authStorage = new AuthStorage(getContextStorage())

            //C2. 전역적으로 참조될 R2ServiceCLient 관련 환경을 설정한다
            const cs = ClientSettings.instance
            cs.appId = config.appId
            cs.serviceEndpoints = config.serviceEndpoints
            cs.isTest = config.isTest

            R2ServiceClient._isInitialized = true   //_/R2ServiceClient 가 초기화되었음을 나타낸다
        }
    }

    static getServiceFactory(): ServiceFactory {
        if(R2ServiceClient._isInitialized) {
            return ServiceFactory.instance
        }

        throw 'R2ServiceClient not initialized'
    }
}

export { R2ServiceClient }
export type { ClientConfig }