import { RouteRecordRaw } from "vue-router";
import List from "@/views/playground/r2center/business-management/member-management/MemberManagementList.vue";
import Add from "@/views/playground/r2center/business-management/member-management/MemberManagementAdd.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/member/list`,
  },
  {
    path: "list",
    component: List,
  },
  {
    path: "add",
    component: Add,
  },
];

export default routes;
