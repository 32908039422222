<script setup lang="ts">
import AuthCodeInput from "@/components/AuthCodeInput.vue";
import { graphql } from "@/libs/R2ServiceClient/service/Visitor";
import router from "@/router";
import { alert, useFormBuilder } from "@/services";
import { useClientWrapperStore, useJoinStore } from "@/store";
import { localize, Validators } from "@/utils";
import { computed, onMounted, reactive, ref } from "vue";

/**
 * 필수 입력 자료
 */
const { member } = useJoinStore();
// console.log("member: ", member);

/**
 * 인증코드 입력 컴포넌트 참조
 */
const authCodeInputRef = ref<InstanceType<typeof AuthCodeInput> | null>(null);

const state = reactive({
  /**
   * 인증 요청 아이디
   */
  verificationRequestId: "",
});

/**
 * 중복확인 가능한 유효한 이메일 여부
 */
const enabledCheck = computed(() => valid("email") && invalid("verifiedEmail"));
/**
 * 인증코드 만료 시간
 */
const expiredDate = ref<Date>();
/**
 * 인증코드 만료 시간 간격
 */
const expiredSeconds = 3 * 60;

/**
 * 폼 빌더
 */
const formBuilder = useFormBuilder();
const { controls, errors, group, touch, validate, valid, invalid } = formBuilder;

group({
  /**
   * 이메일
   */
  email: {
    touched: false,
    value: "",
    validators: [Validators.required, Validators.email],
    dependencies: ["verifiedEmail"], // 변경될 때마다 의존성 컨트롤들도 유효성 검사
  },
  /**
   * 중복확인을 통과한 이메일. 유효성 검사를 통과해서 중복확인을 진행한 이메일.
   */
  verifiedEmail: { value: "", validators: [Validators.required, Validators.match(controls, "email")] },
  code: {
    touched: false,
    value: "",
    validators: [Validators.required, Validators.minLength(6), Validators.expired(expiredDate)],
  },
});

onMounted(init);

/**
 * 초기화
 */
function init(): void {
  validate();
}
/**
 * 이메일 중복 확인
 */
async function existEmail() {
  if (!enabledCheck.value) return;
  const CheckLoginIdDuplication = graphql(`
    query CheckLoginIdDuplication($loginId: String!) {
      checkLoginIdDuplication(loginId: $loginId)
    }
  `);
  const result = await useClientWrapperStore().visitor.typedQuery(CheckLoginIdDuplication, {
    variables: {
      loginId: controls.email.value,
    },
    successCallback: (result) => {
      // console.log("existEmail - successCallback: ", result);
      // 이미 가입된 이메일이 존재하는 경우
      if (result.checkLoginIdDuplication) {
        alert(localize('이미 가입된 이메일입니다.'));
      } else {
        controls.verifiedEmail.value = controls.email.value;
      }
    },
  });
}
/**
 * 인증 메일 발송
 */
async function sendEmail() {
  // console.log("sendEmail - verifiedEmail: ", controls.verifiedEmail.value);
  if (invalid("verifiedEmail")) return;
  const CreateUserVerification = graphql(`
    mutation CreateUserVerificationRequest($input: CreateVerificationRequestInput!) {
      createUserVerificationRequest(input: $input) {
        id
        method
        emailAddress
        requestedAt
        expiredAt
      }
    }
  `);
  const result = await useClientWrapperStore().visitor.typedMutate(CreateUserVerification, {
    variables: {
      input: {
        emailAddress: controls.email.value,
        method: "EMAIL",
      },
    },
    successCallback: (result) => {
      // console.log("sendMail - successCallback: ", result);
      state.verificationRequestId = result.createUserVerificationRequest.id;
      // console.log(
      //   "verificationRequestId: ",
      //   state.verificationRequestId,
      //   "verifiedEmail: ",
      //   controls.verifiedEmail.value
      // );
      controls.code.value = "";
      const now = new Date();
      now.setSeconds(now.getSeconds() + expiredSeconds);
      expiredDate.value = now;
      authCodeInputRef.value?.reset();
    },
  });
}

/**
 * 완료 핸들러
 */
async function done(event: Event): Promise<void> {
  // console.log(errors, valid());
  validate(true);
  if (valid()) {
    const VerifyVerificationCode = graphql(`
      mutation VerifyVerificationCode($input: VerifyVerificationCodeInput!) {
        verifyVerificationCode(input: $input) {
          verificationRequestId
          isVerified
        }
      }
    `);
    // console.log("done - verificationRequestId: ", state.verificationRequestId);
    const result = await useClientWrapperStore().visitor.typedMutate(VerifyVerificationCode, {
      variables: {
        input: {
          verificationRequestId: state.verificationRequestId,
          verificationCode: controls.code.value,
        },
      },
      successCallback: (result) => {
        // console.log("done - successCallback: ", result);
        const success = result.verifyVerificationCode.isVerified;
        // console.log("success: ", success);
        if (!success) {
          alert(localize('인증코드가 올바르지 않습니다.'));
          return;
        }
        member.verificationRequestId = state.verificationRequestId;
        member.loginId = member.emailAddress = controls.email.value;
        // console.log("done: ", member);
        router.push("/home/join/required");
      },
    });
  }
  event.preventDefault();
}
</script>

<template>
  <div class="join-box">
    <div class="progress-line">
      <div class="line" style="width: 40%"></div>
    </div>
    <div class="step-wrap">
      <b>STEP 2</b>
      <h2>{{ localize("이메일 가입 인증") }}</h2>
    </div>
    <form class="join-form">
      <div class="form-group">
        <label class="form-label" for="email">{{ localize("이메일") }}</label>
        <div class="input-group">
          <!-- [D] : 유효하지 않을 시: is-invalid / 유효할 시: is-valid -->
          <input
            type="email"
            id="email"
            class="form-control curved"
            :class="{
              'is-invalid': controls.email.touched && invalid('verifiedEmail'),
              'is-valid': valid('verifiedEmail'),
            }"
            v-model="controls.email.value"
            @focusout="touch('email')"
            @keyup.enter="existEmail"
            :readonly="state.verificationRequestId.length > 0"
            :placeholder="localize('아이디로 사용할 이메일 주소 입력')"
          />

          <a class="btn btn-primary center" :class="{ disabled: !enabledCheck }" @click="existEmail">
            {{ localize("중복 확인") }}</a
          >

          <!--    이메일 에러 메시지-->
          <div v-show="controls.email.touched && errors?.email?.required" class="invalid-feedback">
            * {{ localize("이메일을 입력하세요.") }}
          </div>
          <div v-show="controls.email.touched && errors?.email?.email" class="invalid-feedback">
            * {{ localize("올바른 이메일을 입력하세요.") }}
          </div>
          <div v-show="valid('email') && invalid('verifiedEmail')" class="invalid-feedback">
            * {{ localize("이메일의 중복 확인을 진행하세요.") }}
          </div>
          <!--    사용 가능한 이메일-->
          <div v-show="valid('verifiedEmail')" class="valid-feedback">
            * {{ localize("가입 가능한 이메일입니다.") }}
          </div>
        </div>
      </div>

      <!-- 인증 메일 발송 버튼-->
      <a class="btn btn-square center w-100" @click="sendEmail" :class="{ disabled: invalid('verifiedEmail') }">
        <span v-if="state.verificationRequestId.length > 0">{{ localize("인증 메일 재발송하기") }}</span>
        <span v-else>{{ localize("인증 메일 발송하기") }}</span>
      </a>
      <!-- //인증 메일 발송 버튼-->

      <span v-if="state.verificationRequestId.length > 0">
        <!-- 인증 코드 입력-->
        <auth-code-input ref="authCodeInputRef" :form-builder="formBuilder" @submit="done"></auth-code-input>
        <!-- //인증 코드 입력-->
      </span>
    </form>
    <div class="btn-wrap">
      <a :class="{ disabled: invalid() }" @click="done" class="btn btn-primary center w-100">{{
        localize("다음으로")
      }}</a>
    </div>
  </div>
</template>

<style scoped></style>
