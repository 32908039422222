<script setup lang="ts">
import { reactive, ref } from "vue";
import axios from "axios";
import { LocaleType, useLocalizationStore, useMemberStore, useSessionStore, useNationStore } from "@/store";
import csv2json from "csvtojson";
import { ClientConfig, R2ServiceClient } from "@/libs/R2ServiceClient";
import { graphQlServer } from "@/configuration";

// import css from "@/assets/playground/css/import.css?inline";

const { getLanguage, setItems, initLanguage } = useLocalizationStore();
const { initNation } = useNationStore();

const locales: LocaleType[] = ["ko-KR", "en-US"];
const selectedLocale = ref(locales[0]);

const state = reactive({
  initCompleted: false,
  cssChanged: false,
  style: null as HTMLStyleElement | null,
});

init();

/**
 * 초기화
 */
async function init() {
  createDynamicCss();
  initServiceClient();
  checkSession();
  await initLanguage();
  selectedLocale.value = getLanguage();
  // console.log(`language: ${selectedLocale.value}`);
  initNation(selectedLocale.value);
  await getLocalization();
}
/**
 * 동적으로 생성된 스타일 엘리먼트에 css 적용
 */
function createDynamicCss() {
  state.style = document.head.appendChild(document.createElement("style"));
  // state.style.innerHTML = css;
}
/**
 * css 변경
 * @param css
 */
function changeCss(css: string) {
  if (state.style) {
    state.style.innerHTML = css;
    state.cssChanged = true;
  } else {
    console.log("style element is not created");
  }
}
/**
 * 웹 서버에서 언어 설정 파일을 파싱해서 언어 설정 정보 목록 생성
 */
async function getLocalization() {
  try {
    const response = await axios.get("/language.csv");
    // console.log("getLocalization", response.data.split("\n").slice(0, 5).join("\n"));
    const jsonData = await csv2json().fromString(response.data);
    setItems(jsonData);
    // console.log(getItems());
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
  state.initCompleted = true;
  // console.log("App init complete");
}
/**
 * 서비스 클라이언트 초기화
 */
function initServiceClient() {
  let clientConfig: ClientConfig = {
    appId: "r2-playground",
    isTest: true,
    serviceEndpoints: {
      visitors: `${graphQlServer}/api/visitors`,
      users: `${graphQlServer}/api/users`,
    },
  };
  R2ServiceClient.initialize(clientConfig);
}
/**
 * 세션 설정 검사
 */
function checkSession(): void {
  // console.log("checkSession");
  const member = useSessionStore().getMember();
  if (member != null) {
    // console.log("member session is exists", member);
    useMemberStore().setMember(member);
  }
}
</script>

<template>
  <router-view @change-css="changeCss"></router-view>

  <!-- 로딩 화면-->
  <div v-if="!state.initCompleted || !state.cssChanged" id="loading-wrap">
    <div class="loading modal-dialog-centered vertical-fill">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- //로딩 화면-->
</template>

<style scoped>
#loading-wrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
}
</style>
