<script setup lang="ts">
import { localize, getFormatString } from "@/utils";
import { reactive, ref } from "vue";
import R2Dialog from "@/components/R2Dialog.vue";
import { IListState } from "@/models/i-list-state.ts";
import Pagenation from "@/components/Pagenation.vue";

const historyRef = ref<InstanceType<typeof R2Dialog> | null>(null);

const state = reactive({
  step: 4,
  step1: 1,
  step2: 1,
  step4: 2,
});
const history: IListState = reactive({
  page: 1,
  totalRows: 0,
  items: [],
});
function showHistory(): void {
  historyRef.value?.open();
}
function requestHistory(page: number) {
  history.page = page;
}
function requestConfirm() {
  // TODO: API 연동
  state.step1 = 2;
}
function refreshConfirm() {
  // TODO: API 연동
  // state.step1 = 3;
  state.step = 2;
}
function requestSendEmail() {
  // TODO: API 연동
  state.step2 = 2;
}
function requestChnage() {
  // TODO: API 연동
  state.step = 3;
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <router-link to="../membership" class="btn-back"
          ><i class="icon icon-back">{{ localize("뒤로") }}</i></router-link
        >
        <h2 class="fs-1">
          Login Account<br />
          {{ localize("ID 변경") }}
        </h2>
        <p>{{ localize("신청일 기준 N일 이내에 처리해드립니다.") }}</p>
        <u class="text-muted">Anything else?</u>
        <img src="@/assets/playground/img/membership.png" alt="" />
      </div>
      <div class="grid-content">
        <div class="box box-shadow">
          <ol class="step">
            <li :class="{ active: state.step == 1 }">
              <span>{{ localize("신청 전 확인") }}</span>
            </li>
            <li :class="{ active: state.step == 2 }">
              <span>{{ localize("변경 신청") }}</span>
            </li>
            <li :class="{ active: state.step == 3 }">
              <span>{{ localize("변경 신청 완료") }}</span>
            </li>
            <li :class="{ active: state.step == 4 }">
              <span>{{ localize("관리자 확인") }}</span>
            </li>
            <li :class="{ active: state.step == 5 }">
              <span>{{ localize("확인 완료 및 변경") }}</span>
            </li>
          </ol>

          <div v-if="state.step == 1">
            <!-- 신청 전 확인-->
            <div v-if="state.step1 == 1" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2">{{ localize("ID 변경 전 확인") }}</h3>
              <p>{{ localize("다음의 조건을 모두 충족할 경우 회원탈퇴 신청이 가능합니다.") }}</p>
              <div class="box">
                <ul class="list-dot">
                  <li>{{ localize("개입된 모든 주문 건의 상태가 [완료]인 경우") }}</li>
                  <li>{{ localize("개입된 모든 주문 건의 대금 지불/수취 상태가 [완료]인 경우") }}</li>
                  <li>{{ localize("(R2센터의 경우) 전체 구성원을 모두 해체했을 때") }}</li>
                </ul>
              </div>
              <button @click="requestConfirm" type="button" class="btn btn-md">{{ localize("변경 조건 확인") }}</button>
              <b class="fs-sm text-dark mt-3 d-block"
                ><a @click="showHistory"
                  ><u>{{ localize("이전 변경 내역 확인") }}</u></a
                ></b
              >
            </div>
            <!-- //신청 전 확인-->

            <!-- 신청 전 확인 조회 중-->
            <div v-if="state.step1 == 2" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2">{{ localize("ID 변경 전 확인") }}</h3>
              <p class="mb-5" v-html="localize('조회 중입니다.<br />잠시만 기다려주세요.')" />
              <div class="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <button @click="refreshConfirm" type="button" class="btn btn-outline btn-md">Refresh</button>
            </div>
            <!-- //신청 전 확인 조회 중-->

            <!-- 변경 불가능-->
            <div v-if="state.step1 == 3" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2 text-danger">{{ localize("ID 변경을 하실 수 없습니다.") }}</h3>
              <p>{{ localize("다음 내용을 확인해 주세요.") }}</p>
              <div class="row">
                <div class="col-6">
                  <div class="box box-outline">
                    <div class="flex-center-between">
                      <strong class="fs-3 font-poppins">Player</strong>
                      <a href="#" class="link-txt"
                        >{{ localize("확인하러 가기") }} <i class="icon-arrow-primary ml-2"></i
                      ></a>
                    </div>
                    <div class="flex-center">
                      <div class="box flex-fill">
                        <ul>
                          <li
                            v-html="
                              getFormatString(
                                localize(
                                  '<b class=\'text-primary\'>{0}개</b>의 주문에 대한 <b>[{1}] 처리</b>가 필요합니다.'
                                ),
                                5,
                                '완결'
                              )
                            "
                          />
                          <li
                            v-html="
                              getFormatString(
                                localize(
                                  '<b class=\'text-primary\'>{0}개</b>의 주문에 대한 <b>[{1}] 처리</b>가 필요합니다.'
                                ),
                                5,
                                '지불'
                              )
                            "
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="box box-outline">
                    <div class="flex-center-between">
                      <strong class="fs-3 font-poppins">Planner</strong>
                      <a href="#" class="link-txt"
                        >{{ localize("확인하러 가기") }} <i class="icon-arrow-primary ml-2"></i
                      ></a>
                    </div>
                    <div class="flex-center">
                      <div class="box flex-fill">
                        <ul>
                          <li
                            v-html="
                              getFormatString(
                                localize(
                                  '<b class=\'text-primary\'>{0}개</b>의 주문에 대한 <b>[{1}] 처리</b>가 필요합니다.'
                                ),
                                5,
                                '완결'
                              )
                            "
                          />
                          <li
                            v-html="
                              getFormatString(
                                localize(
                                  '<b class=\'text-primary\'>{0}개</b>의 주문에 대한 <b>[{1}] 처리</b>가 필요합니다.'
                                ),
                                5,
                                '지불'
                              )
                            "
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <router-link to="home">
                <button type="button" class="btn btn-outline btn-md">{{ localize("취소하기") }}</button>
              </router-link>
            </div>
            <!-- //변경 불가능-->
          </div>

          <!-- 변경 가능-->
          <div v-if="state.step == 2" class="step-content">
            <strong class="fs-sm text-primary">step 2</strong>
            <h3 class="fs-2">{{ localize("변경할 ID를 지정해주세요.") }}</h3>

            <!-- 변경 ID 입력-->
            <form v-if="state.step2 == 1" @submit.prevent="requestSendEmail">
              <div class="frm">
                <div class="form-group">
                  <div class="form-label">{{ localize("사용 중인 ID") }}</div>
                  <input type="text" class="form-control" placeholder="p1h2c3@naver.com" disabled />
                </div>
                <div class="form-group is-invalid is-valid">
                  <div class="form-label">{{ localize("변경할 ID") }}</div>
                  <input type="text" class="form-control" :placeholder="localize('변경할 ID를 입력해주세요.')" />
                  <p class="form-text">＊{{ localize("중복된 아이디입니다.") }}</p>
                  <p class="form-text">＊{{ localize("사용할 수 있는 아이디입니다.") }}</p>
                </div>
              </div>
              <button type="submit" class="btn btn-md">{{ localize("인증 메일 발송") }}</button>
            </form>
            <!-- //변경 ID 입력-->

            <!-- 인증번호 입력-->
            <form v-if="state.step2 == 2" @submit.prevent="requestChnage">
              <div class="frm">
                <div class="form-group">
                  <div class="form-label">
                    <span>{{ localize("인증번호 입력") }}</span>
                    <a @click="requestSendEmail" class="text-primary fs-sm ml-auto"
                      ><u>{{ localize("인증번호 재전송") }}</u></a
                    >
                  </div>
                  <div class="form-control-group">
                    <input type="text" class="form-control" value="123456" maxlength="6 " />
                    <span class="time">5:00</span>
                  </div>
                </div>
                <button type="submit" class="btn btn-md mt-3">{{ localize("ID 변경 신청") }}</button>
                <button @click="state.step2 = 1" type="button" class="btn btn-outline btn-md mt-2">
                  {{ localize("이전 단계로") }}
                </button>
              </div>
            </form>
            <!-- //인증번호 입력-->
          </div>
          <!-- //변경 가능-->

          <div v-if="state.step == 3" class="step-content">
            <strong class="fs-sm text-primary">step 3</strong>
            <h3 class="fs-2">{{ localize("ID 변경 신청이 완료되었습니다.") }}</h3>
            <p class="mb-5">관{{ localize("리자가 곧 신청에 대한 심사를 진행할 예정입니다.") }}</p>
            <img src="@/assets/playground/img/application-complete.png" alt="" />
          </div>

          <div v-if="state.step == 4">
            <!-- 관리자 확인 중 -->
            <div v-if="state.step4 == 1" class="step-content">
              <strong class="fs-sm text-primary">step 4</strong>
              <h3 class="fs-2">{{ localize("관리자 확인 중입니다.") }}</h3>
              <p class="mb-5">{{ localize("신청일 기준 N일 이내 확인 완료 예정입니다.") }}</p>
              <img src="@/assets/playground/img/admin-check.png" alt="" />
              <div class="box">
                <p class="info-txt">{{ localize("거절된 경우 [심사완료]에서 확인 가능합니다.") }}</p>
              </div>
            </div>
            <!-- //관리자 확인 중 -->

            <!-- 관리자 승인 거절 -->
            <div v-if="state.step4 == 2">
              <div class="step-content">
                <strong class="fs-sm text-primary">step 4</strong>
                <h3 class="fs-2 text-danger">{{ localize("ID 변경이 거절되었습니다.") }}</h3>
                <p>{{ localize("아래의 사유를 확인해 주세요.") }}</p>
                <div class="box">
                  <strong class="fs-sm">{{ localize("거절 사유") }}</strong>
                  <ul class="list-dot mt-2">
                    <li>{{ localize("개입된 모든 주문 건의 상태가 [완료]인 경우") }}</li>
                    <li>{{ localize("개입된 모든 주문 건의 대금 지불/수취 상태가 [완료]인 경우") }}</li>
                    <li>{{ localize("(R2센터의 경우) 전체 구성원을 모두 해체했을 때") }}</li>
                  </ul>
                </div>
              </div>
              <div class="btn-wrap">
                <router-link to="home">
                  <button type="button" class="btn btn-outline">{{ localize("확인") }}</button>
                </router-link>
                <router-link to="/playground/player/support/trouble-shooting/save">
                  <button type="button" class="btn">{{ localize("트러블 슈팅") }}</button>
                </router-link>
              </div>
            </div>
            <!-- //관리자 승인 거절 -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ID 변경 이력 -->
  <r2-dialog ref="historyRef" modal-class="modal-lg" :no-button="true">
    <h4 class="fs-3 mb-4">{{ localize("ID 변경 이력") }}</h4>
    <table class="tb">
      <colgroup>
        <col />
        <col />
        <col style="width: 16%" />
        <col style="width: 16%" />
      </colgroup>
      <thead>
        <tr>
          <th>{{ localize("변경 이전 아이디") }}</th>
          <th>{{ localize("변경 후 아이디") }}</th>
          <th>{{ localize("신청일") }}</th>
          <th>{{ localize("충전 승인 일시") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>p1h2c3@naver.com</td>
          <td>re.dev4@imegagen.com</td>
          <td>2024.02.14</td>
          <td>2024.00.00</td>
        </tr>
      </tbody>
    </table>
    <pagenation :total-rows="history.totalRows" :page="history.page" @change="requestHistory" />
  </r2-dialog>
  <!-- //ID 변경 이력 -->
</template>

<style scoped></style>
