<script setup lang="ts">
import { computed, provide, reactive } from "vue";
import { localize } from "@/utils";
import { confirm } from "@/services";
import ServiceSettingStepper from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingStepper.vue";
import router from "@/router";
import { ServiceImage, ServiceInfo } from "@/models/service-setting";

export interface ServiceSettingAddProps {
  step: number;
  service: ServiceInfo;
  images: ServiceImage[];
}
/**
 * 서비스 등록 상태
 */
const state = reactive<ServiceSettingAddProps>({
  step: 1,
  service: { id: null, name: null, image: null, time: "3", quantity: null },
  images: [
    { id: null, image: null },
    { id: null, image: null },
    { id: null, image: null },
    { id: null, image: null },
    { id: null, image: null },
  ],
});
// 서비스 등록 상태 제공
provide("service-setting-add-state", state);

const disabledNext = computed(() => {
  switch (state.step) {
    case 1:
      const { service } = state;
      return Object.values(state.service).some((value) => value === null);
  }
});

/**
 * 취소
 */
async function cancel() {
  console.log("state:", state);
  if (await confirm("취소하시겠습니까?")) {
    router.push("../list");
  }
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <router-link to="../list" class="btn-back"><i class="icon icon-back">뒤로</i></router-link>
    <div class="grid">
      <div class="grid-side">
        <div class="step-sticky">
          <h2 class="fs-1">서비스 등록</h2>
          <ol class="step column">
            <!-- 서비스 등록 스텝 -->
            <service-setting-stepper></service-setting-stepper>
            <!-- //서비스 등록 스텝 -->
          </ol>
        </div>
      </div>
      <div class="grid-content">
        <div class="box box-shadow">
          <!-- step -->
          <router-view></router-view>
          <!-- //step -->
          <div class="fieldset d-block">
            <div class="btn-wrap justify-content-between">
              <div>
                <router-link v-if="state.step > 1" :to="`step${state.step - 1}`" class="btn btn btn-outline mr-2"
                  >이전</router-link
                >
                <a @click="cancel" class="btn btn btn-outline">취소</a>
              </div>
              <router-link
                v-if="state.step < 4"
                :to="`./step${state.step + 1}`"
                :class="{ disabled: disabledNext }"
                class="btn btn"
                >다음</router-link
              >
              <router-link v-if="state.step === 4" :to="`./step${state.step + 1}`" class="btn btn">등록</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
