<script setup lang="ts">
import { localize } from "@/utils";

// localize('알림')
// localize('공지사항')
const menuItems = [
  { name: "알림", link: "notification" },
  { name: "공지사항", link: "notice" },
];
</script>

<template>
  <h1 class="hd-title">Messaging</h1>
  <router-view :menu-items="menuItems"></router-view>
</template>

<style scoped></style>
