<script setup lang="ts">
import { IOptionItem } from "@/models";
import { localize } from "@/utils";
import HomeFooter from "@/views/home/HomeFooter.vue";
import HomeHeader from "@/views/home/HomeHeader.vue";
import { reactive, Ref, ref } from "vue";
import { useRoute } from "vue-router";

const sectionRefItems = Array(8)
  .fill(null)
  .map(() => ref<HTMLElement | null>(null));
const sectionItems: IOptionItem[] = [
  { label: "R2 Implant Solution", value: 0 },
  { label: "Digital oral Design", value: 1 },
  { label: "FaceGIDE", value: 2 },
  { label: "R2 Ortho", value: 3 },
  { label: "R2 Dental Prosthodontic", value: 4 },
  { label: "R2 Facial Reconstruction", value: 5 },
  // 6번과 7번은 메뉴에 노출 시키지 않는다.
];

const state = reactive({
  active: 0,
  r2GateWindow: false,
  r2GateLite: false,
  smileCreator: false,
});

const route = useRoute();

requestAnimationFrame(() => {
  if (route.query.section) {
    changeSection(Number(route.query.section));
  }
});

function changeSection(value: number) {
  state.active = value;
  sectionRefItems[value].value?.scrollIntoView({ behavior: "smooth" });
}

// // 10개의 동영상 상태 관리
// const isVideoPlaying = ref(Array(10).fill(false)); // 10개의 비디오 재생 상태를 관리하는 배열
// const showImage = ref(Array(10).fill(true)); // 10개의 이미지 표시 상태를 관리하는 배열

// function playVideo(index: number, event: MouseEvent) {
//   const currentVideo = (event.currentTarget as HTMLElement).querySelector('video'); // 클릭된 요소의 하위 비디오 요소를 참조
//   showImage.value[index] = false; // 해당 인덱스의 이미지 숨기기
//   console.log(currentVideo);

//   if (isVideoPlaying.value[index]) {
//     if (currentVideo) {
//       currentVideo.pause(); // 해당 비디오 일시 정지
//     }
//     isVideoPlaying.value[index] = false; // 재생 상태 업데이트
//   } else {
//     if (currentVideo) {
//       // 동영상이 종료되면 실행되는 이벤트 리스너 추가
//       currentVideo.addEventListener('ended', () => {
//         showImage.value[index] = true; // 동영상이 끝나면 이미지 다시 보이기
//         isVideoPlaying.value[index] = false; // 비디오 상태를 false로 업데이트
//         currentVideo.currentTime = 0; // 동영상을 처음으로 돌리기
//       });
//       currentVideo.play(); // 해당 비디오 재생
//     }
//     isVideoPlaying.value[index] = true; // 재생 상태 업데이트
//   }
// }

//HERE
const downloadLink: Ref<string> = ref("https://download.r2playground.com");
</script>

<template>
  <div id="wrap">
    <div class="scrollspy-body" data-spy="scroll" data-target=".navbar">
      <!-- header -->
      <home-header />
      <!-- //header -->

      <!-- container -->
      <main id="ct" class="solution">
        <!-- 페이지 시작 -->
        <div class="intro">
          <div class="container">
            <span>{{ localize("누구나 쉽게 활용하는 진단 소프트웨어") }}</span>
            <h1 class="fs-1 font-poppins">R2 Solution</h1>
            <p
              class="fs-4"
              v-html="localize('R2 solution은 오랫 동안 임상에서 축적되어 온 다양한 지식과<br /> 이론들을 디지털 방식으로 구현하여, 누구나 쉽게 활용할 수 있도록 개발된 진단 소프트웨어 입니다.<br /> 아울러, 다양한 임상의들의 경험과 의견을 반영하여 현재의 지식이 동시대 뿐만 아니라,<br /> 세대간 공유를 통해 세대와 함께 발전해 나가는 지식 공유 시스템입니다.')"/>
          </div>
        </div>
        <nav class="navbar">
          <ul class="nav nav-pills">
            <li v-for="item in sectionItems" class="nav-item">
              <a
                class="nav-link"
                :class="{ active: state.active === item.value }"
                @click="changeSection(item.value as number)"
              >
                {{ item.label }}
              </a>
            </li>
          </ul>
        </nav>
        <div :ref="sectionRefItems[0]" id="solution1">
          <div class="container">
            <div class="solution-title-wrap">
              <h2>R2 Implant Solution</h2>
              <p
                v-html="localize('Top-down 방식의 R2GATE는 가장 직관적인 임플란트 플래닝 프로그램입니다.<br /> Digital EYE를 이용한 골질 분석을 통해 BIC를 확인하실 수 있고,<br /> Drilling sequence 계산을 통해 최적의 초기 고정을 얻을 수 있도록 합니다.')"
              />
            </div>
            <a class="main-thumb video">
              <video controls poster="@/assets/home/img/solution/r2solution1.jpg">
                <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/R2_Impalant_solution/20190218_R2GUIDE_AD_EN.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </a>
            <div class="program-items">
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <img src="@/assets/home/img/solution/r2solution1-1.jpg" alt="" />
                  </div>
                  <div class="item-desc">
                    <h3>R2GATE WINDOWS</h3>
                    <p>
                      {{
                        localize("R2GATE는 가장 직관적인 임플란트 진단 솔루션입니다. Single implant 에서 Full mouth case까지 다양한 임상 케이스의 임플란트 수술 계획을 효과적으로 수립하실수 있습니다. Digital EYE를 이용한 골질 분석, 초기고정 확보를 위한 맞춤형 Drilling sequence를 제공함으로써, 성공적인 임플란트 수술을 위한 프로토콜을 제시합니다. 정확한 수술을 위한 Surgical guide, stackable guide 디자인이 가능하며, R2 Real Navi system용 프로젝트 파일 추출이 가능합니다.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns" :class="{ active: state.r2GateWindow }">
                  <button
                    type="button"
                    class="btn-drop-down"
                    @click="state.r2GateWindow = !state.r2GateWindow"
                  ></button>
                  <div>
                    <p>Program Download</p>
                    <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">Windows<i class="icon icon-download-wh"></i></a>
                  </div>
                  <div>
                    <p>Manual (implant planning)</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2GATE_Windows/R2GATE_2.0-Manual-Guide%EC%9A%A9(KR)-210208(%EC%B5%9C%EC%A2%85).pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2GATE_Windows/R2GATE_2.0-Manual-for-Guide(EN)-10208(final).pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                    <a href="https://youtube.com/playlist?list=PLWv4l-DIWsiG0jtFt5hQMYRXthKv_GAtS&si=E6KGJxmweLWzLemI" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <img class="custom-icon-youtube" src="@/assets/playground/img/youtube.png" alt="" /> YouTube</a>
                  </div>
                  <div>
                    <p>Demo project download</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2_DEMO.zip" class="btn btn-outline"> Demo<i class="icon icon-download"></i> </a>
                  </div>
                </div>
                <div class="precautions">
                  <p>PRECAUTIONS</p>
                  <div class="tb-head">Prerequisite</div>
                  <table>
                    <tbody>
                      <tr>
                        <td>Processor</td>
                        <td>Intel Core i7</td>
                      </tr>
                      <tr>
                        <td rowspan="2">GPU</td>
                        <td>NVIDIA GeForce GTX660 D5 2G Higher</td>
                      </tr>
                      <tr>
                        <td>Radeon HD7850 D5 2GG Higher</td>
                      </tr>
                      <tr>
                        <td>Disk space requirements</td>
                        <td>1TB Higher</td>
                      </tr>
                      <tr>
                        <td>Operating system</td>
                        <td>Microsoft Windows 7, 10</td>
                      </tr>
                      <tr>
                        <td rowspan="3">Other requirements</td>
                        <td>Higher-resolution (1680X1050 higher) monitor</td>
                      </tr>
                      <tr>
                        <td>keyboard (wheeling enable) and mouse</td>
                      </tr>
                      <tr>
                        <td>Internet access</td>
                      </tr>
                      <tr>
                        <td class="bx" colspan="2">
                          <p class="break-all">
                            <b>SHA 256</b> 9334d1653ae6b135825cfc1985e8dfe9c30120916eed027f0dc3b921547b4484
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution1-2.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/R2GATE_Lite/R2GATE_Lite_AD.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  <div class="item-desc">
                    <h3>R2GATE Lite</h3>
                    <p
                      v-html="
                        localize('R2GATE Lite는 임플란트 진단 컨펌을 위한 전용 Application입니다. R2GATE 설치 없이, PC, MAC, iOS등의 기기에 간단하게 설치가 가능하며, R2 Planner나 R2 Center에서 계획한 임플란트 진단 파일을 자동으로 다운 받아, 수술계획을 컨펌 하거나 직접 수정 하실 수 있습니다.')
                      "
                    />
                  </div>
                </div>
                <div class="item-btns" :class="{ active: state.r2GateLite }">
                  <button type="button" class="btn-drop-down" @click="state.r2GateLite = !state.r2GateLite"></button>
                  <div>
                    <p>Program Download</p>
                    <div class="btns-wrap">
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">Windows<i class="icon icon-download-wh"></i></a>
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">MacOSX<i class="icon icon-download-wh"></i></a>
                    </div>
                  </div>
                  <div>
                    <p>Manual</p>
                    <div>
                      <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2GATE_Lite_Manual/3_R2GATE-Lite(KR)-202310.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                      <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2GATE_Lite_Manual/3_R2GATE-Lite(EN)-202310.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                    </div>
                  </div>
                </div>
                <div class="precautions">
                  <p>PRECAUTIONS</p>
                  <div class="tb-head">Prerequisite</div>
                  <table>
                    <tbody>
                      <tr>
                        <td v-html="localize('')" />
                        <td>
                          R2GATE Lite for PC(Windows) and Mac is free download.<br />
                          But license needed for activation, please ask it to your sales rep or R2-CENTER.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="tb-head">Caution</div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          R2GATE Lite for Mac might be blocked by Apple safety policy. so when you open it, if it
                          doesn't work, please press "Ctrl" key and click the right button of mouse, then choose "open".
                          when windows for use confirmation pop-up, choose "open" again.
                          <div class="bx">
                            <p class="break-all">
                              <b>SHA256(iOS)</b> 250117fe4cee5e6de4a6ceac853462ce883824789187c3a889bc89af87affcca
                            </p>
                            <p class="break-all">
                              <b>SHA256(Windows)</b> c63b6d37f675308b5bd0fd77badb2b4a3a6817bfc90abc8fc351d9bf371c307c
                            </p>
                            <p class="break-all">
                              <b>SHA256(MacOSX)</b> c98608c0a42c8556134a3a9e42de5f331a8a205fc1d339e90549e2ba2fdbfbe5
                            </p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a href="" class="btn btn-outline">Sample Data Download <i class="icon icon-download"></i></a>
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <img src="@/assets/home/img/solution/r2solution1-3.jpg" alt="" />
                  </div>
                  <div class="item-desc">
                    <h3>R2 Real Navi</h3>
                    <p
                      v-html="
                        localize('가이드 제작 없이, R2GATE 임플란트 계획 수립후 바로 수술 하실 수 있습니다.<br /> R2 Real Navi는 최신 Stereo camera 및 tracking 기술을 이용하여, 술자의 핸드피스 및 환자의 움직임을 실시간으로 추적하고, 반영함으로써, 물리적 가이드 없이, 정확한 포지션에 드릴링 및 임플란트 식립이 가능합니다.<br /> 또한, 시술시 임플란트 계획 수정이 가능함으로써, 최대의 자유도와 정밀도를 동시에 제공합니다.')
                      "
                    />
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Program Download</p>
                    <router-link to="/home/support/inquiry-save" class="btn btn-subcolor">{{ localize("메가젠에 문의하세요") }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :ref="sectionRefItems[1]" id="solution2">
          <div class="container">
            <div class="solution-title-wrap">
              <h2>Digital oral Design Solution</h2>
              <span>{{ localize("심미 · 전악 재건 솔루션") }}</span>
              <p
                v-html="localize('Top-down 방식의 R2GATE는 가장 직관적인 임플란트 플래닝 프로그램입니다.<br /> Digital EYE를 이용한 골질 분석을 통해 BIC를 확인하실 수 있고,<br /> Drilling sequence 계산을 통해 최적의 초기 고정을 얻을 수 있도록 합니다.')"
              />
            </div>
            <a class="main-thumb video">
              <video controls poster="@/assets/home/img/solution/r2solution2-4.png">
                <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/Digital_Oral_Design_Solution/DOD_Solution_241014.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
              </video>
            </a>
            <div class="program-items">
              <div class="item">
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution2-1.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/R2StudioQ/R2StudioQ.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  <div class="item-desc">
                    <h3>R2studio Q</h3>
                    <p v-html="localize('전악 임플란트, 보철을 위한 효과적인 데이터 수집이 가능합니다. 10분안에 CBCT, Facial Scan, Impression scan을 채득하고 Dental Avatar를 만들어 냅니다. Panorama, Facial Photo, Cephalo등 복잡한 과정없이  하나의 장비에서 간단하게 전악 치료계획 수립을 위한 환자데이터를 취득하고 분석 하실 수 있습니다. <br/><br/> 함께 제공되는 R2GATE QVD 모듈은 어떤 전악 케이스에서도 간단하게 환자의 VD를 구현 하고, 임플란트 수술 후 템포라리 수복까지 효과적인 전악 치료 프로토콜을 제공합니다.')" />
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Brochure download</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2STUDIO_Q_Broucher/R2-STUDIO-Q-(KR).pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2STUDIO_Q_Broucher/R2_STUDIO-Q-(EN).pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                  </div>
                  <div>
                    <p>Manual</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2STUDIO_Q_Manual/R2GATE_Manual-for-DOD(EN)-230104.pdf" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>ENG </a>
                  </div>
                  <div>
                    <p>Demo project download</p>
                    <router-link to="/home/support/inquiry-save" class="btn btn-subcolor">{{
                      localize("메가젠에 문의하세요")
                    }}</router-link>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution2-2.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/Digital_Oral_Design_&_QVD/R2studioQ_Short_241014.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  <div class="item-desc">
                    <h3>Digital Oral Design & QVD</h3>
                    <p v-html="localize('QVD와 R2Ai 는 Facial pattern 분석을 통해 환자의 이상적인 VD를 제시하고,Occlusal plane 설정, digital wax up library를 통해 수술 가이드 및 Provisional 제작을 위한 기초 데이터 를 제공합니다.<br/>DOD 및 QVD는 예측이 가능하고, 진단한대로 이루어지는 효과적인 심미, 전악 진단 솔루션입니다.')" />
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Program Download</p>
                    <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">Windows<i class="icon icon-download-wh"></i></a>
                  </div>
                  <div>
                    <p>Manual</p>
                    <div>
                      <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Digital_Oral_Desgin/R2GATE_Manual-for-DOD용(KR)-230720.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                      <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Digital_Oral_Desgin/R2GATE_Manual-for-DOD(EN)-230104.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                    </div>
                  </div>
                  <div :ref="sectionRefItems[7]"> <!-- Player 페이지에서 바로가기 해시를 위한 미세조정 위치 -->
                    <p>Demo project download</p>
                    <a href="/home/support/inquiry-save" class="btn btn-subcolor">{{ localize("메가젠에 문의하세요") }}</a>
                  </div>
                </div>
                <div class="item-caution">
                  *
                  {{
                    localize("DOD는 R2GATE에 포함된 기능 모듈이며, 일부 기능은 R2studio Q User에게만 별도로 제공됩니다.")
                  }}
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls>
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/smile_creator_APP/Smile_Creator_3D_241014.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  <div class="item-desc">
                    <h3>Smile Creator App</h3>
                    <p
                      v-html="
                        localize('5분안에 환자의 신뢰를 얻습니다. Smile Creator는  환자의 Facial scan만을 이용해서 Dental avatar를 만들고, Ai 기술로 새로운 (치료후) 스마일을 만들어 냅니다. 5분안에 새로운 스마일로 환자에게 새로운 희망을 제시하고, 효율적인 상담이 가능케 합니다. <br /> Smile Creator는 상담용 PC, Chair side PC, MAC, iOS device에 간편하게 설치가 가능합니다. 상담후 결과를 환자에게 공유할수 있어, 지속적인 동기 부여가 가능합니다.')
                      "
                    />
                  </div>
                </div>
                <div class="item-btns" :class="{ active: state.smileCreator }">
                  <!-- <button
                    type="button"
                    class="btn-drop-down"
                    @click="state.smileCreator = !state.smileCreator"
                  ></button> -->
                  <div>
                    <h1>Coming Soon</h1>
                  </div>
                  <!-- <div>
                    <p>Brochure download</p>
                    <a href="" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>KOR </a>
                    <a href="" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>ENG </a>
                  </div>
                  <div>
                    <p>Manual</p>
                    <a href="" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>ENG </a>
                  </div>
                  <div>
                    <p>Demo project download</p>
                    <a href="" class="btn btn-outline"> Demo<i class="icon icon-download"></i> </a>
                  </div> -->
                </div>
                <!-- <div class="precautions">
                  <p>PRECAUTIONS</p>
                  <div class="tb-head">Prerequisite</div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          The minimum specification required to run the app smoothly are as belows.<br />
                          - Apple iPad Pro 11
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="tb-head">Notice</div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          he app can collect images you've worked on.<br />
                          The information collected does not include sensitive information<br />
                          such as 'who you are', 'what is your name' or 'what is your ID'.<br />
                          The collected images are used to improve AI, collected, it isn't used for any other purpose.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="tb-head">Caution</div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          'MEGAGEN SMILE CREATOR' for Apple iPad might be blocked by Apple's safety policy. so when you
                          open it, if it doesn't work,
                          <div class="bx">
                            <p>1. Go to system settings</p>
                            <p>2. Select 'General'</p>
                            <p>3. Select 'Device Management</p>
                            <p>4. Select 'Megagen Implant Co.,Ltd'</p>
                            <p>5. Tap 'Trust "Megagen Implant Co.,Ltd"</p>
                            <p>6. Tab 'Trust' one more time</p>
                            <p>7. Tab 'SmileCreator' App</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div :ref="sectionRefItems[2]" id="solution3">
          <div class="container">
            <div class="solution-title-wrap">
              <h2>FaceGIDE Solution</h2>
              <span>
                Maxilo-Oral Facial solution
                <b>{{ localize("맞춤형 양악수술 및 악안면 재건") }}</b>
              </span>
              <p
                v-html="
                  localize('FaceGIDE는 더 빠르고, 안전한 양악 및 악안면 재건 수술을 위한 Setup 시뮬레이션 기능을 제공합니다.<br /> 이후 주문을 통해 환자 맞춤형으로 제작되는 맞춤형 Plate와<br /> 보형물로 예측 가능한 수술결과를 얻으실 수 있습니다.')
                "
              />
            </div>
            <div class="main-thumb">
              <a class="main-thumb video">
                <video controls poster="@/assets/home/img/solution/r2solution3.jpg">
                  <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/FaceGIDE_Solution/IDS_FG_FINAL190228_vol.2.mp4" type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </a>
            </div>
            <div class="program-items">
              <div class="item">
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution3-1.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/FaceGIDE_Setup_module/FaceGIDE_Setup_module.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                   </a>  
                  <div class="item-desc">
                    <h3>FaceGIDE setup module</h3>
                    <p>
                      {{
                        localize("FaceGIDE setup 모듈은 Two (one)jaw surgery를 위한 Segmentation 및 Jaw setup simulation기능을 제공합니다. 각 Segment별로 이동량 측정을 위한 Landmark 설정이 가능 하며, Jaw movement시 실시간으로 이동량과 변화 각을 보여줌으로써 효율적이고, 직관적인 시술 계획 수립이 가능토록 합니다.")
                      }}<br />
                      {{
                        localize("확정된 진단 및 수술계획 파일(Project file)은 동료들에게 전송하여 같이 케이스를 분석하거나, FaceGIDE Center에 의뢰하여 수술에 필요한 Saw guide와 맞춤형 plate, wafer 등을 제작하실수 있습니다.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Please contact below email for beta trial program</p>
                    <p class="btn btn-subcolor custom-cursor"><i class="icon-mail"></i> service@r2gate.com</p>
                  </div>
                  <div>
                    <p>Manual</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/FaceGIDE_Setup_Module/FaceGIDE_Setup-module-And-FG-Lite-user-manual-v1-1.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                    <a href="https://www.youtube.com/watch?v=4pvXaKsORpg" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <img class="custom-icon-youtube" src="@/assets/playground/img/youtube.png" alt="" /> YouTube</a>
                  </div>
                </div>
                <div class="item-caution">
                  *
                  {{
                    localize("FaceGIDE setup module은 Beta version으로써 Try를 원하시는 분들에게만 별도로 제공되며 관리됩니다.")
                  }}
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution3-2.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/FaceGIDE_Lite/FaceGIDE_Lite.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  <div class="item-desc">
                    <h3>FaceGIDE Lite</h3>
                    <p>
                      {{
                        localize("FaceGIDE center 또는 FG Planner에게 임상 케이스를 의뢰 하셨다면, FaceGIDE Lite  프로그램에서 Setup simulation파일을 받아 Setup을 확인하거나, 직접 수정 하실 수 있습니다. FaceGIDE Lite는 Window, MAC, iOS 환경에서 사용가능합니다.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Program Download</p>
                    <div class="btns-wrap">
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">ios<i class="icon icon-download-wh"></i></a>
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">Windows<i class="icon icon-download-wh"></i></a>
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">MacOSX<i class="icon icon-download-wh"></i></a>
                    </div>
                  </div>
                  <div>
                    <p>Manual</p>
                    <div>
                      <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/FaceGIDE_Lite/FaceGIDE_Setup-module-And-FG-Lite-user-manual-v1-1.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                      <a href="https://www.youtube.com/watch?v=4pvXaKsORpg" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <img class="custom-icon-youtube" src="@/assets/playground/img/youtube.png" alt="" /> YouTube</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :ref="sectionRefItems[3]" id="solution4">
          <div class="container">
            <div class="solution-title-wrap">
              <h2>R2 Ortho Solution</h2>
              <span>R2 orthodontic solution</span>
              <p>
                {{ localize("진료의 완성은 상실된 기능과 심미의 회복입니다.") }}<br />
                {{ localize("그렇기에 가지런한 치열은 원장님의 진료에 빛을 더해 주는 시너지 효과를 낼 수 있습니다.")}}<br />
                {{ localize("‘맥 얼라이너’로 환자에게 한 차원 높은 감동을 선물하세요.") }}
              </p>
            </div>
            <div class="main-thumb">
              <a class="main-thumb video">
                <video controls >
                  <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/R2_Ortho_Solution/R2_Ortho_Solution.mp4" type="video/mp4"/>
                  Your browser does not support the video tag.
                </video>
              </a>
            </div>
            <div class="program-items">
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution4-1.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/R2Ortho_moudule/R2Ortho_moudule.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  </div>
                  <div class="item-desc">
                    <h3>R2 Ortho module</h3>
                    <p>
                      {{
                        localize("교정환자를 위한 치아 Setup module을 제공합니다. 구강스캔 파일 또는 모델 스캔 파일을 사용하실수 있으며, CBCT를 촬영하신 경우 스캔파일과 CBCT 파일을 매칭하여, 치아 이동시 Bone에서의 이동량을 같이 체크 하실 수 있습니다.")
                      }}
                      <br />
                      {{
                        localize("Meg Aligner Center에 데이터를 보내신 경우, 완성된 Setup파일을 받아 직접 수정또는 컨펌이 가능 합니다.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Please contact below email for beta trial program</p>
                    <p class="btn btn-subcolor custom-cursor"><i class="icon-mail"></i> megaligner@imegagen.com</p>
                  </div>
                  <!-- <div>
                    <p>Manual</p>
                    <a href="" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>ENG </a>
                  </div> -->
                </div>
                <div class="item-caution">
                  * {{ localize("R2 Ortho Module은 Beta version으로 테스트를 원하시는 분에게 별도로 제공됩니다.") }}
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution4-2.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/R2_OrthoLite/R2_OrthoLite.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  </div>
                  <div class="item-desc">
                    <h3>R2 Ortho Lite</h3>
                    <p>
                      {{
                        localize("Meg Aligner center 또는 Meg Aligner Planner에게 임상 케이스를 의뢰 하셨다면, R2 Ortho Lite  프로그램에서 Setup simulation파일을 받아 Setup을 확인하거나, 직접 수정 하실 수 있습니다. R2 Ortho Lite는 Window, MAC, iOS 환경에서 사용가능합니다.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Program Download</p>
                    <div class="btns-wrap">
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">ios<i class="icon icon-download-wh"></i></a>
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">Windows<i class="icon icon-download-wh"></i></a>
                      <a href="https://r2gate.com/integrationDownload" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">MacOSX<i class="icon icon-download-wh"></i></a>
                    </div>
                  </div>
                  <div>
                    <p :ref="sectionRefItems[6]">Manual</p> <!-- Player 페이지에서 바로가기 해시를 위한 미세조정 위치 -->
                    <div>
                      <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2ortho_lite/R2OrthoLite_manual-240521.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="item" >
                <div class="item-top">
                  <a class="item-thumb video">
                    <video controls poster="@/assets/home/img/solution/r2solution4-3.jpg">
                      <source src="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Video/Meg_Aligner/Meg_Aligner.mp4" type="video/mp4"/>
                      Your browser does not support the video tag.
                    </video>
                  </a>
                  <div class="item-desc">
                    <h3>Meg Aligner</h3>

                    <h5>{{ localize('메가젠 임플란트에서 투명 교정을 ?!') }}</h5>
                    <br/>
                    <p>
                      {{ localize("진료의 완성은 상실된 기능과 심미의 완전한 회복입니다.") }}<br />
                      {{ localize("그렇기에 가지런한 치열은 원장님의 임플란트 및 보철 진료에 빛을 더해 주는 시너지 효과를 낼 수 있습니다.")}}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Brochure download</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Meg_Aligner/Meg_Aligner-KOR.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/Meg_Aligner/Meg_Aligner-EN.pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                  </div>
                  <div class="custom-contact">
                    <div>
                      <p class="tel">053-222-2505</p>
                      <p class="kakao">megaligner</p>
                      <p class="email">megaligner@imegagen.com</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :ref="sectionRefItems[4]" id="solution5">
          <div class="container">
            <div class="solution-title-wrap">
              <h2>R2 Dental<br />Prosthodontic Solution</h2>
              <span>{{ localize("가볍고 심플한 Chair-side CAD") }}</span>
              <p
                v-html="
                  localize('복잡하고 어려운 Lab용 CAD는 그만!<br /> 일상에서 가장 많이 쓰이는 템포라리 크라운, 맞춤형 어버트먼트,<br /> 1~3번 Bridge 디자인까지 필요한 기능만 모았습니다.')
                "
              />
            </div>
            <div class="main-thumb">
              <img src="@/assets/home/img/solution/r2solution5.jpg" alt="" />
            </div>
            <div class="program-items">
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <img src="@/assets/home/img/solution/r2solution5-1.jpg" alt="" />
                  </div>
                  <div class="item-desc">
                    <h3>R2 CAD</h3>
                    <p>
                      {{
                        localize("매일의 임상에서 가장 많이 필요로 하는 기능을 탑재한 심플한 Chair-side CAD 입니다. Temporary crown과 Customized abutment, inlay, onlay등 일상에서 간단하게 디자인 하실수 있습니다.")
                      }}
                      <br />
                      {{
                        localize("그래도, 디자인 할 시간이 없으시거나, 캐드 사용에 어려움이 있으시면, R2 planner에게 맡겨주세요.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Program Download</p>
                    <a href="/home/support/inquiry-save" class="btn custom-btn-subcolor">{{ localize("메가젠에 문의하세요") }}</a>
                  </div>
                  <div>
                    <p>Manual</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2CAD/R2CAD_Full-Manual-(KOR).pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>KOR </a>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/R2CAD/R2CAD_Full-manual-(EN).pdf" class="btn btn-outline btn-manual" target="_blank" rel="noopener noreferrer"> <i class="icon-manual"></i>ENG </a>
                  </div>
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <img src="@/assets/home/img/solution/r2solution5-2.jpg" alt="" />
                  </div>
                  <div class="item-desc">
                    <h3>R2 PROS Lite</h3>
                    <p>
                      {{
                        localize("R2 Pros Lite는 가장 가볍고, 편리한 치과용 3D viewer 입니다. STL, PLY, OBJ등 임상에서 자주 사용하는 모든 포맷의 3D파일을 보실 수 있고, 파일의 각부 분에 필요한 코멘트를 남기고 전달 할수 있습니다. R2 Playground에서 보철물을 의뢰 하셨다면, 디자인 컨펌용으로도 사용하실 수 있습니다.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <h1>Coming Soon</h1>
                  </div>
                  <!-- <div>
                    <p>Program Download</p>
                    <div class="btns-wrap">
                      <a href="" class="btn btn-maincolor">ios<i class="icon icon-download-wh"></i></a>
                      <a href="" class="btn btn-maincolor">Windows<i class="icon icon-download-wh"></i></a>
                      <a href="" class="btn btn-maincolor">MacOSX<i class="icon icon-download-wh"></i></a>
                    </div>
                  </div>
                  <div>
                    <p>Manual</p>
                    <div>
                      <a href="" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>KOR </a>
                      <a href="" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>ENG </a>
                    </div>
                  </div> -->
                </div>
              </div>
              <div class="item">
                <div class="item-top">
                  <div class="item-thumb">
                    <img src="@/assets/home/img/solution/r2solution5-3.jpg" alt="" />
                  </div>
                  <div class="item-desc">
                    <h3>CAD/CAM Library</h3>
                    <p>
                      {{
                        localize("메가젠 유저들을 위한 CAD/CAM library를 제공합니다. Scan abutment, Ti-base, Premilled abutment등을 디자인 하실수 있는 정규 CAD(3shape, EXOCAD, Blender, Dental wing등) 라이브러리와 Customized library를 제공합니다.")
                      }}<br />
                      {{
                        localize("CAD/CAM의 정수는 Library의 조합에 있습니다. 어떤 Abutment이던 Library화가 가능합니다. 상상력이 풍부할수록 디지털의 장점은 더 많아 집니다. 지금 필요한 라이브러리를 찾아 보세요. 혹시 발견하지 못하셨다면, 라이브러리 요청 게시판에 요청해 주세요.")
                      }}
                    </p>
                  </div>
                </div>
                <div class="item-btns">
                  <div>
                    <p>Program Download</p>
                    <a href="https://r2gate.com/cad-cam?cate=3shape" class="btn btn-maincolor" target="_blank" rel="noopener noreferrer">Windows<i class="icon icon-download-wh"></i></a>
                  </div>
                  <div>
                    <p>Manual</p>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/CAD_CAM_Library/KOR/[KOR]_CAD_CAM_Library.zip" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>KOR </a>
                    <a href="https://playgroundmaintenance.blob.core.windows.net/webstaticassets/CAD_CAM_Library/ENG/[ENG]_CAD_CAM_Library.zip" class="btn btn-outline btn-manual"> <i class="icon-manual"></i>ENG </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :ref="sectionRefItems[5]" id="solution6">
          <div class="container">
            <div class="solution-title-wrap">
              <h2>R2 Facial Reconstruction</h2>
            </div>
            <div class="main-thumb">
              <img src="@/assets/home/img/solution/r2solution6.jpg" alt="" />
            </div>
          </div>
        </div>

        <div class="outro">
          <div class="container">
            <h3>R2 Playground APP Program</h3>
            <p>{{ localize("R2 playground는 모든 R2 solution을 통합 관리하는 프로그램입니다.") }}</p>
            <ul class="list-dot">
              <li>{{ localize('한번 설치하면 원하는 R2 solution 설치 가능') }}</li>
              <li>{{ localize('실시간 업데이트 및 중요 알림 수신 가능') }}</li>
              <li>{{ localize('온라인 주문으로 간편하게 디자인 및 보철물 주문 가능') }}</li>
              <li>{{ localize('주문자는 의뢰한 주문에 대해 디자인 컨펌까지 가능') }}</li>
            </ul>
            <a v-bind:href="downloadLink" target="_blank" class="btn btn-outline">
              {{ localize("Playground 다운로드") }}<i class="icon icon-download-wh"></i>
            </a>
          </div>
        </div>
        <!-- //container -->
      </main>
      <!-- footer -->
      <home-footer />
      <!-- //footer -->
    </div>
  </div>
</template>

<style scoped>
/* YouTube 버튼 스타일링*/
.custom-icon-youtube {
  width: 100%;
  height: 24px;
  margin-right: 5px;
}

/* contact 클래스 속성에서 flex-direction 속성을 추가한 사용자 정의 클래스 */
.custom-contact { 
  margin-top: 1.11rem;
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  color: var(--color-dark);font-weight: 500;
}

/* contact 하위 클래스 재정의 */
.custom-contact p {display: flex; align-items: center;}
.custom-contact p::before {content: ''; display: inline-block;width: 18px;height: 18px; margin-right: 10px;}
.custom-contact .tel::before {background: url(../../../src/assets/home/img/icon-contact-email.svg);}
.custom-contact .email::before {background: url(../../../src/assets/home/img/icon-contact-tel.svg);}
.custom-contact .kakao::before {background: url(../../../src/assets/home/img/icon-contact-kakao.svg);}
.custom-contact p:last-child::after {content: none;}

/* 블루 계열 버튼 스타일링 */
.custom-btn-subcolor {background-color: rgba(88, 75, 231, 0.1); color: var(--color-solution3);}

/* 마우스 커서 기본 값 설정 */
.container .item-btns .custom-cursor{ cursor: default; }

/* .item-thumb 요소 레이아웃 설정 및 비디오 스타일링 */
.solution .item-thumb {display: block; max-width: 667px; margin: 0 auto 6.67rem; aspect-ratio: 960/540;border-radius: 20px;overflow: hidden;}
.solution .item-thumb video {width: 100%; height: 100%; object-fit: cover}
.solution .video {position: static;}

/* 비디오 재생 버튼 제거  */
.solution .video::after {content: ''; position: absolute; background: none;}
</style>
