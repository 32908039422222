import { DocumentNode } from "@apollo/client/core";
import { GraphQLClient, GraphQLClientConfig, GraphQLRequest } from "../../base";
import { HowToMethods } from "../../proxy";
import { AuthenticationSettings, ClientSettings } from "../../settings";
import { QueryRequest, QueryResponse, ServiceBase } from "../common";

class UserService extends ServiceBase {

    //NOTE" 각 서비스마다 필요로하는 설정 값을 갖춰두고 이를 기반으로 GraphQLClient 인스턴스를 생성한 다음에 상위 클래스로 전달하도록 한다 [한계]
    private static _createGraphQLClient = () => {
        const cs = ClientSettings.instance
        const as = AuthenticationSettings.instance

        const graphQLClientConfig: GraphQLClientConfig = {
            httpUri: cs.serviceEndpoints.users,
            howToSetToken: HowToMethods.howToSetToken,
            howToRefresh: HowToMethods.howToRefresh
        }

        return new GraphQLClient(graphQLClientConfig)
    }

    constructor() {
        /*const graphqlClient = () => {
            const cs = ClientSettings.instance
            const as = AuthenticationSettings.instance

            //1. GraphQLClientConfig 개체 설정
            const graphqlClientConfig: GraphQLClientConfig = {
                httpUri: cs.serviceEndpoints.users,
                howToSetToken: HowToMethods.howToSetToken,
                howToRefresh: HowToMethods.howToRefresh
            }
            return new GraphQLClient(graphqlClientConfig)
        }

        super(graphqlClient())*/

        super(UserService._createGraphQLClient())
    }

    /*async query<TResponse = any, TValue extends Record<string, any> | undefined = Record<string, any>>(
        request: QueryRequest<TValue>
    ): Promise<QueryResponse<TResponse>> {
        return this._client.query<TResponse, TValue>({
            query: request.query,
            variables: request.variables,
        });
    }

    async mutate<TResponse = any, TValue extends Record<string, any> | undefined = Record<string, any>>(
        request: QueryRequest<TValue>
    ): Promise<QueryResponse<TResponse>> {
        return this._client.mutate<TResponse, TValue>({
            query: request.query,
            variables: request.variables,
        });
    }*/
}

export { UserService }