<script setup lang="ts">

</script>

<template>
  <h1>Certification Home</h1>
  <router-link to="detail/1/qualifying-test">License 1</router-link>
  <router-link to="detail/2/qualifying-test">License 2</router-link>
  <router-link to="detail/3/qualifying-test">License 3</router-link>
</template>

<style scoped>

</style>