import { Role } from "@/models";
import { useMemberStore } from "@/store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

/**
 * 권한이 필요한 페이지에 대한 권한 체크
 * @param to
 * @param from
 * @param next
 */
export function requireAuth(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const member = useMemberStore().getMember();
  // TODO: 권한 필드가 정의되면 변경 필요
  const level = member.id ? 1 : 0;
  console.log(`level:${level} > ${(to?.meta?.level as number) ?? 0}, to:${to.path}, from:${from.path}`);
  if (level === 0 || level < ((to?.meta?.level as number) ?? 0)) {
    console.log("required level:", (to?.meta?.level as number) ?? 0);
    // 오류 페이지 이동
    next({ path: "/error/401" });
  } else {
    next();
  }
}
/**
 * 역할이 일치하지 않는 페이지로 이동을 차단
 * @param to
 * @param from
 * @param next
 */
export function correctRole(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const { extraRole } = useMemberStore().getMember();
  const role = to.meta.role;
  if (extraRole && role != "playter" && role != getMetaRole(extraRole)) {
    next({ path: "/error/403" });
  } else {
    next();
  }
}
/**
 * 지정된 페이지로 이동을 차단
 * @param absolutePath 차단할 페이지 절대 경로
 */
export function blockPage(absolutePath: string) {
  return (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    // console.log(`from:${from.path}, to:${to.path}`);
    if (to.path === absolutePath) {
      console.log("blocked page:", absolutePath);
      next(false);
    } else {
      next();
    }
  };
}
/**
 * 해당 역할 값을 meta.role에 설정하기 위한 값 반환
 * @param role 사용자 역할
 * @returns
 */
function getMetaRole(role: Role | null) {
  return role == Role.PLAYER ? "player" : role == Role.PLANNER ? "planner" : "r2center";
}
