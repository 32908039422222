import { RouteRecordRaw } from "vue-router";
import List from "@/views/playground/player/support/trouble-shooting/TroubleShootingList.vue";
import View from "@/views/playground/player/support/trouble-shooting/TroubleShootingView.vue";
import Save from "@/views/playground/player/support/trouble-shooting/TroubleShootingSave.vue";
import component from "*.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/playground/player/support/trouble-shooting/list",
  },
  {
    path: "list",
    name: "trouble-shooting-list",
    component: List,
  },
  {
    path: "view/:id",
    name: "trouble-shooting-view",
    component: View,
  },
  {
    path: "save",
    name: "trouble-shooting-save",
    component: Save,
  },
];

export default routes;
