<script setup lang="ts">
import AuthCodeInput from "@/components/AuthCodeInput.vue";
import { graphql as userGraphql } from "@/libs/R2ServiceClient/service/User";
import { graphql as visitorGraphql } from "@/libs/R2ServiceClient/service/Visitor";
import router from "@/router";
import { alert, useFormBuilder } from "@/services";
import { useClientWrapperStore, useMemberStore } from "@/store";
import { localize, Validators } from "@/utils";
import HomeFooter from "@/views/home/HomeFooter.vue";
import HomeHeader from "@/views/home/HomeHeader.vue";
import { onMounted, reactive, ref } from "vue";

const { emailAddress } = useMemberStore().getMember();

/**
 * 인증코드 입력 컴포넌트 참조
 */
const authCodeInputRef = ref<InstanceType<typeof AuthCodeInput> | null>(null);

const state = reactive({
  /**
   * 이메일
   */
  email: emailAddress,
  /**
   * 인증 요청 아이디
   */
  verificationRequestId: "",
  /**
   * 남은 시간
   */
  remainTime: { minutes: "", seconds: "" },
});
/**
 * 인증코드 만료 시간
 */
const expiredDate = ref<Date>();
/**
 * 인증코드 만료 시간 간격
 */
const expiredSeconds = 3 * 60;

/**
 * 폼 빌더
 */
const formBuilder = useFormBuilder();
const { controls, group, validate, valid, invalid } = formBuilder;
group({
  code: {
    touched: false,
    value: "",
    validators: [Validators.required, Validators.minLength(6), Validators.expired(expiredDate)],
  },
});

onMounted(init);

/**
 * 초기화
 */
function init() {
  validate();
}
/**
 * 인증 메일 발송
 */
async function sendEmail() {
  // if (!enabledAuth.value) return;
  // console.log("sendEmail > enabledEmail", enabledAuth.value);
  const CreateUserVerification = visitorGraphql(`
    mutation CreateUserVerificationRequest($input: CreateVerificationRequestInput!) {
      createUserVerificationRequest(input: $input) {
        id
        method
        emailAddress
        requestedAt
        expiredAt
      }
    }
  `);
  const result = await useClientWrapperStore().visitor.typedMutate(CreateUserVerification, {
    variables: {
      input: {
        emailAddress: state.email!,
        method: "EMAIL",
      },
    },
    successCallback: (result) => {
      console.log("sendMail - successCallback: ", result);
      state.verificationRequestId = result.createUserVerificationRequest.id;
      // console.log("verificationRequestId: ", state.verificationRequestId, "verifiedEmail: ", controls.verifiedEmail.value);
      controls.code.value = "";
      const now = new Date();
      now.setSeconds(now.getSeconds() + expiredSeconds);
      expiredDate.value = now;
      authCodeInputRef.value?.reset();
    },
  });
}
/**
 * 완료 핸들러
 */
async function done(event: Event): Promise<void> {
  // console.log(errors, valid());done - verificationRequestId:
  validate(true);
  if (valid()) {
    const ReleaseDormantLoginAccount = userGraphql(`
      mutation ReleaseDormantLoginAccount($input: DormantLoginAccountReleaseRequestInput!) {
        releaseDormantLoginAccountRequest(input: $input) {
          isSuccess
        }
      }
    `);
    console.log("done - verificationId: ", state.verificationRequestId, "verificationCode: ", controls.code.value);
    const result = await useClientWrapperStore().user.typedMutate(ReleaseDormantLoginAccount, {
      variables: {
        input: {
          verificationId: state.verificationRequestId,
          verificationCode: controls.code.value,
        },
      },
      successCallback: (result) => {
        console.log("done - successCallback: ", result);
        const success = result.releaseDormantLoginAccountRequest.isSuccess;
        console.log("success: ", success);
        if (!success) {
          alert("인증코드가 올바르지 않습니다.");
          return;
        }
        router.push("/");
      },
    });
  }
  event.preventDefault();
}
</script>

<template>
  <div id="wrap" class="r2playground">
    <home-header />

    <main id="ct">
      <div class="container support login-wrap">
        <div class="join-box login-box">
          <div class="step-wrap">
            <img src="@/assets/home/img/logo.svg" alt="" class="mb-4" />
            <h2>{{ localize("휴면 회원 안내") }}</h2>
          </div>
          <form class="join-form mh-0">
            <div class="bx-purple py-4">
              <b>{{ localize("안녕하세요") }}, </b>
              <strong class="mt-2 text-purple"
                ><b>{{ state.email }}</b
                >{{ localize("님") }}</strong
              ><br />
              {{ localize("회원님은 1년 이상의 장기 미이용으로 휴면 상태로 전환되었습니다.") }}<br />
              <b>{{ localize("이메일 인증 후 휴면 해제를 통해 서비스를 이용하실 수 있습니다.") }}</b>
            </div>

            <!-- 인증 메일 발송하기 / 재발송-->
            <a @click="sendEmail" class="btn btn-square center w-100">
              <label v-if="state.verificationRequestId.length > 0">{{ localize("인증 메일 재발송하기") }}</label>
              <label v-else>{{ localize("인증 메일 발송하기") }}</label>
            </a>
            <!-- //인증 메일 발송하기 / 재발송-->

            <span v-if="state.verificationRequestId.length > 0">
              <!-- 인증 코드 입력-->
              <auth-code-input ref="authCodeInputRef" :form-builder="formBuilder" @submit="done"></auth-code-input>
              <!-- //인증 코드 입력-->
            </span>
          </form>
          <div class="btn-wrap">
            <a class="btn btn-primary center w-100" @click="done" :class="{ disabled: invalid() }">{{
              localize("휴면 해제")
            }}</a>
          </div>
        </div>
      </div>
      <!-- //container -->
    </main>

    <home-footer />
  </div>
</template>

<style scoped></style>
