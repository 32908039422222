enum Sex {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

enum MemberStatus {
  JOINED = "JOINED",
  WITHDRAWN = "WITHDRAWN",
}

enum Role {
  PLAYER = "PLAYER",
  PLANNER = "PLANNER",
  R2_CENTER = "R2_CENTER",
}

enum LoginAccountStatus {
  ACTIVE = "ACTIVE",
  DORMANT = "DORMANT",
  PAUSED = "PAUSED",
  DELETED = "DELETED",
}

interface Member {
  office?: Office | null;
  id: string | null;
  loginId: string;
  name: MemberName;
  roles: Role[];
  sex: Sex;
  country: Country;
  address?: Address | null;
  emailAddress?: string | null;
  phoneNumber?: PhoneNumber | null;
  birthDate: Date;
  registeredAt: Date;
  withdrawnAt?: Date | null;
  status: MemberStatus;
}

interface Office {
  member?: Member | null;
  id: string;
  name: string;
  phoneNumber?: PhoneNumber | null;
  address?: Address | null;
  emailAddress?: string | null;
}

interface MemberName {
  firstName: string;
  lastName: string;
  fullName?: string | null;
}

interface Country {
  code: string;
  name: string;
}

interface Address {
  postalCode: string | null;
  address1: string;
  address2: string | null;
  city: string | null;
  state: string | null;
}

interface PhoneNumber {
  countryCode: string;
  number: string;
  fullNumber?: string | null;
}

export { Member, Office, MemberName, Country, Address, PhoneNumber, Sex, MemberStatus, Role, LoginAccountStatus };
