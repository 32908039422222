<script setup lang="ts">
import { reactive, inject } from "vue";
import { deepMergeExistingKeys, localize } from "@/utils";
import { ServiceSettingAddProps } from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAdd.vue";
import { ServiceInfo } from "@/models/service-setting";

/**
 * 서비스 등록 상태
 */
const data = inject<ServiceSettingAddProps>("service-setting-add-state")!;
data.step = 1;
// TODO: 하드코딩이 아니라 API로 받아오는 상황이면 아래처럼 reactive로 상태를 관리
// const state = reactive({
//   services: <ServiceItem[]>[
//     { id: "service1", name: "R2 Guide & ONE-DAY Teeth", image: "/src/assets/playground/img/step-card-1.png" },
//     { id: "service2", name: "Customized Aubtment & Temporary", image: "/src/assets/playground/img/step-card-2.png" },
//     { id: "service3", name: "Crown & Bridge", image: "/src/assets/playground/img/step-card-3.png" },
//     { id: "service4", name: "Implant Bar + Bridge", image: "/src/assets/playground/img/step-card-4.png" },
//   ],
// });
const services = <ServiceInfo[]>[
  { id: "service1", name: "R2 Guide & ONE-DAY Teeth", image: "/src/assets/playground/img/step-card-1.png" },
  { id: "service2", name: "Customized Aubtment & Temporary", image: "/src/assets/playground/img/step-card-2.png" },
  { id: "service3", name: "Crown & Bridge", image: "/src/assets/playground/img/step-card-3.png" },
  { id: "service4", name: "Implant Bar + Bridge", image: "/src/assets/playground/img/step-card-4.png" },
];

/**
 * 서비스 변경
 * @param service 서비스 정보
 */
function changeService(service: ServiceInfo) {
  deepMergeExistingKeys(data.service, service);
  console.log("data.service:", data.service);
}
</script>

<template>
  <div class="fieldset d-block pb-3 mb-4">
    <strong class="fs-sm text-primary">STEP 1</strong>
    <h3 class="fs-2 mt-2 mb-4">서비스 선택 및 작업 시간 지정</h3>
    <p class="mb-4 fs-3">등록하고자 하는 서비스를 선택해주세요.</p>
    <div class="row service-card-wrap">
      <!-- 서비스 아이템 -->
      <label v-for="item in services" class="col service-card form-check" :for="item.id!">
        <input
          :id="item.id!"
          :value="{ id: item.id, name: item.name }"
          :checked="data.service.id === item.id"
          @change="changeService(item)"
          type="radio"
          class="sr-only"
          name="service"
        />
        <div class="d-flex img-bx">
          <img :src="item.image!" alt="" />
          <p class="fs-sm mt-2">{{ item.name }}</p>
        </div>
        <span class="check"></span>
        <div class="card-btn">
          <button class="btn-help fill" data-toggle="modal" data-target="#modal-charging"></button>
        </div>
      </label>
      <!-- 서비스 아이템 -->
    </div>
  </div>
  <div class="fieldset d-block">
    <p class="mb-4 fs-3">등록하고자 하는 서비스를 선택해주세요.</p>
    <div class="form-group d-flex">
      <div class="form-label form-label-plain">의뢰별 완료 시간</div>
      <div class="col">
        <select v-model="data.service.time" class="form-control form-select">
          <option value="3">3시간 내</option>
          <option value="5">5시간 내</option>
        </select>
      </div>
      <div class="form-label form-label-plain">1일 최대 작업 가능한 의뢰 수</div>
      <div class="col">
        <input v-model="data.service.quantity" type="number" min="1" class="form-control" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
