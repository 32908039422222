import { ClientSettings, AuthenticationSettings } from "../settings";
import fetch from 'cross-fetch'

//#region (AuthProxy)
class AuthProxy {
    static async login(loginId: string, password: string): Promise<boolean> {
        let isSuccess: boolean = false

        try {
            //1. GraphQL 쿼리 정의
            const query = `
                mutation LoginRequest($input: LoginRequestInput!) {
                    loginRequest(input: $input) {
                        isSuccess
                        data {
                            token
                            refreshToken
                        }
                    }
                }
            `;
            //2. 질의의 변수 선언
            const variables = { 
                input: {
                    loginId: loginId, 
                    password: password 
                }
            };
            //3. GraphQL 요청
            const response = await post(query, variables);
            if (response.data.loginRequest.data && response.data.loginRequest.isSuccess) {
                const as = AuthenticationSettings.instance;

                //3.1. 로그인이 완료되면, 서버로부터 전달된 token 을 AuthenticationSettings 개체에 저장한다
                const resData = response.data.loginRequest.data
                as.token = resData.token
                as.refreshToken = resData.refreshToken;

                //3.2. 성공여부 설정
                isSuccess = true
            } 
        } catch (error) {
            console.error('Login error:', error);
        }

        return isSuccess
    }

    static async loginByToken(token: string): Promise<boolean> {
        let isSuccess: boolean = false

        try {
            //1. GraphQL 쿼리 정의
            const query = `
                mutation LoginByTokenRequest($token: String!) {
                    loginByToken(token: $token) {
                        isSuccess
                        data {
                            token
                            refreshToken
                        }
                    }
                }
            `;
            //2. 질의의 변수 선언
            const variables = { token: token };
            //3. GraphQL 요청
            const response = await post(query, variables);
            if (response.data && response.data.loginByToken.isSuccess) {
                const as = AuthenticationSettings.instance;

                //3.1. 로그인이 완료되면, 서버로부터 전달된 token 을 AuthenticationSettings 개체에 저장한다
                const resData = response.data.loginByToken.data
                as.token = resData.token
                as.refreshToken = resData.refreshToken
                
                //3.2. 성공여부 설정
                isSuccess = true
            } 
        } catch (error) {
            console.error('Login by token error:', error);
        }

        return isSuccess
    }

    static async logout(): Promise<boolean> {
        let isSuccess: boolean = false

        try {
            //1. GraphQL 쿼리 정의
            const query = `
                mutation LogoutRequest {
                    logoutRequest
                }
            `;
            const response = await post(query, {}); //2. GraphQL 요청
            isSuccess = response.data  //3. 성공여부 설정
        } catch (error) {
            console.error('Logout error:', error);
        } finally {
            //3. 로그아웃을 요청했다면, 반드시 로컬에 저장된 토큰 정보를 삭제해야한다
            const as = AuthenticationSettings.instance;
            as.clear();
        }

        return isSuccess
    }

    static async refresh(): Promise<boolean> {
        let isSuccess: boolean = false

        try {
            //1. GraphQL 쿼리 정의
            const query = `
                mutation RefreshLoginRequest {
                    refreshLoginRequest {
                        isSuccess
                        data {
                            token
                            refreshToken
                        }
                    }
                }
            `;
            const variables = { };

            //2. GraphQL 요청
            const response = await post(query, variables);
            if (response.data && response.data.isSuccess) {
                //3.1. 토큰 갱신이 완료되면, 서버로부터 전달된 token 을 AuthenticationSettings 개체에 저장한다
                const resData = response.data.refreshLoginRequest.data

                const as = AuthenticationSettings.instance
                as.token = resData.token
                as.refreshToken = resData.refreshToken
                
                //3.2. 성공여부 설정
                isSuccess = true
            } 
        } catch (error) {
            console.error('Refresh error:', error);
        }

        return isSuccess;
    }
}

//NOTE" GraphQL 클라이언트가 아닌 ApolloClient 의 하단에 위치한 fetch 함수를 그대로 사용한다
async function post(query: string, variables: any) {
    const as = AuthenticationSettings.instance;
    const cs = ClientSettings.instance
    const response = await fetch(cs.serviceEndpoints.users, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': as.token ? `Bearer ${as.token}` : ''
        },
        body: JSON.stringify({ query, variables }),
    });

    return response.json();
}
//#endregion

export { AuthProxy }