import { RouteRecordRaw } from "vue-router";
import Step1 from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAddStep1.vue";
import Step2 from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAddStep2.vue";
import Step3 from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAddStep3.vue";
import Step4 from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAddStep4.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/order/service/add/step1`,
  },
  {
    path: "step1",
    component: Step1,
  },
  {
    path: "step2",
    component: Step2,
  },
  {
    path: "step3",
    component: Step3,
  },
  {
    path: "step4",
    component: Step4,
  },
];

export default routes;
