import { ClientSettings } from "../../settings"
import { DeletionReportSubmitFile, FileDeletionReportSubmitRequest, ResourceProcessor } from "./resource"
import { FileDeleteResult, FileTransferProgress, FileTransferResult, FileTransferSource, FileUploadSource } from "./type"
import { getFileUploader, IFileUploader } from "./uploader"

interface IFileTransferService {
    transfer(fileTransferSource: FileTransferSource, progressReporter?: ((progress: FileTransferProgress) => void) | undefined): Promise<FileTransferResult>
}

class FileTransferService implements IFileTransferService {
    public constructor() {
        
    }

    public async transfer(fileTransferSource: FileTransferSource, progressReporter: ((progress: FileTransferProgress) => void) | undefined = undefined): Promise<FileTransferResult> {
        let fileTransferResult: FileTransferResult = { }

        //준비.
        if(progressReporter) {
            let progress: FileTransferProgress = {
                state: 'Preparing'
            }

            progressReporter(progress)
        }

        //업로드.
        if(fileTransferSource.uploadSource) {
            //업로드 진행상황을 보여줄 수 있는 '익명메소드'를 준비한다
            let uploadProgressReporter = (uploadProgress: FileTransferProgress): void => {
                if(progressReporter) {
                    let progress: FileTransferProgress = {
                        state: 'Uploading',
                        fileName: uploadProgress.fileName,
                        progressRate: uploadProgress.progressRate,
                        remainingTime: uploadProgress.remainingTime
                    }

                    progressReporter(progress)
                }
            }

            // [웹브라우저] 또는 [파일시스템] 중, 어떤 환경에서 실행했는지에 따라 업로드 처리기를 따로 분리하였다_이렇게 한 이유는_웹브라우저에서 실행될 때, Node 런타임에서만 사용되는 개체가 포함된 스크립트를 읽어들이면(인터프리팅하면) 바로 실행을 중지하기 때문에_만약의 경우에 Node 런타임에서만 사용되는 개체가 포함된 스크립트를 실수로라도 실행 중에 인터프리팅하지않도록 막는다는 의미가 있다
            let fileUploader: IFileUploader = await getFileUploader()
            let fileUploadResults = await fileUploader.upload(fileTransferSource.uploadSource, uploadProgressReporter)
            fileTransferResult.upload = fileUploadResults    
        }

        //삭제.
        if(fileTransferSource.deleteSource) {
            //파일을 물리적으로 삭제하는 것이 아니기 때문에, 삭제는 [매우 빠르게] 진행된다_별도의 진행률 상태를 보여주지 않는다
            if(progressReporter) {
                let progress: FileTransferProgress = {
                    state: 'Deleting'
                }

                progressReporter(progress)
            }

            let submitFiles: DeletionReportSubmitFile[] = []
            fileTransferSource.deleteSource.items.forEach(fn => {
                submitFiles.push({
                    fileId: fn
                })
            })
            let deletionReportSubmitRequest: FileDeletionReportSubmitRequest = {
                submitFiles: submitFiles
            }

            //업로드(등록)과는 다르게 물리적으로 파일을 삭제하는 것이 아니라, 등록된 파일의 상태를 변경할 뿐이므로_[업로드처럼, 환경에 따른 처리기]를 만들지 않고, 바로 실행한다
            let resourceProcessor: ResourceProcessor = new ResourceProcessor()
            let reportSubmitResponse = await resourceProcessor.submitDeletionReport(deletionReportSubmitRequest)
            if(reportSubmitResponse.isSuccess && reportSubmitResponse.data) {
                let fileDeleteResults: FileDeleteResult[] = []
                reportSubmitResponse.data.deletingFiles.forEach(df => {
                    fileDeleteResults.push({
                        fileId: df.fileId,
                        transferResult: 'succeed'
                    })
                })

                fileTransferResult.delete = fileDeleteResults
            }
        }

        //완료.
        if(progressReporter) {
            let progress: FileTransferProgress = {
                state: 'Completed'
            }

            progressReporter(progress)
        }

        return fileTransferResult
    }

    public static getDownloadUri(fileId: string) {
        let cs = ClientSettings.instance
        return `https://testr2plgapp.azurewebsites.net/resource/files/${fileId}`
    }
}

export type { IFileTransferService }
export { FileTransferService }