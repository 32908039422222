import { RouteRecordRaw } from "vue-router";
import List from "@/views/playground/r2center/business-management/customer-management/CustomerManagementList.vue";
import View from "@/views/playground/r2center/business-management/customer-management/CustomerManagementView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/customer/list`,
  },
  {
    path: "list",
    component: List,
  },
  {
    path: "view",
    component: View,
  },
];

export default routes;
