const getEnv = () => {
  // 브라우저 환경인지 Node.js 환경인지 확인하는 함수
  const isBrowser = () => typeof window !== "undefined" && typeof window.document !== "undefined";
  if (isBrowser()) {
    // 브라우저 환경에서는 Vite의 환경 변수를 사용
    return import.meta.env;
  } else {
    // Node.js 환경에서는 dotenv와 path 모듈을 사용
    const dotenv = require("dotenv");
    const { resolve } = require("path");

    // 현재 프로젝트의 루트 디렉토리를 가져옵니다.
    const projectRoot = resolve(__dirname, "../../");
    // NODE_ENV에 따라 다른 .env 파일을 로드
    const envFile = process.env.NODE_ENV === "production" ? ".env.production" : ".env.development";
    const envPath = resolve(projectRoot, envFile);
    dotenv.config({ path: envPath });

    return process.env;
  }
};

const env = getEnv();

/**
 * @description GraphQL 서버 주소
 */
export const graphQlServer = env.VITE_GRAPHQL_SERVER || "https://pre-server.r2playground.com";

/**
 * @description 다국어 서버 주소
 */
export const localizationServer = import.meta.env.VITE_LOCALIZATION_SERVER || "http://localhost:3030";
