<script setup lang="ts">
import { ServiceFactory } from "@/libs/R2ServiceClient";
import { useClientWrapperStore } from "@/store";
import { ref, type Ref, inject, onMounted } from "vue";
import { alert } from "@/services";

// const serviceFactory = inject<ServiceFactory>("R2ServiceFactory")!;

const message: Ref<string> = ref("");
const partnerList: Ref<any[]> = ref([]);

// onMounted(async () => {
//   let loginResponse = await authService.login({ id: "test@test.com", password: "1234" }); //로그인 확인은 'test@test.com', '1234'로 진행해주셨으면 합니다
//   if (loginResponse.isSuccess) {
//     message.value = `로그인 성공`;
//   } else {
//     message.value = `로그인 실패`;
//   }
// });

async function QueryTest() {
  let partnerListQuery = `
    query {
        partners {
                data {
                id
                name
                rating {
                    speed
                    accuracy
                    communication
                    this_average: average
                }
            }
        }
    }
    `;
  //   let queryResponse = await queryService.query({ query: partnerListQuery });
  //   if (queryResponse.isSuccess) {
  //     partnerList.value = queryResponse.data.partners.data;
  //   } else {
  //     message.value = `Query failed`;
  //   }
}
async function testClientWrapper() {
  const query = `
    query {
        partners {
                data {
                id
                name
                rating {
                    speed
                    accuracy
                    communication
                    this_average: average
                }
            }
        }
    }
    `;
  // const response = await useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (data: any) => {
  //       partnerList.value = data.partners.data;
  //     },
  //     errorCallback: (error: any) => {
  //       console.log(error);
  //       alert("지정 에러 처리");
  //     },
  //     afterErrorCallback: (error: any) => {
  //       console.log(error);
  //       alert("에러 후 처리");
  //     },
  //     finallyCallback: () => {
  //       alert("완료 후 공통 처리");
  //     },
  //   }
  // );
}
</script>

<template>
  <div class="container">
    <h1>Query View</h1>
    <h5>{{ message }}</h5>
    <button v-on:click="QueryTest">테스트 쿼리</button>
    <button v-on:click="testClientWrapper">client-wrapper 쿼리</button>
    <hr />
    <div class="list" v-for="partner in partnerList" :key="partner.id" v-if="partnerList.length > 0">
      <p>ID: {{ partner.id }}</p>
      <p>NAME: {{ partner.name }}</p>
      <p>평점: 속도 {{ partner.rating.speed }}</p>
      <p>평점: 정확도{{ partner.rating.accuracy }}</p>
      <p>평점: 의사소통 {{ partner.rating.communication }}</p>
      <p>평균평점: {{ partner.rating.this_average }}</p>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 500px;
}

.list {
  width: 100%;
  border-bottom: 1px solid #333;
}
</style>
