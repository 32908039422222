import {defineStore} from "pinia";
import {reactive} from "vue";

export type WrittenQuizItem = { type: string, question: string, options: string[], answer: number | number[], userAnswer: number[] };
export type PracticeQuizItem = { question: string, fileItems: File[]};
/**
 * 시험 정보를 저장하는 스토어
 */
export const useTestStore = defineStore("test", () => {
    /**
     * 필기 시험 정보
     */
    const written = reactive<{
        endTime: Date,
        quizItems: WrittenQuizItem[],
    }>({
        endTime: new Date(),
        quizItems: [],
    });

    /**
     * 필기 시험 정보를 생성한다.
     * @param endTime
     * @param quiz
     */
    function setWrittenTest(endTime: Date, quiz: WrittenQuizItem[]) {
        written.endTime = endTime;
        written.quizItems = quiz;
    }

    /**
     * 실기 시험 정보
     */
    const practice = reactive<{
        endTime: Date,
        downloadFile: string,
        quizItems: PracticeQuizItem[],
    }>({
        endTime: new Date(),
        downloadFile: "",
        quizItems: [],
    });

    /**
     * 실기 시험 정보를 생성한다.
     * @param endTime
     * @param downloadFile
     * @param quiz
     */
    function setPracticeTest(endTime: Date, downloadFile: string, quiz: PracticeQuizItem[]) {
        practice.endTime = endTime;
        practice.downloadFile = downloadFile;
        practice.quizItems = quiz;
    }

    return {written, practice, setWrittenTest, setPracticeTest};
});