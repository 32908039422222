import { RouteRecordRaw } from "vue-router";
import Information from "@/views/playground/r2center/business-management/service-management/information/Information.vue";
import Operation from "@/views/playground/r2center/business-management/service-management/Operation.vue";
import PaymentMethods from "@/views/playground/r2center/business-management/service-management/PaymentMethods.vue";
import informationRoutes from "@/router/playground/r2center/business/service/information.ts";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/service/information`,
  },
  {
    path: "information",
    component: Information,
    children: informationRoutes,
  },
  {
    path: "operation",
    component: Operation,
  },
  {
    path: "payment-methods",
    component: PaymentMethods,
  },
];

export default routes;
