import Loading from "@/components/Loading.vue";
import R2Dialog from "@/components/R2Dialog.vue";
import { h, nextTick, reactive, render } from "vue";

const state = reactive({
  loading: undefined as HTMLElement | undefined,
  blockLoading: false,
});

/**
 * 알림 다이얼로그
 * @param message
 * @param settings
 */
export async function alert(
  message: string,
  settings?: {
    okLabel?: string;
  }
): Promise<boolean> {
  return showDialog(message, settings);
}
/**
 * 확인 다이얼로그
 * @param message
 * @param settings
 */
export async function confirm(
  message: string,
  settings?: {
    showCloseButton?: boolean;
    isConfirm?: boolean;
    okLabel?: string;
    cancelLabel?: string;
  }
): Promise<boolean> {
  settings = settings || {};
  settings.isConfirm = true;
  return showDialog(message, settings);
}

/**
 * 로딩 다이얼로그 표시
 */
export function showLoading() {
  if (state.loading) return;
  state.loading = document.createElement("div");
  document.body.appendChild(state.loading);
  const dialogInstance = h(Loading, {});
  render(dialogInstance, state.loading);
}
/**
 * 로딩 다이얼로그 숨김
 */
export function hideLoading() {
  if (state.loading) {
    document.body.removeChild(state.loading);
    state.loading = undefined;
  }
}

// localize('확인');
async function showDialog(
  message: string,
  settings?: {
    showCloseButton?: boolean;
    isConfirm?: boolean;
    okLabel?: string;
    cancelLabel?: string;
    noButton?: boolean;
  }
): Promise<boolean> {
  const container = document.createElement("div");
  document.body.appendChild(container);

  let resolvePromise: (value: boolean) => void;

  // localize('확인');
  // localize('취소');
  const dialogInstance = h(R2Dialog, {
    message: message || "",
    showCloseButton: settings?.showCloseButton || false,
    isConfirm: settings?.isConfirm || false,
    okLabel: settings?.okLabel || "확인",
    cancelLabel: settings?.cancelLabel || "취소",
    noButton: settings?.noButton || false,
    show: true,
    onClose: (result: boolean) => {
      if (container) {
        document.body.removeChild(container);
      }
      resolvePromise(result);
    },
  });

  render(dialogInstance, container);

  await nextTick();

  return new Promise((resolve) => {
    resolvePromise = resolve;
  });
}
