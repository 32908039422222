type AuthStorageData = {
    token: string
    refreshToken: string
}

interface IAuthStorage {
    getToken(): string
    getRefreshToken(): string
    getData(): AuthStorageData
    setToken(apiKey: string): void
    setRefreshToken(refreshToken: string): void
    clear(): void
}

class AuthStorage implements IAuthStorage {
    constructor(storage: Storage) {
        this._storage = storage
    }

    private _id: string = 'd32758dc-3ca6-4c4c-beab-c04fe40176f1'
    private _storage: Storage
    private _data: AuthStorageData = {
        token: '',
        refreshToken: ''
    }

    public getToken(): string {
        if(this._data.token === '') {
            this._restore()
        }

        return this._data.token
    }
    
    public getRefreshToken(): string {
        if(this._data.refreshToken === '') {
            this._restore()
        }

        return this._data.refreshToken
    }

    public getData(): AuthStorageData {
        return this._data
    }

    public setToken(apiKey: string): void {
        this._data.token = apiKey
        this._store()
    }

    public setRefreshToken(refreshToken: string): void {
        this._data.refreshToken = refreshToken
        this._store()
    }

    public clear(): void {
        const authStorageData: AuthStorageData = {
            token: ``,
            refreshToken: ``,
        }

        this._data = authStorageData
        this._store()
    }

    private _restore(): void {
        //내부 스토리지에 저장된 AuthStorageData 를 가져온다
        let authStorageString = this._storage.getItem(this._id)
        if(authStorageString) {
            this._data = JSON.parse(authStorageString) as AuthStorageData
        }
    }

    private _store(): void {
        //내부 스토리지에 AuthStorageData를 저장한다
        this._storage.setItem(this._id, JSON.stringify(this._data))
    }
}

class AuthenticationSettings {
    //#region (싱글톤)
    private static _instance: AuthenticationSettings

    private constructor() {
        
    }

    public static get instance() {
        if(!AuthenticationSettings._instance) {
            AuthenticationSettings._instance = new AuthenticationSettings()
        }

        return AuthenticationSettings._instance
    }
    //#endregion

    private _authStorage: IAuthStorage | undefined
    public set authStorage(authStorage: IAuthStorage) {
        this._authStorage = authStorage
    }

    public get token(): string {
        return this._authStorage!.getToken()
    }
    public set token(token: string) {
        this._authStorage!.setToken(token)
    }

    public get refreshToken(): string {
        return this._authStorage!.getRefreshToken()
    }
    public set refreshToken(refreshToken: string) {
        this._authStorage!.setRefreshToken(refreshToken)
    }

    public clear(): void {
        this._authStorage!.clear()
    }
}

export { AuthStorage, AuthenticationSettings }
export type { IAuthStorage, AuthStorageData }