<script setup lang="ts">
import { localize, getFormatDate } from "@/utils";
import { subMonths, subYears } from "date-fns";
import { reactive, ref } from "vue";
import { alert, confirm } from "@/services/dialog.ts";
import R2Dialog from "@/components/R2Dialog.vue";
import { IPeriodItem } from "@/models";

// localize('충전내역')
// localize('지급내역')
const menuItems = [
  { name: "충전내역", link: "charge-history" },
  { name: "지급내역", link: "payment-history" },
];

const dialogItems = [
  ref<InstanceType<typeof R2Dialog> | null>(null),
  ref<InstanceType<typeof R2Dialog> | null>(null),
  ref<InstanceType<typeof R2Dialog> | null>(null),
  ref<InstanceType<typeof R2Dialog> | null>(null),
];

const now = new Date();

// localize('직접입력')
// localize('당월')
// localize('3개월')
// localize('6개월')
// localize('1년')
const periodItems: IPeriodItem[] = [
  { key: "직접입력", value: 0, period: null },
  {
    key: "당월",
    value: 1,
    period: {
      start: getFormatDate(new Date(now.getFullYear(), now.getMonth(), 1)),
      end: getFormatDate(now),
    },
  },
  {
    key: "3개월",
    value: 2,
    period: {
      start: getFormatDate(subMonths(now, 3)),
      end: getFormatDate(now),
    },
  },
  {
    key: "6개월",
    value: 3,
    period: {
      start: getFormatDate(subMonths(now, 6)),
      end: getFormatDate(now),
    },
  },
  {
    key: "1년",
    value: 4,
    period: {
      start: getFormatDate(subYears(now, 1)),
      end: getFormatDate(now),
    },
  },
];

const state = reactive({
  dialog: {
    step: 1,
  },
});
async function charge() {
  // const result = await confirm!("테스트", {
  // });
  //
  // if(result) {
  //   await alert("성공");
  // }
  // console.log(result);
  // return;
  // TODO: fade 효과 구현
  state.dialog.step = 1;
  dialogItems[1].value!.open();
}
async function onClose(result: boolean, step: number) {
  if (step === 0) {
    if (result) {
      dialogItems[state.dialog.step].value!.open();
    }
  } else {
    if (result) {
      dialogItems[++state.dialog.step].value!.open();
    } else {
      dialogItems[0].value!.open();
    }
  }
}
</script>

<template>
  <h1 class="hd-title">My R2 Point</h1>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <div class="box box-point mb-3">
          <div class="total-point">
            <div class="amount">
              <span class="badge">{{ localize("현재 보유 중인 포인트") }}</span>
              <p><b>250</b> P</p>
            </div>
            <div>
              <img src="@/assets/playground/img/point-coin.png" alt="" />
            </div>
          </div>
          <div class="unuvail-point">
            <span>{{ localize("비가용 포인트") }}</span>
            <p><b>142</b> P</p>
          </div>
        </div>
        <button @click="charge" type="button" class="btn btn-md btn-block">{{ localize("충전하기") }}</button>
        <img src="@/assets/playground/img/r2point.png" alt="" />
      </div>
      <router-view :menuItems="menuItems" :periodItems="periodItems"></router-view>
    </div>
  </div>
  <!-- 약관 동의 돌아가기 -->
  <r2-dialog
    :ref="dialogItems[0]"
    :is-confirm="true"
    :cancel-label="localize('아니오')"
    :ok-label="localize('예')"
    :show-close-button="false"
    :message="localize('포인트 약관의 동의가 필요합니다.')"
    @close="onClose($event, 0)"
  >
  </r2-dialog>
  <!-- //약관 동의 돌아가기 -->

  <!-- 약관 동의 Step 1 -->
  <r2-dialog
    :ref="dialogItems[1]"
    :is-confirm="true"
    :cancel-label="localize('돌아가기')"
    :ok-label="localize('동의하고 계속하기')"
    @close="onClose($event, 1)"
  >
    <h4 class="fs-2 pt-3" v-html="localize('안녕하세요!<br />R2 포인트 구매를 위해<br />약관에 동의가 필요합니다.')" />
    <p class="py-3" v-html="localize('R2 포인트로 R2 플랫폼의 컨텐츠를 편하게<br />이용하실 수 있습니다.')" />
  </r2-dialog>
  <!-- //약관 동의 Step 1 -->

  <!-- 약관 동의 Step 2 -->
  <r2-dialog
    :ref="dialogItems[2]"
    :is-confirm="true"
    :cancel-label="'돌아가기'"
    :ok-label="'동의하고 계속하기'"
    @close="onClose($event, 2)"
  >
    <h4 class="fs-3 text-center">{{ localize("메가젠 R2 포인트 이용약관") }}</h4>
    <div class="conditions">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi illum delectus quam nisi est nihil et odit
      perspiciatis earum architecto consectetur dolore, labore repellat, voluptatibus necessitatibus quod amet dolor
      alias? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi illum delectus quam nisi est nihil et odit
      perspiciatis earum architecto consectetur dolore, labore repellat, voluptatibus necessitatibus quod amet dolor
      alias? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi illum delectus quam nisi est nihil et odit
      perspiciatis earum architecto consectetur dolore, labore repellat, voluptatibus necessitatibus quod amet dolor
      alias? Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sequi illum delectus quam nisi est nihil et odit
      perspiciatis earum architecto consectetur dolore, labore repellat, voluptatibus necessitatibus quod amet dolor
      alias?
    </div>
  </r2-dialog>
  <!-- //약관 동의 Step 2 -->

  <!-- 결제 진행중 -->
  <r2-dialog :ref="dialogItems[3]" :show-close-button="false" :no-button="true" @close="onClose($event, 3)">
    <p class="fs-3">{{ localize("결제가 진행 중입니다.") }}</p>
    <div class="loading">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
    <p
      class="text-center"
      v-html="
        localize('결제가 진행 중이니 잠시만 기다려주세요. <br />결제 후, 본 메시지가 계속 보인다면 새로고침 해주세요.')
      "
    />
  </r2-dialog>
  <!-- //결제 진행중 -->
</template>

<style scoped></style>
