<script setup lang="ts">
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { IListState } from "@/models";
import { reactive } from "vue";
import { localize } from "@/utils";
import { FaqCategory, useFaqStore } from "@/store/faq";
import { graphql } from "@/libs/R2ServiceClient/service/Visitor";
import { useClientWrapperStore } from "@/store";

// localize('전체')

const state: IListState = reactive({
  menuItems: <FaqCategory[]>[
    // TODO: 정첵에 맞게 수정 필요.
    { code: "all", name: "전체" },
  ],
  category: "all",
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
});

init();

/**
 * 초기화
 */
async function init(): Promise<void> {
  useFaqStore()
    .getCategories("Board1")
    .then((categories) => {
      state.menuItems.push(...categories);
    });
  request();
}
/**
 * FAQ 목록 요청
 */
async function request(page: number = 1): Promise<void> {
  // console.log("request", state.keyword, page);
  const GetFaqList = graphql(`
    query GetFaqList($page: Int, $pageSize: Int, $input: SearchConditionInput) {
      faqs(page: $page, pageSize: $pageSize, searchCondition: $input) {
        paging {
          totalItems
          totalPages
          currentPage
        }
        items {
          id
          question
          answer
          category {
            code
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  `);
  const variables = {
    page: page,
    pageSize: 5,
    input: {
      fields: [
        // TODO: 정첵에 맞게 수정 필요.
      ...(state.category !== "all" ? [
        {
          name: "CategoryCode",
          value: state.category,
        }
      ] : []),
        {
          name: "Question",
          value: state.keyword,
        },
        {
          name: "Answer",
          value: state.keyword,
        },
      ],
    },
  };
  const response = await useClientWrapperStore().visitor.typedQuery(GetFaqList, {
    variables: variables,
    successCallback: (result) => {
      // console.log("GetFaqList - successCallback: ", result);
      // 열린 답변 전부 닫기
      const detailsElements = document.querySelectorAll(".qna-list li details");
      detailsElements.forEach((details) => {
        details.removeAttribute("open");
      });

      state.items = result.faqs.items;
      state.page = result.faqs.paging.currentPage;
      state.totalRows = result.faqs.paging.totalItems;

      // console.log("state.totalRows", state.totalRows);
    },
  });
}
/**
 * 카테고리 변경
 */
function changeCategory(code: string): void {
  state.category = code;
  // console.log("changeCategory", code);
  request();
}
</script>

<template>
  <ul class="nav nav-tabs support-nav">
    <li class="nav-item">
      <router-link to="" class="nav-link">Home</router-link>
    </li>
    <li class="nav-item">
      <router-link to="" class="nav-link">Customer Support</router-link>
    </li>
    <li class="nav-item">
      <router-link to="" class="nav-link active">FAQ</router-link>
    </li>
  </ul>
  <div class="tit-head">
    <h1>FAQ</h1>
    <span>{{ localize("무엇을 도와드릴까요?") }}</span>
    <img src="@/assets/home/img/playground/r2FAQ-tit.png" alt="" />
  </div>
  <form @submit.prevent="request()">
    <div class="search">
      <search-keyword :state="state" :placeholder="localize('검색어를 입력해 주세요')" />
    </div>
    <div class="form-row">
      <div class="btn-check-wrap">
        <label v-for="item in state.menuItems" class="btn-check">
          <input
            type="radio"
            class="sr-only"
            @change="changeCategory(item.code)"
            v-model="state.category"
            :value="item.code"
          />
          <span class="btn btn-light">{{ localize(item.name) }}</span>
        </label>
      </div>
    </div>
  </form>
  <ul class="qna-list">
    <li v-for="item in state.items">
      <details class="qna" :open="item.open">
        <summary>
          <span class="bullet ask">Q</span>
          <p class="ask-body overflow-text line-height-text" v-html="item.question"></p>
          <i class="icon icon-drop"></i>
        </summary>
        <div class="answer-wrap">
          <span class="bullet answer">A</span>
          <div class="answer-body line-height-text" v-html="item.answer"></div>
        </div>
      </details>
    </li>
  </ul>
  <pagenation :total-rows="state.totalRows" :page="state.page" :rows-size="5" @change="request" />
</template>

<style scoped>
.line-height-text {
  line-height: 120%;
}
</style>
