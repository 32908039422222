// Timer.ts
type TimerCallbacks = {
  onTicks?: (minutes: number, seconds: number) => void;
  onComplete?: () => void;
};

/**
 * 타이머 훅
 * @param totalSeconds 타이머가 완료될 총 시간(초)
 * @param onTicks 타이머가 감소할 때마다 호출될 콜백
 * @param onComplete 타이머가 완료될 때 호출될 콜백
 * @param tick 타이머 감소 간격(밀리초)
 * @returns
 */
export function useTimer(
  totalSeconds: number,
  onTicks: (minutes: number, seconds: number) => void | null,
  onComplete: () => void | null,
  tick: number = 1000
) {
  const initialTotalSeconds = totalSeconds;
  let remainSeconds = totalSeconds;
  let intervalId: NodeJS.Timeout | null = null;
  let minutes = 0;
  let seconds = 0;

  function calculateTime() {
    minutes = Math.floor(remainSeconds / 60);
    seconds = remainSeconds % 60;
    onTicks?.(minutes, seconds);
  }

  function startTimer() {
    stopTimer();
    remainSeconds = initialTotalSeconds;
    intervalId = setInterval(() => {
      if (remainSeconds > 0) {
        remainSeconds--;
        calculateTime();
      } else {
        stopTimer();
        onComplete?.();
      }
    }, tick);
    calculateTime();
  }

  function stopTimer() {
    if (intervalId) {
      clearInterval(intervalId);
      intervalId = null;
    }
  }

  return { startTimer, stopTimer };
}
