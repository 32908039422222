<script setup lang="ts">
import { onBeforeMount, reactive, watch } from "vue";
import { localize } from "@/utils";
const props = defineProps({
  totalRows: { type: Number, default: 0 },
  page: { type: Number, default: 1 },
  pageSize: { type: Number, default: 10 },
  rowsSize: { type: Number, default: 10 },
  class: { type: String, default: "paging" },
});
const emit = defineEmits<{
  (eventType: "change", item: number): void; // 아이템 선택 이벤트
}>();

const state = reactive<{
  pageItems: number[];
  first: number | null;
  previous: number | null;
  next: number | null;
  last: number | null;
}>({ first: 0, last: 0, next: 0, pageItems: [], previous: 0 });

// TODO: 사실상 props 중 하나가 변하면 초기화 해야하는지 검토. 로컬, 리모트에 따라 달라질 수 있음
watch([() => props.page, () => props.totalRows], (newIndex, oldIndex) => {
  init();
});

onBeforeMount(init);

function init() {
  // 총 페이지 수
  const total: number = Math.ceil(props.totalRows / props.rowsSize);
  // 보여줄 첫 페이지
  const first: number =
    props.page - (props.page % props.pageSize === 0 ? props.pageSize : props.page % props.pageSize) + 1;
  // 보여줄 끝 페이지
  const last: number = Math.min(first + props.pageSize - 1, total);

  state.pageItems = [];
  for (let i = first; i <= last; i++) {
    state.pageItems.push(i);
  }

  // pageSize 단위
  state.first = first > 1 ? 1 : null;
  state.previous = first > 1 ? first - 1 : null;

  state.next = last < total ? last + 1 : null;
  state.last = last < total ? total : null;

  // state.first = props.page > 1 ? 1 : null;
  // state.previous = props.page > 1 ? props.page - 1: null;
  //
  // state.next = props.page < total ? props.page + 1 : null;
  // state.last = props.page < total ? total : null;
}
/**
 * 페이지 이동
 * @param page
 */
function change(page: number): void {
  if (page !== props.page) {
    emit("change", page);
  }
}
</script>

<template>
  <ul :class="props.class">
    <!--pageSize 단위 넘기기용 버튼-->
    <li>
      <a class="icon icon-paging" :class="{ disabled: !state.previous }" @click="change(state.previous!)">{{
        localize("이전")
      }}</a>
    </li>
    <!--    <li class="footable-page-arrow" v-show="state.previous"><a @click="change(state.previous!)">‹</a></li>-->

    <li v-for="(item, index) in state.pageItems">
      <a class="paging-item" :class="{ active: item === page }" @click="change(item)">{{ item }}</a>
    </li>
    <!--    <li class="footable-page-arrow" v-show="state.next"><a @click="change(state.next!)">›</a></li>-->
    <li>
      <a class="icon icon-paging" :class="{ disabled: !state.next }" @click="change(state.next!)">{{
        localize("다음")
      }}</a>
    </li>
  </ul>
</template>

<style scoped></style>
