import { ClientSettings } from "../settings"
import { UserService } from "./User"
import { VisitorService } from "./Visitor"

class ServiceFactory {
    //#region 싱글톤
    private constructor() { }
    private static _instance: ServiceFactory

    public static get instance(): ServiceFactory {
        if(ServiceFactory._instance === undefined) {
            ServiceFactory._instance = new ServiceFactory()
        }

        return ServiceFactory._instance
    }
    //#endregion

    public getVisitorService(): VisitorService {
        let cs: ClientSettings = ClientSettings.instance
        let service = (cs.isTest)? new VisitorService() : new VisitorService()

        return service
    }

    public getUserService(): UserService {
        let cs: ClientSettings = ClientSettings.instance
        let service = (cs.isTest)? new UserService() : new UserService()

        return service
    }
}

export { ServiceFactory }