<script setup lang="ts">
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { reactive } from "vue";
import { localize } from "@/utils";
import { IListState } from "@/models/i-list-state.ts";

const state: IListState = reactive({
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
});

request();

function resetForm(): void {
  request();
}
function request(page: number = 1): void {
  console.log("request", state.keyword, page);
}
</script>

<template>
  <h1 class="hd-title">Shared Cloud</h1>
  <div id="ct" class="flex-layout-body">
    <div class="box">
      <form @submit.prevent="request()" @reset.prevent="resetForm">
        <div class="row form-row">
          <div class="search col">
            <search-keyword :show-reset="true" :state="state" :placeholder="localize('Case, 환자 이름 검색')" />
          </div>
          <div class="col-auto flex-center">
            <div class="form-label">{{ localize("스캔 목적") }}</div>
            <select class="form-control form-select mr-3">
              <option value="">Health Report</option>
              <option value="">Health Report</option>
              <option value="">Health Report</option>
            </select>
          </div>
          <div class="col-auto flex-center">
            <div class="form-label">{{ localize("정렬") }}</div>
            <div class="btn-check-wrap">
              <label class="btn-check">
                <input type="radio" name="c1" class="sr-only" />
                <span class="btn btn-outline-primary">{{ localize("케이스 순") }}</span>
              </label>
              <label class="btn-check">
                <input type="radio" name="c1" class="sr-only" checked />
                <span class="btn btn-outline-primary">{{ localize("스캔일자 순") }}</span>
              </label>
            </div>
          </div>
          <div class="col-auto flex-center usage">
            <div class="form-label mr-3">Usage</div>
            <div class="flex-center"><b class="usage-amount">125MB</b>/ 1TB</div>
          </div>
        </div>
      </form>
    </div>
    <div class="content">
      <table class="tb">
        <colgroup>
          <col style="width: 36%" />
          <col span="4" />
          <col style="width: 6%" />
        </colgroup>
        <thead>
          <tr>
            <th>Case</th>
            <th>{{ localize("환자 이름") }}</th>
            <th>{{ localize("스캔 목적") }}</th>
            <th>{{ localize("스캔 일자") }}</th>
            <th>{{ localize("원 소유자") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="font-weight-bold">1 + 0240628102335</td>
            <td class="font-weight-bold">Gwon Hyeong-ju</td>
            <td>Health Report</td>
            <td>2024.02.14<span class="time">23:53</span></td>
            <td>
              <div class="avatar">
                <div class="profile-thumb">
                  <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
                </div>
                <span>Arihan</span>
              </div>
            </td>
            <td>
              <button class="icon icon-download">{{ localize("다운로드") }}</button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
    </div>
  </div>
</template>

<style scoped></style>
