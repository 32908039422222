<script setup lang="ts">
import { ref } from "vue";

const props = defineProps({
  value: { type: String, default: "" },
});
const emit = defineEmits<{
  (eventType: "change", date: string): void;
}>();

/**
 * 날짜 입력 참조
 */
const inputRef = ref<HTMLInputElement | null>(null);
/**
 * 날짜 변경 이벤트 핸들러
 * @param event 이벤트
 */
function change(event: Event): void {
  const target = event.target as HTMLInputElement;
  // console.log('change', target.value);
  emit("change", target.value);
}
/**
 * 달력 표시
 */
function showCalendar(): void {
  inputRef.value?.showPicker();
}
</script>

<template>
  <input ref="inputRef" type="date" v-bind="$attrs" :value="value" @input="change" @focus="showCalendar" />
</template>

<style scoped></style>
