<script setup lang="ts">
import { localize } from "@/utils";
import { reactive } from "vue";

const state = reactive({
  step: 3,
  step1: 1,
  step3: 1,
});
function requestConfirm() {
  // TODO: API 연동
  state.step1 = 2;
}
function refreshConfirm() {
  // TODO: API 연동
  state.step1 = 3;
}
function requestLeave() {
  // TODO: API 연동
  state.step = 2;
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <router-link to="../membership" class="btn-back"
          ><i class="icon icon-back">{{ localize("뒤로") }}</i></router-link
        >
        <h2 class="fs-1">Whithdrawal</h2>
        <p>{{ localize("신청일 기준 N일 이내에 처리해드립니다.") }}</p>
        <u class="text-muted">Anything else?</u>
        <img src="@/assets/playground/img/membership.png" alt="" />
      </div>
      <div class="grid-content">
        <div class="box box-shadow">
          <ol class="step">
            <li :class="{ active: state.step == 1 }">
              <span>{{ localize("신청 전 확인") }}</span>
            </li>
            <li :class="{ active: state.step == 2 }">
              <span>{{ localize("신청 완료") }}</span>
            </li>
            <li :class="{ active: state.step == 3 }">
              <span>{{ localize("관리자 확인") }}</span>
            </li>
            <li :class="{ active: state.step == 4 }">
              <span>{{ localize("확인 완료 및 탈퇴 처리") }}</span>
            </li>
          </ol>

          <div v-if="state.step == 1">
            <!-- 신청 전 확인-->
            <div v-if="state.step1 == 1" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2">{{ localize("탈퇴 신청 전 확인") }}</h3>
              <p>{{ localize("다음의 조건을 모두 충족할 경우 회원탈퇴 신청이 가능합니다.") }}</p>
              <div class="box">
                <ul class="list-dot">
                  <li>개입된 모든 주문 건의 상태가 [완료]인 경우</li>
                  <li>개입된 모든 주문 건의 대금 지불/수취 상태가 [완료]인 경우</li>
                  <li>(R2센터의 경우) 전체 구성원을 모두 해체했을 때</li>
                </ul>
              </div>
              <button @click="requestConfirm" type="button" class="btn btn-md">탈퇴 조건 확인</button>
            </div>
            <!-- //신청 전 확인-->

            <!-- 신청 전 확인 조회 중-->
            <div v-if="state.step1 == 2" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2">탈퇴 신청 전 확인</h3>
              <p class="mb-5">조회 중입니다.<br />잠시만 기다려주세요.</p>
              <div class="loading">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <button @click="refreshConfirm" type="button" class="btn btn-outline btn-md">Refresh</button>
            </div>
            <!-- //신청 전 확인 조회 중-->

            <!-- 탈퇴 가능-->
            <div v-if="state.step1 == 3" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2">탈퇴 신청을 하실 수 있습니다.</h3>
              <p class="mb-4">
                불편을 끼쳐드려 죄송합니다.<br />잠깐 시간을 내주시어 탈퇴 사유를 말씀해주시면 감사하겠습니다.
              </p>
              <div class="management-form-box">
                <select class="form-control form-select">
                  <option value="">탈퇴 사유를 선택해주세요.</option>
                </select>
                <textarea class="form-control" rows="3" placeholder="상세 내용을 입력해주세요."></textarea>
              </div>
              <button @click="requestLeave" type="button" class="btn btn-md">탈퇴 신청하기</button>
            </div>
            <!-- //탈퇴 가능-->

            <!-- 탈퇴 불가능-->
            <div v-if="state.step1 == 4" class="step-content">
              <strong class="fs-sm text-primary">step 1</strong>
              <h3 class="fs-2 text-danger">탈퇴 신청을 하실 수 없습니다.</h3>
              <p>다음 내용을 확인해 주세요.</p>
              <div class="row">
                <div class="col-6">
                  <div class="box box-outline">
                    <div class="flex-center-between">
                      <strong class="fs-3 font-poppins">Player</strong>
                      <a href="#" class="link-txt">확인하러 가기 <i class="icon-arrow-primary ml-2"></i></a>
                    </div>
                    <div class="flex-center">
                      <div class="box flex-fill">
                        <ul>
                          <li><b class="text-primary">5개</b>의 주문에 대한 <b>[완결] 처리</b>가 필요합니다.</li>
                          <li><b class="text-primary">5개</b>의 주문에 대한 <b>[지불] 처리</b>가 필요합니다.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="box box-outline">
                    <div class="flex-center-between">
                      <strong class="fs-3 font-poppins">Planner</strong>
                      <a href="#" class="link-txt">확인하러 가기 <i class="icon-arrow-primary ml-2"></i></a>
                    </div>
                    <div class="flex-center">
                      <div class="box flex-fill">
                        <ul>
                          <li><b class="text-primary">5개</b>의 주문에 대한 <b>[완결] 처리</b>가 필요합니다.</li>
                          <li><b class="text-primary">5개</b>의 주문에 대한 <b>[지불] 처리</b>가 필요합니다.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <router-link to="home">
                <button type="button" class="btn btn-outline btn-md">취소하기</button>
              </router-link>
            </div>
            <!-- //탈퇴 불가능-->
          </div>

          <div v-if="state.step == 2" class="step-content">
            <strong class="fs-sm text-primary">step 2</strong>
            <h3 class="fs-2">회원 탈퇴 신청이 완료 되었습니다.</h3>
            <p class="mb-5">관리자가 곧 신청에 대한 심사를 진행할 예정입니다.</p>
            <img src="@/assets/playground/img/application-complete.png" alt="" />
          </div>

          <div v-if="state.step == 3">
            <!-- 관리자 확인 중 -->
            <div v-if="state.step3 == 1" class="step-content">
              <strong class="fs-sm text-primary">step 3</strong>
              <h3 class="fs-2">관리자 확인 중입니다.</h3>
              <p class="mb-5">신청일 기준 N일 이내 확인 완료 예정입니다.</p>
              <img src="@/assets/playground/img/admin-check.png" alt="" />
              <div class="box">
                <p class="info-txt">승인된 경우 이메일로 통보해드립니다.</p>
                <p class="info-txt">거절된 경우 [심사완료]에서 확인 가능합니다.</p>
              </div>
            </div>
            <!-- //관리자 확인 중 -->

            <!-- 관리자 승인 거절 -->
            <div v-if="state.step3 == 2">
              <div class="step-content">
                <strong class="fs-sm text-primary">step 3</strong>
                <h3 class="fs-2 text-danger">탈퇴가 거절되었습니다.</h3>
                <p>아래의 사유를 확인해 주세요.</p>
                <div class="box">
                  <strong class="fs-sm">거절 사유</strong>
                  <ul class="list-dot mt-2">
                    <li>개입된 모든 주문 건의 상태가 [완료]인 경우</li>
                    <li>개입된 모든 주문 건의 대금 지불/수취 상태가 [완료]인 경우</li>
                    <li>(R2센터의 경우) 전체 구성원을 모두 해체했을 때</li>
                  </ul>
                </div>
              </div>
              <div class="btn-wrap">
                <router-link to="home">
                  <button type="button" class="btn btn-outline">확인</button>
                </router-link>
                <router-link to="/playground/player/support/trouble-shooting/save">
                  <button type="button" class="btn">트러블 슈팅</button>
                </router-link>
              </div>
            </div>
            <!-- //관리자 승인 거절 -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
