<script setup lang="ts">
import { localize, getFormatString } from "@/utils";
import { reactive } from "vue";

const state = reactive({
  // TODO: 실제 데이터 연동 후 삭제
  temp_dimmed: false,
  items: [{}],
});
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <h2 class="fs-1">{{ localize("등록된 서비스") }}</h2>

    <ul class="row card-row">
      <!-- 서비스 상품 아이템 -->
      <li v-for="item in state.items" class="col-4">
        <div :class="{ dimmed: state.temp_dimmed }" class="card service-item">
          <div class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample2.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/service-img.jpg" alt="" /></div>
          </div>
          <div class="card-body">
            <strong class="card-title font-poppins">Customized Abutment &amp; Temporary</strong>
            <div class="service-item-info">
              <div class="text-nowrap">
                <p v-html="getFormatString(localize('총 주문 <b>{0}건</b>'), 52)" />
                <p v-html="getFormatString(localize('진행 중인 주문 <b>{0}건</b>'), 30)" />
              </div>
              <dl class="flex-center font-poppins">
                <dt>Starting</dt>
                <dd class="point">60P</dd>
              </dl>
            </div>
            <router-link to="edit/1" class="btn btn-block btn-lg">{{ localize("수정하기") }}</router-link>
          </div>
          <div v-if="state.temp_dimmed" class="dimmed-alert">
            <i class="icon icon-error"></i>
            <b class="d-block mt-1 fs-4 text-dark">{{ localize("관리자의 승인을 기다리는 중입니다.") }}</b>
            <span class="text-muted fs-sm">{{ localize("등록일") }} : 2024-02-13</span>
          </div>
        </div>
      </li>
      <!-- //서비스 상품 아이템 -->

      <!-- 서비스 없음. 서비스 등록하러 가기 -->
      <li v-if="state.items.length == 0" class="empty">
        <p class="text-center mb-4 fs-20">
          {{ localize("아직 등록된 서비스가 없습니다.") }}<br />{{ localize("서비스를 등록해주세요.") }}
        </p>
        <router-link to="add" class="btn btn-lg">{{ localize("서비스 등록하러 가기") }}</router-link>
      </li>
      <!-- //서비스 없음. 서비스 등록하러 가기 -->
      <!-- 서비스 상품 추가하기 -->
      <li v-else class="col-4 link-only">
        <router-link to="add">
          <div class="card service-item add">
            <div class="add-body">
              <i class="icon icon-card-plus"></i>
              <p class="fs-3 mt-4">{{ localize("서비스 상품 추가하기") }}</p>
            </div>
          </div>
        </router-link>
      </li>
      <!-- //서비스 상품 추가하기 -->
    </ul>
  </div>
</template>

<style scoped></style>
