<script setup lang="ts">
import { localize } from "@/utils";

// localize("구성원 관리")
// localize("구성원 추가")
const menuItems = [
  { name: "구성원 관리", link: "list" },
  { name: "구성원 추가", link: "add" },
];
</script>

<template>
  <h1 class="hd-title">Member Management</h1>
  <router-view :menu-items="menuItems"></router-view>
</template>

<style scoped></style>
