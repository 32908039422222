import { RouteRecordRaw } from "vue-router";
import plannerRoutes from "@/router/playground/player/partner/planner";
import r2CenterRoutes from "@/router/playground/player/partner/r2center";
import Planner from "@/views/playground/player/partner/Planner.vue";
import R2Center from "@/views/playground/player/partner/R2Center.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/partner/planner`,
  },
  {
    path: "planner",
    component: Planner,
    children: plannerRoutes,
  },
  {
    path: "r2center",
    component: R2Center,
    children: r2CenterRoutes,
  },
];

export default routes;
