<script setup lang="ts">
import { useRoute } from "vue-router";
import {useTestStore, WrittenQuizItem} from "@/store";
import {onBeforeMount, onUnmounted, reactive} from "vue";
import router from "@/router";

const route = useRoute();
const store = useTestStore().written;
const state = reactive<{
  index: number,
  quiz: WrittenQuizItem,
  remainTime: string,
}>({index: 0, "quiz": {type: "radio", question: "", options: [], answer: 0, userAnswer: []}, remainTime: ""});
// 시간 갱신용 타이머
let timer: ReturnType<typeof setInterval>;

onBeforeMount(init);
onUnmounted(dispose);

/**
 * 초기화
 */
function init(){
  timer = setInterval(() => {
    // data.quiz.endTime을 기준으로 남은 시간을 "mm : ss" 단위로 초 마다 표시
    const now = new Date().getTime();
    const distance = store.endTime.getTime() - now;
    if(distance < 0) { // 탈락
      done();
    } else { // 시간 갱신
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      state.remainTime = `${minutes.toString().padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
    }
  }, 1000);
}
/**
 * 해제
 */
function dispose(){
  clearInterval(timer);
}
/**
 * 타이머 설정
 */
function setTimer() {
  clearInterval(timer);
  timer = setInterval(() => {
    // data.quiz.endTime을 기준으로 남은 시간을 "mm : ss" 단위로 초 마다 표시
    const now = new Date().getTime();
    const distance = store.endTime.getTime() - now;
    if(distance < 0) { // 탈락
      done();
    } else { // 시간 갱신
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      state.remainTime = `${minutes} : ${seconds}`;
    }
  }, 1000);
}
/**
 * 문제 화면 변경
 * @param offset
 */
function changeQuiz(offset: number) {
  const nextIndex = state.index + offset;
  if(nextIndex > store.quizItems.length){
    done();
  } else if(nextIndex > 0 && nextIndex <= store.quizItems.length){
    router.replace(`/academy/certification/detail/${route.params.license}/qualifying-test/written-test/${nextIndex}`);
    setQuiz(nextIndex);
  }
}
/**
 * 문제 설정
 * @param index
 */
function setQuiz(index: number){
  state.index = index;
  state.quiz = store.quizItems[index - 1];
}
/**
 * 완료 핸들러
 */
function done() {
  clearInterval(timer);
  router.replace(`/academy/certification/detail/${route.params.license}/qualifying-test/checking`);
}
</script>

<template>
  <h1>WrittenTest</h1>
  <p>route.params.license: {{ route.params.license }} / route.param.index: {{ state.index }}</p>
  <p>
    시험문항: {{ state.index }} / {{ store.quizItems.length }}
    남은시간: {{ state.remainTime }}
    data.quiz.userAnswer: {{ state.quiz.userAnswer }}
  </p>
  <p>{{ state.quiz.question }}</p>
  <div v-for="(item, index) in state.quiz.options">
    <label v-if="state.quiz.type == 'checkbox'">
      <input type="checkbox" v-model="state.quiz.userAnswer" :value="index+1"> {{item}}
    </label>
    <label v-if="state.quiz.type == 'radio'">
      <input type="radio" v-model="state.quiz.userAnswer" :value="index+1" name="group"> {{item}}
    </label>
  </div>
<!--  이전, 다음 버튼들-->
  <p>
    <button v-show="state.index > 1" @click="changeQuiz(-1)">Previous</button>
    <button v-if="state.index == store.quizItems.length" @click="changeQuiz(+1)">Submit</button>
    <button v-else @click="changeQuiz(+1)">Next</button>
  </p>
</template>

<style scoped>

</style>