import { RouteRecordRaw } from "vue-router";
import Agreement from "@/views/home/join/Agreement.vue";
import Genuine from "@/views/home/join/Genuine.vue";
import Required from "@/views/home/join/Required.vue";
import Optional from "@/views/home/join/Optional.vue";
import Complete from "@/views/home/join/Complete.vue";
import { blockPage } from "@/services";

const routes: Array<RouteRecordRaw> = [
  {
    alias: [""],
    path: "agreement",
    component: Agreement,
  },
  {
    path: "genuine",
    component: Genuine,
  },
  {
    path: "required",
    component: Required,
  },
  {
    path: "optional",
    component: Optional,
  },
  {
    path: "complete",
    component: Complete,
  },
];

export default routes;
