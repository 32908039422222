import { RouteRecordRaw } from "vue-router";
import ServiceManagement from "@/views/playground/r2center/business-management/service-management/ServiceManagement.vue";
import CustomerManagement from "@/views/playground/r2center/business-management/customer-management/CustomerManagement.vue";
import MemberManagement from "@/views/playground/r2center/business-management/member-management/MemberManagement.vue";
import SettlementManagement from "@/views/playground/r2center/business-management/settlement-management/SettlementManagement.vue";
import ReviewManagement from "@/views/playground/r2center/business-management/review-management/ReviewManagement.vue";
import Statistics from "@/views/playground/r2center/business-management/statistics/Statistics.vue";
import serviceRoutes from "@/router/playground/r2center/business/service";
import customerRoutes from "@/router/playground/r2center/business/customer";
import memberRoutes from "@/router/playground/r2center/business/member";
import settlementRoutes from "@/router/playground/r2center/business/settlement";
import statisticsRoutes from "@/router/playground/r2center/business/statistics";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/${to.meta.role}/business/service`,
  },
  {
    path: "service",
    component: ServiceManagement,
    children: serviceRoutes,
  },
  {
    path: "customer",
    component: CustomerManagement,
    children: customerRoutes,
  },
  {
    path: "member",
    component: MemberManagement,
    children: memberRoutes,
  },
  {
    path: "settlement",
    component: SettlementManagement,
    children: settlementRoutes,
  },
  {
    path: "review",
    component: ReviewManagement,
  },
  {
    path: "statistics",
    component: Statistics,
    children: statisticsRoutes,
  },
];

export default routes;
