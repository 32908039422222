<script setup lang="ts">
import { localize } from "@/utils";
import { reactive } from "vue";

const state = reactive({
  step: 1,
});
function requestConfirm() {
  state.step = 2;
}
function requestChangePassword() {}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <router-link to="../membership" class="btn-back"
          ><i class="icon icon-back">{{ localize("뒤로") }}</i></router-link
        >
        <h2 class="fs-1">
          Login Account<br />
          {{ localize("비밀번호 변경") }}
        </h2>
        <u class="text-muted">Anything else?</u>
        <img src="@/assets/playground/img/membership.png" alt="" />
      </div>
      <div class="grid-content">
        <div class="box box-shadow flex-center">
          <!-- 현재 비밀번호 입력 -->
          <div v-if="state.step == 1" class="frm">
            <img src="@/assets/playground/img/password.png" alt="" class="d-block mx-auto" />
            <h3 class="fs-2">{{ localize("현재 비밀번호를 입력해주세요.") }}</h3>
            <form @submit.prevent="requestConfirm">
              <div class="form-group">
                <div class="form-label">{{ localize("현재 비밀번호") }}</div>
                <div class="form-control-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="localize('변경할 비밀번호를 입력해주세요.')"
                  />
                  <div class="icon icon-eye" role="button">보기</div>
                </div>
              </div>
              <button type="submit" class="btn btn-md btn-block">{{ localize("현재 비밀번호 입력하기") }}</button>
            </form>
          </div>
          <!-- //현재 비밀번호 입력 -->

          <!-- 변경할 비밀번호 입력 -->
          <div v-if="state.step == 2" class="frm">
            <img src="@/assets/playground/img/password2.png" alt="" class="d-block mx-auto" />
            <h3 class="fs-2">{{ localize("변경할 비밀번호를 입력해주세요.") }}</h3>
            <form @submit.prevent="requestChangePassword">
              <div class="form-group">
                <div class="form-label">{{ localize("변경할 비밀번호") }}</div>
                <div class="form-control-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="localize('변경할 비밀번호를 입력해주세요.')"
                  />
                  <div class="icon icon-eye" role="button">{{ localize("보기") }}</div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-label">{{ localize("변경할 비밀번호 확인") }}</div>
                <div class="form-control-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="localize('변경할 비밀번호를 입력해주세요.')"
                  />
                  <div class="icon icon-eye" role="button">{{ localize("보기") }}</div>
                </div>
              </div>
              <button type="submit" class="btn btn-md btn-block">{{ localize("비밀번호 변경하기") }}</button>
            </form>
          </div>
          <!-- //변경할 비밀번호 입력 -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
