<script setup lang="ts">
import { inject, reactive } from "vue";
import { localize } from "@/utils";
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { IListState } from "@/models/i-list-state.ts";
import { languages } from "@/configuration";
import { useClientWrapperStore, useLocalizationStore } from "@/store";
import { toRaw } from "@vue/reactivity";
import { PartnerConfig } from "@/models/partner";

const { getLanguage } = useLocalizationStore();

const { myName } = inject("partner-config") as PartnerConfig;

// localize('최근 주문 순')
// localize('평점 높은 순')
const orderBy = [
  { name: "최근 주문 순", value: "order" },
  { name: "평점 높은 순", value: "rating" },
];

const reset: Record<string, any> = {
  language: getLanguage(),
  service: 0,
  orderBy: orderBy[0].value,
  operating: true,
  isCurrentUserFavorite: false,
};

const state: IListState = reactive({
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
  language: reset.language,
  service: reset.service,
  orderBy: reset.orderBy,
  operating: reset.operating,
  isCurrentUserFavorite: reset.isCurrentUserFavorite,
});
request();

function resetForm(): void {
  for (const key in reset) state[key] = reset[key];
  request();
}
function request(page: number = 1): void {
  console.log("request", toRaw(state));
  const variables = {
    condition: {
      fields: [
        {
          name: "keyword",
          value: state.keyword,
        },
      ],
      // "orderBy": {
      //   "field": "registerDate",
      //   "orderBy": "DESC"
      // }
    },
    page: page,
  };
  const query = `
    query GetPartners(
      $condition: SearchConditionInput = {}
      $page: Int = 1
    ) {
      partners(condition: $condition, page: $page) {
        data{
          id
          name
          languages{
            code
            name
          }
          rating{
            speed
            average
            accuracy
            communication
          }
          favoriteCount
          isCurrentUserFavorite
          image{
            logo{
              id
              fileName
            }
            wallpaper{
              id
              index
              fileName
            }
            sub{
              id
              fileName
            }
          }
          experience{
            id
            items{
              index
              year
              month
              content
              description
            }
          }
          operating{
            status
            workTraffic
          }
        }
        paging{
          currentPage,
          totalItems,
        }
      }
    }
    `;
  // TODO: 쿼리 수정 필요
  // useClientWrapperStore().user.typedQuery(query, {
  //   variables: variables,
  //   successCallback: (result: any) => {
  //     console.log("request - successCallback: ", result.partners);
  //     const paging = result.partners.paging;
  //     state.page = paging.currentPage;
  //     state.totalRows = paging.totalItems;
  //     // state.items = (result.partners.data as Item[]).map(item => reactive(item));
  //     state.items = result.partners.data;
  //   },
  // });
}
function changeFavorite(id: string): void {
  console.log("changeFavorite", toRaw(id));
  const variables = {
    partnerId: id,
  };
  const query = `
    mutation SetFavoriteAddRequest($partnerId: ID!) {
      favoriteAdd(partnerId: $partnerId){
        id
        name
        isCurrentUserFavorite
        favoriteCount
      }
    }
    `;
  // useClientWrapperStore().user.mutate(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("changeFavorite - successCallback: ", result.favoriteAdd);
  //       state.items = state.items!.map((item) =>
  //         item.id === result.favoriteAdd.id
  //           ? {
  //               ...item,
  //               isCurrentUserFavorite: result.favoriteAdd.isCurrentUserFavorite,
  //               favoriteCount: result.favoriteAdd.favoriteCount,
  //             }
  //           : item
  //       );
  //     },
  //   }
  // );
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="box">
      <form @submit.prevent="request()" @reset.prevent="resetForm">
        <div class="search">
          <search-keyword :show-reset="true" :state="state" :placeholder="localize('Case, 환자 이름 검색')" />
        </div>

        <div class="row form-row flex-nowrap">
          <div class="col-auto flex-center">
            <div class="form-label">{{ localize("언어") }}</div>
            <select class="form-control form-select" v-model="state.language">
              <option v-for="item in languages" :value="item.value">{{ localize(item.label) }}</option>
            </select>
          </div>
          <div class="flex-center">
            <div class="form-label">{{ localize("서비스별") }}</div>
            <select class="form-control form-select w2x">
              <option value="">Customized Abutment & Temporary Crown</option>
            </select>
          </div>
          <div class="col-auto flex-center">
            <div class="form-label">{{ localize("정렬") }}</div>
            <div class="btn-check-wrap">
              <label v-for="item in orderBy" class="btn-check">
                <input type="radio" class="sr-only" @change="request()" v-model="state.orderBy" :value="item.value" />
                <span class="btn btn-outline-primary">{{ localize(item.name) }}</span>
              </label>
            </div>
          </div>
          <div class="col-auto">
            <label class="form-check">
              <input type="checkbox" class="sr-only" @change="request()" v-model="state.operating" />
              <span class="check">{{ localize("영업 중") }}</span>
            </label>
          </div>
          <div class="col-auto">
            <label class="form-check">
              <input type="checkbox" class="sr-only" @change="request()" v-model="state.isCurrentUserFavorite" />
              {{ localize(`${myName}만 보기`) }}
            </label>
          </div>
        </div>
      </form>
    </div>

    <!-- 파트너 목록 -->
    <ul class="row card-row">
      <!-- 파트너 -->
      <li class="col-3" v-for="item in state.items">
        <div class="card card-flat">
          <router-link to="about" class="card-top">
            <div class="card-img"><img src="@/assets/playground/img/_temp/sample.jpg" alt="" /></div>
            <div class="profile-thumb"><img src="@/assets/playground/img/_temp/profile.jpg" alt="" /></div>
          </router-link>
          <div class="card-body">
            <div class="media align-items-center">
              <router-link to="about" class="card-title media-body text-truncate">{{ item.name }}</router-link>
              <!-- [D] 활성화 active -->
              <button
                type="button"
                class="icon icon-heart"
                :class="{ active: item.isCurrentUserFavorite }"
                @click="changeFavorite(item.id)"
              >
                like
              </button>
            </div>
            <div class="meta">
              <i class="icon icon-star">{{ localize("평점") }}</i
              >{{ item.rating.average }} / 5.0
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- //파트너 -->

    <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
  </div>
</template>

<style scoped></style>
