<script setup lang="ts">
</script>

<template>
  <h1 class="hd-title">Service Setting</h1>
  <router-view></router-view>
</template>

<style scoped>

</style>