import axios from 'axios'
import type { AxiosRequestConfig, Method } from 'axios'


/*

    작성자: 박효철 (r2.dev4@imegagen.com / p1h2c3@naver.com)
    ------------------------------------------------------
    GOOD BYE - R2ServiceClient (V1)
    -------------------------------
    예전 R2서비스는 RESTful API로 통신했었고, 이를 위해 axios 라이브러리를 사용했었다
    axios 라이브러리는 직관적이라는 장점이 있었지만, RESTful API 요청과 응답에 대해 직관적으로 다루는 '존재'가 없다보니 번거로움이 있었다 
    특히 MSA구조에서 닷넷/자바 등에서 HttpClient/HttpRequestMessage/HttpResponseMessage 같은 개체를 사용해 본 경험이 있어서
    서버로 요청을 수행하는 Client, 요청을 나타내는 Request, 응답을 나타내는 Response 그리고 그 외 부대 표현들에 대한 갈증이 커져만 갔고
    결국 요청과 응답을 묘사한 RestClient/RestRequest/RestRepsonse 와 부대 타입들을 정의/사용했었다
    [아직도 자바스크립트/타입스크립트에서는 이런 라이브러리가 없다는 것이 아쉬운 데, 이전에 만들어 둔 이 라이브러리를 다듬어서 공개해 볼 생각이 있다]

*/

class ValueObject {
    protected _value: any

    constructor() {
        this._value = {}
    }

    get(key: string): any {
        return this._value[key]
    }

    set(k: string, v: any) {
        if (v !== undefined) {
            this._value[k] = v
        } else {
            this._value = k
        }
    }

    parse(sourceObj: any) {
        for (var prop in sourceObj) {
            const currentVal = sourceObj[prop]

            if (Array.isArray(currentVal)) {
                this._value[prop] = ''
                for (var i = 0; i < currentVal.length; i++) {
                    this._value[prop] += currentVal[i]

                    if (i < currentVal.length - 1) {
                        this._value[prop] += ','
                    }
                }
            }
            else if (typeof currentVal === "object") {
                this.parse(currentVal)	// 재귀를 하면서 내부 개체까지 모두 평면화(Flatten)한다
            }
            else {
                this._value[prop] = currentVal
            }
        }
    }

    get value(): any {
        return this._value
    }
}

class HttpHeaders extends ValueObject { }
class HttpParams extends ValueObject { }
class HttpQueryParams extends ValueObject {
    constructor() {
        super()
    }

    private _queryParamsDelimiter: string = '_'

    public get queryParamsDelimiter(): string {
        return this._queryParamsDelimiter
    }

    public set queryParamsDelimiter(queryParamsDelimiter: string) {
        this._queryParamsDelimiter = queryParamsDelimiter
    }

    addOr(keyword: string, ...fields: string[]) {
        let prop = fields[0]
        for (let loopIndex = 1; loopIndex < fields.length; loopIndex++) {
            prop += `${this._queryParamsDelimiter}${fields[loopIndex]}`
        }

        this._value[prop] = keyword
    }

    addAnd(keyword: string, field: string) {
        this._value[field] = keyword
    }
}

/*const RestMethods = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    HEAD: 'head',
    OPTIONS: 'options',
    PATCH: 'patch'
}*/

enum RestMethods {
    GET,
    POST,
    PUT,
    DELETE,
    HEAD,
    OPTIONS,
    PATCH
}

class RestRequest {

    /*public method: RestMethods = RestMethods.GET
    public baseUri: string
    public uri: string
    public params: HttpParams
    public headers: HttpHeaders
    public data: any*/

    constructor(public method: RestMethods = RestMethods.GET, public baseUri: string = '', public uri: string = '', public params: HttpParams = new HttpParams(), public headers = new HttpHeaders(), public data: any = {}) {
        /*this.method = RestMethods.GET
        this.baseUri = ''
        this.uri = ''
        this.params = new Params()
        this.headers = new Headers()
        this.data = {}*/
    }

    public init(): void {
        this.method = RestMethods.GET
        this.baseUri = ''
        this.uri = ''
        this.params = new HttpParams()
        this.headers = new HttpHeaders()
        this.data = {}
    }

    public getAxiosRequestParameter(): AxiosRequestConfig {
        //#region (METHOD-STRING)
        let method: Method = 'GET'
        switch (this.method) {
            case RestMethods.GET:
                method = 'GET'
                break
            case RestMethods.POST:
                method = 'POST'
                break
            case RestMethods.PUT:
                method = 'PUT'
                break
            case RestMethods.DELETE:
                method = 'DELETE'
                break
            case RestMethods.HEAD:
                method = 'HEAD'
                break
            case RestMethods.OPTIONS:
                method = 'OPTIONS'
                break
            case RestMethods.PATCH:
                method = 'PATCH'
                break
        }
        //#endregion

        const axiosRequestParameter = {
            method: method,
            baseURL: this.baseUri,
            url: this.uri,
            headers: this.headers.value,
            params: this.params.value,
            data: this.data
        }

        return axiosRequestParameter
    }
}

class PostFormDataRequest extends RestRequest {
    private _formData: FormData

    constructor() {
        super(RestMethods.POST)

        //INITIALIZE/
        this._formData = new FormData()
        this.headers.set("Content-Type", "multipart/form-data") //POST로 FORM 데이터를 보내기 위해 필요로 하는 HTTP 요청 헤더 값 설정 - "Content-Type": "multipart/form-data"
    }

    addFormData(name: string, formData: string | Blob): void {
        this._formData.append(name, formData)
    }

    removeFormData(name: string) {
        this._formData.delete(name)
    }

    clear(): void {
        for (let prop in this._formData) {
            this._formData.delete(prop)
        }
    }

    get axiosRequestParameter() {
        //1. 상위 클래스의 getAxiosRequestParameter()를 호출하여 기본 AxiosRequestParameter 를 얻어 온 다음,
        let axiosRequestParameter = super.getAxiosRequestParameter()
        //2. HEADERS 와 DATA(FormData) 를 설정한다
        axiosRequestParameter.headers = this.headers.value
        axiosRequestParameter.data = this._formData

        return axiosRequestParameter
    }
}

class RestError {
    public isSystemError: boolean
    public id: string
    public code: number
    public message: string

    constructor() {
        this.isSystemError = false
        this.id = ''
        this.code = 401
        this.message = ''
    }
}

class RestResponse {
    public status: number
    public headers: HttpHeaders
    public isSuccess: boolean
    public data: any
    public error: RestError

    constructor() {
        this.status = 404
        this.headers = new HttpHeaders()
        this.isSuccess = false
        this.data = {}
        this.error = new RestError()
    }
}

interface ResponseBase {
    isSuccess: boolean
}

interface EntityResponseBase<T> extends ResponseBase {
    data?: T
}

class RestClient {
    public baseUri: string

    constructor(baseUri: string) {
        this.baseUri = baseUri
    }

    send(restRequestObj: RestRequest): Promise<RestResponse> {
        //1. REST 요청을 위해 AXIOS 요청 매개 변수를 가져오고, BaseURL 을 설정한다
        const axiosRequestParameter = restRequestObj.getAxiosRequestParameter()
        if (this.baseUri !== '') {
            axiosRequestParameter.baseURL = this.baseUri
        }

        //2. REST 요청에 대한 응답을 가로채서 RestResponse(Promise) 형태로 반환하도록 한다
        // 이를 위해 axios 의 interceptor(response) 를 사용한다
        axios.interceptors.response.use(
            //2.1. 성공
            (response) => {
                return response

                /*
                
                //CHANGE/ axios 버전이 올라가면서 interceptor 의 response 를 가로채 RestResponse 를 만드는 방식에 변동이 발생했다
                const restResponse = new RestResponse()
                
                restResponse.headers.parse(response.headers)
                restResponse.status = response.status
                restResponse.isSuccess = true
                restResponse.data = response.data

                return restResponse 
                
                */
            },
            (error) => {
                //2.2. 실패 (실패가 일어나는 경우는 대부분, 연결이 끊어지는 경우 등 네트워크 장애나 시스템의 문제 상황 때문에 발생하는 것이 대부분이다)
                const restResponse = new RestResponse()
                const restError = new RestError()

                //2.2.1. 시스템 또는 네트워크 장애 등의 문제 상황 때문에 발생한 것인지 확인한다
                if (error.response === undefined) {
                    restError.isSystemError = true
                    restError.message = error.message // RestError 개체 생성 및 설정
                } else {
                    //2.2.1.1. 이는 일반 HTTP API 의 에러 코드를 의미한다
                    restError.isSystemError = false
                    restError.code = error.response.status // RestError 개체 생성 및 설정
                    restError.message = error.response.message
                    restResponse.headers.parse(error.response.headers)
                    restResponse.status = error.response.status
                }
                restResponse.isSuccess = false
                restResponse.error = restError

                return Promise.reject(restResponse)
            }
        )

        //3. 응답 interceptor 까지 모두 설정되었으니 요청을 수행한다
        return axios.request(axiosRequestParameter)
            .then(restResponseSuccess => {
                //CHANGE/ axios 버전이 올라가면서 interceptor 의 response 를 가로채 RestResponse 를 만드는 방식에 변동이 발생했다
                const restResponse = new RestResponse()
                restResponse.headers.parse(restResponseSuccess.headers)
                restResponse.status = restResponseSuccess.status
                restResponse.isSuccess = true
                restResponse.data = restResponseSuccess.data

                return Promise.resolve(restResponse)
                /* 
                return Promise.resolve(restResponseSuccess)
                */
            }).catch(restResponseFailed => {
                return Promise.resolve(restResponseFailed)
            })
    }

    get(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.GET
        return this.send(restRequestObj)
    }
    post(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.POST
        return this.send(restRequestObj)
    }
    put(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.PUT
        return this.send(restRequestObj)
    }
    delete(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.DELETE
        return this.send(restRequestObj)
    }
    head(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.HEAD
        return this.send(restRequestObj)
    }
    options(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.OPTIONS
        return this.send(restRequestObj)
    }
    patch(restRequestObj: RestRequest): Promise<RestResponse> {
        restRequestObj.method = RestMethods.PATCH
        return this.send(restRequestObj)
    }
    request(restRequestObj: RestRequest): Promise<RestResponse> {
        return this.send(restRequestObj)
    }
}

export {
    ValueObject,
    HttpHeaders, HttpParams, HttpQueryParams,
    RestMethods, RestRequest, PostFormDataRequest, RestError, RestResponse, RestClient
}
export type { ResponseBase, EntityResponseBase }
