<script setup lang="ts">
import { onMounted } from "vue";
import { useJoinStore } from "@/store";
import HomeHeader from "@/views/home/HomeHeader.vue";
import HomeFooter from "@/views/home/HomeFooter.vue";
import { useRouter } from "vue-router";

const router = useRouter();
const { initJoin } = useJoinStore();

onMounted(initJoin);
</script>

<template>
  <div id="wrap" class="r2playground">
    <home-header />

    <main id="ct">
      <div class="container support">
        <div class="support-wrap">
          <ul class="nav nav-tabs support-nav">
            <li class="nav-item">
              <router-link to="" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="" class="nav-link active">Sign up</router-link>
            </li>
          </ul>
          <div class="tit-head">
            <h1>Sign up</h1>
            <img src="@/assets/home/img/illust-signup.png" alt="" />
          </div>
        </div>

        <router-view></router-view>
      </div>
    </main>

    <home-footer />
  </div>
</template>

<style></style>
