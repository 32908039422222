<script setup lang="ts">
import { useRoute } from "vue-router";
const route = useRoute();
</script>

<template>
  <h1>Detail</h1>
  <p>route.params.license: {{ route.params.license }}</p>
  <router-link :to="`/academy/certification/detail/${route.params.license}/qualifying-test`"
    >QualifyingTest</router-link
  >
  |
  <router-link :to="`/academy/certification/detail/${route.params.license}/courses`">Courses</router-link>
  <router-view></router-view>
</template>

<style scoped></style>
