<script setup lang="ts">
import HomeHeader from "@/views/home/HomeHeader.vue";
import HomeFooter from "@/views/home/HomeFooter.vue";
import { computed, reactive } from "vue";
import { useMemberStore, useResourceWrapperStore } from "@/store";
import { useFormBuilder } from "@/services";
import { localize } from "@/utils";
import { Validators } from "@/utils";
import router from "@/router";
import { ProfileNameType } from "@/models";

const { emailAddress, name } = useMemberStore().getMember();

const state: Record<string, any> = reactive({
  email: emailAddress,
  firstName: name.firstName,
  lastName: name.lastName,
  nickName: "", // 최초 로그인 시 진입하므로 항상 빈값
  profileNameType: ProfileNameType.NickName,
  image: [],
  uploading: false,
  progressFileName: "",
  progressRate: 0,
  fileItems: [] as File[],
});
/**
 * 중복확인 가능 여부
 */
const enabledCheck = computed(() => {
  console.log("enabledCheck: ", valid("nickName"), invalid("tempNickname"));
  return valid("nickName") && invalid("tempNickname");
});

const { controls, errors, group, touch, validate, valid, invalid } = useFormBuilder();
group({
  nickName: { value: "", validators: [Validators.required], dependencies: ["tempNickname"] },
  tempNickname: { value: "", validators: [Validators.required, Validators.match(controls, "nickName")] },
});

/**
 * 닉네임 중복 확인
 */
async function existNickName(event: Event): Promise<void> {
  if (!enabledCheck.value) return;
  controls.tempNickname.value = controls.nickName.value;
  // console.log(value, controls.tempNickname.value);
}
async function uploadImage(event: Event) {
  const uploadFiles = (<HTMLInputElement>event.target).files;
  if (uploadFiles != null) {
    await useResourceWrapperStore().upload(uploadFiles, {
      successCallback(uploadedFiles) {
        console.log(
          "uploadImage - successCallback: ",
          uploadedFiles.map((info) => `${info.id}/${info.fileName}/${info.fileSize}`).join("\n")
        );
        state.image = uploadedFiles;
        state.uploading = false;
        state.progressFileName = "";
        state.progressRate = 0;
      },
      progressCallback(progress) {
        state.uploading = true;
        state.progressFileName = progress.fileName;
        state.progressRate = progress.progressRate;
      },
    });
  }
  event.preventDefault();
}
function done(event: Event): void {
  validate(true);
  if (valid()) {
    router.push("/");
  }
  event.preventDefault();
}
</script>

<template>
  <div id="wrap" class="r2playground">
    <home-header />

    <main id="ct">
      <div class="container support login-wrap">
        <div class="join-box login-box">
          <div class="step-wrap">
            <img src="@/assets/home/img/logo.svg" alt="" class="mb-4" />
            <h2>{{ localize("R2 Player 회원 프로필 설정") }}</h2>
          </div>
          <div class="bx-purple">
            <img src="@/assets/home/img/check.png" alt="" class="check lg" />
            <b class="d-block fs-4 mt-n2">{{ localize("R2 Player 회원이 되신 것을 축하합니다.") }}</b>
          </div>
          <form class="join-form login-form profile-set mb-0">
            <div class="form-group">
              <div class="form-label form-name w-100">{{ localize("프로필 사진") }}</div>
              <div class="profile-file">
                <label class="profile-thumb mb-0">
                  <input @change="uploadImage" type="file" class="sr-only" />
                  {{ state.progressRate.toFixed(0) }}%
                </label>
              </div>
            </div>
          </form>
          <form class="join-form login-form profile-set mb-0">
            <div class="form-group">
              <div class="form-label form-name w-100">{{ localize("아이디") }}</div>
              <div id="email">test1234@imegagen.com</div>
            </div>
          </form>
          <form class="join-form login-form profile-set">
            <div class="form-group">
              <div class="form-label form-name w-100">{{ localize("프로필 이름") }}</div>
              <div class="d-flex align-items-center mb-2">
                <label class="form-check w-auto p-0 mb-3">
                  <input
                    type="radio"
                    name="c1"
                    class="sr-only"
                    v-model="state.profileNameType"
                    :value="ProfileNameType.Name"
                  />
                  <span class="radio round"></span>
                </label>
                <span class="badge bg-purple-soft rounded col-2 ml-1 mr-3">{{ localize("이름") }}</span>
                <div id="name">{{ state.firstName }} {{ state.lastName }}</div>
              </div>
              <div class="d-flex align-items-center">
                <label class="form-check w-auto p-0 mb-3">
                  <input
                    type="radio"
                    name="c1"
                    class="sr-only"
                    v-model="state.profileNameType"
                    :value="ProfileNameType.NickName"
                  />
                  <span class="radio round"></span>
                </label>
                <span class="badge bg-purple-soft rounded col-2 ml-1 mr-3">{{ localize("닉네임") }}</span>
                <div class="input-group">
                  <input
                    type="text"
                    id="nickName"
                    class="form-control curved"
                    v-model="controls.nickName.value"
                    @focusout="touch('nickName')"
                    :disabled="state.profileNameType !== ProfileNameType.NickName"
                    :placeholder="localize('닉네임 입력')"
                  />
                  <a
                    class="btn btn-primary center"
                    :class="{ disabled: state.profileNameType !== ProfileNameType.NickName || !enabledCheck }"
                    href="#"
                    @click="existNickName"
                    >{{ localize("중복 확인") }}</a
                  >
                </div>
              </div>
              <!-- [D] 닉네임 checked 되면 disabled 해제   -->
            </div>
          </form>
          <div class="btn-wrap">
            <a class="btn btn-primary center w-100" href="#" @click="done" :class="{ disabled: invalid() }">{{
              localize("확인")
            }}</a>
          </div>
        </div>
      </div>
      <!-- //container -->
    </main>

    <home-footer />
  </div>
</template>

<style scoped></style>
