<script setup lang="ts">
const menuItems = [
  { name: "서비스 매출 추이", link: "sales" },
  { name: "소비자 동향", link: "consumer" },
];
</script>

<template>
  <h1 class="hd-title">Statistics</h1>
  <router-view :menu-items="menuItems"></router-view>
</template>

<style scoped></style>
