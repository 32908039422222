import { RouteRecordRaw } from "vue-router";
import membershipRoutes from "@/router/playground/player/support/membership";
import troubleShootingRoutes from "@/router/playground/player/support/trouble-shooting";
import TroubleShooting from "@/views/playground/player/support/trouble-shooting/TroubleShooting.vue";
import Membership from "@/views/playground/player/support/membership/Membership.vue";
import Faq from "@/views/playground/player/support/Faq.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/support/membership`,
  },
  {
    path: "membership",
    component: Membership,
    children: membershipRoutes,
  },
  {
    path: "trouble-shooting",
    component: TroubleShooting,
    children: troubleShootingRoutes,
  },
  {
    path: "faq",
    redirect: (to) => `/playground/player/support/faq/all`,
  },
  {
    path: "faq/:category",
    component: Faq,
  },
];

export default routes;
