<script setup lang="ts">
import { graphql } from "@/libs/R2ServiceClient/service/Visitor";
import { useFormBuilder } from "@/services";
import { useClientWrapperStore } from "@/store";
import { localize, Validators } from "@/utils";
import { onMounted } from "vue";

const { controls, errors, group, touch, validate, valid, invalid } = useFormBuilder();
group({
  /**
   * 개인정보 수집 및 이용 동의
   */
  agreement: { touched: false, value: false, validators: [Validators.required] },
  /**
   * 이름
   */
  name: { touched: false, value: "", validators: [Validators.required] },
  /**
   * 이메일
   */
  email: { touched: false, value: "", validators: [Validators.required, Validators.email] },
  /**
   * 문의 제목
   */
  title: { touched: false, value: "", validators: [Validators.required] },
  /**
   * 문의 제목
   */
  content: { touched: false, value: "", validators: [Validators.required] },
});

onMounted(init);

/**
 * 초기화
 */
function init(): void {
  validate();
}

function cancel() {
  console.log("cancel");
}
async function done(event: Event): Promise<void> {
  event.preventDefault();
  validate();
  if (invalid()) {
    return;
  }
  console.log("done");
  const CreateInquiry = graphql(`
    mutation CreateInquiryRequest($input: CreateInquiryRequestsInput!) {
      createInquiry(input: $input) {
        inquiryRequestId
      }
    }
  `);
  const variables = {
    input: {
      writer: controls.name.value,
      emailAddress: controls.email.value,
      title: controls.title.value,
      content: controls.content.value,
    },
  };
  const response = await useClientWrapperStore().visitor.typedMutate(CreateInquiry, {
    variables: variables,
    successCallback: (result) => {
      console.log("done - successCallback: ", result);
      console.log("문의가 성공적으로 등록되었습니다.", "- inquiryRequestId: ", result.createInquiry.inquiryRequestId);
    },
  });
}
</script>

<template>
  <ul class="nav nav-tabs support-nav">
    <li class="nav-item">
      <router-link to="" class="nav-link">Home</router-link>
    </li>
    <li class="nav-item">
      <router-link to="" class="nav-link">Customer Support</router-link>
    </li>
    <li class="nav-item">
      <router-link to="" class="nav-link active">1:1 Inquiry</router-link>
    </li>
  </ul>
  <div class="tit-head">
    <h1>1:1 inquiry</h1>
    <span>{{ localize('궁금한 점은 1:1 문의로 해결해 보세요') }}</span>
  </div>
  <div class="sub-head start">
    <label class="form-custom">
      <input type="checkbox" class="sr-only" v-model="controls.agreement.value" />
      <span class="form-custom-label">{{ localize('개인정보 수집 및 이용 동의') }}</span>
    </label>
  </div>
  <form @submit.prevent="done">
    <div class="fieldset wide">
      <div class="textarea-wrap">
        <textarea class="form-control" rows="8" placeholder="" disabled>[개인정보 수집 및 이용 동의 안내]

R2playground은 고객님의 문의 처리를 위해 아래와 같이 개인정보를 수집 및 이용하고자 합니다. 내용을 자세히 읽어보신 후, 동의 여부를 결정해 주시기 바랍니다.

1. 개인정보 수집 목적
    • 고객 문의 및 상담 처리
    • 서비스 제공 및 고객 지원
2. 수집하는 개인정보 항목
    • 필수 항목: 이름, 연락처(이메일 또는 전화번호), 문의 내용
    • 선택 항목: 추가 정보(해당 시)
3. 개인정보 보유 및 이용 기간
    • 고객 문의 처리 완료 후 1년간 보관 (관련 법령에 따라 보관 기간이 다를 수 있음)
4. 개인정보 제3자 제공
    • 원칙적으로 고객님의 개인정보를 제3자에게 제공하지 않으며, 고객님의 별도 동의가 있거나 법령에 의거한 경우에만 제공됩니다.
5. 동의 거부권 및 불이익
    • 고객님은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 문의 접수가 제한될 수 있습니다.

위 개인정보 수집 및 이용에 동의하십니까?

 
[Consent to Collect and Use Personal Information]

R2Playground would like to collect and use your personal information as outlined below to process your inquiry. Please read the information carefully and decide whether to consent.

1. Purpose of Collecting Personal Information
    • To process customer inquiries and provide support
    • To offer services and customer assistance
2. Personal Information Collected
    • Required: Name, Contact Information (Email or Phone Number), Inquiry Details
    • Optional: Additional Information (if applicable)
3. Retention and Use Period of Personal Information
    • Personal information will be retained for 1 year after the inquiry has been processed (the retention period may vary depending on relevant laws and regulations).
4. Provision of Personal Information to Third Parties
    • We do not provide your personal information to third parties without your consent, except when required by law or with your explicit permission.
5. Right to Refuse Consent and Possible Disadvantages
    • You have the right to refuse consent to the collection and use of personal information. However, refusal may result in limitations in submitting your inquiry.
Do you agree to the collection and use of your personal information?
        </textarea>
      </div>
      <div class="flex-fill">
        <div class="form-group">
          <div class="form-label">{{ localize('이름') }}<i class="required">*</i></div>
          <div class="form-body">
            <div class="input-group">
              <input
                type="text"
                class="form-control curved"
                :class="{
                  'is-invalid': controls.name.touched && invalid('name'),
                  'is-valid': valid('name'),
                }"
                v-model="controls.name.value"
                @focusout="touch('name')"
              />

              <!--    이름 에러 메시지-->
              <div v-show="controls.name.touched && errors?.name?.required" class="invalid-feedback">
                * {{ localize("이름을 입력하세요.") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-label">{{ localize('이메일') }}<i class="required">*</i></div>
          <div class="form-body">
            <div class="input-group">
              <input
                type="text"
                class="form-control curved"
                :class="{
                  'is-invalid': controls.email.touched && invalid('email'),
                  'is-valid': valid('email'),
                }"
                v-model="controls.email.value"
                @focusout="touch('email')"
              />

              <!--    이메일 에러 메시지-->
              <div v-show="controls.email.touched && errors?.email?.required" class="invalid-feedback">
                * {{ localize("이메일을 입력하세요.") }}
              </div>
              <div v-show="controls.email.touched && errors?.email?.email" class="invalid-feedback">
                * {{ localize("올바른 이메일을 입력하세요.") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-label">{{ localize('문의 제목') }}<i class="required">*</i></div>
          <div class="form-body">
            <div class="input-group">
              <input
                type="text"
                class="form-control curved"
                :class="{
                  'is-invalid': controls.name.touched && invalid('title'),
                  'is-valid': valid('title'),
                }"
                v-model="controls.title.value"
                @focusout="touch('title')"
              />

              <!--    문의 제목 에러 메시지-->
              <div v-show="controls.title.touched && errors?.title?.required" class="invalid-feedback">
                * {{ localize("문의 제목을 입력하세요.") }}
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-label">{{ localize('문의 내용') }}<i class="required">*</i></div>
          <div class="form-body">
            <textarea
              class="form-control curved"
              rows="8"
              placeholder=""
              :class="{
                'is-invalid': controls.content.touched && invalid('content'),
                'is-valid': valid('content'),
              }"
              v-model="controls.content.value"
              @focusout="touch('content')"
            ></textarea>

            <!--    문의 내용 에러 메시지-->
            <div v-show="controls.content.touched && errors?.content?.required" class="invalid-feedback">
              * {{ localize("문의 내용을 입력하세요.") }}
            </div>
          </div>
        </div>
        <!-- <div class="form-group">
          <div class="form-label">첨부파일</div>
          <div class="form-body desc-body">
            <div class="input-group input-group-file">
              <input type="file" id="fileName1" class="sr-only" />
              <input type="text" class="form-control curved w2x" />
              <label class="btn btn-outline ml-2"> <input type="file" class="sr-only" />파일 선택 </label>
            </div>
            <span class="desc">* 파일 개수 제한없이 최대 10MB까지 등록 가능</span>
          </div>
        </div> -->
      </div>
    </div>
  </form>
  <div class="btn-wrap">
    <a @click="done" :class="{ disabled: invalid() }" class="btn btn-primary center">{{ localize('문의하기') }}</a>
  </div>
</template>

<style scoped>
textarea[disabled]{
  background-color: white;
  color: black;
}</style>
