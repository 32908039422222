import { createApp } from "vue";
import { createPinia } from "pinia";
import { vMaska } from "maska";
import { createHead } from "@vueuse/head";
import { BootstrapVue3 } from "bootstrap-vue-3";
import router from "@/router";
import App from "@/views/App.vue";

import "@/assets/home/css/bootstrap.min.css";
import "@/assets/home/css/swiper-bundle.min.css";
import "@/assets/home/css/common.css";
import "@/assets/vue.css";

const app = createApp(App);
const pinia = createPinia();

app.directive("maska", vMaska).use(pinia).use(BootstrapVue3).use(createHead()).use(router);

app.mount("#app");

/*
declare module '@vue/runtime-core' {
    export interface ComponentCustomProperties {
        // $filters: Filters;
    }
}*/
