<script setup lang="ts">
import { alert } from "@/services";
import { localize, openInNewTab } from "@/utils";
import SwiperCore from "swiper";
import { Mousewheel, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper as SwiperClass } from "swiper/types";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed, onUnmounted, reactive, Ref, ref } from "vue";

import HomeFooter from "@/views/home/HomeFooter.vue";
import HomeHeader from "@/views/home/HomeHeader.vue";

SwiperCore.use([Mousewheel]);

const originRef = ref<HTMLElement | null>(null);
const floatRef = ref<HTMLElement | null>(null);
const mainSwiperRef = ref<SwiperClass | null>(null);
const scrollSwiperRef = ref<SwiperClass | null>(null);

//HERE
const downloadLink: Ref<string> = ref("https://download.r2playground.com");

const isMobile = computed(() => window.innerWidth < 992);
let lastOffset = 0;

const mainSwiperEvent = {
  init: function (swiper: SwiperClass) {
    mainSwiperRef.value = swiper;
  },
  slidechangetransitionstart: function (swiper: SwiperClass) {
    state.activeIndex = swiper.activeIndex;
    // console.log("activeIndex", state.activeIndex, "state.isEnd = ", state.activeIndex >= 5);
    state.isEnd = state.activeIndex >= 5;
  },
  slideChangeTransitionEnd: function (swiper: SwiperClass) {
    const currentSlide = swiper.el.firstElementChild?.children[swiper.activeIndex];
    if (window.innerWidth < 992 && currentSlide?.classList.contains("main-system")) {
      swiper.mousewheel.disable();
      scrollSwiperRef.value?.mousewheel?.enable();
    }
  },
  slideChange: function (swiper: SwiperClass) {
    const currentSlide = swiper.el.firstElementChild?.children[swiper.activeIndex];
    state.isDarkMode = currentSlide?.classList.contains("sect-dark") == true;
  },
  setTranslate: function (swiper: SwiperClass) {
    // state.isEnd = swiper.isEnd;
    // console.log("activeIndex", state.activeIndex, "state.isEnd = ", state.activeIndex >= 5);
    // state.isEnd = state.activeIndex >= 5;
  },
};
const scrollSwiperEvent = {
  init: function (swiper: SwiperClass) {
    scrollSwiperRef.value = swiper;
  },
  setTranslate: function (scrollSwiper: SwiperClass) {
    if (isMobile) {
      const nowOffset = scrollSwiper.el.offsetTop;
      const endOffset = window.innerHeight - scrollSwiper.el.clientHeight + 10;

      if (nowOffset < lastOffset && nowOffset < endOffset) {
        scrollSwiperEvent.moveParent(scrollSwiper, nowOffset);
        mainSwiperRef.value?.slideNext();
      } else if (nowOffset > lastOffset && nowOffset > 51) {
        scrollSwiperEvent.moveParent(scrollSwiper, nowOffset);
        mainSwiperRef.value?.slidePrev();
      }
      lastOffset = scrollSwiper.el.offsetTop;
    }
  },
  moveParent: function (scrollSwiper: SwiperClass, nowOffset: number) {
    scrollSwiper.mousewheel.disable();
    mainSwiperRef.value?.mousewheel.enable();
    lastOffset = nowOffset;
  },
};

const state = reactive({
  isDarkMode: false,
  isEnd: false,
  activeIndex: 0,
});

// init();
// onMounted(init);
requestAnimationFrame(() => {
  setTimeout(init, 500);
  // nextTick();
  // init();
});

onUnmounted(() => {
  if (resizeHandler) {
    window.removeEventListener("resize", resizeHandler);
  }
});

function init() {
  window.addEventListener("resize", resizeHandler);
  resizeHandler();
}
function resizeHandler() {
  floatRef.value?.style.setProperty("--right", `${originRef.value?.offsetLeft}px`);
  floatRef.value?.style.setProperty("--bottom", `${originRef.value?.offsetTop}px`);
}
</script>

<template>
  <div id="wrap" :data-swiper="state.activeIndex" :class="{ 'dark-mode': state.isDarkMode, 'is-end': state.isEnd }">
    <!-- header -->
    <home-header />
    <!-- //header -->

    <!-- container -->
    <main id="ct">
      <!-- 페이지 시작 -->

      <swiper
        class="main-swiper"
        direction="vertical"
        :mousewheel="true"
        :speed="1000"
        slides-per-view="auto"
        :resistance-ratio="0"
        :simulate-touch="false"
        :modules="[Navigation]"
        :navigation="{
          nextEl: '.main-swiper>.swiper-button-next',
        }"
        @init="mainSwiperEvent.init"
        @slide-change-transition-start="mainSwiperEvent.slidechangetransitionstart"
        @slide-change-transition-end="mainSwiperEvent.slideChangeTransitionEnd"
        @slide-change="mainSwiperEvent.slideChange"
        @setTranslate="mainSwiperEvent.setTranslate"
      >
        <swiper-slide class="main-sect main-visual" :key="0">
          <div class="container">
            <div class="main-visual-text ani ani-ink-right">
              <h1 class="heading">Let's play<br />Together</h1>
              <strong class="fs-4">{{ localize("지식과 디자인을 주문하다!") }}</strong>
              <p
                v-html="localize('R2 Playground는 맞춤형 디지털 진단 및 <br class=\'d-none d-lg-block\' />치료계획을 위한 다양한 임상 솔루션과 디자인 서비스를 제공합니다.')
                "
              ></p>
              <a v-bind:href="downloadLink" target="_blank"><button class="btn btn-primary">
                {{ localize("Playground 다운로드") }}<i class="icon icon-download-wh"></i>
              
              </button></a>
            </div>
            <div class="visual-illu">
              <i class="villu-r"></i>
              <i class="villu1 ani"></i>
              <i class="villu2 ani"></i>
              <i class="villu3 ani"></i>
              <i class="villu3-2 ani"></i>
              <i class="villu4 ani"></i>
              <i class="villu5 ani"></i>
              <i class="villu-2"></i>
              <i class="villu6 ani"></i>
              <i class="villu7 ani"></i>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide class="main-sect sect-dark main-solution" :key="1">
          <div class="container">
            <div class="position-relative">
              <h2 class="main-solution-title fs-2">
                Enjoy R2 Solutions
                <img src="@/assets/home/img/main/solution1.svg" class="ani" alt="" />
                <img src="@/assets/home/img/main/solution2.svg" class="ani" alt="" />
              </h2>
              <p
                v-html="
                  localize('Dental implant에서, 맞춤형 재건 임플란트까지 <br class=\'d-md-none\' />다양한 분야의 진단 및 <br class=\'d-none d-md-block\' />치료계획 수립을 위한  <br class=\'d-md-none\' />맞춤형 solution을 제공합니다.')
                "
              ></p>
              <div class="swiper-button-wrap">
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
            <swiper
              class="solution-swiper overflow-visible"
              :slides-per-view="1.08"
              :space-between="20"
              :grab-cursor="true"
              :breakpoints="{
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3.25,
                  spaceBetween: 60,
                },
              }"
              :modules="[Navigation, Pagination]"
              :navigation="{
                nextEl: '.main-solution .swiper-button-next',
                prevEl: '.main-solution .swiper-button-prev',
              }"
              :pagination="{
                el: '.main-solution .swiper-pagination',
                type: 'progressbar',
              }"
            >
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/solution1.png" alt="" /></div>
                  <!-- <div class="card-img embed-responsive">
                      <video src="media/01.mp4" class="embed-responsive-item" autoplay muted></video>
                  </div> -->
                  <strong>{{ localize("직관적인 Implant planning") }}</strong>
                  <p class="card-text">
                    {{
                      localize("Top-down 방식의 R2GATE는 가장 직관적인 임플란트 플래닝 프로그램입니다. Digital EYE를 이용한 골질 분석을 통해 BIC를 확인 하실수 있고, Drilling sequence 계산을 통해 최적의 초기고정을 얻을 수 있도록 합니다.")
                    }}
                  </p>
                  <router-link
                    :to="{ name: 'home-r2-solution', query: { section: 0 } }"
                    class="btn btn-outline-white btn-sm"
                    >View more<i class="icon icon-arrow"></i
                  ></router-link>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/solution2-1.png" alt="" /></div>
                  <!-- <div class="card-img embed-responsive">
                      <video src="media/02.mp4" class="embed-responsive-item" autoplay muted></video>
                  </div> -->
                  <strong>{{ localize("Dental Avatar를 이용한 정밀진단") }}</strong>
                  <p class="card-text">
                    {{
                      localize("를 통한 Dental Avatar는 진단 및 치료계획에 필요한 모든 환자 정보를 입체적으로 제공합니다. Ai를 이용한 Smile creator기능은 수술후 변화된 모습을 즉시 구현하여 환자와의 신뢰를 얻을 수 있도록 합니다.")
                    }}
                  </p>
                  <router-link
                    :to="{ name: 'home-r2-solution', query: { section: 1 } }"
                    class="btn btn-outline-white btn-sm"
                    >View more<i class="icon icon-arrow"></i
                  ></router-link>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/solution3.png" alt="" /></div>
                  <!-- <div class="card-img embed-responsive">
                      <video src="media/03.mp4" class="embed-responsive-item" autoplay muted></video>
                  </div> -->
                  <strong>{{ localize("맞춤형 양악수술 및 악안면 재건") }}</strong>
                  <p class="card-text">
                    {{
                      localize("FaceGIDE는 더 빠르고, 안전한 양악 및 악안면 재건 수술을 위한 Setup 시뮬레이션 기능을 제공합니다. 이후 주문을 통해 환자 맞춤형으로 제작되는 맞춤형 Plate와 보형물로 예측 가능한 수술결과를 얻으실 수 있습니다.")
                    }}
                  </p>
                  <router-link
                    :to="{ name: 'home-r2-solution', query: { section: 2 } }"
                    class="btn btn-outline-white btn-sm"
                    >View more<i class="icon icon-arrow"></i
                  ></router-link>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img">
                    <div class="solution-swiper-img4"></div>
                  </div>
                  <strong class="card-title">{{ localize("임플란트(보철)치료를 더 아름답게") }}</strong>
                  <p class="card-text">
                    {{
                      localize("고르지 않은 치열이나, 치아의 malpostion은 임플란트 치료계획에 방해가 되거나, 보철치료 후 전체적인 심미성을 떨어트려 컴플레인의 원인이 될수 있습니다. Meg Align 투명교정 서비스는 빠르고 효과적인 Spot교정으로 보철(임플란트)치료의 심미적인 마무리를 도와 드립니다.")
                    }}
                  </p>
                  <router-link
                    :to="{ name: 'home-r2-solution', query: { section: 3 } }"
                    class="btn btn-outline-white btn-sm"
                    >View more<i class="icon icon-arrow"></i
                  ></router-link>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/solution5.png" alt="" /></div>
                  <!-- <div class="card-img embed-responsive">
                      <video src="media/04.mp4" class="embed-responsive-item" autoplay muted></video>
                  </div> -->
                  <strong>{{ localize("가볍고 심플한 Chair-side CAD") }}</strong>
                  <p class="card-text">
                    {{
                      localize(
                        "복잡하고 어려운 Lab용 CAD는 그만! 일상에서 가장 많이 쓰이는 템포라리 크라운, 맞춤형 어버트먼트, 1~3번 Bridge 디자인까지 필요한 기능만 모았습니다."
                      )
                    }}
                  </p>
                  <router-link
                    :to="{ name: 'home-r2-solution', query: { section: 4 } }"
                    class="btn btn-outline-white btn-sm"
                    >View more<i class="icon icon-arrow"></i
                  ></router-link>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img">
                    <div class="solution-swiper-img6"></div>
                  </div>
                  <strong class="card-title">{{ localize("세상의 모든 CAD Library, 여기에") }}</strong>
                  <p class="card-text">
                    {{
                      localize(
                        "메가젠 임플란트 제품을 위한 3Shape, EXOCAD, Dental Wing등 모든 덴탈 CAD라이브러리를 지원하고 있습니다. 라이브러리 뱅크에서 필요한 라이브러리를 찾고 다운 받으세요."
                      )
                    }}
                  </p>
                  <a href="https://r2gate.com/cad-cam?cate=3shape" target="_blank" class="btn btn-outline-white btn-sm">
                    View more<i class="icon icon-arrow"></i>
                  </a>
                  <!-- <router-link
                    :to="{ name: 'home-r2-solution', query: { section: 5 } }"
                    class="btn btn-outline-white btn-sm"
                    >View more<i class="icon icon-arrow"></i
                  ></router-link> -->
                </div>
              </swiper-slide>
              <div class="swiper-pagination"></div>
            </swiper>
          </div>
        </swiper-slide>
        <swiper-slide class="main-sect main-system" :key="2">
          <swiper
            class="scrollSwiper"
            direction="vertical"
            :slides-per-view="'auto'"
            :free-mode="true"
            :modules="[Scrollbar]"
            :scrollbar="{
              el: '.swiper-scrollbar',
            }"
            @init="scrollSwiperEvent.init"
            @set-translate="scrollSwiperEvent.setTranslate"
          >
            <swiper-slide>
              <div class="container">
                <div class="main-system-hd">
                  <div class="main-system-title">
                    <p>{{ localize("빠르고, 편리한 그리고 세계적인") }}</p>
                    <h2 class="fs-2">R2 Playground</h2>
                    <strong class="font-poppins">Online order system</strong>
                  </div>
                  <p class="d-none d-lg-block ml-auto">
                    <!--<a @click="openInNewTab('/playground/player/order')" class="btn btn-primary"
                      >{{ localize("바로 주문하기") }}<i class="icon icon-arrow"></i
                    ></a>
                    <button @click="alert(localize('준비 중 입니다.'))" class="btn btn-outline-primary">
                      {{ localize("Playground 다운로드") }}<i class="icon icon-download"></i>
                    </button>-->
                  </p>
                </div>
                <ul class="row no-gutters main-system-item">
                  <li class="col-lg-4">
                    <img src="@/assets/home/img/main/system1.svg" class="ani" alt="" />
                    <strong class="fs-4">{{ localize("Drag & Drop으로 주문을 간편하게") }}</strong>
                    <p
                      class="fsmall"
                      v-html="
                        localize('주문 사이트 접속 없이, 파일을 끌어다 놓는 것만으로 <br />간편하게 주문이 시작됩니다.<br />접수, 컨펌, 배송까지 실시간으로 <br />주문 진행 상황을 확인 하실 수 있습니다.')
                      "
                    ></p>
                  </li>
                  <li class="col-lg-4">
                    <img src="@/assets/home/img/main/system2.svg" class="ani" alt="" />
                    <strong class="fs-4">{{ localize("디자인?! R2 Planner가 도와드립니다.") }}</strong>
                    <p
                      class="fsmall"
                      v-html="
                        localize('공인된 R2GATE 전문가인 R2 Planner가<br>진단 및 디자인을 도와 드립니다.<br>선호 언어, 지역, 경력등으로 다양한 R2 Planner를<br>선택하고, 서비스를 의뢰 하실 수 있습니다.')
                      "
                    ></p>
                  </li>
                  <li class="col-lg-4">
                    <img src="@/assets/home/img/main/system3.svg" class="ani" alt="" />
                    <strong class="fs-4">R2Center All-in-ONE Service</strong>
                    <p
                      class="fsmall"
                      v-html="
                        localize('전세계 100여개의 R2 Center는 가이드에서<br>CAD/CAM보철까지, 다양하고 복잡한 케이스를<br>위한 다양한 서비스를 제공합니다.<br>진정한 디지털 전문 센터에 맡겨 보세요.')
                      "
                    ></p>
                  </li>
                </ul>

                <p class="d-lg-none text-center pt-3">
                  <a @click="openInNewTab('/playground/player/order')" class="btn btn-primary ml-0"
                    >{{ localize("바로 주문하기") }}<i class="icon icon-arrow"></i
                  ></a>
                  <button @click="alert(localize('준비 중 입니다.'))" class="btn btn-outline-primary mt-2 ml-0">
                    {{ localize("Playground 다운로드") }}<i class="icon icon-download"></i>
                  </button>
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </swiper-slide>
        <swiper-slide class="main-sect main-academy text-center" :key="3">
          <div class="container">
            <h2 class="main-academy-title font-poppins">
              R2 <br class="d-lg-none" />Academy
              <img src="@/assets/home/img/main/academy2.svg" class="ani" alt="" />
            </h2>
            <div class="acdemy-illu">
              <div class="position-relative ani">
                <i class="aillu1"></i>
                <img src="@/assets/home/img/main/aillu1.svg" class="position-relative" alt="" />
              </div>
              <i class="aillu2 ani"></i>
              <i class="aillu3 ani"></i>
            </div>
            <strong class="main-academy-subtitle font-poppins">Learn together</strong>
            <p
              class="fsmall"
              v-html="
                localize('R2GATE가 처음이세요? 여기 Tutorial 영상부터 응용까지 다양한 교육 컨텐츠를 준비했습니다.<br>R2GATE에 익숙하신 편이신가요?<br class=\'d-lg-none\' /> Professional R2 Planner를 위한 Course 및<br>Qualification test에 도전하셔도 좋습니다.')
              "
            ></p>
            <a @click="alert(localize('준비 중 입니다.'))" class="btn btn-primary">R2 Academy<i class="icon icon-arrow"></i></a>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide main-sect main-play" :key="4">
          <div class="container ani ani-ink-left">
            <div class="main-play-hd">
              <div class="main-play-title">
                <h2 class="fs-3">R2 Playground</h2>
                <p>{{ localize("세대와, 세계를 잇습니다") }}</p>
              </div>
              <p
                class="mx-auto fsmall"
                v-html="
                  localize('“Total health care company”라는 메가젠임플란트의 목표아래, <br>R2 Playground는 과거로 부터 축적되어온 의료 지식과 경험이<br>디지털 도구로 더 발전되어 다음 세대에게 지속될수 있도록 하는,<br>세대와 세계를 잇는 디지털 지식 플랫폼을 지향합니다.')
                "
              ></p>
              <div class="swiper-button-wrap type-line">
                <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div>
              </div>
            </div>
            <swiper
              class="play-swiper overflow-visible"
              :slides-per-view="1.08"
              :space-between="20"
              :grab-cursor="true"
              :modules="[Navigation, Pagination]"
              :navigation="{
                nextEl: '.main-play-hd .swiper-button-next',
                prevEl: '.main-play-hd .swiper-button-prev',
              }"
              :pagination="{
                el: '.play-swiper .swiper-pagination',
                type: 'progressbar',
              }"
              :breakpoints="{
                768: {
                  slidesPerView: 2.2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 60,
                },
              }"
            >
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/play1.png" alt="" /></div>
                  <strong class="fs-4">Share to continue</strong>
                  <p
                    class="card-text"
                    v-html="
                      localize('지식과 경험은 은퇴할수 없습니다.<br>R2 Playground는 당신과 당신의 지식과 경험을 필요로 하는 전세계의 Clinician들을 연결하는 디지털 플랫폼입니다.')
                    "
                  ></p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/play2.png" alt="" /></div>
                  <strong class="fs-4">You are not alone…</strong>
                  <p
                    class="card-text"
                    v-html="
                      localize('전문가가 되기 위해서, 모든 실패를 경험할 필요는 없습니다.<br>R2 Playground에서 다양한 경험을 가진 전문가 혹은 멘토를 찾아 도움을 구해 보세요. R2 Playground는 당신과 세계를 연결합니다.')
                    "
                  ></p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/play3.png" alt="" /></div>
                  <strong class="fs-4">{{ localize("세대와 세계를 잇다") }}</strong>
                  <p
                    class="card-text"
                    v-html="
                      localize('디지털 혁명의 핵심은 커뮤니케이션의 혁신에 있습니다. <br>R2 Playground는 끊임없이 진화하는 디지털 기술과 융합, 보다 직접적이고, 직관적으로 개인과 세계, 세대와 세대를 연결하는 소통의 채널이 될것입니다.')
                    "
                  ></p>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="card">
                  <div class="card-img"><img src="@/assets/home/img/main/play4.png" alt="" /></div>
                  <strong class="fs-4">{{ localize("생각하는 도구로써의 디지털") }}</strong>
                  <p class="card-text">
                    {{
                      localize("디지털 기술은 완전히 새로운 것을 창조하는 것보다는, 이전 세대의 지식과 경험을 정량적으로 축적하고, 보다 많은 사람들에게 공유하며, 계승 발전시키는 도구로서 가치가 있습니다. R2 Playground는 첨단술과 융합을 통해 더 많은 의료 전문가들이 함께 즐길 수 있는 솔루션을 제공합니다.")
                    }}
                  </p>
                </div>
              </swiper-slide>
              <swiper-slide class="swiper-pagination"></swiper-slide>
            </swiper>
          </div>
        </swiper-slide>
        <swiper-slide class="swiper-slide main-sect main-down text-center" :key="5">
          <div class="container">
            <div ref="originRef" class="cube-wrap">
              <div class="cube">
                <div class="front"></div>
                <div class="back"></div>
                <div class="top"></div>
                <div class="btm"></div>
                <div class="left"></div>
                <div class="right"></div>
              </div>
            </div>
            <h2 class="fs-2">
              Let’s Play <br class="d-lg-none" />
              R2 Playground
            </h2>
            <p class="fs-4">{{ localize("치과인의 글로벌 지식 네트워크 R2 Playground,지금 시작해 보세요") }}</p>
            <a v-bind:href="downloadLink" target="_blank"><button class="btn btn-primary">
              {{ localize("Playground 다운로드") }}<i class="icon icon-download-wh"></i>
            </button></a>
          </div>
        </swiper-slide>

        <!-- footer -->
        <swiper-slide :key="6">
          <home-footer />
        </swiper-slide>
        <!-- //footer -->

        <div class="swiper-button-next swiper-button-next"></div>
      </swiper>
      <!-- //페이지 시작 -->
    </main>
    <!-- //container -->

    <div ref="floatRef" id="cube" class="cube-wrap">
      <div class="cube">
        <div class="front"></div>
        <div class="back"></div>
        <div class="top"></div>
        <div class="btm"></div>
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>

    <button class="app-link" title="APP DOwnload">
      <a v-bind:href="downloadLink" target="_blank"><i class="app-link-line"></i></a>
    </button>

    <!-- footer -->

    <!-- //footer -->
  </div>
</template>

<style scoped></style>
