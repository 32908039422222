<script setup lang="ts">
import { onMounted } from "vue";
import axios from "axios";
import csv2json from "csvtojson";
import { useLocalizationStore } from "@/store";
import { localize, getUpperCase } from "@/utils";
import { localizationServer } from "@/configuration";

let { getItems, setItems } = useLocalizationStore();

onMounted(init);

function init() {}
async function getLocalization() {
  try {
    const response = await axios.get("/language.csv");
    console.log("getLocalization", response.data.split("\n").slice(0, 5).join("\n"));
    const jsonData = await csv2json().fromString(response.data);
    setItems(jsonData);
    console.log(getItems());
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
}

async function submit() {
  try {
    const response = await axios.post(`${localizationServer}/extract`);
    console.log(response.data);
    alert("저장하였습니다.");
    getLocalization();
  } catch (error) {
    console.error("Error fetching posts:", error);
  }
}
</script>
<template>
  <div>
    <h1>{{ getUpperCase("Localization") }}</h1>
    <h1>{{ localize("다국어 테스트") }}</h1>
    <table>
      <tr>
        <th>
          key
          <!--                <app-sort [fieldName]="'key'" [items]="items" (itemsChange)="this.items = $event"></app-sort>-->
        </th>
        <th>ko-KR</th>
        <th>en-US</th>
      </tr>
      <tr v-for="(item, index) in getItems()">
        <td class="key">{{ item.key }}</td>
        <td class="key">{{ item["ko-KR"] }}</td>
        <td class="key">{{ item["en-US"] }}</td>
      </tr>
    </table>
  </div>

  <div>
    <button @click="submit">추출 및 저장</button>
  </div>
</template>
<style scoped>
div {
  text-align: center;
}
table {
  margin: 10px;
}
th {
  background: darkgray;
}
th,
td {
  border: solid 1px grey;
  padding: 5px;
}
td {
  text-align: left;
}
.key {
  width: 25%;
}
button {
  padding: 10px 30px;
}
</style>
