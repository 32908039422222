<script setup lang="ts">
import css from "@/assets/playground/css/import.css?inline";

const emit = defineEmits<{
  (eventType: "change-css", style: string): void;
}>();

emit("change-css", css);
</script>
<template>
  <router-view></router-view>
</template>
<style scoped />
