<script setup lang="ts">
import { PartnerConfig } from "@/models/partner";
import { provide, reactive } from "vue";

// localize('My 플래너')
const state = reactive<PartnerConfig>({
  role: "planner",
  name: "Planner",
  myName: "My 플래너",
});

provide("partner-config", state);
</script>

<template>
  <h1 class="hd-title">Planner</h1>
  <router-view></router-view>
</template>

<style scoped></style>
