import { RouteRecordRaw } from "vue-router";
import playerRoutes from "@/router/playground/player";
import plannerRoutes from "@/router/playground/planner";
import r2centerRoutes from "@/router/playground/r2center";
import Player from "@/views/playground/player/Player.vue";
import Planner from "@/views/playground/planner/Planner.vue";
import R2Center from "@/views/playground/r2center/R2Center.vue";
import { requireAuth, correctRole } from "@/services";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player`,
  },
  {
    path: "player",
    component: Player,
    children: playerRoutes,
    meta: {
      role: "player",
    },
  },
  {
    path: "planner",
    component: Planner,
    children: plannerRoutes,
    meta: {
      role: "planner",
    },
    beforeEnter: [requireAuth, correctRole],
  },
  {
    path: "r2center",
    component: R2Center,
    children: r2centerRoutes,
    meta: {
      role: "r2center",
    },
    beforeEnter: [requireAuth, correctRole],
  },
];

export default routes;
