<script setup lang="ts">

</script>

<template>
<h1>Courses</h1>
</template>

<style scoped>

</style>