<script setup lang="ts">
import { reactive, inject } from "vue";
import { deepMerge, localize } from "@/utils";
import { ServiceSettingAddProps } from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAdd.vue";
import { ServiceImage } from "@/models/service-setting";
import { useResourceWrapperStore } from "@/store";
import { FileTransferService } from "@/libs/R2ServiceClient/service/Resource";
import { IUploadedFile } from "@/models";

/**
 * 서비스 등록 상태
 */
const data = inject<ServiceSettingAddProps>("service-setting-add-state")!;
data.step = 2;
const state = reactive({});
/**
 * 이미지 업로드 후 갱신
 * @param event 파일 이벤트
 * @param image 대상 이미지
 */
async function uploadImage(event: Event, image: ServiceImage): Promise<IUploadedFile[] | undefined> {
  // TODO: 파일클라이언트 수정 후 재작업 필요
  const { files } = <HTMLInputElement>event.target;
  if (files != null) {
    return await useResourceWrapperStore().upload(files, {
      successCallback(uploadedFiles) {
        console.log(
          "uploadImage - successCallback: ",
          uploadedFiles.map((info) => `${info.id}/${info.fileName}/${info.fileSize}`).join("\n")
        );
        // 단일 파일이지만 배열로 전달되므로 반복문으로 처리
        for (const uploadedFile of uploadedFiles) {
          deepMerge(image, uploadedFile);
        }
        // state.image = uploadedFiles;
        // state.uploading = false;
        // state.progressFileName = "";
        // state.progressRate = 0;
      },
      progressCallback(progress) {
        console.log("uploadImage - progressCallback: ", progress);
        // state.uploading = true;
        // state.progressFileName = progress.fileName;
        // state.progressRate = progress.progressRate;
      },
    });
  }
}
/**
 * 이미지 제거
 * @param image 이미지 정보
 */
function removeItem(event: Event, image: ServiceImage): void {
  console.log("removeItem");
  event.preventDefault();
}
</script>

<template>
  <div class="fieldset d-block">
    <strong class="fs-sm text-primary">STEP 2</strong>
    <h3 class="fs-2 mt-2 mb-4">이미지 등록</h3>
    <p class="fs-3 mb-2">상품을 가장 잘 나타내는 이미지를 등록해주세요.</p>
    <small class="fs-sm mb-4 d-block">이미지는 최대 5개까지 등록할 수 있습니다.</small>
    <div class="form-group d-flex mb-2">
      <form>
        <div class="thumbnail-file-wrap align-items-start">
          <!-- 이미지 아이템 -->
          <div v-for="item in data.images" class="thumbnail-file">
            <label class="thumbnail-file-label">
              <input type="file" @change="uploadImage($event, item)" accept="image/*" class="sr-only" />
            </label>
            <span v-if="item.id">
              <img :src="item.id ? FileTransferService.getDownloadUri(item.id) : ''" alt="" />
              <button @click="removeItem($event, item)" type="button" class="icon icon-x">삭제</button>
            </span>
          </div>
          <!-- //이미지 아이템 -->
        </div>
      </form>
      <div class="thumbnail-file-wrap flex-column text-center ml-5">
        <div class="thumbnail-file">
          <label class="thumbnail-file-label"
            ><input type="file" @change="uploadImage($event, { id: null, image: null })" class="sr-only"
          /></label>
          <img src="@/assets/playground/img/_temp/img1.jpg" alt="" />
          <button type="button" class="icon icon-x">삭제</button>
        </div>
        <p class="fs-sm">기본 이미지</p>
      </div>
    </div>
    <p class="form-text font-weight-bold">
      용량 5MB 이하의 jpg, png 파일로 500*350 사이즈를 권장합니다.<br />어떤 이미지도 등록하지 않을 경우, 메가젠에서
      지정한 기본 이미지로 등록됩니다.
    </p>
  </div>
</template>

<style scoped></style>
