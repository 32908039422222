<script setup lang="ts">
import { useHead } from "@vueuse/head";
import { reactive } from "vue";
import css from "@/assets/playground/css/import.css?inline";

const emit = defineEmits<{
  (eventType: "change-css", style: string): void;
}>();

emit("change-css", css);

useHead({
  title: "R2 Playground",
  meta: [{ name: "viewport", content: "width=1600" }],
});
</script>

<template>
  <router-view></router-view>
</template>

<style scoped></style>
