<script setup lang="ts">
import { nextTick, ref, watch } from "vue";
import { localize } from "@/utils";
import { IListState } from "@/models/i-list-state.ts";

const keywordRef = ref(null);

const props = defineProps({
  state: { type: Object as () => IListState, default: {} },
  showReset: { type: Boolean, default: false },
});
// 검색 이벤트
const emit = defineEmits<{
  (eventType: "search"): void;
  (eventType: "reset"): void;
}>();

/**
 * 검색. 현재 사용안함. 부트스트랩에서 기본으로 서브밋 이벤트가 발생하는 것이 의심 됨.
 * @param event
 */
function search(event: Event): void {
  event.preventDefault();
  emit("search");
}
// function updateKeyword(event: Event): void {
//   const newValue = (event.target as HTMLInputElement).value;
//   console.log("updateKeyword", newValue)
//   props.state.keyword = newValue;
// }
/**
 * 조건 초기화
 * @param event
 */
function resetKeyword(event: Event): void {
  props.state.keyword = "";
  // emit("reset");
}
</script>

<template>
  <div class="search-control">
    <input ref="keywordRef" type="text" v-bind="$attrs" v-model="state.keyword" @keyup.enter="search" />
    <button type="submit" class="icon icon-srch" @click="search">{{ localize("검색") }}</button>
  </div>
  <button v-if="showReset" type="reset" @click="resetKeyword" class="search-reset">{{ localize("초기화") }}</button>
</template>

<style scoped></style>
