<script setup lang="ts">
import { localize } from "@/utils";
import BaseChange from "@/views/home/password/BaseChange.vue";
import { useMemberStore } from "@/store";
import { reactive } from "vue";

const state = reactive({
  completed: false,
});

function done(password: string): void {
  state.completed = true;
}
</script>

<template>
  <div class="join-box login-box">
    <div class="step-wrap">
      <img src="@/assets/home/img/logo.svg" alt="" class="mb-4" />
      <h2>{{ localize("비밀번호 변경 안내") }}</h2>
    </div>
    <div v-if="!state.completed" class="bx-purple py-4">
      <b>{{ localize("안녕하세요") }}, </b
      ><strong class="mt-2 text-purple"
        ><b>{{ useMemberStore().getMember().emailAddress }}</b
        >{{ localize("님") }}</strong
      ><br />
      {{ localize("개인정보 보호를 위해 안전한 비밀번호로 변경을 부탁드립니다.") }}<br />
      <b>{{ localize("비밀번호 변경 완료 시 서비스 이용 가능합니다.") }}</b>
    </div>
    <base-change :completed="state.completed" @submit="done" />
  </div>
</template>

<style scoped></style>
