import { defineStore } from "pinia";
import { ExtendedMember } from "./member";

export const useSessionStore = defineStore("session", () => {
  /**
   * 사용자 정보 세션 키
   */
  const memberKey = "member";

  /**
   * 사용자 정보 읽어오기
   * @returns 사용자 정보
   */
  function getMember(): ExtendedMember | null {
    const json = window.sessionStorage.getItem(memberKey);
    const member = json ? (JSON.parse(json) as ExtendedMember) : null;
    return member;
  }
  /**
   * 사용자 정보 설정
   * @param member 사용자 정보
   */
  function setMember(member: ExtendedMember): void {
    window.sessionStorage.setItem(memberKey, JSON.stringify(member));
  }
  /**
   * 사용자 정보 삭제
   */
  function removeMember(): void {
    window.sessionStorage.removeItem(memberKey);
  }

  return { getMember, setMember, removeMember };
});
