<script setup lang="ts">
import { useTroubleShootingStore } from "@/store";
import { onMounted } from "vue";

onMounted(() => {
  // 저장 data 초기화
  useTroubleShootingStore().initToubleShooting();
});
</script>

<template>
  <h1 class="hd-title">Trouble Shooting</h1>
  <router-view></router-view>
</template>

<style scoped></style>
