<script setup lang="ts">

</script>

<template>
  <small class="hd-title-sm">Partner</small>
  <router-view></router-view>
</template>

<style scoped>

</style>