<script setup lang="ts">
import { localize } from "@/utils";
import SearchKeyword from "@/components/SearchKeyword.vue";
import { reactive } from "vue";
import Pagenation from "@/components/Pagenation.vue";
import { useClientWrapperStore } from "@/store";
import { TroubleshootingProgressStatus } from "@/models";
import { IListState } from "@/models/i-list-state.ts";

const state: IListState = reactive({
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
});
request();

function request(page: number = 1): void {
  console.log("request", state.keyword, state.page);
  const variables = {
    condition: {
      fields: [
        {
          name: "keyword",
          value: state.keyword,
        },
      ],
      orderBy: {
        field: "registerDate",
        orderBy: "DESC",
      },
    },
    page: page,
  };
  const query = `
    query GetTroubleShootings($condition: SearchConditionInput, $page: Int) {
      troubleshootings(condition: $condition, page: $page) {
          data {
          category {
            name
            description
          }
          id
          title
          status
          registerDate
        }
        paging {
          totalItems
          currentPage
        }
      }
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("successCallback", result.troubleshootings);
  //       const paging = result.troubleshootings.paging;
  //       state.page = paging.currentPage;
  //       state.totalRows = paging.totalItems;
  //       state.items = result.troubleshootings.data;
  //     },
  //     // finallyCallback: () => {
  //     //   alert("완료 후 공통 처리");
  //     // }
  //   }
  // );
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="tb-hd">
      <p>{{ localize("문의 내역") }}</p>
      <form @submit.prevent="request()">
        <search-keyword :state="state" :placeholder="localize('검색어를 입력해 주세요')" />
      </form>
    </div>
    <div class="content">
      <table class="tb">
        <colgroup>
          <col style="width: 6%" />
          <col style="width: 11%" />
          <col />
          <col style="width: 11%" span="2" />
        </colgroup>
        <thead>
          <tr>
            <th>NO</th>
            <th>{{ localize("문의 유형") }}</th>
            <th>{{ localize("제목") }}</th>
            <th>{{ localize("등록일") }}</th>
            <th>{{ localize("처리 상태") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in state.items">
            <td>{{ item.id }}</td>
            <td>{{ item.category.name }}</td>
            <td class="text-left">
              <router-link :to="`view/${item.id}`" class="tb-title">{{ item.title }}</router-link>
            </td>
            <td>{{ item.registerDate }}</td>
            <td v-if="item.status === TroubleshootingProgressStatus.Resolved">
              <span class="badge bg-grn">{{ localize("완료") }}</span>
            </td>
            <td v-if="item.status === TroubleshootingProgressStatus.Open">
              <span class="badge bg-gray">{{ localize("접수") }}</span>
            </td>
            <td v-if="item.status === TroubleshootingProgressStatus.InProgress">
              <span class="badge bg-ylw">{{ localize("처리중") }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="list-bottom">
        <router-link to="save" class="btn">{{ localize("문의하기") }}</router-link>
        <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
