<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { localize } from "@/utils";
import { LocaleType, useLocalizationStore, useNationStore } from "@/store";
import { menuLanguages } from "@/configuration";

const { initNation } = useNationStore();
const { getLanguage, setLanguage } = useLocalizationStore();

/**
 * 언어 변경
 * @param locale
 */
function changeLocale(locale: LocaleType): void {
  setLanguage(locale);
  initNation(locale);
  // location.reload();
}
</script>

<template>
  <div class="hd-side">
    <!-- 언어 변경 -->
    <div class="dropdown">
      <button type="button" class="dropdown-toggle" data-bs-toggle="dropdown">
        {{ menuLanguages.find((item) => item.value == getLanguage())?.label }}
      </button>
      <ul class="dropdown-menu">
        <li v-for="item in menuLanguages">
          <a @click="changeLocale(item.value as LocaleType)">{{ item.label }}</a>
        </li>
      </ul>
    </div>
    <!-- //언어 변경 -->

    <!-- help -->
    <a href="" class="btn-help">help</a>

    <div class="hd-side-personal">
      <!-- 채팅 -->
      <a href="" class="icon icon-chat">{{ localize("채팅") }}</a>

      <!-- 알람 -->
      <div class="dropdown">
        <button type="button" data-toggle="dropdown">
          <i class="icon icon-alarm">{{ localize("알람") }}</i>
          <span class="num"></span>
        </button>
        <div class="dropdown-menu"></div>
      </div>
      <!-- //알람 -->

      <!-- 마이페이지 -->
      <div class="dropdown">
        <button type="button" class="icon icon-my" data-toggle="dropdown">{{ localize("마이페이지") }}</button>
        <div class="dropdown-menu"></div>
      </div>
      <!-- //마이페이지 -->
    </div>
  </div>
</template>
<style scoped></style>
