<script setup lang="ts">
</script>

<template>
  <div class="modal-backdrop show">
    <div style="display: block"
         class="modal show" role="dialog">
      <div class="modal-dialog modal-dialog-centered">
        <div class="loading">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>