import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import { requireAuth } from "@/services";
import Home from "@/views/academy/certification/qualifying-test/test/TestHome.vue";
import WrittenTest from "@/views/academy/certification/qualifying-test/test/WrittenTest.vue";
import Checking from "@/views/academy/certification/qualifying-test/test/Checking.vue";
import Result from "@/views/academy/certification/qualifying-test/test/Result.vue";
import PracticeTest from "@/views/academy/certification/qualifying-test/test/PracticeTest.vue";

export function enableUser(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  // TODO: 시험 시작 시도시 권한 확인
  let condition = false;
  if (condition) {
    // TODO: alert
    // notify({
    //     group: "error",
    //     type: "error",
    //     text: "권한이 없습니다.",
    // });
    next({ path: from.path });
  } else {
    next();
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: "home",
    name: "Home",
    component: Home,
    meta: {
      level: 1,
    },
    beforeEnter: [requireAuth, enableUser],
  },
  {
    path: "written-test/:index",
    name: "WrittenTest",
    component: WrittenTest,
    meta: {
      level: 1,
    },
    beforeEnter: [requireAuth, enableUser],
  },
  {
    path: "practice-test/:index",
    name: "PracticeTest",
    component: PracticeTest,
    meta: {
      level: 1,
    },
    beforeEnter: [requireAuth, enableUser],
  },
  {
    path: "checking",
    name: "Checking",
    component: Checking,
    meta: {
      level: 1,
    },
    beforeEnter: [requireAuth, enableUser],
  },
  {
    path: "result",
    name: "Result",
    component: Result,
    meta: {
      level: 1,
    },
    beforeEnter: [requireAuth, enableUser],
  },
];

export default routes;
