<script setup lang="ts">
import { reactive, ref } from "vue";
import { localize } from "@/utils";
import { IBaseMenuItem, IListState } from "@/models";
import TabMenu from "@/components/TabMenu.vue";
import { confirm } from "@/services";
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import R2Dialog from "@/components/R2Dialog.vue";

interface IMember {
  id: string;
  emailAddress: string;
  name: string;
  authorities: string[];
  date: string;
}

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
});

/**
 * 팝업창 참조
 */
const editDialogRef = ref<InstanceType<typeof R2Dialog> | null>(null);

const state: IListState = reactive({
  page: 1,
  totalRows: 1,
  keyword: "",
  items: <IMember[]>[],
  editItem: null as IMember | null,
});

request();

/**
 * 구성원 목록 요청
 * @param page 페이지 번호
 */
function request(page: number = 1): void {
  console.log("request", state.keyword, page);
  state.items = [
    { id: "1", emailAddress: "test1@test.com", name: "김구성", authorities: ["정산", "작업"], date: "2024.12.31" },
    { id: "2", emailAddress: "test2@test.com", name: "이칠성", authorities: ["정산"], date: "2024.02.01" },
    { id: "3", emailAddress: "test3@test.com", name: "박삼성", authorities: ["작업"], date: "2024.03.21" },
  ];
}
/**
 * 구성원 편집
 * @param item 편집할 구성원 정보
 */
async function editMember(item: IMember) {
  console.log("editMember", item.id);
  state.editItem = item;
  editDialogRef.value?.open();
}
/**
 * 구성원 삭제
 * @param item 삭제할 구성원 정보
 */
async function removeMember(item: IMember) {
  console.log("removeMember", item.id);
  if (await confirm("센터 구성원을 삭제하시겠습니까?")) {
    // TODO: remove member
  }
}
/**
 * 편집 팝업창 닫기 핸들러
 * @param result 실행 여부
 */
function onEditClose(result: boolean) {
  console.log("onEditClose", result);
  if (result) {
    alert("저장되었습니다.");
  }
  state.editItem = null;
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <ul class="nav nav-tabs-fluid v2">
      <tab-menu :items="menuItems" />
    </ul>
    <div class="content">
      <div class="tb-hd align-items-start mb-0">
        <div class="content-hd flex-column">
          <h3 class="fs-2">정산 계좌</h3>
          <p>정산금을 지급받을 은행 계정 정보를 확인하실 수 있습니다.</p>
        </div>
        <form @submit.prevent="request()">
          <search-keyword :state="state" :placeholder="localize('검색어를 입력해 주세요')" />
        </form>
      </div>
      <table class="tb">
        <colgroup>
          <col style="width: 25%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col style="width: 20%" />
          <col />
        </colgroup>
        <thead>
          <tr>
            <th>ID</th>
            <th>이름</th>
            <th>권한</th>
            <th>등록일</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in state.items">
            <th>{{ item.emailAddress }}</th>
            <td>{{ item.name }}</td>
            <td>
              <span
                v-for="authority in item.authorities"
                class="badge"
                :class="{ 'bg-blue': authority == '정산', 'bg-ylw': authority == '작업' }"
                >{{ authority }}</span
              >
              <span class="badge bg-ylw">작업</span>
            </td>
            <td>{{ item.date }}</td>
            <td>
              <button @click="editMember(item)" class="icon icon-setting">설정</button>
              <button @click="removeMember(item)" class="icon icon-delete" data-toggle="modal" data-target="#delete">
                삭제
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
  </div>

  <!-- modal -->
  <r2-dialog
    ref="editDialogRef"
    id="set-member"
    modal-class="modal-md"
    :is-confirm="true"
    :cancel-label="localize('취소')"
    :ok-label="localize('저장')"
    @close="onEditClose"
  >
    <div class="modal-body text-center px-2">
      <p class="fs-2">센터 구성원 설정</p>
      <span class="fs-sm">센터 구성원의 권한을 설정하세요.</span>
      <div class="box box-outline mx-4 mt-3 p-4">
        <div class="row text-left">
          <b class="col-3">ID</b>
          <span class="col">{{ state.editItem.emailAddress }}</span>
        </div>
        <div class="row mt-3 text-left">
          <b class="col-3">이름</b>
          <span class="col">{{ state.editItem.name }}</span>
        </div>
        <div class="row mt-3 text-left">
          <b class="col-3">생년월일</b>
          <span class="col">1900.00.00</span>
        </div>
        <div class="row mt-3 text-left">
          <b class="col-3">성별</b>
          <span class="col">남성</span>
        </div>
        <div class="row mt-3 text-left">
          <b class="col-3">전화번호</b>
          <span class="col">010-1234-1234</span>
        </div>
        <div class="row mt-3 text-left">
          <b class="col-3">이름</b>
          <div class="col">
            <label class="form-check mr-3">
              <input type="radio" class="sr-only" name="authority" />
              <span class="check">정산</span>
            </label>
            <label class="form-check">
              <input type="radio" class="sr-only" name="authority" />
              <span class="check">작업</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </r2-dialog>
</template>

<style scoped></style>
