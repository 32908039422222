<script setup lang="ts">
import AuthCodeInput from "@/components/AuthCodeInput.vue";
import { graphql } from "@/libs/R2ServiceClient/service/Visitor";
import { alert, useFormBuilder } from "@/services";
import { useClientWrapperStore } from "@/store";
import { localize, Validators } from "@/utils";
import BaseChange from "@/views/home/password/BaseChange.vue";
import { computed, onMounted, reactive, ref } from "vue";

/**
 * 인증코드 입력 컴포넌트 참조
 */
const authCodeInputRef = ref<InstanceType<typeof AuthCodeInput> | null>(null);

const state = reactive({
  /**
   * 인증 요청 아이디
   */
  verificationRequestId: "",
  /**
   * 정상 계정 확인 여부
   */
  correctAccount: false,
  /**
   * 현재 단계
   */
  step: 0,
  /**
   * 완료 여부
   */
  completed: false,
});

/**
 * 인증코드 만료 시간
 */
const expiredDate = ref<Date>();
/**
 * 인증코드 만료 시간 간격
 */
const expiredSeconds = 3 * 60;
/**
 * 정상 계정 확인 가능 여부
 */
const enabledCheck = computed(() => valid("firstName") && valid("lastName") && valid("email"));
/**
 * 이메일 인증 가능한 상태 여부
 * */
const enabledAuth = computed(() => state.correctAccount && valid("email"));

/**
 * 폼 빌더
 */
const formBuilder = useFormBuilder();
const { controls, errors, group, touch, validate, valid, invalid } = formBuilder;
group({
  /**
   * 이름
   */
  firstName: { touched: false, value: "", validators: [Validators.required] },
  /**
   * 성
   */
  lastName: { touched: false, value: "", validators: [Validators.required] },
  /**
   * 이메일
   */
  email: {
    touched: false,
    value: "",
    validators: [Validators.required, Validators.email],
  },
  code: {
    touched: false,
    value: "",
    validators: [Validators.required, Validators.minLength(6), Validators.expired(expiredDate)],
  },
});

onMounted(init);

/**
 * 초기화
 */
function init() {
  validate();
}
/**
 * 비밀번호 설정 가능 확인
 */
async function checkAccount(event: Event): Promise<void> {
  console.log("checkAccount - enabledCheck: ", enabledCheck.value);
  if (!enabledCheck.value) return;
  const CheckLoginPasswordResetRequester = graphql(`
    query CheckLoginPasswordResetRequester($input: CheckLoginPasswordResetRequesterInput!) {
      checkLoginPasswordResetRequester(input: $input)
    }
  `);
  const result = await useClientWrapperStore().visitor.typedQuery(CheckLoginPasswordResetRequester, {
    variables: {
      input: {
        firstName: controls.firstName.value,
        lastName: controls.lastName.value,
        loginId: controls.email.value,
      },
    },
    successCallback: (result) => {
      console.log("checkAccount - successCallback: ", result);
      if (result.checkLoginPasswordResetRequester) {
        state.step = 1;
        sendEmail();
      } else {
        alert(localize('회원 정보가 일치하지 않습니다.'));
      }
    },
  });
  event.preventDefault();
}
/**
 * 인증 메일 발송
 */
async function sendEmail(event?: Event): Promise<void> {
  const CreateUserVerification = graphql(`
    mutation CreateUserVerificationRequest($input: CreateVerificationRequestInput!) {
      createUserVerificationRequest(input: $input) {
        id
        method
        emailAddress
        requestedAt
        expiredAt
      }
    }
  `);
  const result = await useClientWrapperStore().visitor.typedMutate(CreateUserVerification, {
    variables: {
      input: {
        emailAddress: controls.email.value,
        method: "EMAIL",
      },
    },
    successCallback: (result) => {
      console.log("sendMail - successCallback: ", result);
      state.verificationRequestId = result.createUserVerificationRequest.id;
      console.log("verificationRequestId: ", state.verificationRequestId, "email: ", controls.email.value);
      controls.code.value = "";
      const now = new Date();
      now.setSeconds(now.getSeconds() + expiredSeconds);
      expiredDate.value = now;
      authCodeInputRef.value?.reset();
    },
  });
}

/**
 * 인증 코드 검사 핸들러
 */
async function checkAuthCode(event: Event): Promise<void> {
  // console.log(errors, valid());
  validate(true);
  if (valid()) {
    const VerifyVerificationCode = graphql(`
      mutation VerifyVerificationCode($input: VerifyVerificationCodeInput!) {
        verifyVerificationCode(input: $input) {
          verificationRequestId
          isVerified
        }
      }
    `);
    console.log("done - verificationRequestId: ", state.verificationRequestId);
    const result = await useClientWrapperStore().visitor.typedMutate(VerifyVerificationCode, {
      variables: {
        input: {
          verificationRequestId: state.verificationRequestId,
          verificationCode: controls.code.value,
        },
      },
      successCallback: (result) => {
        console.log("done - successCallback: ", result);
        const success = result.verifyVerificationCode.isVerified;
        console.log("success: ", success);
        if (!success) {
          alert("인증코드가 올바르지 않습니다.");
          return;
        }
        state.step = 2;
      },
    });
  }
  event.preventDefault();
}
/**
 * 완료 핸들러.
 * 새 비밀번호로 변경한다.
 */
async function done(password: string) {
  console.log(
    "newPassword: ",
    password,
    "verificationId: ",
    state.verificationRequestId,
    "verificationCode: ",
    controls.code.value,
    "loginId: ",
    controls.email.value
  );
  const ResetLoginPassword = graphql(`
    mutation ResetLoginPassword($input: ResetLoginPasswordInput!) {
      resetLoginPassword(input: $input) {
        isSuccess
      }
    }
  `);
  const response = await useClientWrapperStore().visitor.typedMutate(ResetLoginPassword, {
    variables: {
      input: {
        verificationId: state.verificationRequestId,
        verificationCode: controls.code.value,
        loginId: controls.email.value,
        newPassword: password,
      },
    },
  });
  if (response.isSuccess && response.data?.resetLoginPassword.isSuccess) {
    state.completed = true;
  }
}
</script>

<template>
  <div class="join-box login-box">
    <div class="step-wrap">
      <img src="@/assets/home/img/logo.svg" alt="" class="mb-4" />
      <h2>{{ localize("비밀번호 찾기") }}</h2>
    </div>

    <!-- step 0 -->
    <template v-if="state.step == 0">
      <div class="bx-purple py-4">
        <img src="@/assets/home/img/alert-circle.png" alt="" />
        <b class="d-block mt-2 text-purple">{{
          localize("먼저 회원 가입 정보를 입력하고 본인 확인을 진행해주세요.")
        }}</b>
      </div>
      <form @submit.prevent="checkAccount" class="join-form mh-0">
        <!-- //성명-->
        <div class="d-flex">
          <!-- 이름-->
          <div class="form-group">
            <label class="form-label" for="first-name">{{ localize("이름2") }}</label>
            <div class="input-group">
              <input
                type="text"
                id="first-name"
                class="form-control curved"
                :class="{
                  'is-invalid': controls.firstName.touched && invalid('firstName'),
                }"
                v-model="controls.firstName.value"
                @focusout="touch('firstName')"
                :placeholder="localize('이름2')"
              />

              <!--    이름 에러 메시지-->
              <div v-show="controls.firstName.touched && errors?.firstName?.required" class="invalid-feedback">
                * {{ localize("이름을 입력하세요.") }}
              </div>
            </div>
          </div>
          <!-- //이름-->
          <!-- 성-->
          <div class="form-group">
            <label class="form-label" for="last-name">{{ localize("성") }}</label>
            <div class="input-group">
              <input
                type="text"
                id="last-name"
                class="form-control curved"
                :class="{
                  'is-invalid': controls.lastName.touched && invalid('lastName'),
                }"
                v-model="controls.lastName.value"
                @focusout="touch('lastName')"
                :placeholder="localize('성')"
              />

              <!--    성 에러 메시지-->
              <div v-show="controls.lastName.touched && errors?.lastName?.required" class="invalid-feedback">
                * {{ localize("성을 입력하세요.") }}
              </div>
            </div>
          </div>
          <!-- //성-->
        </div>
        <!-- //성명-->
        <!-- 이메일-->
        <div class="form-group">
          <label class="form-label" for="email">{{ localize("이메일") }}</label>
          <div class="input-group">
            <input
              type="email"
              id="email"
              class="form-control curved"
              :class="{
                'is-invalid': controls.email.touched && invalid('email'),
              }"
              v-model="controls.email.value"
              @focusout="touch('email')"
              @keyup.enter="checkAccount"
              :placeholder="localize('회원 가입시 등록한 이메일 입력')"
            />

            <!--    이메일 에러 메시지-->
            <div v-show="controls.email.touched && errors?.email?.required" class="invalid-feedback">
              * {{ localize("이메일을 입력하세요.") }}
            </div>
            <div v-show="controls.email.touched && errors?.email?.email" class="invalid-feedback">
              * {{ localize("올바른 이메일을 입력하세요.") }}
            </div>
          </div>
        </div>
        <!-- //이메일-->
      </form>
      <div class="btn-wrap">
        <a :class="{ disabled: !enabledCheck }" @click="checkAccount" class="btn btn-primary center w-100">{{
          localize("다음으로")
        }}</a>
      </div>
    </template>
    <!-- //step 0 -->

    <!-- step 1 -->
    <template v-if="state.step == 1">
      <div class="bx-purple py-4">
        <img src="@/assets/home/img/alert-circle.png" alt="" />
        <strong class="d-block mt-2 text-purple"
          ><b>{{ controls.email.value }}</b
          >{{ localize("님") }}</strong
        >
        <b>{{ localize("발송된 인증 메일을 확인하시고, 이메일 인증을 완료하세요.") }}</b>
      </div>
      <form @submit.prevent="checkAuthCode" class="join-form mh-0">
        <a href="#" @click="sendEmail" class="btn btn-square center w-100">{{ localize("인증 메일 재발송하기") }}</a>
        <template v-if="state.verificationRequestId.length > 0">
          <!-- 인증 코드 입력-->
          <auth-code-input ref="authCodeInputRef" :form-builder="formBuilder" @submit="checkAuthCode"></auth-code-input>
          <!-- //인증 코드 입력-->
        </template>
      </form>
      <div class="btn-wrap">
        <div class="btn-wrap">
          <a :class="{ disabled: invalid() }" @click="checkAuthCode" class="btn btn-primary center w-100">{{
            localize("다음으로")
          }}</a>
        </div>
      </div>
    </template>
    <!-- //step 1 -->

    <!-- step 2 -->
    <template v-if="state.step == 2">
      <div v-if="!state.completed" class="bx-purple py-4">
        <img src="@/assets/home/img/alert-circle.png" alt="" />
        <b
          class="d-block mt-2"
          v-html="localize('개인정보 보호를 위해 <span class=\'text-purple\'>안전한 비밀번호로 변경</span>을 부탁드립니다.')"
        ></b>
      </div>
      <base-change :completed="state.completed" @submit="done" />
    </template>
    <!-- //step 2 -->
  </div>
</template>

<style scoped></style>
