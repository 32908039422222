<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { localize } from "@/utils";
import Chart from "chart.js/auto";
import TabMenu from "@/components/TabMenu.vue";
import R2Dialog from "@/components/R2Dialog.vue";
import { IBaseMenuItem } from "@/models";

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
});

/**
 * 차트 참조
 */
const chartRef = ref<HTMLCanvasElement | null>(null);
/**
 * 팝업창 참조
 */
const detailDialogRef = ref<InstanceType<typeof R2Dialog> | null>(null);

const state = reactive({
  chart: {
    // TODO: 데이터 구조에 따라 변경 필요. 예) [{ label: "1월", data: 580 }, ...]. map으로 변경
    labels: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월"],
    data: [580, 490, 600, 580, 600, 440, 580, 600, 560],
  },
});

onMounted(() => {
  initChart();
});

/**
 * 차트 초기화
 */
function initChart() {
  const ctx = chartRef.value?.getContext("2d");
  if (!ctx) {
    console.error("ctx is null");
    return;
  }
  const { labels, data } = state.chart;

  new Chart(ctx, {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          backgroundColor: "#F87C27",
          data: data,
          barThickness: 32,
          borderWidth: 1,
          borderRadius: 8,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            color: "transparent",
          },
        },
        y: {
          beginAtZero: false,
          min: 350,
          grid: {
            tickBorderDash: [4],
          },
        },
      },
    },
  });
}
/**
 * 통계 전체보기
 * @param serviceId 서비스 ID
 */
async function openDetail(serviceId: string) {
  console.log("openDetail - serviceId:", serviceId);
  // TODO: 서비스 ID에 따른 데이터 요청
  detailDialogRef.value?.open();
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="d-flex align-items-center justify-content-between">
      <ul class="nav nav-tabs-fluid v2 mb-0">
        <tab-menu :items="menuItems" />
      </ul>
      <p class="info-txt">서비스 상품 등록 및 수정은 [Order Management] → [서비스 상품 관리]에서 하실 수 있습니다</p>
    </div>
    <div class="row">
      <div class="col-4">
        <p class="fs-2 mt-5 mb-3">자주 찾는 주문자</p>
        <div class="box bg-white box-shadow p-4">
          <div class="d-flex align-items-center justify-content-between">
            <p class="fs-3 mr-3">랭킹 TOP 5</p>
            <p @click="openDetail('serviceId1')" class="link-only flex-center text-primary fs-sm font-weight-bold">
              통계 전체보기<i class="icon icon-arrow orange ml-1"></i>
            </p>
          </div>
          <div class="best-ranker box mt-3 d-flex justify-content-between align-items-center">
            <div class="ranker-profile d-flex align-items-center">
              <div class="profile-img mr-3">
                <img src="@/assets/playground/img/_temp/tb-prof.jpg" alt="" />
              </div>
              <div>
                <span class="badge mb-1">Best</span>
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm">Unju Dental Care Service</b>
              </div>
            </div>
            <div class="count flex-center"><b>126</b>건</div>
          </div>
          <div class="ranker-wrap">
            <div class="fieldset d-block mt-2">
              <div class="box bg-white d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                  <div class="rank-circle mr-2" data-rank="1">1</div>
                  <div>
                    <b class="d-block fs-4">Dan Bi Moon</b>
                    <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                    <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
                  </div>
                </div>
                <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
              </div>
            </div>
            <div class="fieldset d-block pt-0 mt-0">
              <div class="box bg-white d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                  <div class="rank-circle mr-2" data-rank="2">2</div>
                  <div>
                    <b class="d-block fs-4">Dan Bi Moon</b>
                    <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                    <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
                  </div>
                </div>
                <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
              </div>
            </div>
            <div class="fieldset d-block pt-0 mt-0">
              <div class="box bg-white d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                  <div class="rank-circle mr-2" data-rank="3">3</div>
                  <div>
                    <b class="d-block fs-4">Dan Bi Moon</b>
                    <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                    <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
                  </div>
                </div>
                <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
              </div>
            </div>
            <div class="fieldset d-block pt-0 mt-0">
              <div class="box bg-white d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                  <div class="rank-circle mr-2" data-rank="4">4</div>
                  <div>
                    <b class="d-block fs-4">Dan Bi Moon</b>
                    <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                    <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
                  </div>
                </div>
                <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <p class="fs-2 mt-5 mb-3">소비자 평가</p>
        <div class="box bg-white box-shadow p-4">
          <div class="box py-2">
            <div class="rating-wrap">
              <div class="rating-total">
                <div class="num">
                  <span>4.9</span>
                  <small>/</small>
                  <span>5.0</span>
                </div>
                <div class="rating">
                  <i class="icon icon-star on"></i>
                  <i class="icon icon-star on"></i>
                  <i class="icon icon-star on"></i>
                  <i class="icon icon-star on"></i>
                  <i class="icon icon-star on"></i>
                </div>
              </div>
              <div class="rating-progress">
                <div class="flex-center">
                  <strong>정확성</strong>
                  <div class="meta"><i class="icon icon-star">평점</i>4.9</div>
                  <div class="progress"><div class="progress-bar" style="width: 98%"></div></div>
                </div>
                <div class="flex-center">
                  <strong>신속성</strong>
                  <div class="meta"><i class="icon icon-star">평점</i>4.9</div>
                  <div class="progress"><div class="progress-bar" style="width: 98%"></div></div>
                </div>
                <div class="flex-center">
                  <strong>응대성</strong>
                  <div class="meta"><i class="icon icon-star">평점</i>4.9</div>
                  <div class="progress"><div class="progress-bar" style="width: 98%"></div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mt-4">
            <div class="d-flex justify-content-between align-items-center mb-4">
              <div class="d-flex align-items-center">
                <p class="fs-3 mr-3">월별 매출 통계</p>
                <p class="flex-center text-primary fs-sm font-weight-bold">
                  리뷰 보러가기<i class="icon icon-arrow orange ml-1"></i>
                </p>
              </div>
              <div class="flex-center">
                <input class="form-control" type="date" />
                <span class="px-1">-</span>
                <input class="form-control" type="date" />
              </div>
            </div>
            <canvas ref="chartRef" id="barChart" style="width: 100%; height: 260px"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- modal -->
  <r2-dialog ref="detailDialogRef" id="orderfrequency-modal" modal-class="modal-md" :no-button="true">
    <div class="text-center">
      <p class="fs-2 mb-3">자주 찾는 주문자</p>
      <div class="content">
        <div class="fieldset d-block mt-2">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="1">1</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
        <div class="fieldset d-block pt-0 mt-0">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="2">2</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
        <div class="fieldset d-block pt-0 mt-0">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="3">3</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
        <div class="fieldset d-block pt-0 mt-0">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="4">4</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
        <div class="fieldset d-block pt-0 mt-0">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="5">5</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
        <div class="fieldset d-block pt-0 mt-0">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="6">6</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
        <div class="fieldset d-block pt-0 mt-0">
          <div class="box bg-white d-flex align-items-center justify-content-between p-3">
            <div class="d-flex align-items-center">
              <div class="rank-circle mr-2" data-rank="7">7</div>
              <div class="text-left">
                <b class="d-block fs-4">Dan Bi Moon</b>
                <b class="d-block fs-sm text-gray">Unju Dental Care Service</b>
                <strong class="fs-xs text-muted">Frequency ordered : R2 Guide Solution</strong>
              </div>
            </div>
            <div class="count flex-center fs-sm font-weight-bold"><b class="fs-2 font-poppins pr-1">126</b> 건</div>
          </div>
        </div>
      </div>
    </div>
  </r2-dialog>
  <!-- //modal -->
</template>

<style scoped></style>
