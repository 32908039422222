<script setup lang="ts">
import { inject, reactive } from "vue";
import { useRoute } from "vue-router";
import TabMenu from "@/components/TabMenu.vue";
import { localize } from "@/utils";
import { PartnerConfig } from "@/models/partner";

const route = useRoute();

const { role, name, myName } = inject("partner-config") as PartnerConfig;

// localize('소개')
// localize('받은 리뷰')
// localize('제공 서비스')
const state = reactive({
  menuItems: [
    { name: "소개", link: "about" },
    { name: "받은 리뷰", link: "review" },
    { name: "제공 서비스", link: "service" },
    { name: myName, link: "my" },
  ],
  current: route.path.split("/").slice().pop(),
});
</script>

<template>
  <router-link to="../list" class="btn-back"
    ><i class="icon icon-back">{{ localize("뒤로") }}</i></router-link
  >
  <div class="profile-detail">
    <div
      class="profile-detail-bg"
      style="background-image: url('/src/assets/playground/img/_temp/profile-bg.jpg')"
    ></div>
    <div class="media">
      <div class="profile-thumb">
        <img src="@/assets/playground/img/_temp/profile.jpg" alt="" />
      </div>
      <div class="media-body">
        <div>
          <strong class="fs-1">언주플래너</strong>
          <div class="meta mt-2">
            <i class="icon icon-star">{{ localize("평점") }}</i
            >4.9 / 5.0 <i class="icon icon-heart active">{{ localize("찜") }}</i
            >300
          </div>
        </div>
        <button type="button" class="btn btn-outline">My {{ name }} +<i class="icon icon-heart ml-2"></i></button>
      </div>
    </div>
  </div>
  <ul class="nav nav-tabs-fluid">
    <tab-menu :items="state.menuItems" />
  </ul>
</template>

<style scoped></style>
