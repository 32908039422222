<script setup lang="ts">
import { localize } from "@/utils";
import { reactive } from "vue";
import Pagenation from "@/components/Pagenation.vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import TabMenu from "@/components/TabMenu.vue";
import { IBaseMenuItem } from "@/models";
import { IListState } from "@/models/i-list-state.ts";

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
});

const state: IListState = reactive({
  page: 1,
  totalRows: 0,
  keyword: "",
  items: [],
});
request();

function request(): void {
  console.log("request", state.keyword, state.page);
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="tb-hd">
      <ul class="nav nav-tabs">
        <tab-menu :items="menuItems" />
      </ul>
      <form @submit.prevent="request()">
        <search-keyword :state="state" :placeholder="localize('검색어를 입력해 주세요')" />
      </form>
    </div>
    <div class="content">
      <table class="tb">
        <colgroup>
          <col style="width: 9%" />
          <col />
          <col style="width: 15%" />
        </colgroup>
        <thead>
          <tr>
            <th>{{ localize("문의 유형") }}</th>
            <th>{{ localize("내용") }}</th>
            <th>{{ localize("수신 일자") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="active">
            <td>{{ localize("알림") }}</td>
            <td class="text-left" data-toggle="table">
              <span class="badge bg-ylw">Action Required</span>
              <strong class="tb-title">[R2-12345897-이승현] 컨펌 승인 요청이 도착했습니다.</strong>
            </td>
            <td>2024.02.14<span class="time">23:53</span></td>
          </tr>
          <tr class="table-collapse">
            <td></td>
            <td colspan="2">
              <p>[R2-12345897-이승현] 컨펌 승인 요청이 도착했습니다.</p>
              <a href="" class="btn btn-sm">확인하러 가기<i class="icon icon-link"></i></a>
              <button type="button" class="icon icon-close" data-toggle="table">닫기</button>
            </td>
          </tr>
          <tr>
            <td>알림</td>
            <td class="text-left" data-toggle="table">
              <span class="badge bg-ylw">Action Required</span>
              <strong class="tb-title">[R2-12345897-이승현] 컨펌 승인 요청이 도착했습니다.</strong>
            </td>
            <td>2024.02.14<span class="time">23:53</span></td>
          </tr>
          <tr class="table-collapse">
            <td></td>
            <td colspan="2">
              <p>[R2-12345897-이승현] 컨펌 승인 요청이 도착했습니다.</p>
              <a href="" class="btn btn-sm">확인하러 가기<i class="icon icon-link"></i></a>
              <button type="button" class="icon icon-close" data-toggle="table">닫기</button>
            </td>
          </tr>
        </tbody>
      </table>
      <pagenation :total-rows="state.totalRows" :page="state.page" @change="request" />
    </div>
  </div>
</template>

<style scoped></style>
