<script setup lang="ts">
import { localize } from "@/utils";

// localize("프로필")
// localize("업력 정보")
const menuItems = [
  {
    name: "Information",
    link: "information",
    children: [
      { name: "프로필", link: "profile" },
      { name: "업력 정보", link: "history" },
    ],
  },
  { name: "Operation", link: "operation" },
  { name: "Payment Method", link: "payment-methods" },
];
</script>

<template>
  <h1 class="hd-title">Service Management</h1>
  <router-view :menu-items="menuItems"></router-view>
</template>

<style scoped></style>
