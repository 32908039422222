<script setup lang="ts">
import { localize } from "@/utils";
import { useClientWrapperStore } from "@/store";
import { reactive, ref, type Ref } from "vue";
import {
  FileTransferProgress,
  FileTransferResult,
  FileTransferService,
  FileTransferSource,
  FileUploadSource,
  IFileTransferService,
} from "@/libs/R2ServiceClient/service/Resource";
import { alert } from "@/services";
import { useResourceWrapperStore } from "@/store";

const fileTransferService: IFileTransferService = new FileTransferService();

const state: Record<string, any> = reactive({
  id: "",
  loginId: "",
  name: "",
  image: [],
  uploading: false,
  progressFileName: "",
  progressRate: 0,
  fileItems: [] as File[],
});
const form: Record<string, any> = reactive({
  name: "",
  note: "",
});

requestProfile();

function requestProfile(): void {
  console.log("request");
  const variables = {
    playerId: "1", //TODO: playerId 실제 가져오기
  };
  const query = `
    query GetPlayerProfile($playerId: ID) {
      playerProfile(playerId: $playerId) {
        id
        loginId
        name
        image{
          id
          index
          fileName
          fileSize
        }
      }
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("request - successCallback: ", result.playerProfile);
  //       const data = result.playerProfile;
  //       for (const key in data) state[key] = data[key];
  //     },
  //   }
  // );
}
function requestDuplicatedCheck(): void {
  console.log("requestDuplicatedCheck");
  const variables = {
    name: form.name,
  };
  const query = `
    query GetPlayerDuplicatedCheck($name: String){
      playerDuplicatedCheck(name: $name)
    }
    `;
  // useClientWrapperStore().user.query(
  //   useClientWrapperStore().user.typedQuery(query, {
  //     variables: variables,
  //     successCallback: (result: any) => {
  //       console.log("requestDuplicatedCheck - successCallback: ", result.playerDuplicatedCheck);
  //       if (result.playerDuplicatedCheck) {
  //         alert("중복된 별칭입니다.");
  //       } else {
  //         alert("사용 가능한 별칭입니다.");
  //       }
  //     },
  //   }
  // );
}
async function uploadImage(event: Event) {
  const { files } = <HTMLInputElement>event.target;
  if (files != null) {
    return await useResourceWrapperStore().upload(files, {
      successCallback(uploadedFiles) {
        console.log(
          "uploadImage - successCallback: ",
          uploadedFiles.map((info) => `${info.id}/${info.fileName}/${info.fileSize}`).join("\n")
        );
        state.image = uploadedFiles;
        state.uploading = false;
        state.progressFileName = "";
        state.progressRate = 0;
      },
      progressCallback(progress) {
        state.uploading = true;
        state.progressFileName = progress.fileName;
        state.progressRate = progress.progressRate;
      },
    });
  }
}
async function remove(id: string) {}
function submitForm() {}
</script>

<template>
  <h1 class="hd-title">Profile</h1>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <div class="user-info box">
          <div class="fs-3">
            <p>{{ localize("어서오세요, 반가워요") }}.</p>
            <p>
              <b class="font-poppins">{{ state.name }}</b> {{ localize("님") }}.
            </p>
          </div>
          <div class="user-detail">
            <div class="profile-thumb">
              <img
                v-if="state.image.length > 0"
                :src="FileTransferService.getDownloadUri(state.image[0].id)"
                :alt="state.image[0].fieldName"
              />
            </div>
            <p>
              <b>{{ state.name }}</b> {{ localize("님") }}
            </p>
            <div class="fs-sm text-muted mt-2">{{ state.loginId }}</div>
          </div>
          <div class="box">
            <dl class="desc">
              <dt>{{ localize("진행 중인 주문") }}</dt>
              <dd class="text-primary">35</dd>
            </dl>
            <dl class="desc">
              <dt>{{ localize("확인 필요한 주문") }}</dt>
              <dd class="text-primary">22</dd>
            </dl>
            <dl class="desc">
              <dt>{{ localize("보유 포인트") }}</dt>
              <dd class="text-primary">20</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="grid-content">
        <div class="box box-shadow">
          <div class="content-hd">
            <h3 class="fs-2">{{ localize("플레이어 프로필 설정") }}</h3>
            <p class="info-txt">{{ localize("프로필 정보의 일부는 서비스 정보에 표기됩니다.") }}</p>
          </div>
          <form @submit.prevent="submitForm">
            <div class="fieldset">
              <h4 class="fs-3">{{ localize("프로필 사진") }}</h4>
              <div class="flex-fill">
                <div class="profile-file">
                  <div class="profile-thumb">
                    <!-- <img src="@/assets/playground/img/_temp/profile2.jpg" alt=""> -->
                  </div>
                  <div>
                    <p class="fs-sm text-muted mb-3">
                      {{ localize("플래너 로고 이미지를 권장하며,") }}<br />
                      {{ localize("용량 5MB 이하의 jpg, png 파일을 등록해주세요.") }}
                    </p>
                    <div class="profile-btn">
                      <label v-if="!state.uploading" class="btn btn-sm">
                        <input type="file" class="sr-only" multiple @change="uploadImage" />{{ localize("등록하기") }}
                      </label>
                      <label v-else class="btn btn-sm">{{ state.progressRate.toFixed(0) }}%</label>
                      <a href="" class="btn btn-outline btn-sm">{{ localize("삭제하기") }}</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fieldset mb-4">
              <h4 class="fs-3">{{ localize("프로필 정보") }}</h4>
              <div class="flex-fill">
                <div class="form-group">
                  <div class="form-label">{{ localize("별칭") }}</div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      id=""
                      placeholder=""
                      @keyup.enter="requestDuplicatedCheck"
                    />
                    <button class="btn btn-sm text-nowrap" @click="requestDuplicatedCheck">
                      {{ localize("중복 확인") }}
                    </button>
                  </div>
                </div>
                <div class="form-group">
                  <div class="form-label">소개</div>
                  <textarea
                    class="form-control"
                    rows="3"
                    :placeholder="localize('자신을 소개하는 메세지를 남겨주세요.')"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="text-right fs-sm text-dark">
              <a href=""
                ><u v-html="localize('계정 및 회원정보 설정은 <b>[회원정보 설정]</b>으로 이동')" /><i
                  class="icon icon-link-blk ml-1"
                ></i
              ></a>
            </div>
            <div class="btn-wrap">
              <button type="button" class="btn btn-md btn-outline">{{ localize("취소") }}</button>
              <button type="submit" class="btn btn-md">{{ localize("저장하기") }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
