<script setup lang="ts">
import { ref, type Ref } from 'vue'
import { FileTransferProgress, FileTransferResult, FileTransferService, FileTransferSource, FileUploadSource, IFileTransferService } from '@/libs/R2ServiceClient/service/Resource/'

const fileTransferService: IFileTransferService = new FileTransferService()
const progressFileName: Ref<string | undefined> = ref('')
const progressRateNumber: Ref<Number> = ref(0)

interface UploadedFiles {
    id: string | undefined,
    fileName: string | undefined
    fileSize: number | undefined
}
const uploadedFiles: Ref<UploadedFiles[]> = ref([])

async function upload(event: Event) {
    //1/ HTMLInputElement.files 로 웹 브라우저 상의 파일에 대한 정보를 가져옴
    const uploadFiles = (<HTMLInputElement>event.target).files
    if(uploadFiles != null)
    {
        //2/ FileUploadSource 를 생성하고, 가져온 파일을 추가 (생성자에는 '어떤 문자열'이 들어가더라도 상관없음)
        let fileUploadSource: FileUploadSource = new FileUploadSource('order')
        for(let index = 0; index < uploadFiles.length; index++)
        {
            //가져 온 파일을 FileUploadSource 에 추가
            fileUploadSource.addBrowserData(uploadFiles[index]!)
        }

        //3/ FileTransferSource 를 생성하고, uploadSource 속성에 준비한 FileUploadSource 개체를 설정
        let fileTransferSource: FileTransferSource = new FileTransferSource()
        fileTransferSource.uploadSource = fileUploadSource

        //4/ 업로드를 진행함, 업로드 진행 상황을 알기 위한 핸들러 함수를 추가할 수 있으며, 이에 대한 결과를 fileTransferResult 개체에서 확인할 수 있음
        let fileTransferResult: FileTransferResult = await fileTransferService.transfer(fileTransferSource, (progress: FileTransferProgress) => {
            if(progress.progressRate) {
                progressFileName.value = progress.fileName
                progressRateNumber.value = progress.progressRate
            }
        })

        //5/ 데모
        if(fileTransferResult.upload != null)
        {
            for(let index = 0; index < fileTransferResult.upload.length; index++)
            {
                uploadedFiles.value.push({
                    id: fileTransferResult.upload[index].fileId,
                    fileName: fileTransferResult.upload[index].fileName,
                    fileSize: fileTransferResult.upload[index].fileSize
                })
            }
        }
    }   
}

async function remove(id: string) {

    /*
        let fileDeleteSource: FileDeleteSource = new FileDeleteSource()
        //1/ 삭제하고자하는 파일의 id 를 추가, 예제에서는 1개를 클릭할 때마다 삭제를 하도록 했지만, 계속해서 연속으로 id 를 추가할 수 있음
        fileDeleteSource.add(id)
        
        //2/ 삭제할 파일의 id 를 모두 추가한 다음, FileTrasferSource 개체를 생성하고, deleteSource 속성에 준비한 FileDeleteSource 개체를 설정
        let fileTransferSource: FileTransferSource = new FileTransferSource()
        fileTransferSource.deleteSource = fileDeleteSource

        //3/ 삭제를 진행함 (삭제의 경우, Progress 를 추가하지 않아도 됨) - 현재 이 부분에서 에러가 발생하고 있음, 수정 필요
        let fileTransferResult: FileTransferResult = await fileTransferService.transfer(fileTransferSource)

        //4/ 정상이라면 삭제된 파일을 제거하도록 해야 함
        fileTransferResult.delete?.forEach((file) => {
            uploadedFiles.value = uploadedFiles.value.filter((uploadedFile) => uploadedFile.id !== file.fileId)
        })
    */

    //NOTE/ 임시로 삭제하는 부분
    uploadedFiles.value = uploadedFiles.value.filter((uploadedFile) => uploadedFile.id !== id)
}

</script>

<template>
    <div>
        <p>업로드 테스트</p>
        <input type="file" multiple @change="upload" id="upload-file" />
        <p>{{ progressRateNumber }}</p>
    </div>
    <div>
        <p>업로드 된 파일</p>
        <div v-for="file in uploadedFiles" :key="file.id">
            <!-- href 는 v-bind 로 걸어둬야 함 -->
            <p><a v-bind:href="FileTransferService.getDownloadUri(file.id!)" target="_blank">{{ file.fileName }}</a> - <span @click="remove(file.id!)"><strong style="cursor: pointer">[삭제]</strong></span></p>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>