<script setup lang="ts">
import { reactive, inject } from "vue";
import { localize } from "@/utils";
import { ServiceSettingAddProps } from "@/views/playground/planner/order-management/service-setting/add/ServiceSettingAdd.vue";
/**
 * 서비스 등록 상태
 */
const data = inject<ServiceSettingAddProps>("service-setting-add-state")!;
data.step = 3;
</script>

<template>
  <div class="fieldset d-block mb-2">
    <strong class="fs-sm text-primary">STEP 3</strong>
    <h3 class="fs-2 mt-2 mb-4">작업 대금 설정</h3>
    <p class="fs-3 mb-2">서비스 제공 시의 작업 대금을 설정해주세요.</p>
    <table class="tb">
      <colgroup>
        <col style="width: 20%" />
        <col span="" />
        <col span="" style="width: 0%" />
      </colgroup>
      <thead>
        <tr class="text-left">
          <th class="text-left">옵션</th>
          <th class="text-left">항목</th>
          <th colspan="" class="text-right"><span class="pr-3">포인트</span></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th class="text-left">진단비</th>
          <td class="text-left">기본 진단비</td>
          <td class="d-flex justify-content-end pr-3"><input type="text" class="form-control sm" /></td>
        </tr>
        <tr>
          <th rowspan="5" class="text-left">서지컬 가이드</th>
          <th class="text-left baseline" rowspan="4">홀</th>
          <td colspan="" class="border-0 pr-3 h-auto pb-1">
            <div class="flex-center justify-content-between">
              <div>
                <label class="form-check radio">
                  <input type="radio" name="c1" class="sr-only" />
                  <span class="radio">첫 홀</span>
                </label>
              </div>
              <div class="flex-center">
                <input type="text" class="form-control sm" /><span class="ml-2">P</span>
                <span class="ml-3 mr-2">이후 홀 당</span>
                <input type="text" class="form-control sm" /><span class="ml-2">P</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="" class="border-0 h-auto pt-1 pb-1">
            <div class="flex-center justify-content-between">
              <div>
                <label class="form-check radio">
                  <input type="radio" name="c1" class="sr-only" />
                  <span class="radio">범위</span>
                </label>
              </div>
              <div class="flex-center justify-content-between">
                <div class="">
                  <span>MIN</span>
                  <input type="text" class="form-control sm" />
                </div>
                <span class="mx-2 mt-3">-</span>
                <div class="">
                  <span>MAX</span>
                  <input type="text" class="form-control sm" />
                </div>
                <div class="ml-3">
                  <span>Per Hole</span>
                  <input type="text" class="form-control sm" />
                </div>
                <span class="ml-2 mt-3">P</span>
                <button class="btn btn-plus ml-3 mt-3"></button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="d-flex justify-content-end border-0 pr-3 h-auto pb-1 pt-1">
            <div class="flex-center">
              <span class="">MIN<b class="ml-2">1</b></span>
              <span class="mx-2">-</span>
              <span class="">MAX<b class="ml-2">5</b></span>
              <span class="mx-3"><b>15 P</b></span>
              <button type="button" class="icon icon-x-circle">삭제</button>
            </div>
          </td>
        </tr>
        <td class="d-flex justify-content-end pr-3 h-auto pt-1">
          <div class="flex-center">
            <span class="">MIN<b class="ml-2">1</b></span>
            <span class="mx-2">-</span>
            <span class="">MAX<b class="ml-2">5</b></span>
            <span class="mx-3"><b>15 P</b></span>
            <button type="button" class="icon icon-x-circle">삭제</button>
          </div>
        </td>
        <tr>
          <th class="text-left">슬리브</th>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
        <tr>
          <th rowspan="2" class="text-left baseline">
            <label class="form-check font-poppins font-weight-normal">
              <input type="checkbox" class="sr-only" />
              <span class="check">Temporary Crown</span>
            </label>
          </th>
          <td class="text-left">PMMA Crown</td>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
        <tr>
          <td class="text-left">PMMA Abutment</td>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
        <tr>
          <th rowspan="2" class="text-left baseline">
            <label class="form-check font-poppins font-weight-normal">
              <input type="checkbox" class="sr-only" />
              <span class="check">Custom Abutment</span>
            </label>
          </th>
          <td class="text-left">Zirconia</td>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
        <tr>
          <td class="text-left">Titanium</td>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
        <tr>
          <th class="text-left">Bridge</th>
          <td class="text-left">홀 당 디자인 비</td>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
        <tr>
          <th class="text-left">
            <label class="form-check">
              <input type="checkbox" class="sr-only" />
              <span class="check">Specialty</span>
            </label>
          </th>
          <td class="text-left">Bone Reduction Guide</td>
          <td class="d-flex justify-content-end pr-3">
            <input type="text" class="form-control sm" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped></style>
