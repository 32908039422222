<script setup lang="ts">
import {getFormatDate, localize} from "@/utils";
import TabMenu from "@/components/TabMenu.vue";
import {IBaseMenuItem, IPeriodItem} from "@/models";
import {IListState} from "@/models/i-list-state.ts";
import {reactive} from "vue";
import {subMonths, subYears} from "date-fns";
import DatePeriod from "@/components/DatePeriod.vue";

const now = new Date();

const periodItems: IPeriodItem[]  = [
  { key: '직접입력', value: 0, period: null },
  { key: '당월', value: "1", period: {
      start: getFormatDate(new Date(now.getFullYear(), now.getMonth(), 1)),
      end: getFormatDate(now),
    }},
  { key: '1개월', value: "2", period: {
      start: getFormatDate(subMonths(now, 1)),
      end: getFormatDate(now),
    }},
  { key: '2개월', value: "3", period: {
      start: getFormatDate(subMonths(now, 2)),
      end: getFormatDate(now),
    }},
  { key: '3개월', value: "4", period: {
      start: getFormatDate(subMonths(now, 3)),
      end: getFormatDate(now),
    }},
  { key: '6개월', value: "5", period: {
      start: getFormatDate(subMonths(now, 6)),
      end: getFormatDate(now),
    }},
  { key: '1년', value: "6", period: {
      start: getFormatDate(subYears(now, 1)),
      end: getFormatDate(now),
    }},
];

const props = defineProps({
  menuItems: {type: Array as () => IBaseMenuItem[], default: []},
});
const state: IListState = reactive({
  periodOption: 1,
  page: 1,
  totalRows: 0,
  keyword: '',
  items: [],
});
function changePeriodOption(option: number): void {
  state.periodOption = option;
  console.log(state.periodOption);
  if(state.periodOption > 0) {
    const item = periodItems.find(item => item.value == state.periodOption);
    console.log("changePeriodOption", `${item?.period?.start} ~ ${item?.period?.end}`);
  }
}
function changePeriodDirect(start: string, end: string): void {
  console.log("changePeriod", `${start} ~ ${end}`);
}
</script>

<template>
  <div class="file-content-hd justify-content-between">
    <ul class="nav nav-tabs-fluid v2">
      <tab-menu :items="menuItems" />
    </ul>
    <p class="info-txt">정산은 메가젠의 정책에 따라, 주문 완료 후 3~5일 사이에 일어나며,  [직접 결제(송금)] 등의 주문 등은 정산되지 않습니다.</p>
  </div>
  <div class="content">
    <div class="box">
      <form action="">
        <div class="row form-row justify-content-between">
          <div class="flex-center">
            <div class="form-label">기간</div>
            <div class="flex-center">
              <input class="form-switch-input" type="checkbox"
                     @change="changePeriodOption(($event.target as HTMLInputElement).checked ? 0 : 1)">
              <h5 class="fs-sm cell-title" style="margin: 10px">직접입력</h5>
            </div>
            <date-period
              class="flex-center"
              v-if="state.periodOption === 0"
              @change="changePeriodDirect"
            />
            <div v-else class="btn-check-wrap">
              <label v-for="item in periodItems.slice(1)" class="btn-check">
                <input type="radio" class="sr-only"
                       @change="changePeriodOption(item.value as number)"
                       v-model="state.periodOption"
                       :value="item.value">
                <span class="btn btn-outline-primary">{{ item.key }}</span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="flex-center justify-content-between">
      <button type="button" class="btn btn-sm btn-outline download-btn"><i class="icon icon-download"></i> 전체 목록 내려받기</button>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" role="switch" id="">
        <label class="fs-sm" for="">상세 내역 표기</label>
      </div>
    </div>
    <table class="tb tb-spacetb-settlement">
      <colgroup>
      </colgroup>
      <thead>
      <tr>
        <th>정산번호 <i class="icon icon-expand"></i></th>
        <th>정산(포인트)</th>
        <th>수수(포인트)</th>
        <th>실지급(포인트)</th>
        <th>정산 일 <i class="icon icon-expand"></i></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr class="tb-link-item active" data-toggle="table">
        <td class="fs-sm">STM-00000001</td>
        <td>330P</td>
        <td>2P</td>
        <td class="fs-2">328P</td>
        <td class="fs-sm">2024.02.16</td>
        <td></td>
        <td></td>
        <td><i class="icon icon-drop"></i></td>
      </tr>
      <tr class="tb table-collapse">
        <td colspan="8">
          <div class="d-flex justify-content-between">
            <table class="tb tb-order col-9">
              <colgroup>
              </colgroup>
              <thead>
              <th>주문번호</th>
              <th>서비스</th>
              <th>주문자</th>
              <th>포인트</th>
              </thead>
              <tbody>
              <tr class="dash">
                <th>R-12345689</th>
                <td>서지컬가이드 (Design)
                <td><span>문단비</span>언주치과</td>
                <td>80P</td>
                </td>
              </tr>
              <tr class="dash">
                <th>R-12345689</th>
                <td>서지컬가이드 (Design)
                <td><span>문단비</span>언주치과</td>
                <td>80P</td>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="point-box" style="width: 200px;">
              <b>실지급액 (포인트)</b><span><span class="num">328</span>P</span>
              <span class="code">Nonghyup<br>123-12-12345678</span>
            </div>
          </div>
        </td>
      </tr>
      <tr class="tb-link-item" data-toggle="table">
        <td class="fs-sm">R-123456789</td>
        <td class="fs-sm">Surgical Guide (Design)</td>
        <td>2024.02.16</td>
        <td class="fs-3 num">23P</td>
        <td class="fs-sm">송금 (개인 간)</td>
        <td><span class="badge bg-grn">결제됨</span></td>
        <td></td>
        <td><i class="icon icon-drop"></i></td>
      </tr>
      <tr class="table-collapse">
        <td colspan="8">
          <div class="d-flex justify-content-between">
            <table class="tb tb-guide col-9">
              <colgroup>
                <col style="width: 20%;">
                <col style="width: 25%;">
                <col style="width: 25%;">
                <col style="width: 7%;">
                <col style="width: 7%;">
                <col style="width: 7%;">
                <col style="width: 7%;">
              </colgroup>
              <thead>
              <th colspan="4" class="fs-3 text-left">Surgical Guide</th>
              <th>단가</th>
              <th>수량</th>
              <th>총액</th>
              </thead>
              <tbody>
              <tr>
                <th>진단비</th>
                <td>기본 진단비</td>
                <td></td>
                <td></td>
                <td>30P</td>
                <td></td>
                <td class="fs-sm">30P</td>
              </tr>
              <tr>
                <th rowspan="3">서지컬 가이드</th>
                <td>홀</td>
                <td></td>
                <td class="fs-xs">첫줄</td>
                <td class="fs-xs">10P</td>
                <td class="fs-xs">이후 홀당</td>
                <td class="fs-xs">8P</td>
              </tr>
              <tr class="dash">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>x4</td>
                <td class="fs-sm">32P</td>
              </tr>
              <tr>
                <td>메탈 슬리브</td>
                <td>PMMA Abutment</td>
                <td></td>
                <td>5P</td>
                <td>x4</td>
                <td class="fs-sm">32P</td>
              </tr>

              </tbody>
            </table>
            <div class="point-box">
              <b >총 합계약 (포인트)</b><span><span class="num">167</span>P</span>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <ul class="paging planner">
      <li><a href="" class="icon icon-paging disabled">이전</a></li>
      <li><a href="" class="paging-item active">1</a></li>
      <li><a href="" class="paging-item">2</a></li>
      <li><a href="" class="paging-item">3</a></li>
      <li><a href="" class="paging-item">4</a></li>
      <li><a href="" class="paging-item">5</a></li>
      <li><button type="button" class="paging-item bubble">...</button></li>
      <li><a href="" class="paging-item">11</a></li>
      <li><a href="" class="icon icon-paging">다음</a></li>
    </ul>
  </div>
</template>

<style scoped>

</style>