import { DocumentNode } from "@apollo/client/core";
import { GraphQLClient, GraphQLClientConfig, GraphQLRequest } from "../../base";
import { AuthProxy, HowToMethods } from "../../proxy";
import { AuthenticationSettings, ClientSettings } from "../../settings";
import { QueryRequest, QueryResponse, ServiceBase } from "../common";

class Authenticator {
    public static async login(id: string, password: string): Promise<boolean> {
        return AuthProxy.login(id, password)
    }

    public static async loginByToken(token: string): Promise<boolean> {
        return AuthProxy.loginByToken(token)
    }

    public static async logout(): Promise<boolean> {
        return AuthProxy.logout()
    }
}

export { Authenticator }