<script setup lang="ts">
import { computed, defineProps, watch } from "vue";
import { localize } from "@/utils";



const props = defineProps({
  controls: {
    type: Object,
    required: true,
  },
  touch: {
    type: Function,
    required: true,
  },
  valid: {
    type: Function,
    required: true,
  },
  invalid: {
    type: Function,
    required: true,
  },
});

// 선택된 연도, 월, 일의 상태를 정의
const selectedYear = computed(() => props.controls.year.value);
const selectedMonth = computed(() => props.controls.month.value);
const selectedDay = computed(() => props.controls.day.value);
/**
 * 달의 마지막 날 계산 함수
 */
 const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month, 0).getDate(); // month - 1을 할 필요가 없음 (1월 = 1)
};
// Compute years, months, and days
const dateOptions = computed(() => {
  const currentYear = new Date().getFullYear();
  // 1950년부터 2003년까지의 연도 생성
  const years: number[] = Array.from({ length: 2003 - 1950 + 1 }, (_, i) => 1950 + i);

  // 1월부터 12월까지의 월 생성
  const months: number[] = Array.from({ length: 12 }, (_, i) => i + 1);
  
  // 선택된 연도와 달에 따라 그 달의 일수 계산
  const days: number[] = Array.from(
    { length: getDaysInMonth(Number(props.controls.year.value), Number(props.controls.month.value)) },
    (_, i) => i + 1
  );

  return { years, months, days };
});
/**
 * 년도변경
 * @param value
 */
 function changeYearOfBirthDay(value: string) {
  props.controls.year.value = value;
}
/**
 * 월 변경
 * @param value
 */
function changeMonthOfBirthDay(value: string) {
    props.controls.month.value = value;
  // 일 값이 존재하면서 dateOptions.value.days에 포함하지 않는다면 초기화
    if(!dateOptions.value.days.includes(Number(props.controls.day.value)) && props.controls.day.value != ""){
      props.controls.day.value="";
  }
}
/**
 * 일 변경
 * @param value
 */
function changeDayOfBirthDay(value: string) {
    props.controls.day.value = value;
}
</script>

<template>
    <!-- 년도 선택 -->
    <select
        :value="selectedYear"
        @focusout="touch('year')"
        @change="(event: Event) => changeYearOfBirthDay((event.target as HTMLSelectElement).value)"
        :class="{
        'form-control': true,
        curved: true,
        'is-invalid': controls.year.touched && invalid('year'),
        'is-valid': valid('year'),
        'placeholder-active' : props.controls.year.value ==''
        }"
    >
        <option disabled value="">{{ localize('년도_커스텀컴포넌트1') }}</option>
        <option v-for="year in dateOptions.years" :key="year" :value="year">{{ year }}</option>
    </select>

    <!-- 월 선택 -->
    <select
        :value="selectedMonth"
        @focusout="touch('month')"
        @change="(event: Event) => changeMonthOfBirthDay((event.target as HTMLSelectElement).value)"
        :class="{
        'form-control': true,
        curved: true,
        'is-invalid': controls.month.touched && invalid('month'),
        'is-valid': valid('month'),
        'placeholder-active' : props.controls.month.value == ''
        }"
    >
        <option disabled value="">{{ localize('월_커스텀컴포넌트1') }}</option>
        <option v-for="month in dateOptions.months" :key="month" :value="month">{{ month }}</option>
    </select>

    <!-- 일 선택 -->
    <select
        :value="selectedDay"
        @focusout="touch('day')"
        @change="(event: Event) => changeDayOfBirthDay((event.target as HTMLSelectElement).value)"
        :class="{
        'form-control': true,
        curved: true,
        'is-invalid': controls.day.touched && (invalid('day')),
        'is-valid': valid('day'),
        'placeholder-active' : props.controls.day.value ===''
        }"
    >
        <option disabled value="">{{ localize('일_커스텀컴포넌트1') }}</option>
        <option v-for="day in dateOptions.days" :key="day" :value="day">{{ day }}</option>
    </select>

    <!-- 생년월일에러 메시지-->
    <div v-show="(controls.year.touched && (!controls.year.value )) 
                || (controls.month.touched && (!controls.month.value)) 
                || (controls.day.touched && (!controls.day.value))" 
                class="invalid-feedback">
        * {{ localize("생년월일을 모두 입력하세요.") }}
    </div>
</template>
  
<style scoped>
/* placeholder가 활성화될 때 흐릿한 색상 적용 */
.placeholder-active {
  color: #bbb9cd;
}
</style>