<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useJoinStore } from "@/store";
import { useFormBuilder, alert } from "@/services";
import { localize } from "@/utils";
import router from "@/router";
import { Validators } from "@/utils";

/**
 * 약관 동의 자료
 */
const { agreement } = useJoinStore().member;
// console.log("agreement: ", agreement);

const { controls, group, validate, valid, invalid } = useFormBuilder();
group({
  age: { value: false, validators: [Validators.required] },
  service: { value: false, validators: [Validators.required] },
  privacy: { value: false, validators: [Validators.required] },
  email: { value: false },
  sms: { value: false },
});

/**
 * 전체 동의 여부
 */
const selectedAll = computed(() => Object.values(controls).every((p) => p.value));
/**
 * 선택 동의 여부
 */
const selectedAds = computed({
  get: () => controls.email.value && controls.sms.value,
  set: (value: boolean) => (controls.email.value = controls.sms.value = value),
});

onMounted(init);
function init() {
  validate();
}

/**
 * 전체 동의 핸들러
 */
function agreeAll() {
  const newValue = !selectedAll.value;
  for (const key in controls) {
    controls[key].value = newValue;
  }
}
/**
 * 새 창 열기
 */
function open(url: string) {
  window.open(url);
}
/**
 * 완료 핸들러
 */
function done() {
  validate(true);
  if (valid()) {
    agreement.email = controls.email.value;
    agreement.sms = controls.sms.value;
    // console.log("done: ", agreement);
    router.push("/home/join/genuine");
  }
}
</script>

<template>
  <div class="join-box">
    <div class="progress-line">
      <div class="line" style="width: 20%"></div>
    </div>
    <div class="step-wrap">
      <b>STEP 1</b>
      <h2>{{ localize("약관 동의") }}</h2>
    </div>
    <form class="join-form">
      <div class="form-check all-chk">
        <input :checked="selectedAll" @click.prevent class="form-check-input" type="checkbox" id="all" />
        <label @click="agreeAll" class="form-check-label" for="all">{{ localize("전체 약관에 동의합니다.") }}</label>
      </div>
      <div class="form-check">
        <input v-model="controls.age.value" class="form-check-input" type="checkbox" id="check1" />
        <label class="form-check-label" for="check1"
          >[{{ localize("필수") }}] {{ localize("만 19세 이상입니다.") }}</label
        >
      </div>
      <div class="form-check">
        <input v-model="controls.service.value" class="form-check-input" type="checkbox" value="" id="check2" />
        <label class="form-check-label" for="check2">
          [{{ localize("필수") }}] {{ localize("이용 약관에 동의합니다.") }}
          <a @click="alert(localize('준비 중 입니다.'))">{{ localize("자세히 보기") }}</a>
        </label>
      </div>
      <div class="form-check">
        <input v-model="controls.privacy.value" class="form-check-input" type="checkbox" value="" id="check3" />
        <label class="form-check-label" for="check3">
          [{{ localize("필수") }}] {{ localize("개인정보 수집 및 이용에 동의합니다.") }}
          <a @click="alert(localize('준비 중 입니다.'))">{{ localize("자세히 보기") }}</a>
        </label>
      </div>
      <div class="form-check">
        <input v-model="selectedAds" class="form-check-input" type="checkbox" value="" id="check4" />
        <label class="form-check-label" for="check4"
          >[{{ localize("선택") }}] {{ localize("광고성 정보 수신에 동의합니다.") }}</label
        >
      </div>
      <div class="ad-agree">
        <div class="form-check">
          <input v-model="controls.email.value" class="form-check-input" type="checkbox" value="" id="email" />
          <label class="form-check-label" for="email">{{ localize("이메일 정보 수신에 동의합니다.") }}</label>
        </div>
        <div class="form-check">
          <input v-model="controls.sms.value" class="form-check-input" type="checkbox" value="" id="sms" />
          <label class="form-check-label" for="sms">{{ localize("SMS 정보 수신에 동의합니다.") }}</label>
        </div>
      </div>
    </form>
    <div class="btn-wrap">
      <button @click="done" :class="{ disabled: invalid() }" class="btn btn-primary center w-100">
        {{ localize("동의하기") }}
      </button>
    </div>
  </div>
</template>

<style scoped></style>
