import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from, gql, Observable, DocumentNode, TypedDocumentNode, OperationVariables } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import fetch from 'cross-fetch';

//#region (에러/예외와 관련된 타입들 정의)
interface LogicErrorExtensions {
    code: string;
    message: string;
    description?: string;
}

interface LogicError {
    message: string;
    extensions: LogicErrorExtensions;
}
//#endregion

interface GraphQLRequest<TValue = Record<string, any>> {
    query: string | DocumentNode;
    variables?: TValue;
}

interface GraphQLResponse<T> {
    data: T | null;
    errors?: any;
    isSuccess: boolean;
}

interface GraphQLClientConfig {
    httpUri: string;
    wsUri?: string;
    howToSetToken?: (headers: any) => void;  // 헤더에 토큰을 설정하는 함수
    howToRefresh?: () => Promise<boolean>;  // 토큰을 갱신하는 함수
}

class GraphQLClient {
    //1/ GraphQLClient 는 ApolloClient 를 래핑
    private client: ApolloClient<any>;
    private isRefreshing: boolean = false;
    private retryCount: number = 0;
    private howToSetToken?: (headers: any) => void;
    private howToRefresh?: () => Promise<boolean>;

    constructor(config: GraphQLClientConfig) {
        /*
            // HowToSetToken
            operation.setContext 를 활용하여 HTTP 헤더를 설정한다
            operation.setContext 는 요청이 서버로 전송되기 전에 요청의 컨텍스트를 수정할 수 있는 함수이다
            이 경우, 요청에 Authorization 헤더를 추가하는데 사용된다

            - 요청에 Authorzation 헤더를 추가하는 방법은 howToSetToken 함수를 통해 설정된다
              외부에서 주입을 할 때는 다음의 절차를 따르도록 한다

                function setAuthToken(headers: any) {
                    const token = AuthenticationSettings.instance.token
                    headers['Authorization'] = `Bearer ${token}`;
                }

                const client = new GraphQLClient({
                    httpUri: 'http://localhost:4000/graphql',
                    howToSetToken: setAuthToken
                });

            // HowToRefresh
            토큰 갱신을 위한 로직을 설정한다
            토큰 갱신이 필요한 경우, howToRefresh 함수를 통해 갱신 로직을 설정한다
        
            - 리프레시 로직을 삽입할 때는 다음의 절차를 따르도록 한다
                
            async function refreshAuthToken() {
                const isSuccess = await AuthProxy.refresh()
                return isSuccess
            }

            const client = new GraphQLClient({
                httpUri: 'http://localhost:4000/graphql',
                howToRefresh: refreshAuthToken
            });

        */
        this.howToSetToken = config.howToSetToken;
        this.howToRefresh = config.howToRefresh;

        /* 
           //2/ 
           ApolloClient 는 HttpLink 와 WebSocketLink 를 조합하여 생성할 수 있다
           또한 httpLink 같이 Link 개체에는 ApolloLink 를 연결할 수 있다 - 아래의 예는 authLink 를 선언하고, 이를 httpLink 에 연결한다
           authLink 는 HTTP 요청을 보낼 때마다 헤더에 Authorization 토큰을 추가하며, 그 다음에 네트워크 요청을 다루는 httpLink 로 넘어가게 된다 
       */
        const httpLink = new HttpLink({
            uri: config.httpUri,
            fetch
        });


        const authLink = new ApolloLink((operation, forward) => {
            const headers = operation.getContext().headers || {};

            if (this.howToSetToken) {
                this.howToSetToken(headers);
            }

            operation.setContext({ headers });
            return forward(operation);
        });

        const wsLink = config.wsUri ? new WebSocketLink({
            uri: config.wsUri,
            options: {
                reconnect: true,
                connectionParams: () => {
                    const headers: Record<string, string> = {};
                    if (this.howToSetToken) {
                        this.howToSetToken(headers);
                    }
                    return { headers };
                }
            }
        }) : null;

        const link = config.wsUri ? ApolloLink.split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
            },
            wsLink!,
            authLink.concat(httpLink)
        ) : authLink.concat(httpLink);

        const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    console.error(`[GraphQL - ERROR]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`);

                    if (err.extensions?.code === 'UNAUTHENTICATED') {
                        if (this.retryCount > 0) {
                            return new Observable(observer => {
                                observer.error(new Error('Authentication failed, please log in again.'));
                            });
                        }

                        return new Observable(observer => {
                            if (!this.isRefreshing && this.howToRefresh) {
                                this.isRefreshing = true;

                                this.howToRefresh().then(success => {
                                    this.isRefreshing = false;

                                    if (success) {
                                        this.retryCount += 1;
                                        forward(operation).subscribe(observer);
                                    } else {
                                        observer.error(new Error('Token refresh failed, please log in again.'));
                                    }
                                }).catch(() => {
                                    this.isRefreshing = false;
                                    observer.error(new Error('Token refresh failed, please log in again.'));
                                });
                            } else {
                                observer.error(new Error('Token refresh in progress or not available.'));
                            }
                        });
                    }
                }
            }

            if (networkError) {
                console.error(`[Network - ERROR]: ${networkError}`);
                return new Observable(observer => {
                    observer.error(new Error(`Network error occurred: ${networkError}`));
                });
            }
        });

        this.client = new ApolloClient({
            link: from([errorLink, link]),
            cache: new InMemoryCache(),
        });
    }

    async query<TData = any, TValue extends Record<string, any> | undefined = Record<string, any> | undefined>(request: GraphQLRequest<TValue>, customHeaders: Record<string, string> = {}): Promise<GraphQLResponse<TData>> {
        this.retryCount = 0;

        try {
            const { data, errors } = await this.client.query({
                query: typeof request.query === "string" ? gql`${request.query}` : request.query, // string인 경우 gql로 래핑
                variables: request.variables,
                context: {
                    headers: customHeaders,
                },
                errorPolicy: 'all',
            });

            return {
                data,
                errors: errors as LogicError[] ?? [],
                isSuccess: !errors || errors.length === 0,
            };
        } catch(error: any) {
            //E/ 예외처리
            return {
                data: null,
                errors: [error],
                isSuccess: false,
            }
        }
    }

    async typedQuery<TData, TVariables extends OperationVariables>(
        document: TypedDocumentNode<TData, TVariables>,
        variables: TVariables
    ): Promise<GraphQLResponse<TData>> {
        try {
            // 실제 쿼리를 실행하여 데이터를 반환한다
            const { data, errors } = await this.client.query<TData, TVariables>({
                query: document,
                variables,
            });

            // GraphQLResponse 형식으로 결과 반환
            return {
                data: data? data : null,
                errors: errors ?? [],
                isSuccess: !errors || errors.length === 0,
            };
        } catch(error: any) {
            //E/ 예외처리
            return {
                data: null,
                errors: [error],
                isSuccess: false,
            }
        }
    }

    async mutate<TData = any, TValue extends Record<string, any> | undefined = Record<string, any>>(request: GraphQLRequest<TValue>, customHeaders: Record<string, string> = {}): Promise<GraphQLResponse<TData>> {
        this.retryCount = 0;

        try {
            const { data, errors } = await this.client.mutate({
                mutation: typeof request.query === "string" ? gql`${request.query}` : request.query, // string인 경우 gql로 래핑
                variables: request.variables,
                context: {
                    headers: customHeaders,
                },
                errorPolicy: 'all',
            });

            return {
                data,
                errors: errors as LogicError[] ?? [],
                isSuccess: !errors || errors.length === 0,
            };
        } catch(error: any) {
            //E/ 예외처리
            return {
                data: null,
                errors: [error],
                isSuccess: false,
            }
        }
    }

    async typedMutate<TData, TVariables extends OperationVariables>(
        document: TypedDocumentNode<TData, TVariables>,
        variables: TVariables
    ): Promise<GraphQLResponse<TData>> {
        try {
            const { data, errors } = await this.client.mutate<TData, TVariables>({
                mutation: document,
                variables,
            });

            return {
                data: data? data : null,
                errors: errors ?? [],
                isSuccess: !errors || errors.length === 0,
            };
        } catch(error: any) {
            //E/ 예외처리
            return {
                data: null,
                errors: [error],
                isSuccess: false,
            }
        }
    }

    subscribe<TData = any>(query: string | DocumentNode, variables?: Record<string, any>, customHeaders?: Record<string, string>, callback?: (data: TData) => void) {
        const observable = this.client.subscribe({
            query: typeof query === "string" ? gql`${query}` : query, // string인 경우 gql로 래핑
            variables: variables ?? {},
            context: {
                headers: customHeaders || {},
            },
        });

        return observable.subscribe({
            next(response) {
                if (callback) {
                    callback(response.data);
                }
            },
            error(err) {
                console.error('Subscription error:', err);
            },
        });
    }
}

export type { GraphQLRequest, GraphQLResponse, GraphQLClientConfig };
export { GraphQLClient };
