import { defineStore } from "pinia";
import { reactive } from "vue";

export const useTroubleShootingStore = defineStore("trouble-shooting", () => {
  const _data = {
    category: {
      name: "",
      description: "",
    },
    id: "",
    title: "",
    content: "",
    status: "",
    registerDate: "",
    attachements: null,
  };

  const data = reactive<Record<string, any>>({ ..._data });

  /**
   * data를 초기화합니다.
   */
  function initToubleShooting() {
    data.category = { name: "", description: "" };
    data.category.description = "";
    data.id = "";
    data.title = "";
    data.content = "";
    data.status = "";
    data.registerDate = "";
    data.attachements = null;
  }

  return { data, initToubleShooting };
});
