import { useLocalizationStore } from "@/store";
import { format } from "date-fns";

/**
 * 해당 키를 현재 설정된 언어로 반환
 * @param key 키
 */
export function localize(key: string): string {
  return useLocalizationStore().pick(key);
}
/**
 * 해당 문자열을 대문자로 반환
 * @param value 문자열
 */
export function getUpperCase(value: string) {
  if (!value) return "";
  return value.toUpperCase();
}
/**
 * 해당 문자열을 소문자로 반환
 * @param value 문자열
 */
export function getLowerCase(value: string) {
  if (!value) return "";
  return value.toLowerCase();
}
/**
 * 해당 숫자 또는 문자열을 통화 형식으로 반환
 * @param value 숫자 또는 문자열
 */
export function getLocaleString(value: string | number | null) {
  return Number(value)?.toLocaleString();
}
/**
 * 해당 파일경로 문자열의 확장자를 포함한 파일명 반환
 * @param value 파일경로 문자열
 */
export function getFileNameOfPath(value: string) {
  if (!value) return "";
  return value.substring(value.lastIndexOf("/") + 1);
}
/**
 * 해당 파일명 문자열의 확장자를 제외한 파일명 반환
 * @param value 파일명 문자열
 */
export function getFileName(value: string) {
  if (!value) return "";
  return value.substring(0, value.lastIndexOf("."));
}
/**
 * 해당 파일명 문자열의 확장자 반환
 * @param value 파일명 문자열
 */
export function getFileExtension(value: string) {
  if (!value) return "";
  return value.substring(value.lastIndexOf(".") + 1);
}
/**
 * C#의 string.Format과 동일한 기능을 하는 함수
 * @param formatString 포맷 문자열
 * @param args 포맷 문자열에 삽입할 값들
 * @returns 포맷된 문자열
 */
export function getFormatString(formatString: string, ...args: any[]): string {
  return formatString.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== "undefined" ? args[index] : match;
  });
}
/**
 * 해당 날짜 객체를 지정한 형식의 문자열로 반환
 * @param value 날짜 객체
 * @param formatString 날짜 형식 문자열
 */
export function getFormatDate(value: string | Date, formatString: string = "yyyy-MM-dd") {
  const date = typeof value === "string" ? new Date(value) : value;
  return format(date, formatString);
}
/**
 * 해당 파일 사이즈를 Bytes, KB, MB, GB 단위로 반환
 * @param value 파일 사이즈
 */
export function getFileSize(value: number) {
  if (value < 1024) {
    return value + "Bytes";
  } else if (value < 1024 * 1024) {
    return (value / 1024).toFixed(2) + "KB";
  } else if (value < 1024 * 1024 * 1024) {
    return (value / 1024 / 1024).toFixed(2) + "MB";
  } else {
    return (value / 1024 / 1024 / 1024).toFixed(2) + "GB";
  }
}
/**
 * 해당 객체를 깊은 병합
 * @param target 대상
 * @param source 소스
 * @returns
 */
export function deepMerge(target: any, source: any): any {
  for (const key in source) {
    if (source[key] && typeof source[key] === "object" && !Array.isArray(source[key])) {
      if (!target[key]) {
        target[key] = {};
      }
      deepMerge(target[key], source[key]);
    } else {
      // console.log("key", key, "target[key]", target[key], "source[key]", source[key]);
      target[key] = source[key];
    }
  }
  return target;
}
/**
 * 해당 객체를 깊은 병합 (대상 객체에 존재하는 병합)
 * @param target
 * @param source
 * @returns
 */
export function deepMergeExistingKeys(target: any, source: any): any {
  for (const key in target) {
    if (source[key]) {
      if (typeof source[key] === "object" && !Array.isArray(source[key])) {
        deepMergeExistingKeys(target[key], source[key]);
      } else {
        // console.log("key", key, "target[key]", target[key], "source[key]", source[key]);
        target[key] = source[key];
      }
    }
  }
  return target;
}
