<script setup lang="ts">
import { localize } from "@/utils";
import { onMounted, reactive, ref } from "vue";
const props = defineProps({
  message: { type: String as () => string }, // 메시지
  isConfirm: { type: Boolean, default: false }, // 확인 창 여부
  showCloseButton: { type: Boolean, default: true }, // 닫기 버튼 노출 여부
  noButton: { type: Boolean, default: false }, // 버튼 노출 여부
  okLabel: { type: String as () => string, default: "확인" }, // 확인 버튼 라벨
  cancelLabel: { type: String as () => string, default: "취소" }, // 취소 버튼 라벨
  show: { type: Boolean, default: false }, // 모달 노출 여부
  modalClass: { type: String as () => string }, // 모달 클래스
});
const emit = defineEmits<{
  (eventType: "close", result: boolean): void; // 아이템 선택 이벤트
}>();

const okButtonRef = ref<HTMLButtonElement | null>(null);

const state = reactive({
  show: props.show,
});

onMounted(() => {
  okButtonRef.value?.focus();
});

function open() {
  state.show = true;
}
function close(result: boolean) {
  state.show = false;
  emit("close", result);
}
defineExpose({
  open,
  close,
});
</script>

<template>
  <div v-if="state.show">
    <div :class="{ show: state.show, fade: state.show }" class="modal-backdrop"></div>
    <div
      :style="{ display: state.show ? 'block' : 'none' }"
      :class="{ show: state.show, fade: state.show }"
      class="modal"
      role="dialog"
    >
      <div class="modal-dialog modal-dialog-centered" :class="modalClass">
        <!-- 닫기 버튼 존재 -->
        <div class="modal-content">
          <!-- 닫기 버튼 -->
          <button v-if="showCloseButton" @click="close(false)" type="button" class="icon-close" data-dismiss="modal">
            <span class="sr-only">닫기</span>
          </button>
          <!-- //닫기 버튼 -->

          <!-- 내용 -->
          <div class="modal-body">
            <div v-if="message">
              <i class="icon-error"></i>
              <p class="fs-3 align-center">{{ message }}</p>
            </div>
            <slot></slot>
            <div class="btn-wrap" v-if="!noButton">
              <button v-if="isConfirm" @click="close(false)" type="button" class="btn btn-outline w-100">
                {{ localize(cancelLabel) }}
              </button>
              <button ref="okButtonRef" @click="close(true)" type="button" class="btn btn-primary w-100">
                {{ localize(okLabel) }}
              </button>
            </div>
          </div>
          <!-- //내용 -->
        </div>
        <!-- //닫기 버튼 존재 -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
