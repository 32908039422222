<script setup lang="ts">
import { onMounted, reactive } from "vue";
import { useRoute } from "vue-router";
import { useMemberStore } from "@/store";
import { localize } from "@/utils";
import router from "@/router";
import { IMenuItem, Role } from "@/models";
import ActionMenu from "@/components/ActionMenu.vue";

const { getMember } = useMemberStore();
const route = useRoute();

const props = defineProps({
  menuItems: { type: Array as () => IMenuItem[], default: [] },
  disabledMenu: { type: Boolean as () => boolean, default: false },
});
const emit = defineEmits<{
  (eventType: "change", item: (IMenuItem | undefined)[]): void; // 아이템 선택 이벤트
}>();

const member = getMember();
const path = route.path;
const pathSegments = path.split("/").slice(1);
const role = pathSegments[1] as String | null;
const menuItems = reactive(props.menuItems);
const depth1 = menuItems.find((item) => item.link === pathSegments[2]);
const depth2 = depth1?.children?.find((item) => item.link === pathSegments[3]);

/**
 * 역할 토글에 사용할 추가 역할 경로
 */
const rolePath = member.extraRole?.toLocaleLowerCase().replace("_", "");

const state = reactive({
  path: path,
  pathSegments: pathSegments,
  depth1: depth1,
  depth2: depth2,
  role: role,
  menuItems: props.menuItems,
  hideActionMenu: false,
});
emit("change", [state.depth1, state.depth2]);

// console.log('state.path', state.path);
// console.log('state.pathSegments', state.pathSegments);
// console.log('state.role', state.role);
onMounted(() => {
  checkHideActionMenu();
  router.beforeEach((to, from, next) => {
    state.path = to.path;
    state.pathSegments = to.path.split("/").slice(1);
    state.depth1 = state.menuItems.find((item) => item.link === state.pathSegments[2]);
    state.depth2 = state.depth1?.children?.find((item) => item.link === state.pathSegments[3]);
    console.log("router changed", state.depth1, state.depth2);
    checkHideActionMenu();
    emit("change", [state.depth1, state.depth2]);
    next();
  });
});

/**
 * 액션 메뉴 숨김 여부 확인
 */
function checkHideActionMenu(): void {
  const hideActionMenuItems = ["dashboard"];
  state.hideActionMenu = hideActionMenuItems.includes(state.depth1?.link ?? "");
}
/**
 * 역할 토글
 */
function toggleRole(): void {
  console.log("toggleRole", state.role);
  router.push(`/playground/${state.role === "player" ? rolePath : "player"}`);
}
/**
 * 메뉴 확장/축소 토글
 */
function toggleMenu(menu1: IMenuItem): void {
  console.log("toggleMenu", menu1.name);
  menu1.open = !menu1.open;
}
</script>

<template>
  <div id="wrap" :class="state.role === 'player' ? 'page-player' : 'page-planner'">
    <!-- 역할 스위치 -->
    <nav id="switch" v-if="member.extraRole">
      <a @click="toggleRole">PLAYER</a>
      <div class="switch-handle"></div>
      <a @click="toggleRole">{{ member.extraRole === Role.PLANNER ? "PLANNER" : "R2 CENTER" }}</a>
    </nav>
    <nav id="no-switch" v-else></nav>

    <!-- 좌측 메뉴 -->
    <nav id="sidebar" class="flex-layout" :class="{ dimmed: props.disabledMenu }">
      <div class="sidebar-hd flex-layout-hd">
        <!-- 사용자 정보 -->
        <div class="profile media">
          <div class="profile-thumb">
            <img :src="member.photo ?? ''" alt="" />
          </div>
          <div class="media-body">
            <div class="profile-name">
              <b>{{ member.name.fullName }}</b> 님
            </div>
            <div>{{ member.emailAddress }}</div>
          </div>
        </div>

        <!-- 주문하기 버튼 -->
        <router-link v-if="state.role === 'player'" to="/playground/player/order/request" class="btn btn-block btn-lg">
          {{ localize("주문하기") }}</router-link
        >
      </div>

      <!-- 메뉴 목록 -->
      <div class="sidebar-body flex-layout-body">
        <ul class="gnb">
          <!-- 메뉴 아이템 -->
          <li
            v-for="menu1 in state.menuItems"
            :class="{
              'gnb-collapse': menu1.children != undefined,
              on: menu1.open,
            }"
          >
            <!-- 1 뎁스 메뉴-->
            <a class="gnb-link" :class="{ active: menu1 == state.depth1 }">
              <router-link :to="`/playground/${state.role}/${menu1.link}`">
                <i class="icon" :class="menu1.icon"></i>{{ menu1.name }}
              </router-link>
              <i v-if="menu1.children != undefined" class="icon icon-toggle" @click="toggleMenu(menu1)"></i>
            </a>

            <ul v-if="menu1.children != undefined" class="gnb-depth">
              <li v-for="menu2 in menu1.children">
                <!-- 2 뎁스 메뉴-->
                <router-link
                  class="gnb-link"
                  :class="{ active: menu2 == state.depth2 }"
                  :to="`/playground/${state.role}/${menu1.link}/${menu2.link}`"
                >
                  <i class="icon" :class="menu2.icon" :title="menu2.name"></i>{{ menu2.name }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>

      <!-- 비활성화시 메시지 창 -->
      <div v-if="props.disabledMenu" class="dimmed-alert">
        <i class="icon icon-error-round"></i>
        <b class="d-block mt-2 fs-4 text-dark">서비스 등록을<br />먼저 완료해주세요!</b>
      </div>
      <!-- //비활성화시 메시지 창 -->
    </nav>
    <!-- //sidebar -->

    <!-- container -->
    <main id="content" class="flex-layout">
      <!-- <div class="content-bg" style="background-image: url('/src/assets/playground/img/bg1.png')"></div> -->

      <!-- 액션 메뉴 -->
      <action-menu v-if="!state.hideActionMenu"></action-menu>
      <!-- //액션 메뉴 -->

      <!-- 컨텐츠 -->
      <router-view></router-view>
      <!-- //컨텐츠 -->
    </main>
    <!-- //container -->

    <!-- footer -->

    <!-- //footer -->
  </div>
</template>

<style scoped>
#no-switch {
  width: 40px;
}
</style>
