<script setup lang="ts">
import { ref } from "vue";
import { useFormBuilder } from "@/services";
import { localize } from "@/utils";
import { Validators } from "@/utils";
import { onMounted } from "vue";

const props = defineProps<{
  completed: boolean;
}>();

const emit = defineEmits<{
  (eventType: "submit", password: string): void;
}>();

const { controls, errors, group, touch, validate, valid, invalid } = useFormBuilder();
group({
  password: {
    touched: false,
    isShow: false,
    value: "",
    validators: [Validators.required, Validators.minLength(8), Validators.password],
    dependencies: ["confirm"],
  },
  confirm: {
    touched: false,
    isShow: false,
    value: "",
    validators: [Validators.required, Validators.match(controls, "password")],
  },
});

onMounted(init);

/**
 * 초기화
 */
function init() {
  validate();
}
/**
 * 비밀번호 보이기 형식 토글
 * @param key
 */
function togglePassword(key: string) {
  controls[key].isShow = !controls[key].isShow;
}
/**
 * 완료 핸들러
 */
function done(event: Event): void {
  // console.log(errors, valid());
  validate(true);
  if (valid()) {
    emit("submit", controls.password.value);
  }
  event.preventDefault();
}
</script>

<template>
  <!-- 변경 입력-->
  <template v-if="!props.completed">
    <form @submit.prevent="done" class="join-form mh-0">
      <!-- 비밀번호 입력-->
      <div class="form-group">
        <label class="form-label" for="pw">{{ localize("비밀번호") }}</label>
        <div class="input-group">
          <input
            id="pw"
            class="form-control curved"
            :class="{
              'is-invalid': controls.password.touched && !valid('password'),
              'is-valid': valid('confirm'),
              active: controls.password.isShow,
            }"
            v-model="controls.password.value"
            @focusout="touch('password')"
            :type="controls.password.isShow ? 'text' : 'password'"
            :placeholder="localize('영문 대소문자, 숫자, 특수문자의 조합 10자리 이상 입력')"
          />
          <div class="visibility">
            <i
              class="icon"
              @click="togglePassword('password')"
              :class="controls.password.isShow ? 'icon-eye-open' : 'icon-eye-close'"
            ></i>
          </div>

          <!--    비밀번호 에러 메시지-->
          <div v-show="controls.password.touched && errors?.password?.required" class="invalid-feedback">
            * {{ localize("비밀번호를 입력하세요.") }}
          </div>
          <div
            v-show="controls.password.touched && (errors?.password?.minLength || errors?.password?.password)"
            class="invalid-feedback"
          >
            * {{ localize("영문 대소문자, 숫자, 특수문자 중 2종의 조합으로 8자리 이상 입력하세요.") }}
          </div>
          <!--    사용 가능한 비밀번호-->
          <div v-show="valid('password')" class="valid-feedback">* {{ localize("사용 가능한 비밀번호입니다.") }}</div>
        </div>
      </div>
      <!-- //비밀번호 입력-->

      <!-- 확인을 위한 비밀번호 입력-->
      <div class="form-group">
        <label class="form-label" for="pw-chk">{{ localize("비밀번호 확인") }}</label>
        <div class="input-group">
          <input
            id="pw-chk"
            class="form-control curved"
            :class="{
              'is-invalid': controls.confirm.touched && !valid('confirm'),
              'is-valid': valid('confirm'),
              active: controls.confirm.isShow,
            }"
            v-model="controls.confirm.value"
            @focusout="touch('confirm')"
            @keyup.enter="done"
            :type="controls.confirm.isShow ? 'text' : 'password'"
            :placeholder="localize('확인을 위한 비밀번호 재입력')"
          />
          <div class="visibility">
            <i
              class="icon"
              @click="togglePassword('confirm')"
              :class="controls.confirm.isShow ? 'icon-eye-open' : 'icon-eye-close'"
            ></i>
          </div>
          <!--    비밀번호 에러 메시지-->
          <div v-show="controls.confirm.touched && errors?.confirm?.required" class="invalid-feedback">
            * {{ localize("확인을 위한 비밀번호를 입력하세요.") }}
          </div>
          <div v-show="controls.confirm.touched && errors?.confirm?.match" class="invalid-feedback">
            * {{ localize("비밀번호와 일치하지 않습니다.") }}
          </div>
          <!--    사용 가능한 비밀번호-->
          <div v-show="valid('confirm')" class="valid-feedback">* {{ localize("비밀번호와 일치합니다.") }}</div>
        </div>
      </div>
      <!-- //확인을 위한 비밀번호 입력-->
    </form>
    <div class="btn-wrap">
      <a :class="{ disabled: invalid() }" href="#" @click="done" class="btn btn-primary center w-100">{{
        localize("비밀번호 변경")
      }}</a>
    </div>
  </template>
  <!-- //변경 입력-->

  <!-- 변경 완료-->
  <template v-else>
    <div class="welcome">
      <img src="@/assets/home/img/find-pw.png" alt="" class="mb-2" />
      <p class="fs-4 font-weight-bold">{{ localize("비밀번호 변경이 완료되었습니다.") }}</p>
    </div>
    <div class="btn-wrap">
      <router-link to="/home/login" class="btn btn-primary center w-100">{{ localize("로그인") }}</router-link>
    </div>
  </template>
  <!-- //변경 완료-->
</template>

<style scoped></style>
