<script setup lang="ts">

</script>

<template>
  <h1>SharpenSkills</h1>
  <router-view></router-view>
</template>

<style scoped>

</style>