<script setup lang="ts">
import { localize } from "@/utils";
import { useTroubleShootingStore } from "@/store";
import { onBeforeUnmount, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
// const data = route.params.data ? JSON.parse(route.params.data as string) : null;
const { data } = useTroubleShootingStore();
// console.log("data:", data);
const state: Record<string, any> = reactive({
  isDragging: false,
  fileItems: [] as File[],
  category: {
    name: "",
    description: "",
  },
  id: "",
  title: "",
  content: "",
  status: "",
  registerDate: "",
  attachements: null,
});

/**
 * 파일 드래그 오버 이벤트
 * @param e 드래그 이벤트
 */
function dragOver(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
  state.isDragging = true;
}
/**
 * 파일 드래그 아웃 이벤트
 * @param e 드래그 이벤트
 */
function dragLeave(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
  state.isDragging = false;
}
/**
 * 파일 드롭 이벤트
 * @param e 드래그 이벤트
 */
function drop(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
  const dt = e.dataTransfer;
  const files = dt?.files;
  if (files) {
    addFiles(files);
  }
}
/**
 * 파일 추가
 * @param files 파일 목록
 */
function addFiles(files: FileList | null) {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      state.fileItems.push(file);
    }
  }
}
/**
 * 파일 제거
 * @param index 파일 인덱스
 */
function removeFile(index: number) {
  state.fileItems.splice(index, 1);
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <router-link :to="{ name: 'trouble-shooting-list' }" class="btn-back"
          ><i class="icon icon-back">{{ localize("뒤로") }}</i></router-link
        >
        <h3 class="fs-1">{{ localize("문의 상세") }}</h3>
        <p v-html="localize('트러블슈팅 등록일 기준 N일 이내에<br/>답변해드립니다.')" />
        <img src="@/assets/playground/img/faq.png" alt="" />
      </div>
      <div class="grid-content">
        <div class="box box-shadow inquiry">
          <form>
            <div class="form-group row">
              <div class="col-auto form-label form-label-plain">{{ localize("문의 유형") }}<i class="dot"></i></div>
              <div class="col">
                <select class="form-control form-select">
                  <option value="">주문</option>
                </select>
              </div>
              <div class="col-auto form-label form-label-plain">{{ localize("연관 주문번호") }}<i class="dot"></i></div>
              <div class="col">
                <input type="tel" class="form-control" value="R-1234567890" disabled />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-auto form-label form-label-plain">{{ localize("문의 제목") }}<i class="dot"></i></div>
              <div class="col">
                <input
                  type="text"
                  :value="data.title"
                  class="form-control"
                  :placeholder="localize('문의 제목을 입력해주세요.')"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-auto form-label">{{ localize("문의 내용") }}<i class="dot"></i></div>
              <div class="col">
                <textarea class="form-control" rows="6" :placeholder="localize('문의 내용을 입력해주세요.')"></textarea>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-auto form-label">{{ localize("첨부파일") }}<i class="dot"></i></div>
              <div class="col">
                <label
                  class="file-box"
                  :style="{ 'background-color': state.isDragging ? '#f0f0f0' : '#434AEA0D' }"
                  @dragover.prevent="dragOver"
                  @dragleave.prevent="dragLeave"
                  @drop.prevent="drop"
                >
                  <input
                    ref="fileInput"
                    @change="(e) => addFiles((e.target as HTMLInputElement).files)"
                    type="file"
                    class="sr-only"
                  />
                  <i class="icon icon-add-box"></i>
                  <span>{{ localize("여기를 클릭해 첨부파일을 등록하세요. (최대 00MB까지 업로드 가능)") }}</span>
                </label>
                <ul class="file-item-wrap">
                  <li>
                    <div class="file-item">
                      <i class="icon icon-filetype"></i>
                      <a href="" class="file-item-name" download>첨부파일_01.docx(0.1mb)</a>
                      <i class="icon icon-file-delete" role="button"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="btn-wrap">
              <a @click="router.go(-1)" class="btn btn-outline btn-lg">{{ localize("돌아가기") }}</a>
              <button type="submit" class="btn btn-lg">{{ localize("수정하기") }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
