<script setup lang="ts">
import PlaygroundLayout from "@/views/playground/PlaygroundLayout.vue";
import { reactive } from "vue";

const menuItems = [
  { name: "Dashboard", icon: "icon-dash", link: "dashboard", open: false },
  {
    name: "Order Management",
    icon: "icon-order",
    link: "order",
    open: false,
    children: [
      { name: "Service Setting", icon: "icon-order-1", link: "service" },
      { name: "Ordered List", icon: "icon-order-2", link: "ordered" },
    ],
  },
  {
    name: "Business Management",
    icon: "icon-dash",
    link: "business",
    open: false,
    children: [
      { name: "Service Management", icon: "icon-manage-1", link: "service" },
      { name: "Customer Management", icon: "icon-manage-2", link: "customer" },
      { name: "Settlement Management", icon: "icon-manage-4", link: "settlement" },
      { name: "Review Management", icon: "icon-manage-5", link: "review" },
      { name: "Statistics", icon: "icon-manage-6", link: "statistics" },
    ],
  },
];

const state = reactive({
  // TODO: 백엔드연동 후 서비스 등록 정보 존재 여부에 따라 분기처리. store로 추출 고려.
  disabledMenu: false,
});
</script>

<template>
  <playground-layout :menu-items="menuItems" :disabled-menu="state.disabledMenu"></playground-layout>
</template>

<style scoped></style>
