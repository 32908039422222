<script setup lang="ts">
import { localize } from "@/utils";
import { reactive } from "vue";

const state = reactive({
  isLeave: false,
});
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="grid">
      <div class="grid-side">
        <div class="user-info box">
          <div class="fs-3">
            <p>{{ localize("어서오세요, 반가워요.") }}</p>
            <p><b class="font-poppins">Cheols</b> {{ localize("님") }}.</p>
          </div>
          <div class="user-detail">
            <div class="profile-thumb">
              <img src="@/assets/playground/img/_temp/profile2.jpg" alt="" />
            </div>
            <span class="badge bg-primary">Hospital</span>
            <p><b>밝은미소치과의원</b> {{ localize("님") }}</p>
            <div class="fs-sm text-muted mt-2">p1h2c3@naver.com</div>
          </div>
          <router-link to="member-setting">
            <button type="button" class="btn btn-md btn-block btn-white mb-3">{{ localize("회원 정보 설정") }}</button>
          </router-link>
          <router-link to="leave" class="fs-sm">
            <u>{{ localize("회원을 탈퇴하고 싶어요") }}</u>
          </router-link>
        </div>
      </div>
      <div class="grid-content">
        <div class="box box-shadow">
          <div class="fieldset">
            <div>
              <h2 class="fs-3 mb-1">Roles</h2>
              <p class="font-poppins fs-sm text-muted">What you have, <br />What you want to be</p>
            </div>
            <div class="support-link-wrap flex-fill">
              <a class="btn btn-outline support-link">
                <i class="icon icon-role-player"></i>
                <div class="flex-fill">
                  <b>Player</b>
                  <p class="flex-center text-success">
                    <i class="icon icon-check-circle"></i>{{ localize("활성화 됨") }}
                  </p>
                </div>
              </a>
              <a class="btn btn-outline support-link">
                <i class="icon icon-role-planner"></i>
                <div class="flex-fill">
                  <b>Planner</b>
                  <p class="flex-center text-primary">
                    {{ localize("Planner 되기") }}<i class="icon icon-arrow-primary"></i>
                  </p>
                </div>
              </a>
              <a class="btn btn-outline support-link">
                <i class="icon icon-role-r2center"></i>
                <div class="flex-fill">
                  <b>R2 Center</b>
                  <p class="flex-center text-primary">
                    {{ localize("등록 요청하기") }}<i class="icon icon-arrow-primary"></i>
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div class="fieldset">
            <div>
              <h2 class="fs-3 mb-1">Notification<br />Settings</h2>
              <p class="font-poppins fs-sm text-muted">Anything else?</p>
            </div>
            <div class="support-link-wrap flex-fill">
              <router-link to="alarm-setting" class="btn btn-outline support-link">
                <i class="icon icon-set-alarm"></i>
                <div class="flex-center text-dark">
                  <span>{{ localize("알림 설정") }}</span
                  ><i class="icon icon-arrow-black"></i>
                </div>
              </router-link>
            </div>
          </div>
          <div class="fieldset">
            <div>
              <h2 class="fs-3 mb-1">Login Account</h2>
              <p class="font-poppins fs-sm text-muted">Login Account setting</p>
            </div>
            <div class="support-link-wrap flex-fill">
              <router-link to="change-id" class="btn btn-outline support-link">
                <i class="icon icon-change-id"></i>
                <div class="flex-center text-dark">
                  <span>{{ localize("아이디 변경") }}</span
                  ><i class="icon icon-arrow-black"></i>
                </div>
              </router-link>
              <router-link to="change-password" class="btn btn-outline support-link">
                <i class="icon icon-change-pw"></i>
                <div class="flex-center text-dark">
                  <span>{{ localize("비밀번호 변경") }}</span
                  ><i class="icon icon-arrow-black"></i>
                </div>
              </router-link>

              <!-- 진행 사항 -->
              <div v-if="true" class="progress-box">
                <strong class="text-dark">{{ localize("현재 진행 중인 사항") }}</strong>
                <div class="box mt-2">
                  <div class="progress-box-info">
                    <p>{{ localize("ID 변경이 진행 중에 있습니다.") }}</p>
                    <div class="ml-auto">
                      <span class="text-muted mr-2">{{ localize("변경 요청 일자") }} 2024.00.00</span>
                      <div class="d-inline-block">
                        <span class="badge bg-primary text-white">{{ localize("관리자 확인중") }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex-center">
                    <div class="box bg-white flex-fill">
                      <b class="text-primary">From</b>
                      <div class="fs-sm mt-1">p1h2c3@naver.com</div>
                    </div>
                    <div class="circle-arrow"></div>
                    <div class="box bg-white flex-fill">
                      <b class="text-primary">To</b>
                      <div class="fs-sm mt-1">re.dev4@imegagen.com</div>
                    </div>
                  </div>
                </div>
                <div class="box mt-2">
                  <div class="progress-box-info">
                    <p class="text-danger">{{ localize("ID 변경이 승인 거절되었습니다.") }}</p>
                    <div class="ml-auto">
                      <span class="text-muted mr-2">{{ localize("변경 요청 일자") }} 2024.00.00</span>
                      <div class="d-inline-block">
                        <span class="badge bg-red text-white">{{ localize("승인 거절") }}</span>
                        <!-- TODO: 간격 이상-->
                        <a class="badge bg-dark text-white"
                          >{{ localize("사유 확인") }}<i class="icon-chevron-right"></i
                        ></a>
                      </div>
                    </div>
                  </div>
                  <div class="flex-center">
                    <div class="box bg-white flex-fill">
                      <b class="text-primary">From</b>
                      <div class="fs-sm mt-1">p1h2c3@naver.com</div>
                    </div>
                    <div class="circle-arrow"></div>
                    <div class="box bg-white flex-fill">
                      <b class="text-primary">To</b>
                      <div class="fs-sm mt-1">re.dev4@imegagen.com</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- //진행 사항 -->
            </div>
          </div>
        </div>

        <!-- 탈퇴 승인 -->
        <div v-if="state.isLeave" class="withdrawal-processed">
          <div class="withdrawal-processed-alarm">
            <i class="icon-error"></i>
            <p class="fs-3">{{ localize("회원 탈퇴 처리 중에 있습니다.") }}</p>
            <p class="mt-2 mb-3">{{ localize("회원 관련 설정을 더 이상 진행하실 수 없습니다.") }}</p>
          </div>
        </div>
        <!-- //탈퇴 승인 -->
      </div>
    </div>
  </div>
</template>

<style scoped></style>
