<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "vue-router";
import { PracticeQuizItem, useTestStore } from "@/store";
import { onBeforeMount, onUnmounted, reactive } from "vue";
import { getLocaleString, getFileNameOfPath } from "@/utils";
import router from "@/router";

const fileInput = ref();

const route = useRoute();
const store = useTestStore().practice;
const state = reactive<{
  index: number;
  quiz: PracticeQuizItem;
  remainTime: string;
}>({ index: 0, quiz: { question: "", fileItems: [] }, remainTime: "" });

// 시간 갱신용 타이머
let timer: ReturnType<typeof setInterval>;

onBeforeMount(init);
onUnmounted(dispose);

/**
 * 초기화
 */
function init() {
  setTimer();
  setQuiz(Number(route.params.index));
}
/**
 * 해제
 */
function dispose() {
  clearInterval(timer);
}
/**
 * 타이머 설정
 */
function setTimer() {
  clearInterval(timer);
  timer = setInterval(() => {
    // data.quiz.endTime을 기준으로 남은 시간을 "mm : ss" 단위로 초 마다 표시
    const now = new Date().getTime();
    const distance = store.endTime.getTime() - now;
    if (distance < 0) {
      // 탈락
      done();
    } else {
      // 시간 갱신
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      state.remainTime = `${minutes.toString().padStart(2, "0")} : ${seconds.toString().padStart(2, "0")}`;
    }
  }, 1000);
}
/**
 * 문제 화면 변경
 * @param offset
 */
function changeQuiz(offset: number) {
  const nextIndex = state.index + offset;
  if (nextIndex > store.quizItems.length) {
    done();
  } else if (nextIndex >= 0 && nextIndex <= store.quizItems.length) {
    router.replace(`/academy/certification/detail/${route.params.license}/qualifying-test/practice-test/${nextIndex}`);
    setQuiz(nextIndex);
  }
}
/**
 * 문제 설정
 * @param index
 */
function setQuiz(index: number) {
  state.index = index;
  state.quiz = store.quizItems[index - 1];
}
function dragOver(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
}
function dragEnter(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
}
function dragLeave(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
}
function drop(e: DragEvent) {
  e.preventDefault();
  e.stopPropagation();
  const dt = e.dataTransfer;
  const files = dt?.files;
  if (files) {
    addFiles(files);
  }
}
function addFiles(files: FileList | null) {
  if (files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      state.quiz.fileItems.push(file);
    }
  }
}
function removeFile(index: number) {
  state.quiz.fileItems.splice(index, 1);
}
/**
 * 해당 파일을 Base64로 인코딩 반환
 * @param file
 */
function getBase64(file: File) {
  /*      const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result as string;
          const base64Data = result.split(',')[1]; // Base64 데이터 추출
          // state.quiz.fileItems.push(base64Data);
        };
        reader.readAsDataURL(file); // 파일을 Base64로 인코딩*/
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
function uploadFile(base64Data: string) {
  // TODO: Base64 데이터를 서버로 업로드.
  // 여기에서 Base64 데이터를 서버로 업로드합니다.
  // axios 또는 fetch 등을 사용하여 서버로 업로드 요청을 보냅니다.
  // 서버에서는 Base64 데이터를 디코딩하여 파일로 저장할 수 있습니다.
  console.log(base64Data);
}
/**
 * 완료 핸들러
 */
function done() {
  for (let i = 0; i < store.quizItems.length; i++) {
    const quiz = store.quizItems[i];
    for (let j = 0; j < quiz.fileItems.length; j++) {
      const file = quiz.fileItems[j];
      getBase64(file).then((base64Data) => {
        // console.log(`name: ${file.name}, size: ${file.size}, type: ${file.type} data: ${base64Data}`);
        // uploadFile(base64Data);
        console.log(`name: ${file.name}, size: ${file.size}, type: ${file.type}`);
      });
    }
  }
  // TODO: 업로드 용량 제한 확인 필요 여부 및 제한 정책 확인
  // TODO: 파일 업로드
  clearInterval(timer);
  router.replace({
    name: "Result",
    params: {
      type: "practice",
    },
  });
}
</script>

<template>
  <h1>PracticeTest</h1>
  <p>route.params.license: {{ route.params.license }} / route.param.index: {{ state.index }}</p>
  <p>시험문항: {{ state.index }} / {{ store.quizItems.length }} 남은시간: {{ state.remainTime }}</p>
  <!--  문제 다운로드 화면-->
  <div v-if="state.index == 0">
    <p>문제 파일을 다운로드하고 실습 작업을 진행하세요</p>
    <p>File {{ getFileNameOfPath(store.downloadFile) }}</p>
    <a :href="store.downloadFile" :download="getFileNameOfPath(store.downloadFile)">Download</a>
  </div>
  <!--  시험문항-->
  <div v-else>
    <p>{{ state.quiz.question }}</p>
    <label for="file-input">파일 찾기</label>
    <input
      id="file-input"
      type="file"
      multiple
      ref="fileInput"
      @change="(e) => addFiles((e.target as HTMLInputElement).files)"
    />
    <div id="drop-area" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent="drop">
      <p v-show="state.quiz.fileItems.length == 0">Drag and Drop Here!!!</p>
      <div v-for="(item, index) in state.quiz.fileItems">
        <p>{{ item.name }} <button @click="removeFile(index)">X</button></p>
        <!--        <p>{{ toLocalString(item.size) }}bytes</p>-->
        <!--        <p>{{ item.type }}</p>-->
      </div>
    </div>
  </div>
  <!--  이전, 다음 버튼들-->
  <p>
    <button v-show="state.index > 0" @click="changeQuiz(-1)">Previous</button>
    <button v-if="state.index == store.quizItems.length" @click="changeQuiz(+1)">Submit</button>
    <button v-else @click="changeQuiz(+1)">Next</button>
  </p>
</template>

<style scoped>
#file-input {
  display: none;
}
#drop-area {
  width: 600px;
  height: 200px;
  border: 1px solid black;
}
</style>
