import { RouteRecordRaw } from "vue-router";
import certificationRoutes from "@/router/academy/certification";
import sharpenSkillsRoutes from "@/router/academy/sharpen-skills";
import myPageRoutes from "@/router/academy/my-page/my-page.ts";
import Courses from "@/views/academy/Courses.vue";
import Certification from "@/views/academy/certification/Certification.vue";
import SharpenSkills from "@/views/academy/sharpen-skills/SharpenSkills.vue";
import MyPage from "@/views/academy/my-page/MyPage.vue";
import { requireAuth } from "@/services";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/academy/courses",
  },
  {
    path: "courses",
    component: Courses,
  },
  {
    path: "certification",
    component: Certification,
    children: certificationRoutes,
  },
  {
    path: "sharpen-skills",
    component: SharpenSkills,
    meta: {
      level: 1,
    },
    children: sharpenSkillsRoutes,
    beforeEnter: requireAuth,
  },
  {
    path: "my-page",
    component: MyPage,
    meta: {
      level: 1,
    },
    children: myPageRoutes,
    beforeEnter: requireAuth,
  },
];

export default routes;
