import { NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import supportRoutes from "@/router/home/support";
import joinRoutes from "@/router/home/join";
import passwordRoutes from "@/router/home/password";
import About from "@/views/home/About.vue";
import Player from "@/views/home/playground/Player.vue";
import Planner from "@/views/home/playground/Planner.vue";
import R2Center from "@/views/home/playground/R2Center.vue";
import Join from "@/views/home/join/Join.vue";
import Login from "@/views/home/Login.vue";
import Dormant from "@/views/home/Dormant.vue";
import Password from "@/views/home/password/Password.vue";
import Solution from "@/views/home/Solution.vue";
import Support from "@/views/home/support/Support.vue";
import Profile from "@/views/home/Profile.vue";
import { requireAuth } from "@/services";

const routes: Array<RouteRecordRaw> = [
  // Main
  {
    path: "",
    redirect: "/home/about",
  },
  {
    path: "about",
    component: About,
  },
  // R2 Playground
  {
    path: "playground",
    redirect: "/home/playground/player",
  },
  {
    path: "playground/player",
    component: Player,
  },
  /*{
    path: "playground/planner",
    component: Planner,
  },
  {
    path: "playground/r2center",
    component: R2Center,
  },*/
  // R2 Solution
  {
    path: "solution",
    name: "home-r2-solution",
    component: Solution,
  },
  // Customer Support
  {
    path: "support",
    component: Support,
    children: supportRoutes,
  },
  // Auth
  {
    path: "login",
    component: Login,
  },
  {
    path: "join",
    component: Join,
    children: joinRoutes,
  },
  {
    path: "dormant",
    component: Dormant,
    meta: {
      requiredAuth: true,
      level: 1,
    },
    children: joinRoutes,
    beforeEnter: requireAuth,
  },
  {
    path: "profile",
    component: Profile,
    children: joinRoutes,
    meta: {
      requiredAuth: true,
      level: 1,
    },
    beforeEnter: requireAuth,
  },
  {
    path: "password",
    component: Password,
    children: passwordRoutes,
  },
];

export default routes;
