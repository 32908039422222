import { isRef, Ref } from "vue";
import { Controls } from "@/services/form-builder";

type ValidatorsError = { [key: string]: boolean };

export class Validators {
  static required(value: string | boolean): ValidatorsError | null {
    return value ? null : { required: true };
  }
  static minLength(length: number) {
    return (value: string): ValidatorsError | null => {
      return value.length >= length ? null : { minLength: true };
    };
  }
  static maxLength(length: number) {
    return (value: string): ValidatorsError | null => {
      return value.length <= length ? null : { maxLength: true };
    };
  }
  static email(value: string): ValidatorsError | null {
    const pattern = /^[^@]+@[a-zA-Z0-9.]+$/;
    return pattern.test(value) ? null : { email: true };
  }
  static password(value: string): ValidatorsError | null {
    let count = Number(/[a-zA-Z]/.test(value)) + Number(/\d/.test(value)) + Number(/[\W_]/.test(value));
    return count >= 2 ? null : { password: true };
  }
  static match(controls: Controls, key: string) {
    return (value: string): ValidatorsError | null => {
      return value == controls[key].value ? null : { match: true };
    };
  }
  static expired(target: Ref<Date | undefined> | (Date | undefined)) {
    return (value: string): ValidatorsError | null => {
      const newTarget = isRef(target) ? target.value : target;
      const now = new Date();
      // console.log('target: ', newTarget, now, !newTarget || now < newTarget);
      return !newTarget || now < newTarget ? null : { expired: true };
    };
  }
}
