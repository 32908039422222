<script setup lang="ts">
import { useRoute } from "vue-router";
import { computed } from "vue";
import router from "@/router";
import { getFileNameOfPath } from "@/utils";
import { reactive } from "vue";
import { confirm } from "@/services";

const route = useRoute();
const isShowTest = computed(() => {
  return route.name !== "Courses";
});

const state = reactive({
  downloadFile: "/public/language.csv",
});

function startTest() {
  router.push(`/academy/certification/detail/${route.params.license}/courses/home`);
}
async function close() {
  let message = "시험을 종료하시겠습니까?";
  if (route.name == "WrittenTest" || route.name == "PracticeTest") {
    message = "응시 중인 시험을 종료하시겠습니까?\n답안 미제출 상태로 종료하시면 불합격(실격) 처리됩니다.";
  } else if (route.name == "Checking") {
    message = "시험을 종료하시겠습니까?\n채점 결과는 추후에 확인하실 수 있습니다.";
  }
  if (await confirm(message)) {
    router.push(`/academy/certification/detail/${route.params.license}/qualifying-test`);
  }
}
</script>

<template>
  <h1>Courses</h1>
  <button @click="startTest">Start Course</button>
  <!--  강의 목록-->
  <div>
    <p>강의 목록</p>
  </div>
  <div>
    <p>강의 자료</p>
    <div>
      <p>File {{ getFileNameOfPath(state.downloadFile) }}</p>
      <a :href="state.downloadFile" :download="getFileNameOfPath(state.downloadFile)">Download</a>
    </div>
  </div>
  <div v-if="isShowTest" class="shadow">
    <div class="window">
      <div class="title-bar">
        <button @click="close">close</button>
      </div>
      <!--      TODO: Youtube 영상을 팝업으로 삽입. 확인 필요-->
      <router-view></router-view>
    </div>
  </div>
</template>

<style scoped></style>
