import { RouteRecordRaw } from "vue-router";
import Localization from "@/views/admin/Localization.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/admin/localization",
  },
  {
    path: "localization",
    component: Localization,
  },
];

export default routes;
