import {RouteRecordRaw} from "vue-router";
import Faq from "@/views/home/support/Faq.vue";
import Inquiry from "@/views/home/support/Inquiry.vue";
import InquirySave from "@/views/home/support/InquirySave.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/home/support/faq",
  },
  {
    path: "faq",
    component: Faq,
  },
  // {
  //   path: "inquiry",
  //   component: Inquiry,
  // },
  {
    path: "inquiry-save",
    component: InquirySave,
  },

];

export default routes;