<script setup lang="ts">
import { getFormatDate, localize } from "@/utils";
import TabMenu from "@/components/TabMenu.vue";
import { IBaseMenuItem, IPeriodItem } from "@/models";
import { reactive } from "vue";
import Pagenation from "@/components/Pagenation.vue";
import DatePeriod from "@/components/DatePeriod.vue";
import { subMonths, subYears } from "date-fns";

const props = defineProps({
  menuItems: { type: Array as () => IBaseMenuItem[], default: [] },
  periodItems: { type: Array as () => IPeriodItem[], default: [] },
});

const state = reactive({
  page: 1,
  totalRows: 3,
  items: ["충전", "충전", "충전"],
  periodOption: 1,
});

function changePage(page: number): void {
  console.log("changePage", page);
  state.page = page;
}
function changePeriodOption(event: Event): void {
  if (state.periodOption > 0) {
    const item = props.periodItems.find((item) => item.value == state.periodOption)!;
    console.log("changePeriodOption", `${item?.period?.start} ~ ${item?.period?.end}`);
  }
}
function changePeriodDirect(start: string, end: string): void {
  console.log("changePeriod", `${start} ~ ${end}`);
}
</script>

<template>
  <div class="grid-content">
    <div class="box box-shadow">
      <div class="tb-hd">
        <ul class="nav nav-tabs fs-3 mr-2 text-nowrap">
          <tab-menu :items="menuItems" />
        </ul>
        <div class="flex-center">
          <select class="form-control form-select mr-3" v-model="state.periodOption" @change="changePeriodOption">
            <option v-for="option in periodItems" :value="option.value">{{ option.key }}</option>
          </select>
          <date-period class="flex-center" v-if="state.periodOption === 0" @change="changePeriodDirect" />
        </div>
      </div>
      <div class="content">
        <table class="tb">
          <colgroup>
            <col />
            <col style="width: 13%" span="4" />
            <col style="width: 10%" />
          </colgroup>
          <thead>
            <tr>
              <th>{{ localize("내용") }}</th>
              <th>{{ localize("수량") }}</th>
              <th>{{ localize("금액") }}</th>
              <th>{{ localize("충전 포인트") }}</th>
              <th>{{ localize("결재일") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in state.items">
              <td>
                <strong class="tb-title">{{ item }}</strong>
              </td>
              <td>3</td>
              <td>18$</td>
              <td>18P</td>
              <td>2024.02.14</td>
              <td>
                <button class="icon icon-receipt">{{ localize("영수증") }}</button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagenation :total-rows="state.totalRows" :page="state.page" @change="changePage" :rows-size="1" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
