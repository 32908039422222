// TODO: 현재 접수, 처리중, 완료만 존재함. 확인 후 적용 필요
export enum TroubleshootingProgressStatus {
  Open, // 접수중
  InProgress, // 접수됨
  Resolved, // 완료됨(해결됨)
  Closed, // 완료됨(닫힘)
}
export enum ScanPurposeType {
  HealthReport
}
export enum ProfileNameType {
  Name,
  NickName,
}
export enum EditMode {
  View = 'view',
  Edit = 'edit',
}