import { RouteRecordRaw } from "vue-router";
import List from "@/views/playground/player/partner/planner/List.vue";
import About from "@/views/playground/player/partner/planner/About.vue";
import Review from "@/views/playground/player/partner/planner/Review.vue";
import Service from "@/views/playground/player/partner/planner/Service.vue";
import MyPlanner from "@/views/playground/player/partner/planner/MyPlanner.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: (to) => `/playground/player/partner/planner/list`,
  },
  {
    path: "list",
    component: List,
  },
  {
    path: ":partnerId/about",
    component: About,
  },
  {
    path: ":partnerId/review",
    component: Review,
  },
  {
    path: ":partnerId/service",
    component: Service,
  },
  {
    path: ":partnerId/my",
    component: MyPlanner,
  },
];

export default routes;
