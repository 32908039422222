<script setup lang="ts">
import { localize } from "@/utils";

// localize("완료된 주문 집계")
// localize("수익 정산 집계")
// localize("정산 내역")
// localize("정산 예정 주문 목록")
// localize("정산 설정")
const menuItems = [
  { name: "완료된 주문 집계", link: "completed" },
  {
    name: "수익 정산 집계",
    link: "revenue",
    children: [
      { name: "정산 내역", link: "history" },
      { name: "정산 예정 주문 목록", link: "order-list" },
    ],
  },
  { name: "정산 설정", link: "settings" },
];
</script>

<template>
  <h1 class="hd-title">Settlement Management</h1>
  <router-view :menu-items="menuItems"></router-view>
</template>

<style scoped></style>
