import { defineStore } from "pinia";
import { reactive } from "vue";
import { Member, Sex, Role, MemberStatus } from "@/models";
import { deepMerge } from "@/utils";
import { useSessionStore } from "./session";

export const useMemberStore = defineStore("member", () => {
  /**
   * 세션스토리지 사용
   */
  const sessionManager = useSessionStore();
  /**
   * 확장된 사용자 정보 기본값
   */
  const _extendedMember = {
    currentRole: Role.PLAYER,
    extraRole: null,
    id: null,
    loginId: "",
    name: {
      firstName: "",
      lastName: "",
      fullName: "",
    },
    roles: [],
    sex: Sex.MALE,
    country: {
      code: "",
      name: "",
    },
    address: {
      postalCode: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
    },
    emailAddress: "",
    phoneNumber: {
      number: "",
      countryCode: "",
    },
    birthDate: new Date(),
    registeredAt: new Date(),
    withdrawnAt: new Date(),
    status: MemberStatus.JOINED,
    office: {
      id: "",
      name: "",
      phoneNumber: {
        number: "",
        countryCode: "",
      },
      address: {
        postalCode: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
      },
      emailAddress: "",
    },
  };

  /**
   * 확장된 사용자 정보
   */
  const extendedMember = reactive<ExtendedMember>({ ..._extendedMember });

  /**
   * 사용자 정보 설정
   * @param member 사용자 정보
   */
  function setMember(member: Member): void {
    deepMerge(extendedMember, member);
    // 사용자 추가 역할 설정. Role.PLANNER 또는 Role.R2_CENTER
    const roles = member.roles.filter((role) => role != Role.PLAYER);
    extendedMember.extraRole = roles.length > 0 ? roles[0] : null;
    // TODO: 테스트 후 삭제
    extendedMember.extraRole = Role.R2_CENTER;
    // 세션스토리지에 사용자 정보 저장
    sessionManager.setMember(extendedMember);
    // console.log("setMember", extendedMember);
  }
  /**
   * 사용자 정보 조회
   * @returns 사용자 정보
   */
  function getMember(): ExtendedMember {
    return extendedMember;
  }
  /**
   * 사용자 정보 삭제
   */
  function removeMember(): void {
    initMember();
    // console.log("removeMember", extendedMember);
    // 세션스토리지에 사용자 정보 삭제
    sessionManager.removeMember();
  }

  /**
   * 초기화
   */
  function initMember(): void {
    // console.log("before - id", extendedMember.id);
    deepMerge(extendedMember, _extendedMember);
    // console.log("after - id", extendedMember.id);
  }

  return { setMember, getMember, removeMember, initMember };
});

/**
 * 사용자 정보 확장 인터페이스
 * playground에서 사용자 상태를 활용 하기 위해 사용자 정보를 확장함.
 */
export interface ExtendedMember extends Member {
  /**
   * 추가로 설정된 사용자 역할. Role.PLANNER 또는 Role.R2_CENTER
   */
  extraRole: Role | null;
  /**
   * TODO: 사용자 사진. 추후에는 Member에 추가 하고, 제거 해야 함.
   */
  photo?: string;
}
