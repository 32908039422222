<script setup lang="ts">
import { getFormatDate, getLocaleString, localize } from "@/utils";
import { IListState } from "@/models/i-list-state.ts";
import { reactive } from "vue";
import SearchKeyword from "@/components/SearchKeyword.vue";
import Pagenation from "@/components/Pagenation.vue";
interface ICustomer {
  id: string;
  name: string;
  hospital: string;
  recentOrderDate: string;
  phoneNumber: string;
  email: string;
  image: string;
  // 확장 속성
  open: boolean;
  detail: ICustomerDetail | null;
}
interface ICustomerDetail {
  id: string;
  nationality: string;
  contact: string;
  email: string;
  address: string;
  order: {
    count: number;
    mostOrderedItem: string;
    totalAmount: number;
    unpaidAmount: number;
  };
}
const state: IListState = reactive({
  page: 1,
  totalRows: 0,
  keyword: "",
  items: <ICustomer[]>[
    {
      id: "1",
      name: "Hyeo Cheol Park",
      hospital: "Eonju Dental Clinic",
      recentOrderDate: "2024-09-01T12:39:00Z",
      phoneNumber: "+82-10-1234-1234",
      email: "P1h2c3@naver.com",
      image: "/src/assets/playground/img/tb-thumnail.png",
      // 확장 속성
      open: false,
      detail: null,
    },
    {
      id: "2",
      name: "Hyeo Cheol Park",
      hospital: "Eonju Dental Clinic1",
      recentOrderDate: "2022-11-01T01:09:00Z",
      phoneNumber: "+82-10-1111-2222",
      email: "test1@naver.com",
      image: "/src/assets/playground/img/tb-thumnail.png",
      // 확장 속성
      open: false,
      detail: null,
    },
  ],
});

request();

/**
 * 검색 조건 초기화
 */
function resetForm(): void {
  request();
}
/**
 * 목록 요청
 * @param page 페이지
 */
function request(page: number = 1): void {
  console.log("request", state.keyword, page);
}
/**
 * 상세 정보 요청
 */
function requestDetail(item: ICustomer): void {
  console.log("requestDetail - id:", item.id);
  // TODO: 상세 정보 요청 구현
  setTimeout(() => {
    // 상세 정보 설정
    item.detail = {
      id: "1",
      nationality: "Republic of Korea",
      contact: "+82-10-1234-1234",
      email: "dami_moon@eonju.co.kr",
      address: "서울특별시 강동 구 길동 410-10 강동성심빌리지 1000호",
      order: {
        count: 40,
        mostOrderedItem: "R2 가이드 솔루션",
        totalAmount: 459,
        unpaidAmount: 35,
      },
    };
  }, 2 * 1000);
}
/**
 * 상세 정보 토글
 * @param item 해당 아이템
 */
function toggleItem(item: ICustomer): void {
  console.log("toggleItem", item);
  item.open = !item.open;
  if (item.open && item.detail == null) {
    requestDetail(item);
  }
}
</script>

<template>
  <div id="ct" class="flex-layout-body">
    <div class="box">
      <form @submit.prevent="request()" @reset.prevent="resetForm">
        <div class="search">
          <search-keyword
            :show-reset="true"
            :state="state"
            :placeholder="localize('이름, 치과명, 전화번호, 이메일로 검색해보세요')"
          />
        </div>
        <div class="row form-row">
          <div class="flex-center">
            <div class="form-label">국가별</div>
            <select class="form-control form-select w2x">
              <option value="">Republic of Korea</option>
            </select>
          </div>
          <div class="col-auto flex-center">
            <div class="form-label">정렬</div>
            <div class="btn-check-wrap">
              <label class="btn-check">
                <input type="radio" name="c1" class="sr-only" checked />
                <span class="btn btn-outline-primary">이름 순</span>
              </label>
              <label class="btn-check">
                <input type="radio" name="c1" class="sr-only" />
                <span class="btn btn-outline-primary">최근 주문 순</span>
              </label>
              <label class="btn-check -last">
                <input type="radio" name="c1" class="sr-only" />
                <span class="btn btn-outline-primary">미수금</span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
    <table class="tb tb-space">
      <colgroup></colgroup>
      <thead>
        <tr>
          <th>이름</th>
          <th>병원 (클리닉)</th>
          <th>최근 주문 일자</th>
          <th>전화번호</th>
          <th>이메일</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in state.items">
          <tr class="tb-link-item" :class="{ active: item.open }">
            <td class="tb-prof flex-center justify-content-center"><img :src="item.image" alt="" />{{ item.name }}</td>
            <td>{{ item.hospital }}</td>
            <td>
              {{ getFormatDate(item.recentOrderDate, "yyyy.MM.dd")
              }}<span class="time">{{ getFormatDate(item.recentOrderDate, "hh:mm") }}</span>
            </td>
            <td>{{ item.phoneNumber }}</td>
            <td>{{ item.email }}</td>
            <td @click="toggleItem(item)"><i class="icon icon-drop"></i></td>
          </tr>
          <tr class="table-collapse">
            <td colspan="6">
              <!-- Loading -->
              <div v-if="!item.detail" class="text-center">
                <div class="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <div class="form-text">고객 정보를 불러오는 중입니다.</div>
              </div>
              <!-- //Loading -->

              <!-- 상세 정보 -->
              <div v-else class="order-list-wrap">
                <div>
                  <strong>주문자 정보</strong>
                  <ul>
                    <li class="sub-label"><span>국적</span>{{ item.detail.nationality }}</li>
                    <li class="sub-label flex-center">
                      <span>연락처</span>
                      <ul class="list-icon">
                        <li><i class="icon icon-tel"></i>{{ item.detail.contact }}</li>
                        <li><i class="icon icon-mail"></i>{{ item.detail.email }}</li>
                        <li><i class="icon icon-message"></i>채팅 (온라인)</li>
                      </ul>
                    </li>
                    <li class="sub-label"><span>주소</span>{{ item.detail.address }}</li>
                  </ul>
                </div>
                <div>
                  <strong class="sub-label">주문 정보</strong>
                  <ul class="order-list">
                    <li>
                      주문 회수<span class="num">{{ getLocaleString(item.detail.order.count.toLocaleString()) }}</span>
                    </li>
                    <li>
                      가장 많이<br />주문한 품목<span>{{ item.detail.order.mostOrderedItem }}</span>
                    </li>
                    <li>
                      전체 주문 금액<span
                        ><span class="num">{{ getLocaleString(item.detail.order.totalAmount) }}</span
                        >$<i class="icon icon-plus"></i
                      ></span>
                    </li>
                    <li>
                      미수금<span
                        ><span class="num">{{ getLocaleString(item.detail.order.unpaidAmount) }}</span
                        >P<i class="icon icon-plus"></i
                      ></span>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- //상세 정보 -->
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <pagenation class="paging planner" :total-rows="state.totalRows" :page="state.page" @change="request" />
  </div>
</template>

<style scoped></style>
