<script setup lang="ts">
import { onMounted, onUnmounted, PropType, reactive, ref, watch } from "vue";
import { localize } from "@/utils";

import { IOptionItem } from "@/models";

const props = defineProps({
  items: { type: Array as PropType<IOptionItem[]>, default: [] }, // 전체 아이템 목록
  selectedItem: { type: Object as PropType<IOptionItem> }, // 선택된 아이템
  rows: { type: Number, default: 5 }, // 표시할 아이템 수
});
const emit = defineEmits<{
  (eventType: "change", item: IOptionItem): void; // 아이템 선택 이벤트
}>();

/**
 * 컴포넌트 참조
 */
const thisRef = ref();
/**
 * 검색어 입력창
 */
const keywordRef = ref();

const state = reactive({
  selectedItem: <IOptionItem>props.selectedItem,
  keyword: "",
  isShow: false,
  filteredItems: [] as IOptionItem[],
});

onMounted(init);
onUnmounted(dispose);

/**
 * 레이블 클릭 시 검색 UI를 표시하고, 검색어 입력창에 포커스
 */
function focusInLabel() {
  state.isShow = true;
  keywordRef.value.focus();
  search();
}
/**
 * 검색어로 아이템 필터링
 * 아이템을 소문자로 변환하여 검색어가 포함되는지 확인
 */
function search(event?: Event) {
  if (event) {
    state.keyword = (event.target as HTMLInputElement).value;
  }
  // console.log("keyword: " + state.keyword);
  if (state.keyword == "") {
    state.filteredItems = props.items ?? [];
  } else {
    state.filteredItems = props.items.filter((item) => item.label.toLowerCase().includes(state.keyword.toLowerCase()));
  }
  // console.log(state.filteredItems.length);
}
/**
 * 아이템 선택
 */
function selectItem(item: IOptionItem) {
  state.selectedItem = item;
  emit("change", state.selectedItem);
  // state.keyword = '';
  // state.filteredItems = [];
  state.isShow = false;
}
/**
 * 클릭 이벤트가 컴퍼넌트 영역 밖으로 나갔는지 확인
 * 영역 밖이면 리스트를 숨김
 * @param e
 */
function checkEventArea(e: MouseEvent) {
  if (e.target !== thisRef.value && !thisRef.value.contains(e.target)) {
    state.isShow = false;
    if (state.filteredItems.length > 0) {
      state.filteredItems = [];
    }
  }
}
/**
 * 초기화
 */
function init() {
  window.addEventListener("click", checkEventArea);
  // console.log(JSON.stringify(mapData.sort((a, b) => a.key.localeCompare(b.key))));
}
/**
 * 해제
 */
function dispose() {
  window.removeEventListener("click", checkEventArea);
}
</script>

<template>
  <div ref="thisRef" class="search-select w-100">
    <div class="selected-label" @click="focusInLabel">{{ state.selectedItem?.label }}</div>
    <div
      v-show="state.isShow"
      class="dropdown-container search-control flex-column bg-none shadow-none pt-2 p-0 overflow-visible"
    >
      <!-- TODO: 위/아래/엔터 핸들러 필요 -->
      <input
        ref="keywordRef"
        class="search-input w-100 mb-2"
        :value="state.keyword"
        @input="search"
        :placeholder="`${localize('검색')}...`"
      />
      <ul class="form-control form-select">
        <li v-for="(item, index) in state.filteredItems" :key="index" @click="selectItem(item)">{{ item.label }}</li>
      </ul>
    </div>
  </div>
</template>

<style scoped></style>
